import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllUsers = () => {
  return AxiosAuth.get(Config.API + "/api/users/getAllUsers", undefined, undefined);
};

const loadUsersForPrimaryContacts = () => {
  return AxiosAuth.get(Config.API + "/api/users/getUsers", undefined, undefined);
};

const loadUserById = (id) => {
  return AxiosAuth.get(Config.API + "/api/users/getUserById/" + id, undefined, undefined);
};

const loadCurrentLoggedInUser = () => {
  return AxiosAuth.get(Config.API + "/api/users/getLoggedInUser", undefined, undefined);
};

const addUser = (data) => {
  return AxiosAuth.post(Config.API + "/api/users/addUser", JSON.stringify(data), undefined);
};

const updateUser = (id, data) => {
  return AxiosAuth.put(Config.API + "/api/users/editUser/"+id, JSON.stringify(data), undefined);
};

export default {
  loadAllUsers,
  loadUserById,
  loadCurrentLoggedInUser,
  addUser,
  updateUser,
  loadUsersForPrimaryContacts
};
