import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { closeUpdateDmaRegionModal, updateDisplayLine, updateVideoLine } from "../../../redux/actions/line-actions";

const { Option } = Select;

const EditDmaRegion = ({ lineType, selectedDmaRegionList, dmaRegions, isUpdateDmaRegionModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [result, setResult] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        const selectValuesTemp = [];
        if (selectedDmaRegionList && selectedDmaRegionList.length > 0) {
            selectedDmaRegionList.filter(function (obj) {
                selectValuesTemp.push(obj.name);
            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("EditDmaRegion Unmounting");
        };
    }, [selectedDmaRegionList, isUpdateDmaRegionModalOpen, dmaRegions]);

    const handleSave = (values) => {
        let selectedRegions = [];
        if (values) {
            values.dmaRegions.map((value) => 
            dmaRegions &&
                dmaRegions.map((dmaOption) =>
                    dmaOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
                        ? selectedRegions.push({name: dmaOption.name, code: dmaOption.code})
                        : ""
                )
            );
        }

        values["dmaRegions"] = JSON.stringify(selectedRegions);
        if (lineType === "display") {
            dispatch(updateDisplayLine(id, values));
        } else {
            dispatch(updateVideoLine(id, values));
        }
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateDmaRegionModal());
    };

    const handleCancel = () => {
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateDmaRegionModal());
    };

    const handleSearch = (value) => {
        let res = [];
        if (value) {
            dmaRegions &&
                dmaRegions.map((dmaOption) =>
                    dmaOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
                        ? res.push(dmaOption)
                        : ""
                );
        }
        setResult(res);
    };

    const onChange = (value) => {
        console.log(value)
    };

    return (
        <>
            <Modal
                title="Dma Targeting"
                visible={isUpdateDmaRegionModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form form={form} layout={"vertical"} onFinish={handleSave}>
                    <Form.Item label="Add Dma Targeting" name="dmaRegions">
                        <Select
                            mode="multiple"
                            dropdownStyle={{ display: result.length > 0 ? "" : "none" }}
                            onSearch={handleSearch}
                            onChange={onChange}
                            key={selectedValues}
                            defaultValue={selectedValues}
                        >
                            {result.map((dmaOption) => (
                                <Option key={dmaOption.name}>{dmaOption.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    return {
        dmaRegions: state.line.dmaRegions,
        isUpdateDmaRegionModalOpen: state.line.isUpdateDmaRegionModalOpen,
        selectedDmaRegionList: state.line.selectedDmaRegionList,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditDmaRegion);
