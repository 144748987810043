import React, { useState } from "react";
import { connect } from "react-redux";

import { List, Row, Col, Card, Typography, Checkbox, Select, Divider } from "antd";
import { compose } from "redux";
import {
  desktopOptions,
  mobileOptions,
  pixelOptions,
  displayTagOptions,
  displayTagOptionQueryParms,
} from "../../constants/defaultValues";
import Config from "../../config";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";
const { Option } = Select;
const { Title, Paragraph } = Typography;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const SiteBuyTags = ({ siteBuy, siteBuyType }) => {
  const [queryParms, setQueryParms] = useState(displayTagOptionQueryParms["DFP"]);
  const [desktopValues, setDesktopValues] = useState([]);
  const [mobileValues, setMobileValues] = useState([]);
  const [pixelValues, setPixelValues] = useState([]);
  const [scriptList, setScriptList] = useState([]);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  function onChangeDesktopOptions(checkedValues) {
    setDesktopValues(checkedValues);
    setScriptList([...checkedValues, ...mobileValues, ...pixelValues]);
  }
  function onChangeMobileOptions(checkedValues) {
    setMobileValues(checkedValues);
    setScriptList([...desktopValues, ...checkedValues, ...pixelValues]);
  }
  function onChangePixelOptions(checkedValues) {
    setPixelValues(checkedValues);
    setScriptList([...desktopValues, ...mobileValues, ...checkedValues]);
  }
  function handleSelctChange(value) {
    setQueryParms(displayTagOptionQueryParms[value]);
    setDesktopValues([]);
    setMobileValues([]);
    setPixelValues([]);
    setScriptList([]);
  }

  return (
    <>
      {siteBuy && (
        <Row gutter={16}>
          <Col span={24}>
            <Card title="Tags">
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Select defaultValue="DFP" style={{ width: 120 }} onChange={handleSelctChange}>
                  {displayTagOptions.map((option) => (
                    currentUserPermissionMap[currentUserRole]["canUpdateSiteBuyTag"] ? (
                      <Option key={option}>{option}</Option>
                    ) : (
                      ""
                    )
                  ))
                  }
                </Select>
              </Card.Grid>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Title level={4}>Desktop</Title>
                <Checkbox.Group
                  options={desktopOptions}
                  value={desktopValues}
                  onChange={onChangeDesktopOptions}
                />
              </Card.Grid>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Title level={4}>Mobile</Title>
                <Checkbox.Group
                  options={mobileOptions}
                  value={mobileValues}
                  onChange={onChangeMobileOptions}
                />
              </Card.Grid>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Title level={4}>Pixel</Title>
                <Checkbox.Group
                  options={pixelOptions}
                  value={pixelValues}
                  onChange={onChangePixelOptions}
                />
              </Card.Grid>

              <List
                className="space-sec-15"
                dataSource={scriptList}
                renderItem={(item) => {
                  let itemArr = item.split("x");
                  return (
                    <>
                      <List.Item style={{ display: "block" }}>
                        <Paragraph
                          copyable={{ tooltips: false }}
                        >{`<script src="${Config.AdUrl}tag/${siteBuy.displaySiteBuyId}.js?w=${itemArr[0]}&h=${itemArr[1]}"></script>`}</Paragraph>
                        <Paragraph
                          copyable={{ tooltips: false }}
                        >{`<iframe src="${Config.AdUrl}iframe/${siteBuy.displaySiteBuyId}.js?w=${itemArr[0]}&h=${itemArr[1]}" width=${itemArr[0]} height=${itemArr[1]}></iframe>`}</Paragraph>
                      </List.Item>
                      <Divider />
                    </>
                    // <List.Item>
                    //   <Paragraph
                    //     copyable={{ tooltips: false }}
                    //   >{`<script src="${Config.AdUrl}tag/${siteBuy.displaySiteBuyId}.js?w=${itemArr[0]}&h=${itemArr[1]}"></script>`}</Paragraph>
                    // </List.Item>
                  );
                }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteBuyTags);
