import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import userService from "../app/services/user-service";
import UnauthorizedPageError from "./pages/error/403";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const currentUser = userService.getCurrentUser();
  const currentRole = currentUser && currentUser.roles[0];

  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem(`authToken`) && currentRole && roles ? (
          roles.indexOf(currentRole) !== -1 ? (
            <Component {...props} />
          ) : (
            <UnauthorizedPageError {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, null, null, { pure: false })(PrivateRoute));
