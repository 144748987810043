import { Types } from "../constants/private-buy-types";
const initialState = {
  displayPrivateBuyList: [],
  videoPrivateBuyList: [],
  isAddPrivateBuyModalOpen: false,
  displayBuyAdded: undefined,
  vedioBuyAdded: undefined,
  displayBuys: [],
  videoBuys: [],
  editDisplayPrivateBuy: undefined,
  editVideoPrivateBuy: undefined,
  displayBuyUpdated: undefined,
  videoBuyUpdated: undefined,
  privateBuyReport: undefined,
  privateBuyOverviewData: undefined
};
export default function privateBuyReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DISPLAY_PRIVATE_BUY_LIST:
      return {
        ...state,
        displayPrivateBuyList: action.payload,
      };
    case Types.GET_VIDEO_PRIVATE_BUY_LIST:
      return {
        ...state,
        videoPrivateBuyList: action.payload,
      };
    case Types.OPEN_ADD_PRIVATE_BUY_MODAL:
      return {
        ...state,
        isAddPrivateBuyModalOpen: action.payload,
      };
    case Types.ADD_DISPLAY_PRIVATE_BUY:
      return {
        ...state,
        displayBuyAdded: action.payload,
      };
    case Types.ADD_VIDEO_PRIVATE_BUY:
      return {
        ...state,
        vedioBuyAdded: action.payload,
      };
    case Types.GET_DISPLAY_PRIVATE_BUYS:
      return {
        ...state,
        displayBuys: action.payload,
      };
    case Types.GET_VIDEO_PRIVATE_BUYS:
      return {
        ...state,
        videoBuys: action.payload,
      };
    case Types.GET_EDIT_DISPLAY_PRIVATE_BUY:
      return {
        ...state,
        editDisplayPrivateBuy: action.payload,
      };
    case Types.GET_EDIT_VIDEO_PRIVATE_BUY:
      return {
        ...state,
        editVideoPrivateBuy: action.payload,
      };
    case Types.UPDATE_DISPLAY_PRIVATE_BUY:
      return {
        ...state,
        displayBuyUpdated: action.payload,
      };
    case Types.UPDATE_VIDEO_PRIVATE_BUY:
      return {
        ...state,
        videoBuyUpdated: action.payload,
      };
    case Types.PRIVATE_BUY_REPORT:
      return {
        ...state,
        privateBuyReport: action.payload,
      };
    case Types.PRIVATE_BUY_OVERVIEW_DATA:
      return {
        ...state,
        privateBuyOverviewData: action.payload,
      };
    case Types.CLEAR_PRIVATE_BUY_STATES:
      return {
        ...state,
        displayPrivateBuyList: [],
        videoPrivateBuyList: [],
        displayBuyAdded: undefined,
        vedioBuyAdded: undefined,
        displayBuys: [],
        videoBuys: [],
        editDisplayPrivateBuy: undefined,
        editVideoPrivateBuy: undefined,
        displayBuyUpdated: undefined,
        videoBuyUpdated: undefined,
      };
    default:
      return state;
  }
}
