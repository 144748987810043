import { Types } from "./../constants/publisher-types";
import publisherApi from "../api/publisher-api";

// Load all Publishers
export const loadPublishers = () => async (dispatch) => {
  try {
    await publisherApi.loadAllPublishers().then(
      (data) => {
        dispatch(setPublisherList(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// Add new Publisher
export const addPublisher = (payload) => async (dispatch) => {
  try {
    // let publisherData = { payload };
    await publisherApi.addPublisher(payload).then(
      (data) => {
        if (data && data.message && data.message === "Publisher added successfully!") {
          dispatch({
            type: Types.ADD_PUBLISHER,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_PUBLISHER,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// Update existing Publisher
export const updatePublisher = (publisherId, data) => async (dispatch) => {
  try {
    await publisherApi.updatePublisher(publisherId, data).then(
      (data) => {
        if (data && data.message && data.message === "Publisher updated successfully!") {
          dispatch({
            type: Types.UPDATE_PUBLISHER,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_PUBLISHER,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

export const loadPublisherById = (id) => async (dispatch) => {
  try {
    await publisherApi.loadPublisherById(id).then(
      (data) => {
        dispatch(setEditPublisher(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

export const openBlacklistWhitelistAccountModal = (type, accountList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_ACCOUNT_MODAL,
    payload: {
      status: true,
      accountType: type,
      selectedAccountList: accountList,
    },
  });
};

export const closeBlacklistWhitelistAccountModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_ACCOUNT_MODAL,
    payload: {
      status: false,
      accountType: undefined,
    },
  });
};

// To open add new Publisher Modal
export const openNewPublisherModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_PUBLISHER_MODAL,
    payload: true,
  });
};

// To close add new Publisher Modal
export const closeNewPublisherModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_PUBLISHER_MODAL,
    payload: false,
  });
};

const setPublisherList = (publishers) => {
  return {
    type: Types.GET_PUBLISHER_LIST,
    payload: publishers,
  };
};

const setEditPublisher = (publisher) => {
  return {
    type: Types.GET_EDIT_PUBLISHER,
    payload: publisher,
  };
};

// To clear states from redux store
export const clearEditPublisherStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_EDIT_PUBLISHER_STATES,
    payload: undefined,
  });
};

export const loadPublisherViewDashboard = (values, isNew) => async (dispatch) => {
  try {
    await publisherApi.loadPublisherViewDashboard(values).then(
      (data) => {
        if (data)
          dispatch({
            type: Types.LOAD_PUBLISHER_VIEW_DASHBOARD,
            payload: { data: data, isNew: isNew }
          });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

export const exportPublisherDashboardData = (values) => async (dispatch) => {
  try {
    return await publisherApi.exportPublisherDashboardData(values);
  } catch (err) { }
};