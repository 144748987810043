import { Types } from "../constants/segment-types";
const initialState = {
  segmentList: [],
  isAddSegmentModalOpen: false,
  segmentAdded: undefined,
  errorMessage: undefined,
  editSegment: undefined,
  segmentUpdated: undefined,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_SEGMENT_LIST:
      return {
        ...state,
        segmentList: action.payload,
      };
    case Types.GET_EDIT_SEGMENT:
      return {
        ...state,
        editSegment: action.payload,
      };
    case Types.OPEN_ADD_SEGMENT_MODAL:
      return {
        ...state,
        isAddSegmentModalOpen: action.payload,
      };
    case Types.ADD_SEGMENT:
      return {
        ...state,
        segmentAdded: action.payload.segmentAdded,
        errorMessage: action.payload.errorMessage
          ? action.payload.errorMessage
          : initialState.errorMessage,
      };
    case Types.UPDATE_SEGMENT:
      return {
        ...state,
        segmentUpdated: action.payload,
      };
    case Types.CLEAR_EDIT_SEGMENT_STATES:
      return {
        ...state,
        segmentAdded: undefined,
        errorMessage: undefined,
        editSegment: undefined,
        segmentUpdated: undefined,
      };
    default:
      return state;
  }
}
