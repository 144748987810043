import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { closeUpdateExcelateSegmentsModal, updateDisplayLine, updateVideoLine } from "../../../redux/actions/line-actions";

const { Option } = Select;

const EditExelateSegments = ({ lineType, selectedExelateSegmentsList, isUpdateExelateSegmentsModalOpen, exelateSegments }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [result, setResult] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        const selectValuesTemp = [];
        if (selectedExelateSegmentsList && selectedExelateSegmentsList.length > 0) {
            selectedExelateSegmentsList.filter(function (obj) {
                selectValuesTemp.push(obj.exelateSegmentId + " - " + obj.name);
            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("EditExelateSegments Unmounting");
        };
    }, [selectedExelateSegmentsList, isUpdateExelateSegmentsModalOpen, exelateSegments]);

    const handleSave = (values) => {
        console.log(values);
        let selectedExelateSegments = [];
        if (values) {
            values.exelateSegments.map((value) =>
                exelateSegments && exelateSegments.map((exelateSegment) =>
                (exelateSegment.exelateSegmentId + " - " + exelateSegment.name).toUpperCase().indexOf(value.toUpperCase()) !== -1
                        ? selectedExelateSegments.push({ name: exelateSegment.name, exelateSegmentId: exelateSegment.exelateSegmentId })
                        : ""
                )
            );
        }

        values["exelateSegments"] = JSON.stringify(selectedExelateSegments);
        if (lineType === "display") {
            dispatch(updateDisplayLine(id, values));
        } else {
            dispatch(updateVideoLine(id, values));
        }
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateExcelateSegmentsModal());
    };

    const handleCancel = () => {
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateExcelateSegmentsModal());
    };

    const handleSearch = (value) => {
        let res = [];
        if (value) {
            exelateSegments &&
                exelateSegments.map((exelateSegmentsOption) =>
                    exelateSegmentsOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
                        ? res.push(exelateSegmentsOption)
                        : ""
                );
        }
        setResult(res);
    };

    return (
        <>
            <Modal
                title="Exelate Segments"
                visible={isUpdateExelateSegmentsModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form form={form} layout={"vertical"} onFinish={handleSave}>
                    <Form.Item label="Add Exelate Segments" name="exelateSegments">
                        <Select
                            mode="multiple"
                            dropdownStyle={{ display: result.length > 0 ? "" : "none" }}
                            onSearch={handleSearch}
                            key={selectedValues}
                            defaultValue={selectedValues}
                        >
                            {result.map((exelateSegment) => (
                                <Option key={exelateSegment.exelateSegmentId + " - " + exelateSegment.name}>{exelateSegment.exelateSegmentId + " - " + exelateSegment.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    return {
        exelateSegments: state.line.exelateSegments,
        isUpdateExelateSegmentsModalOpen: state.line.isUpdateExelateSegmentsModalOpen,
        selectedExelateSegmentsList: state.line.selectedExelateSegmentsList,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditExelateSegments);
