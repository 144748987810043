import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import {
  Form,
  Space,
  DatePicker,
  Select,
  Table,
  Collapse,
  Drawer,
  Button,
  Spin,
  Card,
  Col,
  Row,
} from "antd";
import moment from "moment";
import { timezones } from "../../constants/defaultValues";
import { loadPublisherViewDashboard, exportPublisherDashboardData } from "../../redux/actions/publisher-actions";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  FilterFilled,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  DownloadOutlined,
} from "@ant-design/icons";
import { } from "../../constants/defaultValues";
import { getDayEndDateTime, getDayStartDateTime, isFloat, camelCaseToString, getUTCDate } from "../../utils/appUtils";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;
const gridStyle = {
  border: "none",
  boxShadow: "none",
};

const PublisherDashboard = ({ getPublisherDashboardData, isNew }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const [startEndDate, setStartEndDate] = useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  const [zone, setZone] = useState("America/Los_Angeles");
  const [visible, setVisible] = useState(false);
  const [selectedPublisherValues, setSelectedPublisherValues] = useState([]);
  const [selectedSiteBuyValues, setSelectedSiteBuyValues] = useState([]);
  const [selectedMediaValues, setSelectedMediaValues] = useState([]);
  const [selectedRateTypeValues, setSelectedRateTypeValues] = useState([]);
  const [dashboardData, setdashboardData] = useState([]);
  const [checkedmetrics, setcheckedmetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);
  //const cloneMetrics = [...metrics];
  const [unCheckedmetrics, setUnCheckedmetrics] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnTitles, setColumnTitles] = useState([]);
  const [isSet, setIsSet] = useState(true);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      var data = {};
      // data["startDate"] = startEndDate && startEndDate[0] ?
      //   getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
      // data["endDate"] = startEndDate && startEndDate[1] ?
      //   getDayEndDateTime(new Date(startEndDate[1])) : getDayEndDateTime(new Date());
      data["startDate"] = startEndDate && startEndDate[0] ?
        new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
      data["endDate"] = startEndDate && startEndDate[1] ?
        new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString() : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
      data["timezone"] = zone;
      data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setSubmitted(true);
      await dispatch(loadPublisherViewDashboard(data, true));
      if(isMounted ){

      if (
        getPublisherDashboardData &&
        getPublisherDashboardData["dashboardList"]
      ) {
        setdashboardData(
          getPublisherDashboardData &&
          getPublisherDashboardData["dashboardList"]
        );
        loadFilters();
        var columnArray = [];
        columnArray.push({
          title: "Publisher",
          dataIndex: "publisher",
          key: "publisher",
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        },
          {
            title: "Site Buy",
            dataIndex: "siteBuy",
            key: "siteBuy",
            render: (text) =>
              text && text !== null
                ? text.includes("-")
                  ? text.replace("-", "").replace("-", "")
                  : text
                : "unknown"

          });
        getPublisherDashboardData && getPublisherDashboardData["header"] && getPublisherDashboardData["header"].map((header) => {
          columnArray.push({
            title: camelCaseToString(header),
            dataIndex: header,
            key: header,
            render: (text, record) =>
              (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? ((isFloat(record.eventData[header]) ? record.eventData[header].toFixed(2) : record.eventData[header])) : "-")

          });
        });
        columnArray.push({
          title: "Media",
          dataIndex: "media",
          key: "media",
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        },
          {
            title: "Rate Type",
            dataIndex: "rateType",
            key: "rateType",
            render: (text) =>
              text && text !== null
                ? text.includes("-")
                  ? text.replace("-", "").replace("-", "")
                  : text
                : "unknown"

          }, {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          render: (text) =>
            text && text !== null
              ? text
              : "unknown"

        });
        setColumns(columnArray);
        var columnTitle = [];
        columnArray.map(item => {
          columnTitle.push(item.key);
        })
        setColumnTitles(columnTitle);
        var headers = getPublisherDashboardData["header"] ? getPublisherDashboardData["header"] : [];
        setMetrics(headers);
        const cloneMetrics = [...headers];
        setUnCheckedmetrics(cloneMetrics);
      }
      setSubmitted(false);
   } })();

    var values = {
      date: [
        startEndDate && startEndDate[0] ? moment(new Date(startEndDate[0])) : "",
        startEndDate && startEndDate[1] ? moment(new Date(startEndDate[1])) : "",
      ],
      timezone: zone,
    };
    form.setFieldsValue(values);

    return () => {   
      isMounted = false;
 
    };
  }, [isNew]);

  useEffect(() => {
    handleFilters();
    return () => { };
  }, [isSet]);

  function loadFilters() {
    loadPublisherFilters();
    loadSiteBuyFilters();
    loadMediaFilters();
    loadRateTypeFilters();
  }

  function loadPublisherFilters() {
    const selectPublisherValuesTemp = [];
    if (
      getPublisherDashboardData &&
      getPublisherDashboardData["publisher"] &&
      getPublisherDashboardData["publisher"].length > 0
    ) {
      getPublisherDashboardData["publisher"].filter(function (obj) {
        selectPublisherValuesTemp.push(obj.id);
      });
      setSelectedPublisherValues(selectPublisherValuesTemp);
    }
    return selectPublisherValuesTemp;
  }

  function loadSiteBuyFilters() {
    const selectSiteBuyValuesTemp = [];
    if (
      getPublisherDashboardData &&
      getPublisherDashboardData["siteBuy"] &&
      getPublisherDashboardData["siteBuy"].length > 0
    ) {
      getPublisherDashboardData["siteBuy"].filter(function (obj) {
        selectSiteBuyValuesTemp.push(obj.id);
      });
      setSelectedSiteBuyValues(selectSiteBuyValuesTemp);
    }
  }

  async function downloadFile() {
    var values = {};
    // values["startDate"] = startEndDate && startEndDate[0] ?
    //   getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
    // values["endDate"] = startEndDate && startEndDate[1] ?
    //   getDayEndDateTime(new Date(startEndDate[1])) : getDayEndDateTime(new Date());
    values["startDate"] = startEndDate && startEndDate[0] ?
      new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    values["endDate"] = startEndDate && startEndDate[1] ?
      new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString() : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    values["timezone"] = zone;
    values["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values["selectedMetrics"] = columnTitles;
    var response = await dispatch(exportPublisherDashboardData(values));
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
    a.download = "PublisherDelivery.xlsx";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)

  }

  function loadMediaFilters() {
    const selectMediaValuesTemp = [];
    if (
      getPublisherDashboardData &&
      getPublisherDashboardData["media"] &&
      getPublisherDashboardData["media"].length > 0
    ) {
      getPublisherDashboardData["media"].filter(function (obj) {
        selectMediaValuesTemp.push(obj);
      });
      setSelectedMediaValues(selectMediaValuesTemp);
    }
  }

  function loadRateTypeFilters() {
    const selectRateTypeValuesTemp = [];
    if (
      getPublisherDashboardData &&
      getPublisherDashboardData["rateType"] &&
      getPublisherDashboardData["rateType"].length > 0
    ) {
      getPublisherDashboardData["rateType"].filter(function (obj) {
        selectRateTypeValuesTemp.push(obj);
      });
      setSelectedRateTypeValues(selectRateTypeValuesTemp);
    }
  }

  async function loadData(dates, timezone) {
    var values = {};
    if (!dates) {
      dates = startEndDate;
    }
    if (!timezone) {
      timezone = zone;
    }
    // values["startDate"] =
    //   dates && dates !== null && dates[0] ?
    //     getDayStartDateTime(new Date(dates[0])) : getDayStartDateTime(new Date());
    // values["endDate"] =
    //   dates && dates !== null && dates[1]
    //     ? getDayEndDateTime(new Date(dates[1]))
    //     : getDayEndDateTime(new Date());
    values["startDate"] =
      dates && dates !== null && dates[0] ?
        new Date(getUTCDate(getDayStartDateTime(new Date(dates[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    values["endDate"] =
      dates && dates !== null && dates[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(dates[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    values["timezone"] = timezone;
    values["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSubmitted(true);
    await dispatch(loadPublisherViewDashboard(values, false));
    setdashboardData(
      getPublisherDashboardData && getPublisherDashboardData["dashboardList"]
    );
    setSubmitted(false);
  }

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const onMetricsChange = (e) => {
    var checkedTemp = checkedmetrics;
    var unCheckedTemp = unCheckedmetrics;
    if (checkedTemp.includes(e.target.value)) {
      checkedTemp.splice(checkedTemp.indexOf(e.target.value), 1);
      unCheckedTemp.push(e.target.value);
    } else if (unCheckedTemp.includes(e.target.value)) {
      unCheckedTemp.splice(unCheckedTemp.indexOf(e.target.value), 1);
      checkedTemp.push(e.target.value);
    }

    setcheckedmetrics(checkedTemp);
    setUnCheckedmetrics(unCheckedTemp);
    var columnArray = [];
    columnArray.push({
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      render: (text) =>
        text && text !== null
          ? text.includes("-")
            ? text.replace("-", "").replace("-", "")
            : text
          : "unknown"

    },
      {
        title: "Site Buy",
        dataIndex: "siteBuy",
        key: "siteBuy",
        render: (text) =>
          text && text !== null
            ? text.includes("-")
              ? text.replace("-", "").replace("-", "")
              : text
            : "unknown"

      });
    unCheckedTemp && unCheckedTemp.map((header) => {
      columnArray.push({
        title: camelCaseToString(header),
        dataIndex: header,
        key: header,
        render: (text, record) =>
          (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? ((isFloat(record.eventData[header]) ? record.eventData[header].toFixed(2) : record.eventData[header])) : "-")

      });
    });
    columnArray.push({
      title: "Media",
      dataIndex: "media",
      key: "media",
      render: (text) =>
        text && text !== null
          ? text.includes("-")
            ? text.replace("-", "").replace("-", "")
            : text
          : "unknown"

    },
      {
        title: "Rate Type",
        dataIndex: "rateType",
        key: "rateType",
        render: (text) =>
          text && text !== null
            ? text.includes("-")
              ? text.replace("-", "").replace("-", "")
              : text
            : "unknown"

      }, {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) =>
        text && text !== null
          ? text
          : "unknown"

    });
    setColumns(columnArray);
    var columnTitle = [];
    columnArray.map(item => {
      columnTitle.push(item.key);
    })
    setColumnTitles(columnTitle);
  };

  const onDateChange = (value, dateString) => {
    setStartEndDate(dateString);
    loadData(dateString, zone);
  };

  const onChangeZone = (e) => {
    setZone(e);
    loadData(startEndDate, e);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onReset = () => {
    loadFilters();
    setdashboardData(
      getPublisherDashboardData && getPublisherDashboardData["dashboardList"]
    );
  };

  const isPublisherSelected = (id) => {
    var filtered = selectedPublisherValues.filter(function (obj) {
      return id === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isSiteBuySelected = (id) => {
    var filtered = selectedSiteBuyValues.filter(function (obj) {
      return id === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isMediaSelected = (item) => {
    var filtered = selectedMediaValues.filter(function (obj) {
      return item === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isRateTypeSelected = (item) => {
    var filtered = selectedRateTypeValues.filter(function (obj) {
      return item === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const onPublisherChange = (e) => {
    const selectPublisherValuesTemp = selectedPublisherValues;
    if (selectPublisherValuesTemp.indexOf(e.target.value) > -1) {
      selectPublisherValuesTemp.splice(
        selectPublisherValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectPublisherValuesTemp.push(e.target.value);
    }
    setSelectedPublisherValues(selectPublisherValuesTemp);
    handleFilters();
  };

  const onSiteBuyChange = (e) => {
    const selectSiteBuyValuesTemp = selectedSiteBuyValues;
    if (selectSiteBuyValuesTemp.indexOf(e.target.value) > -1) {
      selectSiteBuyValuesTemp.splice(
        selectSiteBuyValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectSiteBuyValuesTemp.push(e.target.value);
    }
    setSelectedSiteBuyValues(selectSiteBuyValuesTemp);
    handleFilters();
  };

  const onMediaChange = (e) => {
    const selectMediaValuesTemp = selectedMediaValues;
    if (selectMediaValuesTemp.indexOf(e.target.value) > -1) {
      selectMediaValuesTemp.splice(
        selectMediaValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectMediaValuesTemp.push(e.target.value);
    }
    setSelectedMediaValues(selectMediaValuesTemp);
    handleFilters();
  };

  const onRateTypeChange = (e) => {
    const selectRateTypeValuesTemp = selectedRateTypeValues;
    if (selectRateTypeValuesTemp.indexOf(e.target.value) > -1) {
      selectRateTypeValuesTemp.splice(
        selectRateTypeValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectRateTypeValuesTemp.push(e.target.value);
    }
    setSelectedRateTypeValues(selectRateTypeValuesTemp);
    handleFilters();
  };

  function handleFilters() {
    var selectPublishers = [];
    var selectSiteBuys = [];
    var selectMedia = [];
    var selectRateType = [];

    const selectSiteBuyValuesTemp = selectedSiteBuyValues;
    const selectPublisherValuesTemp = selectedPublisherValues;
    const selectMediaValuesTemp = selectedMediaValues;
    const selectRateTypeValuesTemp = selectedRateTypeValues;

    if (
      getPublisherDashboardData &&
      getPublisherDashboardData["dashboardList"] &&
      getPublisherDashboardData["publisher"] &&
      getPublisherDashboardData["siteBuy"] &&
      getPublisherDashboardData["media"] &&
      getPublisherDashboardData["rateType"]
    ) {
      getPublisherDashboardData["publisher"].map(
        (publisher) =>
          selectPublisherValuesTemp &&
          selectPublisherValuesTemp.length > 0 &&
          selectPublisherValuesTemp.map((selectedValue) =>
            selectedValue === publisher.id
              ? selectPublishers.push(publisher.name)
              : ""
          )
      );
      getPublisherDashboardData["siteBuy"].map(
        (siteBuy) =>
          selectSiteBuyValuesTemp &&
          selectSiteBuyValuesTemp.length > 0 &&
          selectSiteBuyValuesTemp.map((selectedValue) =>
            selectedValue === siteBuy.id
              ? selectSiteBuys.push(siteBuy.name)
              : ""
          )
      );
      getPublisherDashboardData["media"].map(
        (media) =>
          selectMediaValuesTemp &&
          selectMediaValuesTemp.length > 0 &&
          selectMediaValuesTemp.map((selectedValue) =>
            selectedValue === media ? selectMedia.push(media) : ""
          )
      );
      getPublisherDashboardData["rateType"].map(
        (rateType) =>
          selectRateTypeValuesTemp &&
          selectRateTypeValuesTemp.length > 0 &&
          selectRateTypeValuesTemp.map((selectedValue) =>
            selectedValue === rateType ? selectRateType.push(rateType) : ""
          )
      );

      var tempDashboardData = [];
      getPublisherDashboardData["dashboardList"].map((data) =>
        selectSiteBuys.includes(data.siteBuy) &&
          selectPublishers.includes(data.publisher) &&
          selectMedia.includes(data.media) &&
          selectRateType.includes(data.rateType)
          ? tempDashboardData.push(data)
          : ""
      );
      setdashboardData(tempDashboardData);
    }
  }

  const onCheck = (type) => {
    if (type === "publisher") {
      loadPublisherFilters();
    } else if (type === "siteBuy") {
      loadSiteBuyFilters();
    } else if (type === "media") {
      loadMediaFilters();
    } else if (type === "rateType") {
      loadRateTypeFilters();
    }
    setIsSet(!isSet);
    handleFilters();
  };

  const onUncheck = (type) => {
    if (type === "publisher") {
      setSelectedPublisherValues([]);
    } else if (type === "siteBuy") {
      setSelectedSiteBuyValues([]);
    } else if (type === "media") {
      setSelectedMediaValues([]);
    } else if (type === "rateType") {
      setSelectedRateTypeValues([]);
    }
    setIsSet(!isSet);
    handleFilters();
  };

  return (
    <div>
      <Spin size={"large"} spinning={submitted}></Spin>
      <Card>
        <Form form={form} layout={"vertical"}>
          <Row className="account-form">
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Form.Item label="Date" name="date">
                  <RangePicker
                    showTime
                    format={dateFormat}
                    onChange={onDateChange}
                  />
                </Form.Item>
              </Card.Grid>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Form.Item label="Timezone" name="timezone">
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    onChange={onChangeZone}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {timezonesOptions}
                  </Select>
                </Form.Item>
              </Card.Grid>
            </Col>
            <Col xs={24} sm={24} md={24} lg={2} xl={2}>
              <div className="upload-right account-form-filter">
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <FilterFilled onClick={showDrawer} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <DownloadOutlined onClick={downloadFile} />
                </Card.Grid>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <Drawer
        title={
          <>
            <Space size={80}>
              <span>Filters</span>
              <Button type="primary" onClick={onReset}>
                RESET
              </Button>
            </Space>
          </>
        }
        placement={"right"}
        width={300}
        onClose={onClose}
        visible={visible}
      >
        <Collapse>
          <Panel header="Publisher" key="1">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("publisher")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("publisher")}
              />
            </Space>
            {getPublisherDashboardData &&
              getPublisherDashboardData["publisher"].map((item) => (
                <ul key={item.id}>
                  <Checkbox
                    onChange={onPublisherChange}
                    checked={isPublisherSelected(item.id)}
                    value={item.id}
                  >
                    {item.name}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Site Buy" key="2">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("siteBuy")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("siteBuy")}
              />
            </Space>
            {getPublisherDashboardData &&
              getPublisherDashboardData["siteBuy"].map((item) => (
                <ul key={item.id}>
                  <Checkbox
                    onChange={onSiteBuyChange}
                    checked={isSiteBuySelected(item.id)}
                    defaultChecked={isSiteBuySelected(item.id)}
                    value={item.id}
                  >
                    {item.name}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Media" key="3">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("media")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("media")}
              />
            </Space>
            {getPublisherDashboardData &&
              getPublisherDashboardData["media"].map((item) => (
                <ul key={item}>
                  <Checkbox
                    onChange={onMediaChange}
                    checked={isMediaSelected(item)}
                    value={item}
                  >
                    {item}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="RateType" key="4">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("rateType")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("rateType")}
              />
            </Space>
            {getPublisherDashboardData &&
              getPublisherDashboardData["rateType"].map((item) => (
                <ul key={item}>
                  <Checkbox
                    onChange={onRateTypeChange}
                    checked={isRateTypeSelected(item)}
                    value={item}
                  >
                    {item}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Metrics" key="5">
            {metrics.map((metrics) => (
              <ul key={metrics}>
                <Checkbox onChange={onMetricsChange} key={metrics} value={metrics} defaultChecked={metrics}>
                  {metrics}
                </Checkbox>
              </ul>
            ))}
          </Panel>
        </Collapse>
      </Drawer>
      <Card>
        <Table
          rowKey={record => JSON.stringify(record)}
          scroll={{ x: 1200 }}
          columns={columns && columns}
          dataSource={dashboardData ? dashboardData : []}
        />
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    getPublisherDashboardData: state.publisher.getPublisherDashboardData,
    isNew: state.publisher.isNew,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublisherDashboard
);
