import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadAllCampaignsByAccountId,
  openNewCampaignModal,
} from "../../redux/actions/campaign-actions";
import { loadAccountById, clearEditAccountStates } from "../../redux/actions/account-actions";
import { openNewRetargetingPixelModal, clearRetartgetingPixelStates, updateRetargetingPixels } from "../../redux/actions/retargeting-pixel-actions";
import { openNewConversionPixelModal, clearConversionPixelStates } from "../../redux/actions/conversion-pixel-actions";
import { clearEditCampaignStates } from "../../redux/actions/campaign-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { notifyUser } from "./../../services/notification-service";
import { List, Row, Col, Breadcrumb, Button, PageHeader, Modal, Input, Form, Spin, Select } from "antd";
import { compose } from "redux";
import AddCampaignModal from "./../../components/modals/campaign/add-campaign";
import AddRetargetingPixelModal from "./../../components/modals/retargeting-pixel/add-retargeting-pixel";
import AddConversionPixelModal from "./../../components/modals/conversion-pixel/add-conversion-pixel";
import { pixelUrl } from "../../constants/defaultValues";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";
import { retargetingPixelActions } from "../../constants/defaultValues";

const { Option } = Select;
const EditAccount = ({ editAccount, campaignAdded, retargetingPixelAdded, retargetingPixelUpdated, conversionPixelAdded }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();
  const [viewOldCampaign, setViewOldCampaign] = useState(false);
  const oldCampaigns = [];
  const recentCampaigns = [];
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  const handleOk = () => {
    var payload = {};
    console.log(payload);
    dispatch(updateRetargetingPixels(id, payload));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadAccountById(id));
      if(isMounted ){

      setSubmitted(false);
  }})();

    if (campaignAdded !== undefined) {
      if (campaignAdded.success === true && campaignAdded.message) {
        notifyUser(campaignAdded.message, "success");
      } else {
        notifyUser(campaignAdded.message, "error");
      }
    }
    if (retargetingPixelAdded !== undefined) {
      if (retargetingPixelAdded.success === true && retargetingPixelAdded.message) {
        notifyUser(retargetingPixelAdded.message, "success");
      } else {
        notifyUser(retargetingPixelAdded.message, "error");
      }
    }

    if (retargetingPixelUpdated !== undefined) {
      if (retargetingPixelUpdated.success === true && retargetingPixelUpdated.message) {
        notifyUser(retargetingPixelUpdated.message, "success");
      } else {
        notifyUser(retargetingPixelUpdated.message, "error");
      }
    }


    if (conversionPixelAdded !== undefined) {
      if (conversionPixelAdded.success === true && conversionPixelAdded.message) {
        notifyUser(conversionPixelAdded.message, "success");
      } else {
        notifyUser(conversionPixelAdded.message, "error");
      }
    }
    return () => {
      isMounted = false;

      console.log("EditAccount Unmounting");
      dispatch(clearEditAccountStates());
      dispatch(clearEditCampaignStates());
      dispatch(clearRetartgetingPixelStates());
      dispatch(clearConversionPixelStates());
    };
  }, [campaignAdded, retargetingPixelAdded, retargetingPixelUpdated, conversionPixelAdded]);

  if (editAccount && editAccount.campaigns && editAccount.campaigns.length > 0) {
    editAccount.campaigns.map((campaign, index) => {
      if (campaign && campaign.age && campaign.age === "RECENT") {
        recentCampaigns.push(campaign);
      } else {
        oldCampaigns.push(campaign);
      }
    });
  }

  // to list out Campaigns
  function _bindCampaigns(campaigns) {
    return campaigns.map((campaign, index) => {
      return (
        <li key={"campaign" + index} className="check-box">
          <Link to={`/campaigns/${campaign.campaignId}`}>
            <span>{campaign.name} </span>
          </Link>
        </li>
      );
    });
  }

  const actionOptions = retargetingPixelActions.map((action) => (
    <Option key={action} value={action}>
      {action}
    </Option>
  ));
  function _bindTargetingStates(targetingStates) {
    return targetingStates.map((taState, index) => {
      return (
        <Row gutter={16}>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label={"State"}>
              <Input defaultValue={taState.state} />
            </Form.Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label={"Action"}>
              {/* <Input defaultValue={taState.action} /> */}
              <Select placeholder="Pixel Action Type" value={taState.action}>{actionOptions}</Select>
            </Form.Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <Form.Item label={"Next State"}>
              <Input defaultValue={taState.nextState} />
            </Form.Item>
          </Col>
        </Row>
      );
    });
  }

  function _bindRetargetingPixels(pixels) {
    const header = pixels.length + " " + "  Retargeting Pixels";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              currentUserPermissionMap[currentUserRole]["canCreateAccountPixels"] ? (
                <div>
                    <Button onClick={() => dispatch(openNewRetargetingPixelModal(undefined))} type="link">
                      {"New Retargeting Pixel"}
                    </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={pixels}
            renderItem={(pixel) => 
            <Link onClick={() => dispatch(openNewRetargetingPixelModal(pixel)) }>
              <List.Item>{pixel.name}</List.Item></Link>}
          />
        </Col>
      </Row>
    );
  }


  function _bindAccountPixels(pixelType, pixels) {
    const header = pixels.length + " " + pixelType + " Pixels";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              currentUserPermissionMap[currentUserRole]["canCreateAccountPixels"] ? (
                <div>
                  {pixelType === "Conversion" ? (
                    <Button onClick={() => dispatch(openNewConversionPixelModal())} type="link">
                      {"New " + pixelType + " Pixel"}
                    </Button>
                  ) : (
                    <Button onClick={() => dispatch(openNewRetargetingPixelModal(undefined))} type="link">
                      {"New " + pixelType + " Pixel"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={pixels}
            renderItem={(pixel) => <Link  onClick={() => pixelInfo(pixel, pixelType)}><List.Item>{pixel.name}</List.Item></Link>}
          />
        </Col>
      </Row>
    );
  }

  function pixelInfo(pixel, pixelType) {
    // Modal.info({
    //   title: null,
    //   icon: null,
    //   content: (
    //     <div className="card-body">
    //       <Form layout={"vertical"}>
    //         {pixelType === "Conversion" ? (

    //           <>
    //             <Form.Item label="Post-Impression TTL (days)">
    //               <Input defaultValue={pixel.postClickTtl} />
    //             </Form.Item>
    //             <Form.Item label="Post-Click TTL (days)">
    //               <Input defaultValue={pixel.postImpressionTtl} />
    //             </Form.Item>
    //             <Form.Item label="Script">
    //               <Input defaultValue={'<script src="' + pixelUrl.conversion_url + pixel.conversionPixelId + '.js" ></script>'} />
    //             </Form.Item>
    //             <Form.Item label="Image">
    //               <Input defaultValue={'<img src="' + pixelUrl.conversion_url + pixel.conversionPixelId + '.gif" width="0" height="0" style="display: hidden;">'} />
    //             </Form.Item>
    //           </>
    //         ) : (
    //           <>
    //             <Form.Item label="Retargeting TTL (days)">
    //               <Input defaultValue={pixel.retargetingTtl} disabled={true} />
    //             </Form.Item>
    //             <Form.Item label="Script">
    //               <Input
    //                 disabled={true}
    //                 defaultValue={'<script src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '.js" ></script>'} />
    //             </Form.Item>
    //             <Form.Item label="Image">
    //               <Input
    //                 disabled={true}
    //                 defaultValue={'<img src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + ',gif" width="0" height="0" style="display: hidden;">'} />
    //             </Form.Item>
    //             {pixel.targetingStates && pixel.targetingStates.length > 0 ?
    //               <Form.Item label="Targeting States" name="devices">{_bindTargetingStates(pixel.targetingStates)}</Form.Item> : ""}
    //           </>
    //         )}
    //       </Form>
    //     </div>
    //   ),
    //  onOk() { },
    // });

    if (pixelType === "Conversion") {
      Modal.info({
        title: null,
        icon: null,
        content: (
          <div className="card-body">
            <Form layout={"vertical"}>
              {/* {pixelType === "Conversion" ? ( */}
              <>
                <Form.Item label="Post-Impression TTL (days)">
                  <Input defaultValue={pixel.postClickTtl} />
                </Form.Item>
                <Form.Item label="Post-Click TTL (days)">
                  <Input defaultValue={pixel.postImpressionTtl} />
                </Form.Item>
                <Form.Item label="Script">
                  <Input defaultValue={'<script src="' + pixelUrl.conversion_url + pixel.conversionPixelId + '.js" ></script>'} />
                </Form.Item>
                <Form.Item label="Image">
                  <Input defaultValue={'<img src="' + pixelUrl.conversion_url + pixel.conversionPixelId + '.gif" width="0" height="0" style="display: hidden;">'} />
                </Form.Item>
              </>
            </Form>
          </div>
        ),
        onOk() { },
      })
    }
    else {

      return (
        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}>
          <div className="card-body">
            <Form layout={"vertical"}>
              <>
                <Form.Item label="Retargeting TTL (days)">
                  <Input defaultValue={pixel.retargetingTtl} disabled={true} />
                </Form.Item>
                <Form.Item label="Script">
                  <Input
                    disabled={true}
                    defaultValue={'<script src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '" ></script>'} />
                </Form.Item>
                <Form.Item label="Image">
                  <Input
                    disabled={true}
                    defaultValue={'<img src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '.gif" width="0" height="0" style="display: hidden;">'} />
                </Form.Item>
                {pixel.targetingStates && pixel.targetingStates.length > 0 ?
                  <Form.Item label="Targeting States" name="devices">{_bindTargetingStates(pixel.targetingStates)}</Form.Item> : ""}
              </>
            </Form>
          </div>
        </Modal >
      );
    }

  }

  

  return (
    <section className="white-box-wrapper">
      <Spin size={"large"} spinning={submitted}></Spin>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/accounts/${editAccount && editAccount.accountId}`}>Account</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader className="site-page-header" title={editAccount && editAccount.name} />

      {editAccount && editAccount.campaigns ? (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="white-box old_line">
                <div className="title">
                  <h2>
                    {editAccount && editAccount.campaigns.length > 1
                      ? editAccount.campaigns.length + " Campaigns"
                      : editAccount.campaigns.length + " Campaign"}
                  </h2>
                </div>
                <div className="card-body">
                  <ul id="recentCampaigns">
                    {recentCampaigns && recentCampaigns.length > 0 ? _bindCampaigns(recentCampaigns) : <span className="leftSpace">There is no recent Campaign</span>}
                  </ul>
                  {editAccount && oldCampaigns && oldCampaigns.length > 0 ? <span
                    className="view-campaign"
                    onClick={() => setViewOldCampaign(!viewOldCampaign)}
                  >
                    {editAccount && oldCampaigns && oldCampaigns.length > 1
                      ? oldCampaigns.length + " Old Campaigns"
                      : oldCampaigns.length + " Old Campaign"}
                  </span> : ""}
                  {viewOldCampaign ? (
                    <ul id="oldCampaigns">{oldCampaigns ? _bindCampaigns(oldCampaigns) : ""}</ul>
                  ) : (
                    ""
                  )}
                </div>
                {currentUserPermissionMap[currentUserRole]["canCreateAccountCampaign"] ? (
                  <div className="card-footer">
                    <Button onClick={() => dispatch(openNewCampaignModal())} type="link">
                      New Campaign
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          {editAccount &&
            editAccount.retargetingPixels &&
            _bindRetargetingPixels(editAccount.retargetingPixels)}
          {editAccount &&
            editAccount.retargetingPixels &&
            _bindAccountPixels("Conversion", editAccount.conversionPixels)}
        </>
      ) : (
        ""
      )}

      <AddCampaignModal editCampaignInfo={undefined} />
      <AddRetargetingPixelModal accountId={editAccount && editAccount.accountId} />
      <AddConversionPixelModal accountId={editAccount && editAccount.accountId} />
    </section>
  );
};

function mapStateToProps(state) {
  return {
    campaignAdded: state.campaign.campaignAdded,
    editAccount: state.account.editAccount,
    retargetingPixelAdded: state.retargetingPixel.retargetingPixelAdded,
    retargetingPixelUpdated: state.retargetingPixel.retargetingPixelUpdated,
    conversionPixelAdded: state.conversionPixel.conversionPixelAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAllCampaignsByAccountId: () => dispatch(loadAllCampaignsByAccountId()),
    openNewConversionPixelModal: () => dispatch(openNewConversionPixelModal()),
    openNewRetargetingPixelModal: () => dispatch(openNewRetargetingPixelModal()),
    openNewCampaignModal: () => dispatch(openNewCampaignModal()),
    loadAccountById: (id) => dispatch(loadAccountById(id)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditAccount);
