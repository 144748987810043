import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input } from "antd";
import { useParams } from "react-router-dom";
import {
  closeEditSiteBuyModal,
  editDisplaySiteBuy,
  editVideoSiteBuy,
} from "../../../redux/actions/site-buy-actions";

const { TextArea } = Input;
const EditSiteBuyModal = ({ siteBuy, isEditSiteBuyModalOpen, siteBuyType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    let isMounted = true;
    if(isMounted ){

    if (siteBuy !== undefined) {
      var values = { name: siteBuy.name }
      form.setFieldsValue(values);
    }}
    return () => {
      isMounted = false;

      console.log("EditSiteBuyModal Unmounting-2");
    };
  }, []);

  //const siteBuyId = siteBuy && siteBuyType === "display" ? siteBuy.displaySiteBuyId : siteBuy.videoSiteBuyId;

  const handleSave = async (values) => {
    if (siteBuyType === "display") {
      dispatch(editDisplaySiteBuy(values, id));
    } else {
      dispatch(editVideoSiteBuy(values, id));
    }
    form.resetFields();
    dispatch(closeEditSiteBuyModal());
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeEditSiteBuyModal());
  };

  const SiteBuyType = siteBuyType.charAt(0).toUpperCase() + siteBuyType.slice(1);

  return (
    <>
      <Modal
        title={`Edit ${SiteBuyType} Site Buy`}
        visible={isEditSiteBuyModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        {siteBuy &&
          <Form
            form={form}
            initialValues={{
              remember: true,
            }}
            layout={"vertical"}
            onFinish={handleSave}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: `Please input ${SiteBuyType} Site Buy name!`,
                  whitespace: true,
                },
              ]}

            >
              <TextArea value={siteBuy.name} showCount maxLength={255} placeholder="Name" autoSize />
            </Form.Item>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        }
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isEditSiteBuyModalOpen: state.siteBuy.isEditSiteBuyModalOpen,
    siteBuyType: state.siteBuy.siteBuyType ? state.siteBuy.siteBuyType : "display",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeEditSiteBuyModal: () => dispatch(closeEditSiteBuyModal()),
    editDisplaySiteBuy: () => dispatch(editDisplaySiteBuy()),
    editVideoSiteBuy: () => dispatch(editVideoSiteBuy()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditSiteBuyModal);
