import { Types } from "../constants/site-list-types";
const initialState = {
  isAddSiteListModalOpen: false,
  siteListAdded: undefined,
  editSiteList: undefined,
};
export default function siteListReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ADD_SITE_LIST_MODAL:
      return {
        ...state,
        isAddSiteListModalOpen: action.payload,
      };
    case Types.ADD_SITE_LIST:
      return {
        ...state,
        siteListAdded: action.payload,
      };
    case Types.GET_EDIT_SITE_LIST:
      return {
        ...state,
        editSiteList: action.payload,
      };
    case Types.CLEAR_SITE_LIST_STATES:
      return {
        ...state,
        siteListAdded: undefined,
        editSiteList: undefined,
      };
    default:
      return state;
  }
}
