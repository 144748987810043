import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import {
  Form,
  Space,
  DatePicker,
  Select,
  Table,
  Collapse,
  Drawer,
  Button,
  Spin,
  Card,
  Col,
  Row,
} from "antd";
import moment from "moment";
import { timezones } from "../../constants/defaultValues";
import { loadViewDashboard, exportDashboardData } from "../../redux/actions/account-actions";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  FilterFilled,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  DownloadOutlined,
} from "@ant-design/icons";
// import { metrics } from "../../constants/defaultValues";
// import * as R from "ramda";
import { getDayEndDateTime, getDayStartDateTime, camelCaseToString, getUTCDate } from "../../utils/appUtils";
//import { metrics } from "../../constants/defaultValues";
import { isFloat } from "../../utils/appUtils";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;
const gridStyle = {
  border: "none",
  boxShadow: "none",
};

const AccountDashboard = ({ getDashboardData, isNew }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const [startEndDate, setStartEndDate] = useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  const [zone, setZone] = useState("America/Los_Angeles");
  const [updateUI, setUpdateUI] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedAccountValues, setSelectedAccountValues] = useState([]);
  const [selectedCampaignValues, setSelectedCampaignValues] = useState([]);
  const [selectedLineValues, setSelectedLineValues] = useState([]);
  const [selectedMediaValues, setSelectedMediaValues] = useState([]);
  const [selectedRateTypeValues, setSelectedRateTypeValues] = useState([]);
  const [dashboardData, setdashboardData] = useState([]);
  const [checkedmetrics, setcheckedmetrics] = useState([]);
  const [metrics, setMetrics] = useState([]);
  //const cloneMetrics = [...metrics];
  const [unCheckedmetrics, setUnCheckedmetrics] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [isSet, setIsSet] = useState(true);
  const [columns, setColumns] = useState([]);
  const [columnTitles, setColumnTitles] = useState([]);


  useEffect(() => {
    let isMounted = true;

    (async () => {
      var data = {};
      // data["startDate"] = startEndDate && startEndDate[0] ?
      //   getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
      // data["endDate"] = startEndDate && startEndDate[1] ?
      //   getDayEndDateTime(new Date(startEndDate[1])) : getDayEndDateTime(new Date());
      data["startDate"] = startEndDate && startEndDate[0] ?
        new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
      data["endDate"] = startEndDate && startEndDate[1] ?
        new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString() : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
      data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data["timezone"] = zone;
      setSubmitted(true);
      await dispatch(loadViewDashboard(data, true));
      if(isMounted ){

      if (getDashboardData && getDashboardData["dashboardList"]) {
        setdashboardData(getDashboardData && getDashboardData["dashboardList"]);
        loadFilters();
        var columnArray = [];
        columnArray.push({
          title: "Account",
          dataIndex: "account",
          key: "account",
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        },
          {
            title: "Campaign",
            dataIndex: "campaign",
            key: "campaign",
            render: (text) =>
              text && text !== null
                ? text.includes("-")
                  ? text.replace("-", "").replace("-", "")
                  : text
                : "unknown"

          }, {
          title: "Line",
          dataIndex: "line",
          key: "line",
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        });
        getDashboardData && getDashboardData["header"] && getDashboardData["header"].map((header) => {
          columnArray.push({
            title: camelCaseToString(header),
            dataIndex: header,
            key: header,
            render: (text, record) =>
              (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? ((isFloat(record.eventData[header]) ? record.eventData[header].toFixed(2) : record.eventData[header])) : "-")

          });
        });
        columnArray.push({
          title: "Media",
          dataIndex: "media",
          key: "media",
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        },
          {
            title: "Rate Type",
            dataIndex: "rateType",
            key: "rateType",
            render: (text) =>
              text && text !== null
                ? text.includes("-")
                  ? text.replace("-", "").replace("-", "")
                  : text
                : "unknown"

          }, {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          render: (text) =>
            text && text !== null
              ? text
              : "unknown"

        });
        setColumns(columnArray);
        var columnTitle = [];
        columnArray.map(item => {
          columnTitle.push(item.key);
        })
        setColumnTitles(columnTitle);
        var headers = getDashboardData["header"] ? getDashboardData["header"] : [];
        setMetrics(headers);
        const cloneMetrics = [...headers];
        setUnCheckedmetrics(cloneMetrics);

       } 
      setSubmitted(false);
   } })();

    var values = {
      date: [
        startEndDate && startEndDate[0] ? moment(new Date(startEndDate[0])) : "",
        startEndDate && startEndDate[1] ? moment(new Date(startEndDate[1])) : "",
      ],
      timezone: zone,
    };
    form.setFieldsValue(values);

    return () => { 
      isMounted = false;

    };
  }, [isNew]);

  useEffect(() => {
    handleFilters();
    return () => { };
  }, [isSet]);

  function loadFilters() {
    loadAccountFilters();
    loadCampaignFilters();
    loadLineFilters();
    loadMediaFilters();
    loadRateTypeFilters();
  }

  function loadAccountFilters() {
    const selectAccountValuesTemp = [];
    if (
      getDashboardData &&
      getDashboardData["accounts"] &&
      getDashboardData["accounts"].length > 0
    ) {
      getDashboardData["accounts"].filter(function (obj) {
        selectAccountValuesTemp.push(obj.id);
      });
      setSelectedAccountValues(selectAccountValuesTemp);
      setUpdateUI(!updateUI);
    }
    return selectAccountValuesTemp;
  }

  function loadCampaignFilters() {
    const selectCampaignValuesTemp = [];
    if (
      getDashboardData &&
      getDashboardData["campaigns"] &&
      getDashboardData["campaigns"].length > 0
    ) {
      getDashboardData["campaigns"].filter(function (obj) {
        selectCampaignValuesTemp.push(obj.id);
      });
      setSelectedCampaignValues(selectCampaignValuesTemp);
    }
  }

  function loadLineFilters() {
    const selectLineValuesTemp = [];
    if (
      getDashboardData &&
      getDashboardData["lines"] &&
      getDashboardData["lines"].length > 0
    ) {
      getDashboardData["lines"].filter(function (obj) {
        selectLineValuesTemp.push(obj.id);
      });
      setSelectedLineValues(selectLineValuesTemp);
    }
  }

  function loadMediaFilters() {
    const selectMediaValuesTemp = [];
    if (
      getDashboardData &&
      getDashboardData["media"] &&
      getDashboardData["media"].length > 0
    ) {
      getDashboardData["media"].filter(function (obj) {
        selectMediaValuesTemp.push(obj);
      });
      setSelectedMediaValues(selectMediaValuesTemp);
    }
  }

  function loadRateTypeFilters() {
    const selectRateTypeValuesTemp = [];
    if (
      getDashboardData &&
      getDashboardData["rateType"] &&
      getDashboardData["rateType"].length > 0
    ) {
      getDashboardData["rateType"].filter(function (obj) {
        selectRateTypeValuesTemp.push(obj);
      });
      setSelectedRateTypeValues(selectRateTypeValuesTemp);
    }
  }

  async function loadData(dates, timezone) {
    var values = {};
    if (!dates) {
      dates = startEndDate;
    }
    if (!timezone) {
      timezone = zone;
    }

    // values["startDate"] =
    //   dates && dates !== null && dates[0] ?
    //     getDayStartDateTime(new Date(dates[0])) : getDayStartDateTime(new Date());
    // values["endDate"] =
    //   dates && dates !== null && dates[1]
    //     ? getDayEndDateTime(new Date(dates[1]))
    //     : getDayEndDateTime(new Date());

    values["startDate"] =
      dates && dates !== null && dates[0] ?
        new Date(getUTCDate(getDayStartDateTime(new Date(dates[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    values["endDate"] =
      dates && dates !== null && dates[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(dates[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    values["timezone"] = timezone;
    values["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSubmitted(true);
    await dispatch(loadViewDashboard(values, false));
    setdashboardData(getDashboardData && getDashboardData["dashboardList"]);
    setSubmitted(false);
  }

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const onMetricsChange = (e) => {
    var checkedTemp = checkedmetrics;
    var unCheckedTemp = unCheckedmetrics;
    if (checkedTemp.includes(e.target.value)) {
      checkedTemp.splice(checkedTemp.indexOf(e.target.value), 1);
      unCheckedTemp.push(e.target.value);
    } else if (unCheckedTemp.includes(e.target.value)) {
      unCheckedTemp.splice(unCheckedTemp.indexOf(e.target.value), 1);
      checkedTemp.push(e.target.value);
    }
    console.log("unchecked temp: ", unCheckedTemp)
    setcheckedmetrics(checkedTemp);
    setUnCheckedmetrics(unCheckedTemp);
    //setColumns(unCheckedTemp);

    var columnArray = [];
    var columnTitle = [];
    columnArray.push({
      title: "Account",
      dataIndex: "account",
      key: "account",
      render: (text) =>
        text && text !== null
          ? text.includes("-")
            ? text.replace("-", "").replace("-", "")
            : text
          : "unknown"

    },
      {
        title: "Campaign",
        dataIndex: "campaign",
        key: "campaign",
        render: (text) =>
          text && text !== null
            ? text.includes("-")
              ? text.replace("-", "").replace("-", "")
              : text
            : "unknown"

      }, {
      title: "Line",
      dataIndex: "line",
      key: "line",
      render: (text) =>
        text && text !== null
          ? text.includes("-")
            ? text.replace("-", "").replace("-", "")
            : text
          : "unknown"

    });
    unCheckedTemp && unCheckedTemp.map((header) => {
      columnArray.push({
        title: camelCaseToString(header),
        dataIndex: header,
        key: header,
        render: (text, record) =>
          (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? ((isFloat(record.eventData[header]) ? record.eventData[header].toFixed(2) : record.eventData[header])) : "-")

      });
    });
    columnArray.push({
      title: "Media",
      dataIndex: "media",
      key: "media",
      render: (text) =>
        text && text !== null
          ? text.includes("-")
            ? text.replace("-", "").replace("-", "")
            : text
          : "unknown"

    },
      {
        title: "Rate Type",
        dataIndex: "rateType",
        key: "rateType",
        render: (text) =>
          text && text !== null
            ? text.includes("-")
              ? text.replace("-", "").replace("-", "")
              : text
            : "unknown"

      }, {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) =>
        text && text !== null
          ? text
          : "unknown"

    });
    setColumns(columnArray);
    columnArray.map(item => {
      columnTitle.push(item.key);
    })
    setColumnTitles(columnTitle);
  };

  const onDateChange = (value, dateString) => {
    setStartEndDate(dateString);
    loadData(dateString, zone);
  };

  const onChangeZone = (e) => {
    setZone(e);
    loadData(startEndDate, e);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  async function downloadFile() {
    var values = {};

    // values["startDate"] = startEndDate && startEndDate[0] ?
    //   getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
    // values["endDate"] = startEndDate && startEndDate[1] ?
    //   getDayEndDateTime(new Date(startEndDate[1])) : getDayEndDateTime(new Date());
    values["startDate"] = startEndDate && startEndDate[0] ?
    new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    values["endDate"] = startEndDate && startEndDate[1] ?
    new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString() : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    values["timezone"] = zone;
    values["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    values["selectedMetrics"] = columnTitles;

    var response = await dispatch(exportDashboardData(values));
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
    a.download = "AccountDelivery.xlsx";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)

  }

  const onClose = () => {
    setVisible(false);
  };

  const onReset = () => {
    loadFilters();
    setdashboardData(getDashboardData && getDashboardData["dashboardList"]);
  };

  const isAccountSelected = (id) => {
    var filtered = selectedAccountValues.filter(function (obj) {
      return id === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isCampaignSelected = (id) => {
    var filtered = selectedCampaignValues.filter(function (obj) {
      return id === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isLineSelected = (id) => {
    var filtered = selectedLineValues.filter(function (obj) {
      return id === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isMediaSelected = (item) => {
    var filtered = selectedMediaValues.filter(function (obj) {
      return item === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const isRateTypeSelected = (item) => {
    var filtered = selectedRateTypeValues.filter(function (obj) {
      return item === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const onAccountChange = (e) => {
    const selectAccountValuesTemp = selectedAccountValues;
    if (selectAccountValuesTemp.indexOf(e.target.value) > -1) {
      selectAccountValuesTemp.splice(
        selectAccountValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectAccountValuesTemp.push(e.target.value);
    }
    setSelectedAccountValues(selectAccountValuesTemp);
    handleFilters();
  };

  const onCampaignChange = (e) => {
    const selectCampaignValuesTemp = selectedCampaignValues;
    if (selectCampaignValuesTemp.indexOf(e.target.value) > -1) {
      selectCampaignValuesTemp.splice(
        selectCampaignValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectCampaignValuesTemp.push(e.target.value);
    }
    setSelectedCampaignValues(selectCampaignValuesTemp);
    handleFilters();
  };

  const onLineChange = (e) => {
    const selectLineValuesTemp = selectedLineValues;
    if (selectLineValuesTemp.indexOf(e.target.value) > -1) {
      selectLineValuesTemp.splice(
        selectLineValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectLineValuesTemp.push(e.target.value);
    }
    setSelectedLineValues(selectLineValuesTemp);
    handleFilters();
  };

  const onMediaChange = (e) => {
    const selectMediaValuesTemp = selectedMediaValues;
    if (selectMediaValuesTemp.indexOf(e.target.value) > -1) {
      selectMediaValuesTemp.splice(
        selectMediaValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectMediaValuesTemp.push(e.target.value);
    }
    setSelectedMediaValues(selectMediaValuesTemp);
    handleFilters();
  };

  const onRateTypeChange = (e) => {
    const selectRateTypeValuesTemp = selectedRateTypeValues;
    if (selectRateTypeValuesTemp.indexOf(e.target.value) > -1) {
      selectRateTypeValuesTemp.splice(
        selectRateTypeValuesTemp.indexOf(e.target.value),
        1
      );
    } else {
      selectRateTypeValuesTemp.push(e.target.value);
    }
    setSelectedRateTypeValues(selectRateTypeValuesTemp);
    handleFilters();
  };

  function handleFilters() {
    var selectAccounts = [];
    var selectCampaigns = [];
    var selectLines = [];
    var selectMedia = [];
    var selectRateType = [];

    const selectCampaignValuesTemp = selectedCampaignValues;
    const selectAccountValuesTemp = selectedAccountValues;
    const selectLineValuesTemp = selectedLineValues;
    const selectMediaValuesTemp = selectedMediaValues;
    const selectRateTypeValuesTemp = selectedRateTypeValues;

    if (
      getDashboardData &&
      getDashboardData["dashboardList"] &&
      getDashboardData["accounts"] &&
      getDashboardData["campaigns"] &&
      getDashboardData["lines"] &&
      getDashboardData["media"] &&
      getDashboardData["rateType"]
    ) {
      getDashboardData["accounts"].map(
        (account) =>
          selectAccountValuesTemp &&
          selectAccountValuesTemp.length > 0 &&
          selectAccountValuesTemp.map((selectedValue) =>
            selectedValue === account.id
              ? selectAccounts.push(account.name)
              : ""
          )
      );
      getDashboardData["campaigns"].map(
        (campaign) =>
          selectCampaignValuesTemp &&
          selectCampaignValuesTemp.length > 0 &&
          selectCampaignValuesTemp.map((selectedValue) =>
            selectedValue === campaign.id
              ? selectCampaigns.push(campaign.name)
              : ""
          )
      );
      getDashboardData["lines"].map(
        (line) =>
          selectLineValuesTemp &&
          selectLineValuesTemp.length > 0 &&
          selectLineValuesTemp.map((selectedValue) =>
            selectedValue === line.id ? selectLines.push(line.name) : ""
          )
      );
      getDashboardData["media"].map(
        (media) =>
          selectMediaValuesTemp &&
          selectMediaValuesTemp.length > 0 &&
          selectMediaValuesTemp.map((selectedValue) =>
            selectedValue === media ? selectMedia.push(media) : ""
          )
      );
      getDashboardData["rateType"].map(
        (rateType) =>
          selectRateTypeValuesTemp &&
          selectRateTypeValuesTemp.length > 0 &&
          selectRateTypeValuesTemp.map((selectedValue) =>
            selectedValue === rateType ? selectRateType.push(rateType) : ""
          )
      );

      var tempDashboardData = [];
      getDashboardData["dashboardList"].map((data) =>
        selectCampaigns.includes(data.campaign) &&
          selectAccounts.includes(data.account) &&
          selectLines.includes(data.line) &&
          selectMedia.includes(data.media) &&
          selectRateType.includes(data.rateType)
          ? tempDashboardData.push(data)
          : ""
      );
      setdashboardData(tempDashboardData);
      setUpdateUI(!updateUI);
    }
  }

  const onCheck = (type) => {
    if (type === "account") {
      loadAccountFilters();
    } else if (type === "campaign") {
      loadCampaignFilters();
    } else if (type === "line") {
      loadLineFilters();
    } else if (type === "media") {
      loadMediaFilters();
    } else if (type === "rateType") {
      loadRateTypeFilters();
    }
    setIsSet(!isSet);
    handleFilters();
  };

  const onUncheck = (type) => {
    if (type === "account") {
      setSelectedAccountValues([]);
    } else if (type === "campaign") {
      setSelectedCampaignValues([]);
    } else if (type === "line") {
      setSelectedLineValues([]);
    } else if (type === "media") {
      setSelectedMediaValues([]);
    } else if (type === "rateType") {
      setSelectedRateTypeValues([]);
    }
    setIsSet(!isSet);
    handleFilters();
  };

  return (
    <div>
      <Spin size={"large"} spinning={submitted}></Spin>
      <Card>
        <Form form={form} layout={"vertical"}>
          <Row className="account-form">
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Form.Item label="Date" name="date">
                  <RangePicker
                    showTime
                    format={dateFormat}
                    onChange={onDateChange}
                  />
                </Form.Item>
              </Card.Grid>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Form.Item label="Timezone" name="timezone">
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    onChange={onChangeZone}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {timezonesOptions}
                  </Select>
                </Form.Item>
              </Card.Grid>
            </Col>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <div className="upload-right account-form-filter">
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <FilterFilled onClick={showDrawer} />
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <DownloadOutlined onClick={downloadFile} />
                </Card.Grid>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
      <Drawer
        title={
          <>
            <Space size={80}>
              <span>Filters</span>
              <Button type="primary" onClick={onReset}>
                RESET
              </Button>
            </Space>
          </>
        }
        placement={"right"}
        width={300}
        onClose={onClose}
        visible={visible}
      >
        <Collapse>
          <Panel header="Account" key="1">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("account")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("account")}
              />
            </Space>
            {getDashboardData &&
              getDashboardData["accounts"].map((item) => (
                <ul key={item.id}>
                  <Checkbox
                    onChange={onAccountChange}
                    checked={isAccountSelected(item.id)}
                    value={item.id}
                  >
                    {item.name}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Campaign" key="2">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("campaign")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("campaign")}
              />
            </Space>
            {getDashboardData &&
              getDashboardData["campaigns"].map((item) => (
                <ul key={item.id}>
                  <Checkbox
                    onChange={onCampaignChange}
                    checked={isCampaignSelected(item.id)}
                    value={item.id}
                  >
                    {item.name}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Line" key="3">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("line")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("line")}
              />
            </Space>
            {getDashboardData &&
              getDashboardData["lines"].map((item) => (
                <ul key={item.id}>
                  <Checkbox
                    onChange={onLineChange}
                    checked={isLineSelected(item.id)}
                    value={item.id}
                  >
                    {item.name}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Media" key="4">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("media")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("media")}
              />
            </Space>
            {getDashboardData &&
              getDashboardData["media"].map((item) => (
                <ul key={item}>
                  <Checkbox
                    onChange={onMediaChange}
                    checked={isMediaSelected(item)}
                    value={item}
                  >
                    {item}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="RateType" key="5">
            <Space size={10}>
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                onClick={() => onCheck("rateType")}
              />
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                onClick={() => onUncheck("rateType")}
              />
            </Space>
            {getDashboardData &&
              getDashboardData["rateType"].map((item) => (
                <ul key={item}>
                  <Checkbox
                    onChange={onRateTypeChange}
                    checked={isRateTypeSelected(item)}
                    value={item}
                  >
                    {item}
                  </Checkbox>
                </ul>
              ))}
          </Panel>
          <Panel header="Metrics" key="6">
            {metrics.map((metrics) => (
              <ul key={metrics}>
                <Checkbox
                  onChange={onMetricsChange}
                  key={metrics}
                  value={metrics}
                  defaultChecked={metrics}>
                  {metrics}
                </Checkbox>
              </ul>
            ))}
          </Panel>
        </Collapse>
      </Drawer>
      <Card>{console.log("Columns: ", columns)}
        <Table
          rowKey={record => JSON.stringify(record)}
          scroll={{ x: 1200 }}
          columns={columns && columns}
          dataSource={dashboardData ? dashboardData : []}
        />
      </Card>

    </div>
  );
};

function mapStateToProps(state) {
  return {
    getDashboardData: state.account.getDashboardData,
    isNew: state.account.isNew,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AccountDashboard
);
