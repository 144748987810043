import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { UploadOutlined } from '@ant-design/icons';
import {
  Input,
  Image,
  InputNumber,
  Radio,
  Row,
  Col,
  Card,
  Checkbox,
  DatePicker,
  Typography,
  Collapse,
  Spin,
  Select
} from "antd";

import {
  closeAddVideoCreativeModal,
  loadAllVideoResourcesByAccountId,
  addVideoCreative,
  loadAllImageResourcesByAccountId,
  addTruengageCreative,
  addTagVideoCreative,
  clearEditCreativeReduxState
} from "./../../../redux/actions/creative-actions";
import { notifyUser } from "./../../../services/notification-service";
import moment from "moment";
import {
  getDayStartTime,
  getDayEndTime,
  setImagePath,
  setVideoPath,
  getUTCDate,
  getLocalDate
} from "../../../utils/appUtils";
import VideoCreativeForm from "./../../forms/video-creative-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import TruengagePreview from "./../../TruengagePreview";
import { loadCampaignById } from "../../../redux/actions/campaign-actions";
const { TextArea } = Input;
const { Title } = Typography;
const { Panel } = Collapse;
const { Meta } = Card;
const creativeTypeOptions = ["Tag Video Creative", "Video Creative", "Truengage Creative"];
const flightStartDateOptions = ["Start when line starts", "Custom Start"];
const flightEndDateOptions = ["End when line ends", "Custom End"];
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const { Option } = Select;
const AddCreativeModal = ({
  siteBuyType,
  isAddVideoCreativeModalOpen,
  editVideoLine,
  accountId,
  allVideoResources,
  allImageResources,
  devices,
  videoResourceAdded,
  editCampaign
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDevices, setSelectedDevices] = useState(devices);
  const [creativeType, setCreativeType] = useState("Video Creative");
  const [flightStartDateType, setFlightStartDateType] = useState("Start when line starts");
  const [flightEndDateType, setFlightEndDateType] = useState("End when line ends");
  const [selectVideoResourceId, setSelectVideoResourceId] = useState("");
  const [selectImageResourceId, setSelectImageResourceId] = useState("");
  const [startDate, setStartDate] = useState(moment(getDayStartTime(), dateFormat));
  const [endDate, setEndDate] = useState(moment(getDayEndTime(), dateFormat));
  const [startAt, setStartAt] = useState(getDayStartTime().getTime());
  const [endAt, setEndAt] = useState(getDayEndTime().getTime());
  const [sourceCode, setSourceCode] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const fileRef = useRef();
  useEffect(() => {
    dispatch(loadCampaignById(editVideoLine.campaignId));
    dispatch(loadAllVideoResourcesByAccountId(accountId));
    dispatch(loadAllImageResourcesByAccountId(accountId));
    const deviceNames = [];
    if (devices && devices.length > 0) {
      devices.filter(function (obj) {
        deviceNames.push(obj.name);
      });
      setSelectedDevices(deviceNames);
    }
    if (videoResourceAdded !== undefined) {
      if (videoResourceAdded.success === true && videoResourceAdded.message) {
        notifyUser(videoResourceAdded.message, "success");
      } else {
        notifyUser(videoResourceAdded.message, "error");
      }
    // } else {
    }
      if (editVideoLine && editVideoLine.videoLineVersion && editVideoLine.videoLineVersion.startAt) {
        setStartAt(getLocalDate(editVideoLine.videoLineVersion.startAt));
        setStartDate(moment(new Date(getLocalDate(editVideoLine.videoLineVersion.startAt)), dateFormat));
      }
      if (editVideoLine && editVideoLine.videoLineVersion && editVideoLine.videoLineVersion.endAt) {
        setEndAt(getLocalDate(editVideoLine.videoLineVersion.endAt));
        setEndDate(moment(new Date(getLocalDate(editVideoLine.videoLineVersion.endAt)), dateFormat));
      }
    // }
    return () => {
      console.log("AddPrivateBuyModal Unmounting");
      dispatch(clearEditCreativeReduxState());
    };
  }, [isAddVideoCreativeModalOpen, videoResourceAdded]);

  const checkboxSettings = () => {
    setIsChecked(!isChecked);
    if(isChecked === false) {
      form.setFieldsValue({retargetingPixelId: null, targetingState: null})
    }
  };
  console.log("editCampaign : ", editCampaign);
  const handleSave = (values) => {
    values["devices"] = JSON.stringify(selectedDevices);
    values["startDateType"] = flightStartDateType;
    values["startAt"] = getUTCDate(startAt);
    values["endDateType"] = flightEndDateType;
    values["endAt"] = getUTCDate(endAt);
    values["accountId"] = editVideoLine.accountId;
    values["videoLineId"] = editVideoLine.videoLineId;
    values["paused"] = false;
    if(!values["weight"]) {
      values["weight"] = 1;
    }

    if (creativeType === "Video Creative") {
      values["videoResourceId"] = selectVideoResourceId;
      if (selectVideoResourceId === "") {
        alert("Please select one of the video resources before proceed!");
        return;
      }
      dispatch(addVideoCreative(values));
    } else if (creativeType === "Truengage Creative") {
      values["videoResourceId"] = selectVideoResourceId;
      dispatch(addTruengageCreative(values));
    } else {
      dispatch(addTagVideoCreative(values));
    }
    form.resetFields();
    dispatch(closeAddVideoCreativeModal());
    resetFormStates();
  };
  const resetFormStates = () => {
    setCreativeType("Video Creative");
    setFlightStartDateType("Start when line starts");
    setFlightEndDateType("End when line ends");
    setSelectVideoResourceId("");
    setSelectImageResourceId("");
    setStartDate(moment(getDayStartTime(), dateFormat));
    setEndDate(moment(getDayEndTime(), dateFormat));
    setStartAt(getDayStartTime().getTime());
    setEndAt(getDayEndTime().getTime());
  };

  const [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeImageSrc,
    handleFileChange,
    onChangeFlightDateType,
    onDeviceChange,
    onChangeCreativeType,
    onChangeSourceCode,
  ] = VideoCreativeForm(
    form,
    setSelectVideoResourceId,
    setStartDate,
    setEndDate,
    setStartAt,
    setEndAt,
    editVideoLine && editVideoLine,
    accountId,
    setFlightStartDateType,
    setFlightEndDateType,
    selectedDevices,
    setSelectedDevices,
    setCreativeType,
    setSourceCode,
    setSubmitted
  );

  const videoes = [];
  if (allVideoResources) {
    Object.entries(allVideoResources).forEach(([key, value]) => {
      videoes.push(
        <Col key={key} xs={24} sm={12} md={8} lg={6} xl={6}>
          <Card
            style={{ padding: "10px" }}
            onClick={(e) => onChangeImageSrc(e, value.videoResourceId)}
            className={
              selectVideoResourceId === value.videoResourceId
                ? "selected-image-resource"
                : "image-resource"
            }
            bordered={false}
            cover={
              <video controls>
                <source src={setVideoPath(value)} type={value.mimeType} />
              </video>
            }
          ></Card>
        </Col>
      );
    });
  }

  const images = [];
  if (allImageResources) {
    Object.entries(allImageResources).forEach(([key, value]) => {
      images.push(
        <Col span={6}>
          <Card
            style={{ padding: "10px" }}
            onClick={(e) => onChangeImageSrc(e, value.imageResourceId)}
            className={
              selectImageResourceId === value.imageResourceId
                ? "selected-image-resource"
                : "image-resource"
            }
            bordered={false}
            cover={<Image preview={true} src={setImagePath(value)} />}
          >
            <Meta description={value.width + "x" + value.height}></Meta>
            <CopyToClipboard text={setImagePath(value)} onCopy={() => console.log(`copied`)}>
              <CopyOutlined />
            </CopyToClipboard>
          </Card>
        </Col>
      );
    });
  }

  function _bindTruengageCreativeProps() {
    return (
      <>
        <div className="card-cnt-sec">
          <Title level={3}>{"Setup Resource"}</Title>
          <Form.Item
            label="Game Duration(Seconds)"
            name="duration"
            rules={[
              {
                required: true,
                message: "Please input Game Duration!",
              },
            ]}
          >
            <InputNumber placeholder="Game Duration(Seconds)" />
          </Form.Item>
          <Form.Item
            label="Game Engine Version"
            name="engineVersion"
            rules={[
              {
                required: true,
                message: "Please input Game Engine Version!",
              },
            ]}
          >
            <Input placeholder="Game Engine Version" />
          </Form.Item>
          <Collapse bordered={false}>
            <Panel
              header={
                selectVideoResourceId === ""
                  ? "Background Video (None Selected)"
                  : "Background Video  (1 Selected)"
              }
              key="1"
            >
              <Row gutter={16}>{videoes}</Row>
            </Panel>
          </Collapse>
          <Collapse bordered={false}>
            <Panel header={"Image Resource (None Selected)"} key="2">
              <Row gutter={16}>{images}</Row>
            </Panel>
          </Collapse>
          <Collapse bordered={false}>
            <Panel header={"Source"} key="3">
              <Form.Item
                label="Source"
                name="source"
                rules={[
                  {
                    required: true,
                    message: "Please input Source!",
                  },
                ]}
              >
                <TextArea
                  rows={10}
                  placeholder="Truengage Source Code"
                  onChange={onChangeSourceCode}
                />
              </Form.Item>
              <div className="ad-content">
                <h2>Ad Preview</h2>

                {sourceCode !== "" ? (
                  <TruengagePreview
                    sourceCode={sourceCode}
                    creativeName={"Untitled Creative"}
                    width={640}
                    height={360}
                    versionURL={"https://assets.xpsads.com/jynx/v2.2.1.js"}
                    truengageCreativeType={"Draggable"}
                  />
                ) : (
                    ""
                  )}
              </div>
            </Panel>
          </Collapse>
          <Collapse bordered={false}>
            <Panel header={"Event Pixel"} key="3">
              <Input placeholder="Game Engine Version" />
            </Panel>
          </Collapse>
        </div>
      </>
    );
  }

  function _bindVideoCreativeProps() {
    return (
      <div className="card-cnt-sec">
        <Title level={3}>{"Setup Resource"}</Title>
        <Form.Item name="resource">
          <div className="add-image-div">
            <input
              type="file"
              id="addImageResource"
              ref={fileRef}
              accept="video/*"
              onChange={handleFileChange}
            />
            <label htmlFor="addImageResource">
              <UploadOutlined />
              <span className="plus-icon">+</span>Add Video
            </label>
          </div>
          <Row className="overflow-v" gutter={16}>{videoes}</Row>
        </Form.Item>
      </div>
    );
  }

  function _bindTagVideoCreativeProps() {
    return (
      <div className="card-cnt-sec">
        <Title level={3}>{"Setup Resource"}</Title>
        <Form.Item
          label="Source"
          name="source"
          rules={[
            {
              required: true,
              message: "Please input Source!",
            },
          ]}
        >
          <TextArea placeholder="Source" />
        </Form.Item>
        <Form.Item
          label="Duration(Seconds)"
          name="duration"
          rules={[
            {
              required: true,
              message: "Please input Duration!",
            },
          ]}
        >
          <InputNumber placeholder="Duration(Seconds)" />
        </Form.Item>
      </div>
    );
  }

  return (
    <>
      <Modal
        title="New Creative"
        visible={isAddVideoCreativeModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
      <Spin className="spin-loader" style={{zIndex:9999}} size={"large"} spinning={submitted}></Spin>
        <Form form={form} layout={"vertical"} onFinish={handleSave}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input creative name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Untitled Creative" autoSize />
          </Form.Item>
          <Form.Item label="Resource Type" name="resourceType">
            <Radio.Group
              defaultValue={creativeType}
              options={creativeTypeOptions}
              onChange={onChangeCreativeType}
            />
          </Form.Item>
          {creativeType === "Video Creative"
            ? _bindVideoCreativeProps()
            : creativeType === "Truengage Creative"
              ? _bindTruengageCreativeProps()
              : _bindTagVideoCreativeProps()}

          <div className="card-cnt-sec grid-col-2">
            <Title level={3}>{"Flight Dates"}</Title>
            <Form.Item label="Start Date" name="flighStartDateType">
              <Radio.Group
                name={"startDate"}
                defaultValue={"Start when line starts"}
                onChange={onChangeFlightDateType}
                options={flightStartDateOptions}
              />
              {flightStartDateType === "Start when line starts" ? (
                ""
              ) : (
                  <DatePicker
                    defaultValue={startDate}
                    value={startDate}
                    showTime
                    format={dateFormat}
                    onChange={onStartDateChange}
                  />
                )}
            </Form.Item>
            <Form.Item label="End Date" name="flighEndDateType">
              <Radio.Group
                name={"endDate"}
                defaultValue={"End when line ends"}
                onChange={onChangeFlightDateType}
                options={flightEndDateOptions}
              />
              {flightEndDateType === "End when line ends" ? (
                ""
              ) : (
                  <DatePicker
                    defaultValue={endDate}
                    value={endDate}
                    showTime
                    format={dateFormat}
                    onChange={onEndDateChange}
                  />
                )}
            </Form.Item>
          </div>
          <Form.Item
            label="Weight"
            name="weight"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input weight!",
            //   },
            // ]}
          >
            <InputNumber defaultValue={1} min={1}/>
          </Form.Item>
          <Form.Item label="Devices" name="devices">
            <Row>
              {devices &&
                devices.map((device) => (
                  <Col key={device} xs={12} sm={8} md={6} lg={6} xl={6} >
                    <Checkbox defaultChecked value={device.name} onChange={onDeviceChange}>
                      {device.name}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Form.Item>
          <Form.Item>
            <Checkbox 
            label = "Do you want to set as sequential?"
            checked={isChecked}
            onChange={checkboxSettings} >Do you want to set as sequential?</Checkbox>
          </Form.Item>
          {isChecked ? (<><Form.Item label="Retargeting Pixel" name="retargetingPixelId">
            <Select defaultValue="" style={{ width: '100%' }}>
              {editCampaign && editCampaign.assignedRetargetingPixels.map((option) => (
                (
                  <Option value={option.retargetingPixelId} key={option.retargetingPixelId}>{option.name}</Option>
                )
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Target State"
            name="targetingState"
          >
              <Input />
            </Form.Item></>) : ""
            }
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddVideoCreativeModalOpen: state.creative.isAddVideoCreativeModalOpen,
    allVideoResources: state.creative.allVideoResources,
    allImageResources: state.creative.allImageResources,
    devices: state.line.devices,
    videoResourceAdded: state.creative.videoResourceAdded,
    editCampaign: state.campaign.editCampaign
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCreativeModal);
