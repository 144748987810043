import React from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select, InputNumber } from "antd";
import {
  closeNewSiteBuyModal,
  addDisplaySiteBuy,
  addVideoSiteBuy,
} from "../../../redux/actions/site-buy-actions";
import { displayRateTypes, videoRateTypes } from "./../../../constants/defaultValues";
const { TextArea } = Input;
const { Option } = Select;
const AddSiteBuyModal = ({ isAddSiteBuyModalOpen, siteBuyType, publisherID }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSave = async (values) => {
    if (siteBuyType === "display") {
      dispatch(addDisplaySiteBuy(values, publisherID));
    } else {
      dispatch(addVideoSiteBuy(values, publisherID));
    }
    form.resetFields();
    dispatch(closeNewSiteBuyModal());
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewSiteBuyModal());
  };

  const SiteBuyType = siteBuyType.charAt(0).toUpperCase() + siteBuyType.slice(1);

  const options = siteBuyType === "display" ? displayRateTypes : videoRateTypes;
  return (
    <>
      <Modal
        title={`New ${SiteBuyType} Site Buy`}
        visible={isAddSiteBuyModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: `Please input ${SiteBuyType} Site Buy name!`,
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Name" autoSize />
          </Form.Item>
          <Form.Item className="left-space"
            label="Rate"
            name="rate"
            rules={[
              {
                required: true,
                message: `Please input ${SiteBuyType} Site Buy Rate!`,
              },
            ]}
          >
            <InputNumber
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Rate Type"
            name="rateType"
            rules={[
              {
                required: true,
                message: `Please input ${SiteBuyType} Site Buy Rate!`,
                whitespace: true,
              },
            ]}
          >
            <Select>
              {options.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddSiteBuyModalOpen: state.siteBuy.isAddSiteBuyModalOpen,
    siteBuyType: state.siteBuy.siteBuyType ? state.siteBuy.siteBuyType : "display",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewSiteBuyModal: () => dispatch(closeNewSiteBuyModal()),
    addDisplaySiteBuy: () => dispatch(addDisplaySiteBuy()),
    addVideoSiteBuy: () => dispatch(addVideoSiteBuy()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddSiteBuyModal);
