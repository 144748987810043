export const Types = {
  GET_PUBLISHER_LIST: "GET_PUBLISHER_LIST",
  OPEN_ADD_PUBLISHER_MODAL: "OPEN_ADD_PUBLISHER_MODAL",
  ADD_PUBLISHER: "ADD_PUBLISHER",
  GET_EDIT_PUBLISHER: "GET_EDIT_PUBLISHER",
  OPEN_BLACKLIST_WHITELIST_ACCOUNT_MODAL: "OPEN_BLACKLIST_WHITELIST_ACCOUNT_MODAL",
  UPDATE_PUBLISHER: "UPDATE_PUBLISHER",
  CLEAR_EDIT_PUBLISHER_STATES: "CLEAR_EDIT_PUBLISHER_STATES",
  LOAD_PUBLISHER_VIEW_DASHBOARD: "LOAD_PUBLISHER_VIEW_DASHBOARD",
};
