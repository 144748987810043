import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { openNewSegmentModal, loadAllSegments, clearEditSegmentStates } from "../../redux/actions/segment-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { notifyUser } from "../../services/notification-service";
import { List, Row, Col, Button, PageHeader, Breadcrumb, Spin } from "antd";
import { compose } from "redux";
import AddSegmentModal from "../../components/modals/segments/add-segment";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";


const Pk4SegmentList = ({ segmentList, segmentAdded }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadAllSegments());
      if(isMounted ){

      setSubmitted(false);
  }})();

    if (segmentAdded !== undefined) {
      if (segmentAdded.success === true) {
        notifyUser(segmentAdded.message, "success");
      } else {
        notifyUser(segmentAdded.message, "error");
      }
    }
    return () => {
      isMounted = false;

      console.log("AddPk4Segment Unmounting");
      dispatch(clearEditSegmentStates());
    };
  }, [segmentAdded]);

  return (
    <>
      <section className="white-box-wrapper">
        <Spin size={"large"} spinning={submitted}></Spin>
        
        <Row className="align-center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/pk4segments">Pk4Segment</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        
        
        <Row>
        
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PageHeader
              title="Pk4 Segments"
              extra={
                <div>
                {currentUserPermissionMap[currentUserRole]["canCreateOrUpdatePk4Segments"] ? (
                <Button key="1" type="primary" onClick={() => dispatch(openNewSegmentModal())}>
                  New
                </Button>
              ) : (
                ""
              )}
              </div>
            }
              
            />
          </Col>
          
        </Row>
       
        <Row gutter={30}>
          
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              className="card-box"
              bordered
              dataSource={segmentList && segmentList.length > 0 ? segmentList : []}
              renderItem={(pk4Segment) =>
                <List.Item>
                  {/* {item.name} */}
                  <Link to={`/pk4segments/${pk4Segment.segmentId}`}>{pk4Segment.name}</Link>
                </List.Item>}
            />
          </Col>
          
        </Row>
        
        <AddSegmentModal />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    segmentList: state.pk4Segment.segmentList,
    segmentAdded: state.pk4Segment.segmentAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openNewSegmentModal: () => dispatch(openNewSegmentModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Pk4SegmentList);
