import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loadUserById,
  openAssignPublisherModal,
  openAssignAccountModal,
  openNewUserModal,
  clearEditUserStates
} from "../../redux/actions/user-actions";
import { loadAccounts } from "../../redux/actions/account-actions";
import { loadPublishers } from "../../redux/actions/publisher-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Breadcrumb,
  PageHeader,
  Switch,
  List,
  Row,
  Col,
  Typography,
  Spin
} from "antd";
import { compose } from "redux";
import { adminCanAccess } from "./../../constants/defaultValues";
import { checkUserRole } from "./../../utils/appUtils";
import AccountPrivilegeModal from "./../../components/modals/assign-privilege/account-privilege";
import PublisherPrivilegeModal from "./../../components/modals/assign-privilege/publisher-privilege";
import AddUserModal from "./../../components/modals/user/add-user";
import { notifyUser } from "./../../services/notification-service";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";

const { Text, Title } = Typography;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const EditUser = ({ editUserData, accountList, publisherList, userUpdated }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  useEffect(() => {
    (async () => {
      setSubmitted(true);
      await dispatch(loadUserById(id));
      await dispatch(loadAccounts());
      await dispatch(loadPublishers());
      setSubmitted(false);
    })();

    if (userUpdated !== undefined) {
      if (userUpdated.success === true) {
        notifyUser(userUpdated.message, "success");
      } else {
        notifyUser(userUpdated.message, "error");
      }
    }
    return () => {
      console.log("UserList Unmounting");
      dispatch(clearEditUserStates());
    };
  }, [userUpdated]);

  var userRole = undefined;
  if (editUserData) {
    userRole = checkUserRole(editUserData.roles);
  }

  function _bindAllPrivileges(privilegeType, privileges) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={privilegeType}
            bordered
            dataSource={
              privilegeType === "Publishers" ? privileges.publishers : privileges.accounts
            }
            renderItem={(privilege) => <List.Item>{privilege}</List.Item>}
          />
        </Col>
      </Row>
    );
  }

  function _bindAccountPrivileges(privilegeType, privileges) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={privilegeType}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateUser"] ? (
                <div>
                  <Button onClick={() => dispatch(openAssignAccountModal())} type="link">
                    Edit
                  </Button>
                </div>
              ) : ""
            }
            bordered
            dataSource={privileges.accounts}
            renderItem={(privilege) => <List.Item>{privilege.name}</List.Item>}
          />
        </Col>
      </Row>
    );
  }

  function _bindPublisherPrivileges(privilegeType, privileges) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={privilegeType}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateUser"] ? (
                <div>
                  <Button onClick={() => dispatch(openAssignPublisherModal())} type="link">
                    Edit
                  </Button>
                </div>
              ) : ""
            }
            bordered
            dataSource={privileges.publishers}
            renderItem={(privilege) => <List.Item>{privilege.name}</List.Item>}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <section className="white-box-wrapper">
        <Spin size={"large"} spinning={submitted}></Spin>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/users">Users</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        {editUserData ? (
          <>
            <PageHeader
              className="site-page-header"
              title={editUserData.firstName + " " + editUserData.lastName}
            />

            <Row gutter={16}>
              <Col span={24}>
                <Card title="User Details">
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>{editUserData.firstName}</Title>
                    <Text type="secondary">First Name</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>{editUserData.lastName}</Title>
                    <Text type="secondary">Last Name</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>{editUserData.email}</Title>
                    <Text type="secondary">Email</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editUserData.roles && editUserData.roles[0] ? editUserData.roles[0].name : ""}
                    </Title>
                    <Text type="secondary">User Role</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Switch disabled={true} checked={editUserData.isInternal} />
                    {" Is Internal"}
                  </Card.Grid>
                  {currentUserPermissionMap[currentUserRole]["canUpdateUser"] ? (
                    <div className="ant-card-footer">
                      <Button key="1" type="primary" onClick={() => dispatch(openNewUserModal())}>
                        Edit
                      </Button>
                    </div>
                  ) : ""}
                </Card>
              </Col>
            </Row>

            {userRole && userRole === "ROLE_ADMIN" ? (
              <>
                {_bindAllPrivileges("Accounts", adminCanAccess)}
                {_bindAllPrivileges("Publishers", adminCanAccess)}
              </>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_PARTNER_DEVELOPMENT" ? (
              <>
                {_bindAccountPrivileges("Accounts", editUserData)}
                {_bindPublisherPrivileges("Publishers", editUserData)}
              </>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_PARTNER_DEVELOPMENT_LEAD" ? (
              <>
                {_bindAllPrivileges("Accounts", adminCanAccess)}
                {_bindAllPrivileges("Publishers", adminCanAccess)}
              </>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_PUBLISHER_OBSERVER" ? (
              <> {_bindPublisherPrivileges("Publishers", editUserData)}</>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_ACCOUNT_MANAGER_LEAD" ? (
              <>{_bindAllPrivileges("Accounts", adminCanAccess)}</>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_ACCOUNT_OBSERVER" ? (
              <>{_bindAccountPrivileges("Accounts", editUserData)}</>
            ) : (
              ""
            )}
            {userRole && userRole === "ROLE_ACCOUNT_MANAGER" ? (
              <>{_bindAccountPrivileges("Accounts", editUserData)}</>
            ) : (
              ""
            )}
            <AccountPrivilegeModal
              accountList={accountList}
              selectedAccountList={editUserData && editUserData.accounts}
            />

            <PublisherPrivilegeModal
              publisherList={publisherList}
              selectedPublisherList={editUserData && editUserData.publishers}
            />
          </>
        ) : (
          ""
        )}
        <AddUserModal editUserInfo={editUserData} />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    editUserData: state.user.editUserData,
    accountList: state.account.accountList,
    publisherList: state.publisher.publisherList,
    userUpdated: state.user.userUpdated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadUserById: () => dispatch(loadUserById()),
    loadAccounts: () => dispatch(loadAccounts()),
    loadPublishers: () => dispatch(loadPublishers()),
    openAssignPublisherModal: () => dispatch(openAssignPublisherModal()),
    openAssignAccountModal: () => dispatch(openAssignAccountModal()),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(EditUser);
