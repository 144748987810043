import { Types } from "../constants/site-buy-types";
const initialState = {
  displaySiteBuyList: [],
  videoSiteBuyList: [],
  isAddSiteBuyModalOpen: false,
  isSiteBuyAdded: undefined,
  siteBuyAdded: undefined,
  siteBuyMessageOnAdd: undefined,
  displaySiteBuy: undefined,
  videoSiteBuy: undefined,
  siteBuyReport: undefined,
  siteBuyOverviewData: undefined
};
export default function siteBuyReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_DISPLAY_SITE_BUY_LIST:
      return {
        ...state,
        displaySiteBuyList: action.payload,
      };
    case Types.GET_VIDEO_SITE_BUY_LIST:
      return {
        ...state,
        videoSiteBuyList: action.payload,
      };
    case Types.OPEN_ADD_SITE_BUY_MODAL:
      return {
        ...state,
        isAddSiteBuyModalOpen: action.payload.isAddSiteBuyModalOpen,
        siteBuyType: action.payload.siteBuyType,
      };
    case Types.ADD_SITE_BUY:
      return {
        ...state,
        siteBuyAdded: action.payload.success,
        siteBuyMessageOnAdd: action.payload.message,
      };
    case Types.GET_DISPLAY_SITE_BUY:
      return {
        ...state,
        displaySiteBuy: action.payload,
      };
    case Types.GET_VIDEO_SITE_BUY:
      return {
        ...state,
        videoSiteBuy: action.payload,
      };
    case Types.OPEN_EDIT_SITE_BUY_MODAL:
      return {
        ...state,
        isEditSiteBuyModalOpen: action.payload.isEditSiteBuyModalOpen,
        siteBuyType: action.payload.siteBuyType,
        siteBuyUpdated: (action.payload.isEditSiteBuyModalOpen && state.siteBuyUpdated) ? false : state.siteBuyUpdated,
      };
    case Types.EDIT_SITE_BUY:
      return {
        ...state,
        siteBuyUpdated: action.payload.success,
        siteBuyMessageOnUpdate: action.payload.message,
      };
    case Types.SITE_BUY_REPORT:
      return {
        ...state,
        siteBuyReport: action.payload,
      };
    case Types.SITE_BUY_OVERVIEW_DATA:
      return {
        ...state,
        siteBuyOverviewData: action.payload,
      };
    case Types.CLEAR_SITE_BUY_STATES:
      return {
        ...state,
        displaySiteBuyList: [],
        videoSiteBuyList: [],
        siteBuyAdded: undefined,
        siteBuyMessageOnAdd: undefined,
        displaySiteBuy: undefined,
        videoSiteBuy: undefined,
      };
    default:
      return state;
  }
}
