export const Types = {
  GET_CAMPAIGN_LIST: "GET_CAMPAIGN_LIST",
  OPEN_ADD_CAMPAIGN_MODAL: "OPEN_ADD_CAMPAIGN_MODAL",
  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  EDIT_CAMPAIGN: "EDIT_CAMPAIGN",
  UPDATE_CAMPAIGN: "UPDATE_CAMPAIGN",
  OPEN_BLACKLIST_WHITELIST_PUBLISHER_MODAL: "OPEN_BLACKLIST_WHITELIST_PUBLISHER_MODAL",
  OPEN_BLACKLIST_WHITELIST_PRIVATE_BUY_MODAL: "OPEN_BLACKLIST_WHITELIST_PRIVATE_BUY_MODAL",
  OPEN_BLACKLIST_WHITELIST_SITE_BUY_MODAL: "OPEN_BLACKLIST_WHITELIST_SITE_BUY_MODAL",
  OPEN_ADD_CAMPAIGN_CATEGORY_MODAL: "OPEN_ADD_CAMPAIGN_CATEGORY_MODAL",
  GET_ALL_CAMPAIGN_CATEGORIES: "GET_ALL_CAMPAIGN_CATEGORIES",
  OPEN_ADD_CAMPAIGN_PIXEL_MODAL: "OPEN_ADD_CAMPAIGN_PIXEL_MODAL",
  CLEAR_EDIT_CAMPAIGN_STATES: "CLEAR_EDIT_CAMPAIGN_STATES",
};
