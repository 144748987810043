import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Row, Col, Breadcrumb, PageHeader, Spin } from "antd";
import {
  loadImageCreativeById,
  openUpdateCreativeModal,
  openUpdateTagDisplayCreativeModal,
  loadTagDisplayCreativeById,
  clearEditCreativeReduxState,
} from "../../redux/actions/creative-actions";
import EditImageCreativeModal from "../../components/modals/creative/edit-display-creative";
import EditTagDisplayCreativeModal from "../../components/modals/creative/edit-tag-display-creative";
import { loadAllDevices } from "../../redux/actions/line-actions";
import { notifyUser } from "../../services/notification-service";
import ImageCreativeDetailLayout from "./../../components/layout/edit-creative/image-creative-details";
import TagDisplayCreativeDetailLayout from "./../../components/layout/edit-creative/tag-display-creative-details";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";

const EditCreative = ({
  editImageCreative,
  displayCreativeUpdated,
  editTagDisplayCreative,
  tagDisplayCreativeUpdated,
}) => {
  const dispatch = useDispatch();
  const { id, creativeType } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  useEffect(() => {
    (async () => {
      setSubmitted(true);
      if (creativeType === "image-creative") {
        await dispatch(loadImageCreativeById(id));
      } else {
        await dispatch(loadTagDisplayCreativeById(id));
      }
      await dispatch(loadAllDevices());
      setSubmitted(false);
    })();

    if (displayCreativeUpdated !== undefined) {
      if (displayCreativeUpdated.success === true) {
        notifyUser(displayCreativeUpdated.message, "success");
      } else {
        notifyUser(displayCreativeUpdated.message, "error");
      }
    }

    if (tagDisplayCreativeUpdated !== undefined) {
      if (tagDisplayCreativeUpdated.success === true) {
        notifyUser(tagDisplayCreativeUpdated.message, "success");
      } else {
        notifyUser(tagDisplayCreativeUpdated.message, "error");
      }
    }

    return () => {
      dispatch(clearEditCreativeReduxState());
    };
  }, [displayCreativeUpdated, tagDisplayCreativeUpdated]);

  return (
    <section className="white-box-wrapper">
      <Spin size={"large"} spinning={submitted}></Spin>
      {editImageCreative && creativeType === "image-creative" ? (
        <Row className="align-top">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editImageCreative.displayLine.accountId}`}>Account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/campaigns/${editImageCreative.displayLine.accountId}`}>Campaign</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/display/${editImageCreative.displayLine.displayLineId}`}>Line</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/creatives/${creativeType}/${id}`}>{creativeType}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
              className="site-page-header"
              subTitle={
                editImageCreative &&
                editImageCreative.displayLine &&
                editImageCreative.displayLine.account.name +
                " - " +
                editImageCreative.displayLine.campaign.name +
                " - " +
                editImageCreative.displayLine.displayLineVersion.name
              }
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PageHeader
              className="site-page-header justify-right edit-block"
              title={
                editImageCreative &&
                editImageCreative.imageCreativeVersion &&
                editImageCreative.imageCreativeVersion.name
              }
              subTitle={currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <i
                  className="fas fa-edit"
                  onClick={() => dispatch(openUpdateCreativeModal("Name"))}
                ></i>
              ) : ""
              }
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
      {editTagDisplayCreative && creativeType === "tag-display-creative" ? (
        <Row className="align-top">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editTagDisplayCreative.displayLine.accountId}`}>Account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editTagDisplayCreative.displayLine.accountId}`}>Campaign</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/display/${editTagDisplayCreative.displayLine.displayLineId}`}>Line</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/creatives/${creativeType}/${id}`}>{creativeType}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
              className="site-page-header"
              subTitle={
                editTagDisplayCreative &&
                editTagDisplayCreative.displayLine &&
                editTagDisplayCreative.displayLine.account.name +
                " - " +
                editTagDisplayCreative.displayLine.campaign.name +
                " - " +
                editTagDisplayCreative.displayLine.displayLineVersion.name
              }
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PageHeader
              className="site-page-header justify-right edit-block"
              title={
                editTagDisplayCreative &&
                editTagDisplayCreative.tagDisplayCreativeVersion &&
                editTagDisplayCreative.tagDisplayCreativeVersion.name
              }
              subTitle={
                currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                  <i
                    className="fas fa-edit"
                    onClick={() => dispatch(openUpdateTagDisplayCreativeModal("Name"))}
                  ></i>
                ) : ""
              }
            />
          </Col>
        </Row>
      ) : (
        ""
      )}
      <>
        {editImageCreative && editImageCreative.imageCreativeVersion ? (
          <>
            <ImageCreativeDetailLayout editImageCreative={editImageCreative} />
            {editImageCreative.displayLine && (
              <EditImageCreativeModal
                creativeType={creativeType}
                editCreativeInfo={editImageCreative}
                displayLine={editImageCreative.displayLine}
                accountId={editImageCreative.displayLine.accountId}
              />
            )}
          </>
        ) : (
          ""
        )}

        {editTagDisplayCreative && editTagDisplayCreative.tagDisplayCreativeVersion ? (
          <>
            <TagDisplayCreativeDetailLayout editTagDisplayCreative={editTagDisplayCreative} />
            {editTagDisplayCreative.displayLine && (
              <EditTagDisplayCreativeModal
                creativeType={creativeType}
                editCreativeInfo={editTagDisplayCreative}
                displayLine={editTagDisplayCreative.displayLine}
                accountId={editTagDisplayCreative.displayLine.accountId}
              />
            )}
          </>
        ) : (
          ""
        )}
      </>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    editImageCreative: state.creative.editImageCreative,
    displayCreativeUpdated: state.creative.displayCreativeUpdated,
    editTagDisplayCreative: state.creative.editTagDisplayCreative,
    tagDisplayCreativeUpdated: state.creative.tagDisplayCreativeUpdated,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditCreative);
