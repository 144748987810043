import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  loadAccounts,
  openNewAccountModal,
  clearEditAccountStates,
} from "../../redux/actions/account-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AddAccountModal from "../../components/modals/account/add-account";
import AddPublisherModal from "../../components/modals/publisher/add-publisher";
import { notifyUser } from "../../services/notification-service";
import {  Row, Col, Button, Spin, Divider } from "antd";
import { compose } from "redux";
import {
  loadPublishers,
  openNewPublisherModal,
  clearEditPublisherStates,
} from "../../redux/actions/publisher-actions";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";

const Dashboard = ({
  accountList,
  publisherList,
  accountAdded,
  publisherAdded,
}) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
  const [viewOldAccount, setViewOldAccount] = useState(false);
  const oldAccounts = [];
  const recentAccounts = [];

  useEffect(() => {
    setSubmitted(true);
    dispatch(loadAccounts());
    dispatch(loadPublishers());
    setSubmitted(false);
    if (accountAdded !== undefined) {
      if (accountAdded.success === true) {
        notifyUser(accountAdded.message, "success");
      } else {
        notifyUser(accountAdded.message, "error");
      }
    }
    if (publisherAdded !== undefined) {
      if (publisherAdded.success === true) {
        notifyUser(publisherAdded.message, "success");
      } else {
        notifyUser(publisherAdded.message, "error");
      }
    }
    return () => {};
  }, [accountAdded, publisherAdded]);

  useEffect(() => {
    return () => {
      dispatch(clearEditAccountStates());
      dispatch(clearEditPublisherStates());
    };
  }, []);

  if (accountList && accountList.length > 0) {
    accountList.map((account, index) => {
      if (account && account.age && account.age === "RECENT") {
        recentAccounts.push(account);
      } else {
        oldAccounts.push(account);
      }
    });
  }

  // to list out Accounts
  function _bindAccounts(accountList) {
    return accountList.map((account, index) => {
      return (
        <li key={"account" + index} className="check-box">
          <Link to={`/accounts/${account.accountId}`}>
            <span>{account.name} </span>
          </Link>
        </li>
      );
    });
  }

  // to list out Publishers
  function _bindPublishers(publishertList) {
    return publishertList.map((publisher, index) => {
      return (
        <li key={"publisher" + index} className="check-box">
          <Link to={`/publishers/${publisher.publisherId}`}>
            <span>{publisher.name} </span>
          </Link>
        </li>
      );
    });
  }

  return (
    <>
      <Spin size={"large"} spinning={submitted}></Spin>
      {currentUserRole !== "" ? (
        <Row gutter={30}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="white-box old_line ">
              <div className="title">
                <h2>Accounts</h2>
              </div>
              <Divider />
              <div className="card-body account_check">
                <div className="box-height">
                  <ul id="recentAccounts">
                    {recentAccounts && recentAccounts.length > 0 ? (
                      _bindAccounts(recentAccounts)
                    ) : (
                      <span className="leftSpace">There is no account</span>
                    )}
                  </ul>
                  {accountList &&
                  accountList.length > 0 &&
                  oldAccounts &&
                  oldAccounts.length > 0 ? (
                    <span
                      className="view-campaign"
                      onClick={() => setViewOldAccount(!viewOldAccount)}
                    >
                      {accountList && oldAccounts && oldAccounts.length > 1
                        ? oldAccounts.length + " Old Accounts"
                        : oldAccounts.length + " Old Account"}
                    </span>
                  ) : (
                    ""
                  )}
                  {viewOldAccount ? (
                    <ul id="oldAccounts">
                      {oldAccounts ? _bindAccounts(oldAccounts) : ""}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {currentUserPermissionMap[currentUserRole][
                "canCreateAccountCampaign"
              ] ? (
                <div className="card-footer">
                  <Button
                    onClick={() => dispatch(openNewAccountModal())}
                    type="link"
                  >
                    New Account
                  </Button>
                  <Button type="link">
                    <Link to="/account-dashboard">View Dashboard</Link>
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
          {currentUserPermissionMap[currentUserRole][
            "canAccessPublisherList"
          ] ? (
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="white-box old_line ">
                <div className="title">
                  <h2>Publishers</h2>
                </div>
                <Divider />
                <div className="card-body account_check">
                  <div className="box-height">
                    <ul id="recentAccounts">
                      {publisherList && publisherList.length > 0
                        ? _bindPublishers(publisherList)
                        : ""}
                    </ul>
                  </div>
                </div>
                {currentUserPermissionMap[currentUserRole][
                  "canCreateOrUpdatePublisherInfo"
                ] ? (
                  <div className= "card-footer ">
                    <Button
                      onClick={() => dispatch(openNewPublisherModal())}
                      type="link"
                    >
                      New Publisher
                    </Button>
                    <Button type="link">
                      <Link to="/publisher-dashboard">View Dashboard</Link>
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <List
                className="card-box account_check"
                header={"Publishers"}
                footer={
                  <div>
                    {currentUserPermissionMap[currentUserRole][
                      "canCreateOrUpdatePublisherInfo"
                    ] ? (
                      <Button
                        onClick={() => dispatch(openNewPublisherModal())}
                        type="link"
                      >
                        New Publisher
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button type="link">
                      <Link to="/publisher-dashboard">View Dashboard</Link>
                    </Button>
                  </div>
                }
                bordered
                dataSource={
                  publisherList && publisherList.length > 0 ? publisherList : []
                }
                renderItem={(publisher) => (
                  <List.Item>
                    <Link to={`/publishers/${publisher.publisherId}`}>
                      {publisher.name}
                    </Link>
                  </List.Item>
                )}
              /> */}
            </Col>
          ) : (
            ""
          )}
        </Row>
      ) : (
        ""
      )}
      <AddAccountModal />
      <AddPublisherModal />
    </>
  );
};

function mapStateToProps(state) {
  return {
    accountList: state.account.accountList,
    accountAdded: state.account.accountAdded,
    publisherList: state.publisher.publisherList,
    publisherAdded: state.publisher.publisherAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAccounts: () => dispatch(loadAccounts()),
    openNewPublisherModal: () => dispatch(openNewPublisherModal()),
    openNewAccountModal: () => dispatch(openNewAccountModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard);
