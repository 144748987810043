import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Select } from "antd";
import {
  closeAddCampaignCategoryModal,
  loadAllCampaignCategories,
  updateCampaign,
} from "./../../../redux/actions/campaign-actions";

const { Option } = Select;
const AddCampaignCategoryModal = ({
  isAddCampaignCategoryModalOpen,
  campaignCategoryList,
  selectedCategories,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [selectedValues, setSelectedValues] = useState([]);
  const [result, setResult] = useState([]);

  useEffect(() => {
    (async () => {
      await dispatch(loadAllCampaignCategories());
      const selectValuesTemp = [];
      if (selectedCategories && selectedCategories.length > 0) {
        selectedCategories.filter(function(obj) {
          selectValuesTemp.push(obj.name);
        });
        setSelectedValues(selectValuesTemp);
      }
    })();
  }, [ isAddCampaignCategoryModalOpen]);

  const handleSave = (categoryData) => {
    categoryData["isDisplayPrivateBuysUpdated"] = false;
    categoryData["isVideoPrivateBuysUpdated"] = false;
    categoryData["isDisplaySiteBuysUpdated"] = false;
    categoryData["isVideoSiteBuysUpdated"] = false;
    categoryData["isPublishersUpdated"] = false;
    categoryData["isRetargetingPixelsUpdated"] = false;
    categoryData["isConversionPixelsUpdated"] = false;
    console.log(categoryData);
    dispatch(updateCampaign(id, categoryData));
    dispatch(closeAddCampaignCategoryModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAddCampaignCategoryModal());
  };

  const handleSearch = (value) => {
    let res = [];
    if (value) {
      campaignCategoryList &&
        campaignCategoryList.map((categoryOption) =>
          categoryOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
            ? res.push(categoryOption)
            : ""
        );
    }
    setResult(res);
  };

  return (
    <>
      <Modal
        title={"Campaign Categories"}
        visible={isAddCampaignCategoryModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item label="Add Campaign Category" name="categories">
            <Select
              mode="multiple"
              dropdownStyle={{ display: result.length > 0 ? "" : "none" }}
              onSearch={handleSearch}
              key={selectedValues}
              defaultValue={selectedValues}
            >
              {result.map((category) => (
                <Option key={category.categoryId} value={category.name}>{category.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddCampaignCategoryModalOpen: state.campaign.isAddCampaignCategoryModalOpen,
    campaignCategoryList: state.campaign.campaignCategoryList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAddCampaignCategoryModal: () => dispatch(closeAddCampaignCategoryModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCampaignCategoryModal);
