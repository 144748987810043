import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import {
  List,
  Row,
  Col,
  Button,
  Switch,
  Modal,
  Card,
} from "antd";
import {
  openUpdateVideoCreativeModal,
  updateVideoCreative,
} from "../../../redux/actions/creative-actions";
import { setVideoPath } from "../../../utils/appUtils";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import { getLocalDate } from "../../../utils/appUtils";

const { confirm } = Modal;

const EditVideoCreativeDetail = ({ editVideoCreative }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pauseValue, setPauseValue] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  useEffect(() => {
    if (editVideoCreative && editVideoCreative.videoCreativeVersion && editVideoCreative.videoLine) {
      setPauseValue(
        editVideoCreative.videoCreativeVersion.paused !== null
          ? editVideoCreative.videoCreativeVersion.paused
          : false
      );
    }
  }, []);

  function _bindCreativeProps(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType !== "Retargeting Pixel" ?
                <ul id={propType}>
                  <li className="no-list-style click-url">{propType && propValue}</li>
                </ul>
                : <ul>
                  <li className="no-list-style">{editVideoCreative.videoCreativeVersion.retargetingPixel ?
                    editVideoCreative.videoCreativeVersion.retargetingPixel.name : ""}</li></ul>}
              {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openUpdateVideoCreativeModal(propType))}>
                    Edit
                  </Button>
                  {propType === "Retargeting Pixel" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                  {propType === "Target State" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }


  function removeValues(propType) {
    if (propType === "Target State") {
      dispatch(updateVideoCreative(id, { "targetingState": "" }))
    } else if (propType === "Retargeting Pixel") {
      dispatch(updateVideoCreative(id, { "retargetingPixelId": "" }))
    }
  }

  function showConfirm() {
    if (pauseValue === false) {
      confirm({
        title: "Are you sure you want to pause?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setPauseValue(true);
          dispatch(updateVideoCreative(id, { "paused": true }))
        },
        onCancel() {
          setPauseValue(pauseValue);
        },
      });
    } else {
      setPauseValue(false);
      dispatch(updateVideoCreative(id, { "paused": false }))
      return;
    }
  }

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="card-body grid-col overflow-v">
              <Card className="grid-items"
                style={{ padding: "10px" }}
                cover={
                  <video controls>
                    <source src={setVideoPath(editVideoCreative && editVideoCreative.videoCreativeVersion
                      && editVideoCreative.videoCreativeVersion.videoResource)}
                      type={editVideoCreative && editVideoCreative.videoCreativeVersion
                        && editVideoCreative.videoCreativeVersion.videoResource.mimeType} />
                  </video>
                }
              >
              </Card>
            </div>
          </div>
        </Col>
      </Row>

      {_bindCreativeProps(
        "Flight",
        moment.unix(getLocalDate(editVideoCreative.videoCreativeVersion.startAt) / 1000).format("DD MMM, YYYY") +
        " - " +
        moment.unix(getLocalDate(editVideoCreative.videoCreativeVersion.endAt) / 1000).format("DD MMM, YYYY")
      )}
      {_bindCreativeProps("Weight", editVideoCreative.videoCreativeVersion.weight)}
      {_bindCreativeProps(
        "Click URL",
        editVideoCreative.videoCreativeVersion.clickUrl !== null && editVideoCreative.videoCreativeVersion.clickUrl !== ""
          ? editVideoCreative.videoCreativeVersion.clickUrl
          : "N/A"
      )}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Impression Pixels"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button type="link" onClick={() => dispatch(openUpdateVideoCreativeModal("Impression Pixels"))}>
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={editVideoCreative.videoCreativeVersion.videoImpressionPixels !== null ?
              JSON.parse(editVideoCreative.videoCreativeVersion.videoImpressionPixels) : []}
            renderItem={(pixel) => <List.Item>{pixel.source}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button type="link" onClick={() => dispatch(openUpdateVideoCreativeModal("Devices"))}>
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={editVideoCreative.videoCreativeVersion.devices !== null ?
              JSON.parse(editVideoCreative.videoCreativeVersion.devices) : []}
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="white-box">
              <div className="title">
                <h2>Paused</h2>
              </div>
              <div className="card-body">
                <ul id="paused">
                  <li className="no-list-style">
                    <Switch
                      checked={pauseValue}
                      onClick={showConfirm}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}


      {editVideoCreative &&
        _bindCreativeProps("Retargeting Pixel",
          editVideoCreative.videoCreativeVersion.retargetingPixelId ||
            editVideoCreative.videoCreativeVersion.retargetingPixelId !== "" ?
            editVideoCreative.videoCreativeVersion.retargetingPixel : "NA")}
      {editVideoCreative &&
        _bindCreativeProps("Target State",
          editVideoCreative.videoCreativeVersion.targetingState !== null ||
            editVideoCreative.videoCreativeVersion.targetingState !== "" ?
            editVideoCreative.videoCreativeVersion.targetingState : "NA")}
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditVideoCreativeDetail);
