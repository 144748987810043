import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Maps from "fusioncharts/fusioncharts.maps";
import Usadma from "fusioncharts/maps/fusioncharts.usadma";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, Maps, Usadma, FusionTheme);

const UsaDmaMap = ({ selectedDmaRegions }) => {
  const [dmaData, setDmaData] = useState([]);
  const [colorData, setColorData] = useState([]);

  useEffect(() => {
    if (selectedDmaRegions && selectedDmaRegions !== null && selectedDmaRegions.length > 0) {
      let regions = JSON.parse(selectedDmaRegions);
      var dataArray = []; var colorArray = [];
      if (regions && regions.length > 0) {
        regions.map((region) => {
          if (region && region.code && region.value) {
            dataArray.push({
              id: region.code,
              value: region.graphMetric + " : " + region.value,
              showtooltip: "1",
              showlabel: "1",
              alpha: "100",
              fontbold: "1"
            });
            colorArray.push({
              minValue: region.value,
              maxValue: region.value,
              code: "#7570B3",
            });
          } else if(region && region.code && region.name) {
              dataArray.push({
                id: region.code ? region.code+"" : "unknown",
                value: region.name,
                showtooltip: "1",
                showlabel: "1",
                alpha: "100",
                fontbold: "1"
              });
              colorArray.push({
                minValue: 100,
                maxValue: 200,
                code: "#7570B3",
              });
            }
        });
        setColorData(colorArray);
        setDmaData(dataArray);
      }
    } else {
      setDmaData([]);
      setColorData([]);
    }
    return () => {
      console.log("UsaDmaMap Unmounting");
    };
  }, [selectedDmaRegions]);

  const dataSource = {
    chart: {
      theme: "fusion",
      showlabels: "0",
      nullentitycolor: "#4FCAF6",
      fillcolor: "#D6DBEF",
      entitytooltext: "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$value</b></div>",
      entityfillhovercolor: "#9DA6D4",
      showLegend: "0",
    },
    colorrange: {
      gradient: "0",
      color: colorData,

    },
    data: dmaData
  };

  const chartConfigs = {
    type: "usadma",
    width: 600,
    height: 400,
    dataFormat: "json",
    dataSource: dataSource,
  };

  return <ReactFC {...chartConfigs} />;
};

export default UsaDmaMap;
