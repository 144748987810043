import { Types } from "./../constants/conversion-pixel-types";
import conversionPixelApi from "../api/conversion-pixel-api";

// Add new Conversion Pixel
export const addConversionPixel = (pixelData) => async (dispatch) => {
  try {
    await conversionPixelApi.addConversionPixel(pixelData).then(
      (data) => {
        if (data && data.message && data.message === "Conversion Pixel added successfully!")
          dispatch({
            type: Types.ADD_CONVERSION_PIXEL,
            payload: {
              conversionPixelAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_CONVERSION_PIXEL,
            payload: {
              conversionPixelAdded: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};

// Add new Conversion Piggyback Pixel
export const addConversionPiggybackPixel = (pixelData) => async (dispatch) => {
  try {
    await conversionPixelApi.addConversionPiggybackPixel(pixelData).then(
      (data) => {
        if (data && data.message && data.message === "Conversion Piggyback Pixel added successfully!")
          dispatch({
            type: Types.ADD_CONVERSION_PIGGYBACK_PIXEL,
            payload: {
              conversionPiggybackPixelAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_CONVERSION_PIGGYBACK_PIXEL,
            payload: {
              conversionPiggybackPixelAdded: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};

// Update Conversion Piggyback Pixel
export const updateConversionPiggybackPixel = (pixelData) => async (dispatch) => {
  try {
    await conversionPixelApi.updateConversionPiggybackPixel(pixelData).then(
      (data) => {
        if (data && data.message && data.message === "Conversion Piggyback Pixel updated successfully!")
          dispatch({
            type: Types.UPDATE_CONVERSION_PIGGYBACK_PIXEL,
            payload: {
              conversionPiggybackPixelUpdated: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_CONVERSION_PIGGYBACK_PIXEL,
            payload: {
              conversionPiggybackPixelUpdated: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};


// get all Conversion Pixels by AccountId
export const getAllConversionPixels = () => async (dispatch) => {
  try {
    await conversionPixelApi.getAllConversionPixels().then((data) => {
      dispatch({
        type: Types.GET_ALL_CONVERSION_PIXELS,
        payload: data,
      });
    });
  } catch (err) {}
};

// To open add new Conversion Pixel Modal
export const openNewConversionPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CONVERSION_PIXEL_MODAL,
    payload: true,
  });
};

// To close add new Conversion Pixel Modal
export const closeNewConversionPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CONVERSION_PIXEL_MODAL,
    payload: false,
  });
};

// To open add new Conversion Piggyback Pixel Modal
export const openNewConversionPiggybackPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CONVERSION_PIGGYBACK_PIXEL_MODAL,
    payload: true,
  });
};

// To close add new Conversion Piggyback Pixel Modal
export const closeNewConversionPiggybackPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CONVERSION_PIGGYBACK_PIXEL_MODAL,
    payload: false,
  });
};

// To clear states from redux store
export const clearConversionPixelStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_CONVERSION_PIXEL_STATES,
    payload: undefined,
  });
};
