import { Types } from "../constants/conversion-pixel-types";
const initialState = {
  isAddConversionPixelModalOpen: false,
  isAddConversionPiggybackPixelModalOpen: false,
  conversionPixelAdded: undefined,
  errorMessage: undefined,
  conversionPixelList: undefined,
  conversionPiggybackPixelAdded: undefined,
  conversionPiggybackPixelUpdated: undefined,
};
export default function conversionPixelReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ADD_CONVERSION_PIXEL_MODAL:
      return {
        ...state,
        isAddConversionPixelModalOpen: action.payload,
      };
    case Types.ADD_CONVERSION_PIXEL:
      return {
        ...state,
        conversionPixelAdded: action.payload.conversionPixelAdded,
        errorMessage: action.payload.errorMessage
          ? action.payload.errorMessage
          : initialState.errorMessage,
      };
    case Types.OPEN_ADD_CONVERSION_PIGGYBACK_PIXEL_MODAL:
      return {
        ...state,
        isAddConversionPiggybackPixelModalOpen: action.payload,
      };
    case Types.GET_ALL_CONVERSION_PIXELS:
      return {
        ...state,
        conversionPixelList: action.payload,
      };
    case Types.ADD_CONVERSION_PIGGYBACK_PIXEL:
      return {
        ...state,
        conversionPiggybackPixelAdded: action.payload.conversionPiggybackPixelAdded,
        errorMessage: action.payload.errorMessage
          ? action.payload.errorMessage
          : initialState.errorMessage,
      };
    case Types.UPDATE_CONVERSION_PIGGYBACK_PIXEL:
        return {
          ...state,
          conversionPiggybackPixelUpdated: action.payload.conversionPiggybackPixelUpdated,
          errorMessage: action.payload.errorMessage
            ? action.payload.errorMessage
            : initialState.errorMessage,
        };  
    case Types.CLEAR_CONVERSION_PIXEL_STATES:
      return {
        ...state,
        conversionPixelAdded: undefined,
        errorMessage: undefined,
        conversionPixelList: undefined,
        conversionPiggybackPixelAdded: undefined,
        conversionPiggybackPixelUpdated: undefined
      };
    default:
      return state;
  }
}
