import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input } from "antd";
import { closeNewSiteModal, addSite } from "../../../redux/actions/site-actions";
import { useParams } from "react-router";
const { TextArea } = Input;

const AddSiteModal = ({ isAddSiteModalOpen, siteType}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const  {id} = useParams();

  useEffect(() => {
     
    return () => {
      console.log("AddSiteModal Unmounting");
    };
  }, [isAddSiteModalOpen]);

  const handleSave = (values) => {
    if(siteType === "segment") {
      values["segmentId"] = id;
    } else {
      values["siteListId"] = id;
    }
    console.log(values);
    dispatch(addSite(values));
    dispatch(closeNewSiteModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewSiteModal());
  };

  return (
    <>
      <Modal
        title="New Site"
        visible={isAddSiteModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Domain"
            name="domain"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please input domain!",
                whitespace: true,
              },
              {
                //pattern: new RegExp(/([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in)/),
                pattern: new RegExp(/^[A-Z0-9._%+-]+\.[A-Z]{2,4}$/i),
                message: "Please enter a valid domain!"
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              placeholder="Add Site"
              autoSize
            />           
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddSiteModalOpen: state.site.isAddSiteModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewSiteModal: () => dispatch(closeNewSiteModal()),
    addSite: (domain) => dispatch(addSite(domain)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddSiteModal);
