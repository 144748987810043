import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Breadcrumb, PageHeader, Row, Col, List, Button, Input, Modal, Spin } from "antd";
import {
  loadSiteListById,
  clearSiteListStates
} from "../../redux/actions/site-list-actions";
import { notifyUser } from "../../services/notification-service";
import { DeleteFilled, ExclamationCircleOutlined, DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { openNewSiteModal, removeSite, clearSiteStates } from "../../redux/actions/site-actions";
import AddSiteModal from "../../components/modals/sites/add-site";

const { confirm } = Modal;

const EditSiteList = ({
  editSiteList, siteAdded, siteRemoved
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchResult, setSearchResult] = useState([]);
  const [isSearchStarted, setIsSearchStarted] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      setSubmitted(true);
      await dispatch(loadSiteListById(id));
      setSubmitted(false);
    })();

    if (siteAdded !== undefined) {
      if (siteAdded.success === true && siteAdded.message) {
        notifyUser(siteAdded.message, "success");
      } else {
        notifyUser(siteAdded.message, "error");
      }
    }
    if (siteRemoved !== undefined) {
      if (siteRemoved.success === true && siteRemoved.message) {
        notifyUser(siteRemoved.message, "success");
      } else {
        notifyUser(siteRemoved.message, "error");
      }
    }

    return () => {
      console.log("EditSiteList Unmounting");
      dispatch(clearSiteListStates());
      dispatch(clearSiteStates());
    };
  }, [siteAdded, siteRemoved]);

  const onChange = (e) => {
    setIsSearchStarted(true);

    let res = [];
    if (e.target.value) {
      editSiteList && editSiteList.sites &&
        editSiteList.sites.map((site) =>
          site.domain.toUpperCase().indexOf(e.target.value.toUpperCase()) !== -1
            ? res.push(site)
            : ""
        );
    } else if (e.target.value === "") {
      setIsSearchStarted(false);
    }
    setSearchResult(res);
  };

  function showConfirm(siteId) {
    confirm({
      title: "Are you sure you want to remove this site?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(removeSite(siteId, id, "siteList"))
      },
      onCancel() { },
    });

  }

  console.log(editSiteList);
  return (
    <>
      <section className="white-box-wrapper">
        <Spin size={"large"} spinning={submitted}></Spin>
        {editSiteList ? <><Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/accounts/${editSiteList.campaign && editSiteList.campaign.accountId}`}>Account</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/campaigns/${editSiteList.campaign && editSiteList.campaign.campaignId}`}>Campaign</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/site-list/${editSiteList.siteListId}`}>Site List</Link>
          </Breadcrumb.Item>
        </Breadcrumb></> : ""}

        <PageHeader
          className="site-page-header"
          title={
            editSiteList &&
            editSiteList.name
          }
          subTitle={
            (editSiteList && editSiteList.campaign.account && editSiteList.campaign.account.name) +
            " - " +
            (editSiteList && editSiteList.campaign && editSiteList.campaign.name)
          }
        />

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Input className="filter-search"  onChange={onChange} style={{ width: "200px" }} placeholder="Filter" prefix={<FilterOutlined />} />
            <Col className="text-right" xs={24} sm={24} md={24} lg={24} xl={24}>
              <DownloadOutlined />
            </Col>

            <List className="card-box"
              header={editSiteList && editSiteList.sites.length + " Sites"}
              dataSource={isSearchStarted === false ? (
                editSiteList && editSiteList.sites
              ) : (
                  searchResult && searchResult
                )}
              bordered
              renderItem={(site) => (
                <List.Item >
                  {site.domain}
                  <DeleteFilled onClick={() => showConfirm(site.siteId)} />
                </List.Item>
              )}
              footer={
                <div>
                  <Button type="link" onClick={() => dispatch(openNewSiteModal())}>
                    Edit
                  </Button>
                </div>
              }
            ></List>
          </Col>
        </Row>
        <AddSiteModal siteType={"siteList"} />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    editSiteList: state.siteList.editSiteList,
    siteAdded: state.site.siteAdded,
    siteRemoved: state.site.siteRemoved,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditSiteList);
