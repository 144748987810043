import { Types } from "../constants/site-types";
import siteApi from "../api/site-api";

// Add new Site
export const addSite = (siteData) => async (dispatch) => {
  try {
    await siteApi.addSite(siteData).then(
      (data) => {
        if (data && data.message && data.message === "Site added successfully!")
          dispatch({
            type: Types.ADD_SITE,
            payload: {
              siteAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_SITE,
            payload: {
              siteAdded: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};

export const removeSite = (siteId, id, type) => async (dispatch) => {
  try {
    await siteApi.removeSite(siteId, id, type).then(
      (data) => {
        if (data && data.message && data.message === "Site removed successfully!")
          dispatch({
            type: Types.REMOVE_SITE,
            payload:  { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.REMOVE_SITE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// To open add new Modal
export const openNewSiteModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SITE_MODAL,
    payload: true,
  });
};

// To close add new Modal
export const closeNewSiteModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SITE_MODAL,
    payload: false,
  });
};

// To clear states from redux store
export const clearSiteStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_SITE_STATES,
    payload: undefined,
  });
};
