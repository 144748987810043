import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select, Switch } from "antd";
import { systemUserRoles } from "./../../../constants/defaultValues";
import { checkUserRole } from "./../../../utils/appUtils";

import { closeNewUserModal, addUser, updateUser } from "./../../../redux/actions/user-actions";
const { Option } = Select;

const AddUserModal = ({ isAddUserModalOpen, editUserInfo }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isNewUser, setIsNewUser] = useState(true);
  const [isInternal, setIsinternal] = useState(false);

  useEffect(() => {
    if (editUserInfo !== undefined) {
      console.log(editUserInfo);
      setIsNewUser(false);
      var values = {
        firstName: editUserInfo && editUserInfo.firstName ? editUserInfo.firstName : "",
        lastName: editUserInfo && editUserInfo.lastName ? editUserInfo.lastName : "",
        email: editUserInfo && editUserInfo.email ? editUserInfo.email : "",
        userRole:
          editUserInfo && editUserInfo.roles && editUserInfo.roles.length > 0
            ? checkUserRole(editUserInfo.roles)
            : "",
      };
      form.setFieldsValue(values);
      setIsinternal(editUserInfo && editUserInfo.isInternal);
    }
    return () => {
      console.log("AddUserModal Unmounting");
    };
  }, [isAddUserModalOpen, form, editUserInfo]);

  const userRoleOptions = systemUserRoles.map((role) => (
    <Option key={role.name} value={role.role}>
      {role.name}
    </Option>
  ));

  const handleSave = (userData) => {
    userData["isInternal"] = isInternal;
    if (isNewUser) {    
      dispatch(addUser(userData));
    } else {
      userData["isPrivilegesUpdated"] = false;
      dispatch(updateUser(editUserInfo.userId, userData));
    }
    dispatch(closeNewUserModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsinternal(false);
    dispatch(closeNewUserModal());
  };

  const onChange = (e) => {
    setIsinternal(e);
  };

  return (
    <>
      <Modal
        title={isNewUser ? "New User" : "Update User"}
        visible={isAddUserModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input first name!",
                whitespace: true,
              },
            ]}
          >
            <Input name={"firstName"} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input last name!",
                whitespace: true,
              },
            ]}
          >
            <Input name={"lastName"} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input email!",
                whitespace: true,
              },
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input name={"email"} />
          </Form.Item>
          {isNewUser ? (
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password!",
                  whitespace: true,
                },
              ]}
            >
              <Input.Password name={"password"} />
            </Form.Item>
          ) : (
            ""
          )}
          <Form.Item
            name="userRole"
            label="Select Role"
            rules={[{ required: true, message: "Please select role!" }]}
          >
            <Select placeholder="Select a option" allowClear>
              {userRoleOptions}
            </Select>
          </Form.Item>
          <Form.Item name="isInternal" label="Is Internal User">
            <Switch checked={isInternal} onChange={onChange}/>
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddUserModalOpen: state.user.isAddUserModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewUserModal: () => dispatch(closeNewUserModal()),
    addUser: (userData) => dispatch(addUser(userData)),
    updateUser: (userData) => dispatch(updateUser(userData)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddUserModal);
