import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { closeUpdateClickUrlModal, updateDisplayClickUrl, updateVideoClickUrl } from "../../../redux/actions/line-actions";

const EditClickUrl = ({ lineType, creatives, isUpdateClickUrlModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const { id } = useParams();

    useEffect(() => {

        return () => {
            console.log("EditClickUrl Unmounting");
        };
    }, [isUpdateClickUrlModalOpen, creatives]);

    const handleSave = (values) => {

        if (selectedValues && selectedValues.length > 0) {
            if(!values.clickUrl) {
                values["clickUrl"] = null;
            }
            if (lineType === "display") {
                var selectedImageCreatives = [];
                var selectedTagDisplayCreatives = [];
                selectedValues.map(value => {
                    var filteredValue = creatives.filter(e => e.id === value);
                    if (filteredValue && filteredValue[0]) {
                        if (filteredValue[0].type === "Image Creatives") {
                            selectedImageCreatives.push(value);
                        } else {
                            selectedTagDisplayCreatives.push(value);
                        }
                    }
                })
                values["imageCreativeIds"] = selectedImageCreatives;
                values["tagDisplayCreativeIds"] = selectedTagDisplayCreatives;
            } else {
                var selectedVideoCreatives = [];
                var selectedTagVideoCreatives = [];
                var selectedTruengageCreatives = [];
                selectedValues.map(value => {
                    var filteredValue = creatives.filter(e => e.id === value);
                    if (filteredValue && filteredValue[0]) {
                        if (filteredValue[0].type === "Video Creatives") {
                            selectedVideoCreatives.push(value);
                        } else if (filteredValue[0].type === "Truengage Creatives") {
                            selectedTruengageCreatives.push(value);
                        } else {
                            selectedTagVideoCreatives.push(value);
                        }
                    }
                })
                values["videoCreativeIds"] = selectedVideoCreatives;
                values["tagVideoCreativeIds"] = selectedTagVideoCreatives;
                values["truengageCreativeIds"] = selectedTruengageCreatives;
            }
        }
        values["lineId"] = id;
        if (lineType === "display") {
            dispatch(updateDisplayClickUrl(values));
        } else {
            dispatch(updateVideoClickUrl(values));
        }
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateClickUrlModal());
    };

    const handleCancel = () => {
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateClickUrlModal());
    };

    const onChange = (e) => {
        const selectValuesTemp = selectedValues;
        if (selectValuesTemp.indexOf(e.target.value) > -1) {
            selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
        } else {
            selectValuesTemp.push(e.target.value);
        }
        setSelectedValues(selectValuesTemp);
        setIsSet(!isSet);
    };

    const isCreativeExists = (creative) => {
        var filtered = selectedValues.filter(function (obj) {
            return creative === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    function _bindCreatives(creatives) {
        return creatives.map((creative, index) => {
            return (
                <Row>
                    <Col span={24}>
                        {lineType === "display" ?
                            <Checkbox
                                checked={isCreativeExists(creative.id)}
                                onChange={onChange}
                                value={creative.id}
                            >
                                {creative.name}
                            </Checkbox>
                            : <Checkbox
                                checked={isCreativeExists(creative.id)}
                                onChange={onChange}
                                value={creative.id}
                            >
                                {creative.name}
                            </Checkbox>
                        }
                    </Col>
                </Row>
            );
        });
    }

    return (
        <>
            <Modal
                title={"Set Click Url"}
                visible={isUpdateClickUrlModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    initialValues={{
                        creatives: selectedValues,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item
                        name="clickUrl"
                        label="Click Url"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: `Please enter click url!`,
                        //     },
                        // ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="creatives"
                        label="Select Creative"
                        rules={[
                            {
                                required: true,
                                message: `Please select creative!`,
                            },
                        ]}
                    >
                        {creatives && _bindCreatives(creatives)}
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

};

function mapStateToProps(state) {
    return {
        isUpdateClickUrlModalOpen: state.line.isUpdateClickUrlModalOpen,
        creatives: state.line.creatives,
        selectedCreativesList: state.line.selectedCreativesList,
        clickUrl: state.line.clickUrl
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditClickUrl);
