export const Types = {
  GET_DISPLAY_SITE_BUY_LIST: "GET_DISPLAY_SITE_BUY_LIST",
  GET_VIDEO_SITE_BUY_LIST: "GET_VIDEO_SITE_BUY_LIST",
  OPEN_ADD_SITE_BUY_MODAL: "OPEN_ADD_SITE_BUY_MODAL",
  ADD_SITE_BUY: "ADD_SITE_BUY",
  GET_DISPLAY_SITE_BUY:"GET_DISPLAY_SITE_BUY",
  GET_VIDEO_SITE_BUY:"GET_VIDEO_SITE_BUY",
  OPEN_EDIT_SITE_BUY_MODAL:"OPEN_EDIT_SITE_BUY_MODAL",
  EDIT_SITE_BUY:"EDIT_SITE_BUY",
  CLEAR_SITE_BUY_STATES: "CLEAR_SITE_BUY_STATES",
  SITE_BUY_REPORT: "SITE_BUY_REPORT",
  SITE_BUY_OVERVIEW_DATA: "SITE_BUY_OVERVIEW_DATA"
};
