import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const addSite = (siteData) => {
  return AxiosAuth.post(Config.API + "/api/sites/addSite", siteData, undefined);
};

const removeSite = (siteId, id, type) => {
  if(type === "siteList") {
    return AxiosAuth.delete(Config.API + "/api/sites/removeSite/"+siteId+"?siteListId="+id, undefined, undefined);
  } else if (type === "segment") {
    return AxiosAuth.delete(Config.API + "/api/sites/removeSite/"+siteId+"?segmentId="+id, undefined, undefined);
  }
};

export default {
  addSite,
  removeSite,
};
