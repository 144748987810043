import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const addSiteList = (data) => {
    return AxiosAuth.post(
      Config.API + "/api/siteLists/addSiteList",
      JSON.stringify(data),
      undefined
    );
  };

const loadSiteListById = (siteListId) => {
    return AxiosAuth.get(
      Config.API + "/api/siteLists/getSiteListById/" + siteListId,
      undefined,
      undefined
    );
  };  

export default {
    addSiteList,
    loadSiteListById
  };