import React, { useEffect, useState } from 'react';
import FusionCharts from 'fusioncharts';
import Maps from 'fusioncharts/fusioncharts.maps';
import Usa from 'fusioncharts/maps/fusioncharts.usa';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, Maps, Usa, FusionTheme);

const UsaMap = ({ selectedDmaRegions }) => {
    const [dmaData, setDmaData] = useState([]);

    console.log(selectedDmaRegions);

    useEffect(() => {
        if (selectedDmaRegions && selectedDmaRegions !== null && selectedDmaRegions.length > 0) {
            let regions = JSON.parse(selectedDmaRegions);
            if (regions && regions.length > 0) {
                var dataArray = [];
                regions.map((region) => {
                    if (region && region.code) {
                        dataArray.push({
                            id: region.code,
                            value: region.graphMetric +" : "+ region.value,
                            showtooltip: "1",
                            showlabel: "1",
                            alpha: "100",
                            fontbold: "1"
                        });
                    }
                });
                setDmaData(dataArray);
            }
        } else {
            setDmaData([]);
        }
        return () => {
            console.log("UsaMap Unmounting");
        };
    }, [selectedDmaRegions]);

    const dataSource = {
        chart: {
            theme: "fusion",
            showlabels: "0",
            nullentitycolor: "#9DA6D4",
            fillcolor: "#D6DBEF",
            entitytooltext: "<div style='font-size:14px; text-align:center; padding: 2px 4px 2px 4px; color:black;'>$lName</div><div style='font-size:12px; color:black;'><b>$value</b></div>",
            entityfillhovercolor: "#9DA6D4",
            showLegend: "0",
        },
        colorrange: {
            minvalue: "0",
            code: "#EAD95F",
            gradient: "1",
            color: [
                {
                    maxvalue: "100000",
                    code: "#EE8D50"
                },
                {
                    maxvalue: "200000",
                    code: "#F24141"
                }
            ]
        },
        data: dmaData
    };

    const chartConfigs = {
        type: 'usa',
        width: 600,
        height: 400,
        dataFormat: 'json',
        dataSource: dataSource
    };

    return (
        <ReactFC {...chartConfigs} />
    );
}

export default UsaMap;