import AxiosAuth from "../../services/axios-service";
import Config from "../../config";


const loadForecastingData = (date) => {
    return AxiosAuth.get(Config.API + "/api/forecasting/"+date, undefined, undefined);
};

const exportForecastingData = (date) => {
    return AxiosAuth.post(Config.API + "/api/forecasting/export/"+date, undefined, undefined, true);
};

export default {
    loadForecastingData,
    exportForecastingData
};