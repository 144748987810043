import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllDevices = () => {
  return AxiosAuth.get(Config.API + "/api/devices/getAllDevices", undefined, undefined);
};

const loadAllGeoNames = () => {
  return AxiosAuth.get(Config.API + "/api/geoNames/getAllGeoNames", undefined, undefined);
};

const loadAllDmaRegions = () => {
  return AxiosAuth.get(Config.API + "/api/dmaRegions/getAllDMARegions", undefined, undefined);
};

const loadAllExelateSegments = () => {
  return AxiosAuth.get(Config.API + "/api/exelateSegments/getAllExelateSegments", undefined, undefined);
};

const addDisplayLine = (campaignId, data) => {
  return AxiosAuth.post(
    Config.API + "/api/displaylines/addDisplayLine/" + campaignId,
    JSON.stringify(data),
    undefined
  );
};

const addVideoLine = (campaignId, data) => {
  return AxiosAuth.post(
    Config.API + "/api/videolines/addVideoLine/" + campaignId,
    JSON.stringify(data),
    undefined
  );
};

const loadDisplayLinesByCampaignId = (campaignId) => {
  return AxiosAuth.get(
    Config.API + "/api/displaylines/getAllDisplayLines/" + campaignId,
    undefined,
    undefined
  );
};

const loadVideoLinesByCampaignId = (campaignId) => {
  return AxiosAuth.get(
    Config.API + "/api/videolines/getAllVideoLines/" + campaignId,
    undefined,
    undefined
  );
};

const loadDisplayLineById = (lineId) => {
  return AxiosAuth.get(
    Config.API + "/api/displaylines/getDisplayLineById/" + lineId,
    undefined,
    undefined
  );
};

const loadDisplayLineHistoryById = (lineId) => {
  return AxiosAuth.get(
    Config.API + "/api/displaylines/getDisplayLineHistory/"+lineId,
    undefined,
    undefined
  );
};

const loadVideoLineHistoryById = (lineId) => {
  return AxiosAuth.get(
    Config.API + "/api/videolines/getVideoLineHistory/"+lineId,
    undefined,
    undefined
  );
};

const loadVideoLineById = (lineId) => {
  return AxiosAuth.get(
    Config.API + "/api/videolines/getVideoLineById/" + lineId,
    undefined,
    undefined
  );
};

const updateDisplayLine = (displayLineId, data) => {
  return AxiosAuth.put(
    Config.API + "/api/displaylines/editDisplayLine/" + displayLineId,
    JSON.stringify(data),
    undefined
  );
};

const updateVideoLine = (videoLineId, data) => {
  return AxiosAuth.put(
    Config.API + "/api/videolines/editVideoLine/" + videoLineId,
    JSON.stringify(data),
    undefined
  );
};

const loadDisplayLineReport = (data) => {
  return AxiosAuth.post(
    Config.API + "/api/reportingTab/displayLine",
    JSON.stringify(data),
    undefined
  );
};

const exportDisplayLineReport = (data) => {
  return AxiosAuth.post(
    Config.API + "/api/reportingTab/displayLine/export",
    JSON.stringify(data),
    undefined, 
    true
  );
};

const exportVideoLineReport = (data) => {
  return AxiosAuth.post(
    Config.API + "/api/reportingTab/videoLine/export",
    JSON.stringify(data),
    undefined, 
    true
  );
};

const loadVideoLineReport = (data) => {
  return AxiosAuth.post(
    Config.API + "/api/reportingTab/videoLine",
    JSON.stringify(data),
    undefined
  );
};

const loadDisplayLineOverviewData = (lineId) => {
  return AxiosAuth.get(Config.API + "/api/overviewTab/displayLine/" + lineId, undefined, undefined);
};

const loadVideoLineOverviewData = (lineId) => {
  return AxiosAuth.get(Config.API + "/api/overviewTab/videoLine/" + lineId, undefined, undefined);
};

const updateDisplayClickUrl = (data) => {
  return AxiosAuth.put(
    Config.API + "/api/displaylines/clickUrl",
    JSON.stringify(data),
    undefined
  );
};

const updateVideoClickUrl = (data) => {
  return AxiosAuth.put(
    Config.API + "/api/videolines/clickUrl",
    JSON.stringify(data),
    undefined
  );
};

export default {
  updateVideoClickUrl,
  updateDisplayClickUrl,
  loadAllDevices,
  loadAllGeoNames,
  loadAllDmaRegions,
  loadAllExelateSegments,
  addDisplayLine,
  addVideoLine,
  loadDisplayLinesByCampaignId,
  loadVideoLinesByCampaignId,
  loadDisplayLineById,
  loadDisplayLineHistoryById,
  loadVideoLineHistoryById,
  loadVideoLineById,
  updateDisplayLine,
  updateVideoLine,
  loadDisplayLineReport,
  loadVideoLineReport,
  loadDisplayLineOverviewData,
  loadVideoLineOverviewData,
  exportDisplayLineReport,
  exportVideoLineReport
};
