import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Tabs, Breadcrumb, PageHeader, Spin } from "antd";
import LineDetailLayout from "../../components/layout/edit-line/display-line-details";
import LineHistoryLayout from "../../components/layout/edit-line/display-line-history";
import LineOverviewLayout from "../../components/layout/edit-line/line-overview";
import {
  loadDisplayLineById,
  loadAllDevices,
  loadAllGeoNames,
  loadAllDmaRegions,
  loadAllExelateSegments,
  clearEditDisplayLineStates,
  loadDisplayLineHistoryById,
  loadDisplayLineOverviewData,
} from "../../redux/actions/line-actions";
import AddPrivateBuyModal from "../../components/modals/private-buy/add-private-buy";
import AddCreativeModal from "../../components/modals/creative/add-display-creative";
import EditDevice from "../../components/modals/line/edit-device";
import EditGeoname from "../../components/modals/line/edit-geoname";
import EditDmaRegion from "../../components/modals/line/edit-dma-targeting";
import EditExelateSegments from "../../components/modals/line/edit-exelate-segments";
import EditSiteList from "../../components/modals/line/edit-site-list";
import EditCreativeSize from "../../components/modals/line/edit-creative-size";
import { loadPublishers } from "../../redux/actions/publisher-actions";
import { loadDisplayPrivateBuysByDisplayLineId, clearPrivateBuyReduxState } from "../../redux/actions/private-buy-actions";
import { notifyUser } from "../../services/notification-service";
import { clearEditCreativeReduxState } from "../../redux/actions/creative-actions";
import EditExportReportModal from "../../components/modals/export-report/edit-export-report";
import DisplayLineReporting from "../../components/layout/edit-line/display-line-reporting";
import { loadDisplayLineReport } from "../../redux/actions/line-actions";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";
import EditClickUrl from "./../../components/modals/line/edit-click-url";
import { allDisplaySiteBuys } from "../../redux/actions/site-buy-actions";
import { clearExportReportStates } from "../../redux/actions/export-report-actions";
import { getDayStartDateTime, getDayEndDateTime, getUTCDate } from "../../utils/appUtils";

const { TabPane } = Tabs;

const EditDisplayLine = ({
  editDisplayLine,
  publisherList,
  displayBuyAdded,
  displayLineUpdated,
  imageCreativeAdded,
  tagDisplayCreativeAdded,
  displayHistory,
  lineOverviewData,
  reportExported,
  displaySiteBuyList
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const lineType = "display";
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadDisplayLineById(id));
      await dispatch(loadPublishers());
      await dispatch(loadDisplayPrivateBuysByDisplayLineId(id));
      await dispatch(loadAllDevices());
      await dispatch(loadAllGeoNames());
      await dispatch(loadAllDmaRegions());
      await dispatch(loadAllExelateSegments());
      await dispatch(loadDisplayLineHistoryById(id));
      await dispatch(loadDisplayLineOverviewData(id));
      await dispatch(allDisplaySiteBuys());
      let data = {};
      // data["startDate"] = new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000)).getTime();
      // data["endDate"] = new Date().getTime();
      data["startDate"] = new Date(getUTCDate(getDayStartDateTime(new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000))))).toUTCString();
      data["endDate"] = new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
      data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data["report"] = "date";
      data["id"] = id;
      data["timezone"] = "America/Los_Angeles";
      await dispatch(loadDisplayLineReport(data));
      if(isMounted ){

      setSubmitted(false);
   } })();

    if (displayBuyAdded !== undefined) {
      if (displayBuyAdded.success === true) {
        notifyUser(displayBuyAdded.message, "success");
      } else {
        notifyUser(displayBuyAdded.message, "error");
      }
    }
    if (displayLineUpdated !== undefined) {
      if (displayLineUpdated.success === true) {
        notifyUser(displayLineUpdated.message, "success");
      } else {
        notifyUser(displayLineUpdated.message, "error");
      }
    }
    if (imageCreativeAdded !== undefined) {
      if (imageCreativeAdded.success === true) {
        notifyUser(imageCreativeAdded.message, "success");
      } else {
        notifyUser(imageCreativeAdded.message, "error");
      }
    }
    if (tagDisplayCreativeAdded !== undefined) {
      if (tagDisplayCreativeAdded.success === true) {
        notifyUser(tagDisplayCreativeAdded.message, "success");
      } else {
        notifyUser(tagDisplayCreativeAdded.message, "error");
      }
    }

    if (reportExported !== undefined) {
      if (reportExported.success === true) {
        notifyUser(reportExported.message, "success");
      } else {
        notifyUser(reportExported.message, "error");
      }
    }

    return () => {
      isMounted = false;

      console.log("EditDisplayLine Unmounting");
      dispatch(clearEditDisplayLineStates());
      dispatch(clearPrivateBuyReduxState());
      dispatch(clearEditCreativeReduxState());
      dispatch(clearExportReportStates());
    };
  }, [displayBuyAdded, displayLineUpdated, imageCreativeAdded, tagDisplayCreativeAdded, reportExported]);

  function callback(key) {
    console.log(key);
  }

  return (
    <>
      <section className="white-box-wrapper">
        {editDisplayLine ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/accounts/${editDisplayLine.account && editDisplayLine.account.accountId}`}
                >
                  Account
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link
                  to={`/campaigns/${editDisplayLine.campaign &&
                    editDisplayLine.campaign.campaignId}`}
                >
                  Campaign
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/display/${editDisplayLine.displayLineId}`}>Display Line</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </>
        ) : (
          ""
        )}

        <PageHeader
          className="site-page-header"
          title={
            editDisplayLine &&
              editDisplayLine.displayLineVersion &&
              editDisplayLine.displayLineVersion.name ? editDisplayLine.displayLineVersion.name : ""
          }
          subTitle={
            (editDisplayLine && editDisplayLine.account && editDisplayLine.account.name ? editDisplayLine.account.name : "") +
            " - " +
            (editDisplayLine && editDisplayLine.campaign && editDisplayLine.campaign.name ? editDisplayLine.campaign.name : "")
          }
        />

        <Tabs defaultActiveKey={"details"} size="large" type="line" onChange={callback}>
          <TabPane tab="OVERVIEW" key="overview">
            <LineOverviewLayout lineOverviewData={lineOverviewData && lineOverviewData} lineType="display" line={editDisplayLine} />
          </TabPane>
          <TabPane tab="DETAILS" key="details">
            <LineDetailLayout  editDisplayLine={editDisplayLine && editDisplayLine} displaySiteBuyList={displaySiteBuyList && displaySiteBuyList} />
          </TabPane>
          <TabPane tab="REPORTING" key="reporting">
            <DisplayLineReporting editDisplayLine={editDisplayLine && editDisplayLine} />
          </TabPane>
          {currentUserPermissionMap[currentUserRole]["canAccessDisplayHistory"] ? (
            <TabPane tab="HISTORY" key="history">
              <LineHistoryLayout displayHistory={displayHistory && displayHistory} />
            </TabPane>
          ) : ("")
          }
        </Tabs>
        <Spin size={"large"} spinning={submitted}></Spin>
        {editDisplayLine && (
          <AddCreativeModal
            siteBuyType={lineType}
            editDisplayLine={editDisplayLine}
            accountId={editDisplayLine.accountId}
          />
        )}
        <AddPrivateBuyModal
          privateBuyType={lineType}
          publisherList={publisherList}
          editPrivateBuy={undefined}
        />
        <EditDevice lineType={lineType} />
        <EditGeoname lineType={lineType} />
        <EditDmaRegion lineType={lineType} />
        <EditExelateSegments lineType={lineType} />
        <EditSiteList lineType={lineType} />
        <EditExportReportModal type="display" />
        <EditCreativeSize editDisplayLine={editDisplayLine} />
        <EditClickUrl lineType={lineType} />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    editDisplayLine: state.line.editDisplayLine,
    publisherList: state.publisher.publisherList,
    displayBuyAdded: state.privateBuy.displayBuyAdded,
    displayLineUpdated: state.line.displayLineUpdated,
    imageCreativeAdded: state.creative.imageCreativeAdded,
    tagDisplayCreativeAdded: state.creative.tagDisplayCreativeAdded,
    displayHistory: state.line.displayHistory,
    lineOverviewData: state.line.lineOverviewData,
    reportExported: state.exportReport.reportExported,
    displaySiteBuyList: state.siteBuy.displaySiteBuyList
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditDisplayLine);
