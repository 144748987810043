import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeAddCampaignPixelModal, updateCampaign } from "./../../../redux/actions/campaign-actions";
import { useParams } from "react-router-dom";

const CampaignPixelModal = ({
  pixelList,
  selectedPixelList,
  pixelType,
  isAddCampaignPixelModalOpen,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const selectValuesTemp = [];
    if (pixelType && selectedPixelList && selectedPixelList.length > 0) {
      selectedPixelList.filter(function (obj) {
        selectValuesTemp.push(pixelType === "Conversion" ? obj.conversionPixelId : obj.retargetingPixelId);
      });
      setSelectedValues(selectValuesTemp);
    }
    return () => {
      console.log("CampaignPixelModal Unmounting");
    };
  }, [selectedPixelList, pixelType, pixelList, isAddCampaignPixelModalOpen]);

  const handleSave = (values) => {
    let campaignData = {};
    campaignData["isDisplayPrivateBuysUpdated"] = false;
    campaignData["isVideoPrivateBuysUpdated"] = false;
    campaignData["isDisplaySiteBuysUpdated"] = false;
    campaignData["isVideoSiteBuysUpdated"] = false;
    campaignData["isPublishersUpdated"] = false;
    
    if (pixelType === "Conversion") {
      campaignData["isRetargetingPixelsUpdated"] = false;
      campaignData["isConversionPixelsUpdated"] = true;
      campaignData["conversionPixelIds"] = selectedValues
    } else {
      campaignData["isRetargetingPixelsUpdated"] = true;
      campaignData["isConversionPixelsUpdated"] = false;
      campaignData["retargetingPixelIds"] = selectedValues
    }
    console.log(campaignData);
    dispatch(updateCampaign(id, campaignData));
    form.resetFields();
    dispatch(closeAddCampaignPixelModal());
    setSelectedValues([]);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAddCampaignPixelModal());
    setSelectedValues([]);
  };

  const onChange = (e) => {
    const selectValuesTemp = selectedValues;
    if (selectValuesTemp.indexOf(e.target.value) > -1) {
      selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
    } else {
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  const isPixelExists = (pixelId) => {
    var filtered = selectedValues.filter(function (obj) {
      return pixelId === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  function _bindPublishers(pixels, pixelType) {
    return pixels.map((pixel, index) => {
      return (
        <Row>
          <Col span={24}>
            <Checkbox
              checked={isPixelExists(pixelType === "Conversion" ? pixel.conversionPixelId : pixel.retargetingPixelId)}
              onChange={onChange}
              value={pixelType === "Conversion" ? pixel.conversionPixelId : pixel.retargetingPixelId}
            >
              {pixel.name}
            </Checkbox>
          </Col>
        </Row>
      );
    });
  }

  return (
    <>
      <Modal
        title={pixelType && (pixelType + " Pixel List")}
        visible={isAddCampaignPixelModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            pixelIds: selectedValues,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item name="pixelIds" label={pixelType && "Select "+pixelType+" Pixel"}>
            {pixelList && pixelType && _bindPublishers(pixelList, pixelType)}
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddCampaignPixelModalOpen: state.campaign.isAddCampaignPixelModalOpen,
    pixelType: state.campaign.pixelType,
    selectedPixelList: state.campaign.selectedPixelList,
    pixelList: state.campaign.pixelList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAddCampaignPixelModal: () => dispatch(closeAddCampaignPixelModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CampaignPixelModal
);
