import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { closeUpdateDeviceModal, updateDisplayLine, updateVideoLine } from "../../../redux/actions/line-actions";

const EditDevice = ({ lineType, selectedDeviceList, devices, isUpdateDeviceModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const selectValuesTemp = [];
        if (selectedDeviceList && selectedDeviceList.length > 0) {
            selectedDeviceList.filter(function (obj) {
                selectValuesTemp.push(obj);
            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("EditDevice Unmounting");
        };
    }, [selectedDeviceList, isUpdateDeviceModalOpen, devices]);

    const handleSave = (values) => {
        values["devices"] = JSON.stringify(selectedValues);
        if (lineType === "display") {
            dispatch(updateDisplayLine(id, values));
        } else {
            dispatch(updateVideoLine(id, values));
        }
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateDeviceModal());
    };

    const handleCancel = () => {
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateDeviceModal());
    };

    const onChange = (e) => {
        const selectValuesTemp = selectedValues;
        if (selectValuesTemp.indexOf(e.target.value) > -1) {
            selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
        } else {
            selectValuesTemp.push(e.target.value);
        }
        setSelectedValues(selectValuesTemp);
        setIsSet(!isSet);
    };

    const isDeviceExists = (device) => {
        var filtered = selectedValues.filter(function (obj) {
            return device === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    function _bindDevice(devices) {
        return devices.map((device, index) => {
            return (
                <Row key={devices}>
                    <Col span={24}>
                        {lineType === "display" ?
                            <Checkbox
                           
                                checked={isDeviceExists(device.name)}
                                onChange={onChange}
                                value={device.name}
                            >
                                {device.name}
                            </Checkbox>
                            : <Checkbox
                          
                                checked={isDeviceExists(device.name)}
                                onChange={onChange}
                                value={device.name}
                            >
                                {device.name}
                            </Checkbox>
                        }
                    </Col>
                </Row>
            );
        });
    }

    return (
        <>
            <Modal
                title={"Devices"}
                visible={isUpdateDeviceModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    initialValues={{
                        devices: selectedValues,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item name="devices" label="Select Device">
                        {devices && _bindDevice(devices)}
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

};

function mapStateToProps(state) {
    return {
        isUpdateDeviceModalOpen: state.line.isUpdateDeviceModalOpen,
        devices: state.line.devices,
        selectedDeviceList: state.line.selectedDeviceList,
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditDevice);
