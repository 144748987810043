import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { UploadOutlined } from '@ant-design/icons';
import {
  List,
  Row,
  Col,
  Button,
  Switch,
  Modal,
  Card,
  Collapse,
  Image,
} from "antd";
import {
  openUpdateTruengageCreativeModal,
  updateTruengageCreative,
  loadAllVideoResourcesByAccountId,
  loadAllImageResourcesByAccountId,
  uploadVideoResource,
  uploadImageResource,
} from "../../../redux/actions/creative-actions";
import moment from "moment";
import { ExclamationCircleOutlined, CopyOutlined } from "@ant-design/icons";
import {
  setImagePath,
  setVideoPath,
} from "../../../utils/appUtils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notifyUser } from "../../../services/notification-service";
import JSONPretty from "react-json-pretty";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import { getLocalDate } from "../../../utils/appUtils";

const { confirm } = Modal;
const { Meta } = Card;
const { Panel } = Collapse;

const EditTruengageCreativeDetail = ({ editTruengageCreative, allVideoResources, allImageResources,
  imageResourceAdded, videoResourceAdded }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pauseValue, setPauseValue] = useState(false);
  const fileRef = useRef();
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  const [selectVideoResourceId, setSelectVideoResourceId] = useState("");

  useEffect(() => {
    if (editTruengageCreative && editTruengageCreative.truengageCreativeVersion && editTruengageCreative.videoLine) {
      dispatch(loadAllVideoResourcesByAccountId(editTruengageCreative.videoLine.accountId));
      dispatch(loadAllImageResourcesByAccountId(editTruengageCreative.videoLine.accountId));
      setPauseValue(
        editTruengageCreative.truengageCreativeVersion.paused !== null
          ? editTruengageCreative.truengageCreativeVersion.paused
          : false
      );
      if (editTruengageCreative.truengageCreativeVersion.videoResource) {
        setSelectVideoResourceId(editTruengageCreative.truengageCreativeVersion.videoResource.videoResourceId)
      }
    }
    if (imageResourceAdded !== undefined) {
      if (imageResourceAdded.success === true && imageResourceAdded.message) {
        notifyUser(imageResourceAdded.message, "success");
      } else {
        notifyUser(imageResourceAdded.message, "error");
      }
    }
    if (videoResourceAdded !== undefined) {
      if (videoResourceAdded.success === true && videoResourceAdded.message) {
        notifyUser(videoResourceAdded.message, "success");
      } else {
        notifyUser(videoResourceAdded.message, "error");
      }
    }
  }, [imageResourceAdded, videoResourceAdded]);

  const handleVideoFileChange = (e) => {
    let fileObj = e.target.files[0];
    if (fileObj.type.indexOf("video/") === -1) {
      alert("Only Videos are allowed");
      return;
    }
    dispatch(uploadVideoResource(fileObj, editTruengageCreative.videoLine.accountId));
  };

  const handleImageFileChange = (e) => {
    let fileObj = e.target.files[0];
    if (fileObj.type.indexOf("image/") === -1) {
      alert("Only Images are allowed");
      return;
    }
    dispatch(uploadImageResource(fileObj, editTruengageCreative.videoLine.accountId, 0));
  };

  function _bindCreativeProps(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType !== "Retargeting Pixel" ?
                <ul id={propType}>
                  <li className="no-list-style">{propType && propValue}</li>
                </ul>
                : <ul>
                  <li className="no-list-style">{editTruengageCreative.truengageCreativeVersion.retargetingPixel ?
                    editTruengageCreative.truengageCreativeVersion.retargetingPixel.name : ""}</li></ul>}
              {propType !== "Engine Version" ? (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openUpdateTruengageCreativeModal(propType))}>
                    Edit
                  </Button>
                  {propType === "Retargeting Pixel" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                  {propType === "Target State" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                </div>
              ) : ("")}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function _bindCreativeProps2(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType !== "Retargeting Pixel" ?
                <ul id={propType}>
                <li className="no-list-style">{propType && propType !== "Source" ? propValue : <JSONPretty id="json-pretty" data={propValue}></JSONPretty>}</li>
              </ul>

                : <ul>
                  <li className="no-list-style">{editTruengageCreative.truengageCreativeVersion.retargetingPixel ?
                    editTruengageCreative.truengageCreativeVersion.retargetingPixel.name : ""}</li></ul>}
              {propType !== "Engine Version" ? (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openUpdateTruengageCreativeModal(propType))}>
                    Edit
                  </Button>
                  {propType === "Retargeting Pixel" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                  {propType === "Target State" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                </div>
              ) : ("")}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function removeValues(propType) {
    if (propType === "Target State") {
      dispatch(updateTruengageCreative(id, { "targetingState": "" }))
    } else if (propType === "Retargeting Pixel") {
      dispatch(updateTruengageCreative(id, { "retargetingPixelId": "" }))
    }
  }

  function showConfirm() {
    if (pauseValue === false) {
      confirm({
        title: "Are you sure you want to pause?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setPauseValue(true);
          dispatch(updateTruengageCreative(id, { "paused": true }))
        },
        onCancel() {
          setPauseValue(pauseValue);
        },
      });
    } else {
      setPauseValue(false);
      dispatch(updateTruengageCreative(id, { "paused": false }))
      return;
    }
  }

  const onChangeImageSrc = (e, id) => {
    setSelectVideoResourceId(id);
  };

  const handleCancel = () => {
    if (editTruengageCreative && editTruengageCreative.truengageCreativeVersion &&
      editTruengageCreative.truengageCreativeVersion.videoResource) {
      setSelectVideoResourceId(editTruengageCreative.truengageCreativeVersion.videoResource.videoResourceId)
    }
    else {
      setSelectVideoResourceId("")
    }
  };

  const handleSave = (values) => {
    values["videoResourceId"] = selectVideoResourceId;

    if (selectVideoResourceId === "") {
      alert("Please select one of video resource before proceed!");
      return;
    }
    dispatch(updateTruengageCreative(id, values));

  };
  const videoes = [];
  if (allVideoResources && editTruengageCreative && editTruengageCreative.truengageCreativeVersion) {
    Object.entries(allVideoResources).forEach(([key, value]) => {
      videoes.push(
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <Card
            style={{ padding: "10px" }}
            bordered={false}
            onClick={(e) => onChangeImageSrc(e, value.videoResourceId)}
            className={
              selectVideoResourceId !== null ? (
                selectVideoResourceId === value.videoResourceId
                  ? "selected-image-resource"
                  : "image-resource") : ("image-resource")
            }
            cover={
              <video controls>
                <source src={setVideoPath(value)} type={value.mimeType} />
              </video>
            }
          ></Card>
        </Col>
      );
    });
  }

  const images = [];
  if (allImageResources) {
    Object.entries(allImageResources).forEach(([key, value]) => {
      images.push(
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <Card
            style={{ padding: "10px" }}
            bordered={false}
            cover={<Image preview={true} src={setImagePath(value)} />}
          >
            <Meta description={value.width + "x" + value.height}></Meta>
            <CopyToClipboard text={setImagePath(value)} onCopy={() => console.log(`copied`)}>
              <CopyOutlined />
            </CopyToClipboard>
          </Card>
        </Col>
      );
    });
  }

  function _bindVideoProps() {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{"Background Video"}</h2>
            </div>
            <div className="card-body">
              <Collapse bordered={false}>
                <Panel
                  header={
                    selectVideoResourceId === ""
                      ? "Background Video (None Selected)"
                      : "Background Video"
                  }
                  key="1"
                >
                  <div className="add-image-div">
                    <input
                      type="file"
                      id="addImageResource"
                      ref={fileRef}
                      accept="video/*"
                      onChange={handleVideoFileChange}
                    />
                    <label htmlFor="addImageResource">
                      <span className="plus-icon">+</span>Add Video
                      <UploadOutlined />
                    </label>
                  </div>
                  <Row gutter={16}>{videoes}</Row>
                  <div>
                    <Button
                      onClick={handleSave}
                    >Save</Button>
                    <Button
                      onClick={handleCancel}
                    >Cancel</Button>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function _bindImageProps() {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{"Image Resources"}</h2>
            </div>
            <div className="card-body">
              <Collapse bordered={false}>
                <Panel
                  header={"Image Resources"}
                  key="2"
                >
                  <div className="add-image-div">
                    <input
                      type="file"
                      id="addImageResource"
                      ref={fileRef}
                      accept="image/*"
                      onChange={handleImageFileChange}
                    />
                    <label htmlFor="addImageResource">
                      <span className="plus-icon">+</span>Add Image
                      <UploadOutlined />
                    </label>
                  </div>
                  <Row gutter={16}>{images}</Row>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Col>
      </Row>

    );
  }

  return (
    <>
      {_bindCreativeProps(
        "Flight",
        moment.unix(getLocalDate(editTruengageCreative.truengageCreativeVersion.startAt) / 1000).format("DD MMM, YYYY") +
        " - " +
        moment.unix(getLocalDate(editTruengageCreative.truengageCreativeVersion.endAt) / 1000).format("DD MMM, YYYY")
      )}
      {_bindCreativeProps("Weight", editTruengageCreative.truengageCreativeVersion.weight)}
      {_bindCreativeProps("Duration", editTruengageCreative.truengageCreativeVersion.duration)}
      {_bindCreativeProps("Engine Version", editTruengageCreative.truengageCreativeVersion.gameEngineVersion)}
      {_bindCreativeProps2("Source", editTruengageCreative.truengageCreativeVersion.source && JSON.parse(editTruengageCreative.truengageCreativeVersion.source))}
      {_bindVideoProps()}
      {_bindImageProps()}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Event Pixels"}
            footer={
              <div>
                <Button type="link" onClick={() => dispatch(openUpdateTruengageCreativeModal("Event Pixels"))}>
                  Edit
                </Button>
              </div>
            }
            bordered
            dataSource={editTruengageCreative && editTruengageCreative.truengageCreativeVersion.truengageEventPixels !== null ?
              JSON.parse(editTruengageCreative.truengageCreativeVersion.truengageEventPixels) : []}
            renderItem={(pixel) => <List.Item>{pixel.eventName + " : " + pixel.url}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Impression Pixels"}
            footer={
              <div>
                <Button type="link" onClick={() => dispatch(openUpdateTruengageCreativeModal("Impression Pixels"))}>
                  Edit
                </Button>
              </div>
            }
            bordered
            dataSource={editTruengageCreative && editTruengageCreative.truengageCreativeVersion.truengageImpressionPixels !== null ?
              JSON.parse(editTruengageCreative.truengageCreativeVersion.truengageImpressionPixels) : []}
            renderItem={(pixel) => <List.Item>{pixel.source}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              <div>
                <Button type="link" onClick={() => dispatch(openUpdateTruengageCreativeModal("Devices"))}>
                  Edit
                </Button>
              </div>
            }
            bordered
            dataSource={editTruengageCreative && editTruengageCreative.truengageCreativeVersion.devices !== null ?
              JSON.parse(editTruengageCreative.truengageCreativeVersion.devices) : []}
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="white-box">
              <div className="title">
                <h2>Paused</h2>
              </div>
              <div className="card-body">
                <ul id="paused">
                  <li className="no-list-style">
                    <Switch
                      checked={pauseValue}
                      onClick={showConfirm}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editTruengageCreative &&
        _bindCreativeProps("Retargeting Pixel",
          editTruengageCreative.truengageCreativeVersion.retargetingPixelId ||
            editTruengageCreative.truengageCreativeVersion.retargetingPixelId !=="" ?
            editTruengageCreative.truengageCreativeVersion.retargetingPixel : "NA")}
      {editTruengageCreative &&
        _bindCreativeProps("Target State",
          editTruengageCreative.truengageCreativeVersion.targetingState !== null ||
            editTruengageCreative.truengageCreativeVersion.targetingState !== "" ?
            editTruengageCreative.truengageCreativeVersion.targetingState : "NA")}
    </>
  );
};

function mapStateToProps(state) {
  return {
    allVideoResources: state.creative.allVideoResources,
    allImageResources: state.creative.allImageResources,
    imageResourceAdded: state.creative.imageResourceAdded,
    videoResourceAdded: state.creative.videoResourceAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTruengageCreativeDetail);
