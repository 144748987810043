import { Types } from "../constants/auth-types";
import API from "../api/auth-api";
import Config from "../../config";
import UserService from "./../../services/user-service";

export function signOut() {
  return async function(dispatch, getState) {
    try {
      UserService.signOut();
      dispatch({
        type: Types.LOG_OUT,
        payload: true,
      });
      window.location = window.location.origin;
    } catch (e) {}
  };
}

export function signin(email, password) {
  return async function(dispatch, _getState) {
    try {
      let data = {
        email: email,
        password: password,
      };
      let user = await API.login(data);
      console.log(user);
      if (user != null) {
        var redirectUrl = "/login";
        if (user.roles && user.roles[0]) {
          redirectUrl = Config.userRoleTypes[user.roles[0]].url;
        }
        user.redirect = redirectUrl;
        UserService.setUserData(user);
        if (redirectUrl.indexOf("/login") === -1) {
          dispatch({
            type: Types.CURRENT_LOGGED_IN_USER,
            payload: {
              user: user,
              token: user.token,
              isLoggedIn: true,
            },
          });
        }
        return user;
      } else {
        dispatch({
          type: Types.LOGIN_FAIL,
        });
        return { error: "Error logging in. Please try again!" };
      }
    } catch (e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.status &&
        e.response.status === 401
      ) {
        return { error: e.response.data.message };
      } else {
        return { error: "Invalid email / password!" };
      }
    }
  };
}
