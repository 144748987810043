import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Radio, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { closeUpdateSiteListModal, updateDisplayLine, updateVideoLine } from "../../../redux/actions/line-actions";
const EditLineSiteList = ({ lineType, selectedSiteList, siteLists, isUpdateSiteListModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState(0);
    const [isSet, setIsSet] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (selectedSiteList) {
            setSelectedValues(selectedSiteList.siteListId);
        }
        return () => {
            console.log("Edit SiteList Unmounting");
        };
    }, [selectedSiteList, siteLists, isUpdateSiteListModalOpen]);

    const handleSave = (values) => {
        values["siteListId"] = selectedValues;
        
        if (lineType === "display") {
            dispatch(updateDisplayLine(id, values));
        } else {
            dispatch(updateVideoLine(id, values));
        }
        setSelectedValues(0);
        form.resetFields();
        dispatch(closeUpdateSiteListModal());
    };

    const handleCancel = () => {
        setSelectedValues(0);
        form.resetFields();
        dispatch(closeUpdateSiteListModal());
    };
    const handleClear = () => {
        setSelectedValues("0");
        form.resetFields();
    };

    const onChange = (e) => {
        setSelectedValues(e.target.value);
        setIsSet(!isSet);
    };

    function _bindSiteList(siteLists) {
        return siteLists.map((site, index) => {
            return (
                <Row>
                    <Col span={24}>
                        <Radio.Group onChange={onChange} value={selectedValues}>
                            <Radio value={site.siteListId}>
                                {site.name}
                            </Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            );
        });
    }

    return (
        <>
            <Modal
                title={"Site List"}
                visible={isUpdateSiteListModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    initialValues={{
                        siteList: selectedValues,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item name="siteListId" label="Select Site List">
                        {siteLists && _bindSiteList(siteLists)}
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button type="primary" htmlType="button" onClick={handleClear}>
                            Clear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

};

function mapStateToProps(state) {
    return {
        isUpdateSiteListModalOpen: state.line.isUpdateSiteListModalOpen,
        selectedSiteList: state.line.selectedSiteList,
        siteLists: state.line.siteLists,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditLineSiteList);

