import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { Input, Select, InputNumber, Switch } from "antd";
import { displayRateTypes, videoRateTypes } from "./../../../constants/defaultValues";
import { closeAddPrivateBuyModal } from "./../../../redux/actions/private-buy-actions";
import {
  addDisplayPrivateBuy,
  addVideoPrivateBuy,
  updateDisplayPrivateBuy,
  updateVideoPrivateBuy,
} from "../../../redux/actions/private-buy-actions";

const { TextArea } = Input;
const { Option } = Select;
const AddPrivateBuyModal = ({
  privateBuyType,
  publisherList,
  isAddPrivateBuyModalOpen,
  editPrivateBuy,
}) => {
  
  const options = privateBuyType === "display" ? displayRateTypes : videoRateTypes;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [switchValues, setSwitchValues] = useState({
    useNetworkFill: false,
    ignoreSiteList: false,
    ignoreDailyCap: false,
    ignoreDeliveryCap: false,
  });
  const [isNewPrivateBuy, setIsNewPrivateBuy] = useState(true);
  useEffect(() => {
    console.log(editPrivateBuy);
    if (editPrivateBuy !== undefined) {
      setIsNewPrivateBuy(false);
      var values = {
        name: editPrivateBuy && editPrivateBuy.name ? editPrivateBuy.name : "",
        rate: editPrivateBuy && editPrivateBuy.rate ? editPrivateBuy.rate : "",
        rateType: editPrivateBuy && editPrivateBuy.rateType ? editPrivateBuy.rateType : "",
        publisherId:
          editPrivateBuy && editPrivateBuy.publisher && editPrivateBuy.publisher.publisherId
            ? editPrivateBuy.publisher.publisherId
            : "",
      };
      setSwitchValues({
        useNetworkFill: editPrivateBuy.useNetworkFill,
        ignoreSiteList: editPrivateBuy.ignoreSiteList,
        ignoreDailyCap: editPrivateBuy.ignoreDailyCap,
        ignoreDeliveryCap: editPrivateBuy.ignoreDeliveryCap,
      });
      form.setFieldsValue(values);
    }
    return () => {
      console.log("AddPrivateBuyModal Unmounting");
    };
  }, [isAddPrivateBuyModalOpen, form, editPrivateBuy]);

  const handleSave = (values) => {
    values["useNetworkFill"] = switchValues["useNetworkFill"];
    values["ignoreSiteList"] = switchValues["ignoreSiteList"];
    values["ignoreDailyCap"] = switchValues["ignoreDailyCap"];
    values["ignoreDeliveryCap"] = switchValues["ignoreDeliveryCap"];
    
    if (privateBuyType === "display") {
      isNewPrivateBuy
        ? dispatch(addDisplayPrivateBuy(id, values))
        : dispatch(updateDisplayPrivateBuy(editPrivateBuy.displayPrivateBuyId, values));
    } else {
      isNewPrivateBuy
        ? dispatch(addVideoPrivateBuy(id, values))
        : dispatch(updateVideoPrivateBuy(editPrivateBuy.videoPrivateBuyId, values));
    }
    form.resetFields();
    dispatch(closeAddPrivateBuyModal());
    setSwitchValues({
      useNetworkFill: false,
      ignoreSiteList: false,
      ignoreDailyCap: false,
      ignoreDeliveryCap: false,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAddPrivateBuyModal());
    setSwitchValues({
      useNetworkFill: false,
      ignoreSiteList: false,
      ignoreDailyCap: false,
      ignoreDeliveryCap: false,
    });
  };

  const onChange = (value, name) => {
    const switchValueTemp = switchValues;
    if (name in switchValueTemp) {
      switchValueTemp[name] = value;
    }
    setSwitchValues(switchValueTemp);
  };

  return (
    <>
      <Modal
        title={isNewPrivateBuy ? "New Private Buy" : "Update Private Buy"}
        visible={isAddPrivateBuyModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} layout={"vertical"} onFinish={handleSave}>
          <Form.Item className="left-space"
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input private buy name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Add Private Buy Name" autoSize />
          </Form.Item>
          <Form.Item
            label="Rate"
            name="rate"
            rules={[
              {
                required: true,
                message: `Please input private buy rate!`,
              },
            ]}
          >
            <InputNumber
              min={0}
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Rate Type"
            name="rateType"
            rules={[
              {
                required: true,
                message: `Please input private buy rate type!`,
                whitespace: true,
              },
            ]}
          >
            <Select>
              {options.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Publisher"
            name="publisherId"
            rules={[
              {
                required: true,
                message: `Please input publisher!`,
              },
            ]}
          >
            <Select defaultValue={editPrivateBuy && editPrivateBuy.publisher.publisherId}>
              {publisherList &&
                publisherList.map((publisher) => (
                  <Option key={publisher.publisherId} value={publisher.publisherId}>
                    {publisher.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="useNetworkFill" label="Network Fill">
            <Switch
              key={switchValues["useNetworkFill"]}
              defaultChecked={switchValues["useNetworkFill"]}
              onChange={(e) => onChange(e, "useNetworkFill")}
            />
          </Form.Item>
          <Form.Item name="ignoreSiteList" label="Ignore Site List">
            <Switch
              key={switchValues["ignoreSiteList"]}
              defaultChecked={switchValues["ignoreSiteList"]}
              onChange={(e) => onChange(e, "ignoreSiteList")}
            />
          </Form.Item>
          <Form.Item name="ignoreDailyCap" label="Ignore Daily Cap">
            <Switch
              key={switchValues["ignoreDailyCap"]}
              defaultChecked={switchValues["ignoreDailyCap"]}
              onChange={(e) => onChange(e, "ignoreDailyCap")}
            />
          </Form.Item>
          <Form.Item name="ignoreDeliveryCap" label="Ignore Delivery Cap">
            <Switch
              key={switchValues["ignoreDeliveryCap"]}
              defaultChecked={switchValues["ignoreDeliveryCap"]}
              onChange={(e) => onChange(e, "ignoreDeliveryCap")}
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddPrivateBuyModalOpen: state.privateBuy.isAddPrivateBuyModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddPrivateBuyModal);
