import { Types } from "./../constants/account-types";
import accountApi from "../api/account-api";

// Load all accounts
export const loadAccounts = () => async (dispatch) => {
  try {
    await accountApi.loadAllAccounts().then(
      (data) => {
        dispatch(setAccountList(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

export const loadAccountById = (id) => async (dispatch) => {
  try {
    await accountApi.loadAccountById(id).then(
      (data) => {
        dispatch(setEditAccount(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Add new Account
export const addAccount = (name) => async (dispatch) => {
  try {
    let accountData = { name: name };
    await accountApi.addAccount(accountData).then(
      (data) => {
        if (data && data.message && data.message === "Account added successfully!")
          dispatch({
            type: Types.ADD_ACCOUNT,
            payload: {
              accountAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_ACCOUNT,
            payload: {
              accountAdded: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) { }
};

export const loadViewDashboard = (values, isNew) => async (dispatch) => {
  try {
    await accountApi.loadViewDashboard(values).then(
      (data) => {
        if (data)
          dispatch({
            type: Types.LOAD_VIEW_DASHBOARD,
            payload: { data: data, isNew: isNew }
          });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

export const exportDashboardData = (values) => async (dispatch) => {
  try {
    return await accountApi.exportDashboardData(values);
  } catch (err) { }
};

// clear Account state from Redux store
export const clearEditAccountStates = () => async (dispatch) => {
  dispatch({ type: Types.GET_EDIT_ACCOUNT, payload: undefined });
  dispatch({
    type: Types.ADD_ACCOUNT,
    payload: {
      accountAdded: undefined,
    },
  });
};

// To open add new Account Modal
export const openNewAccountModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_ACCOUNT_MODAL,
    payload: true,
  });
};

// To close add new Account Modal
export const closeNewAccountModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_ACCOUNT_MODAL,
    payload: false,
  });
};

const setAccountList = (accounts) => {
  return {
    type: Types.GET_ACCOUNT_LIST,
    payload: accounts,
  };
};

const setEditAccount = (account) => {
  return {
    type: Types.GET_EDIT_ACCOUNT,
    payload: account,
  };
};
