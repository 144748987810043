import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadSegmentById, openNewSegmentModal, clearEditSegmentStates } from "../../redux/actions/segment-actions";
import { openNewSiteModal, removeSite, clearSiteStates } from "../../redux/actions/site-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { notifyUser } from "../../services/notification-service";
import { List, Row, Col, Button, PageHeader, Breadcrumb, Input, Modal, Spin } from "antd";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import AddPk4SegmentModal from "../../components/modals/segments/add-segment"
import AddSiteModal from "../../components/modals/sites/add-site";
import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";


const EditPk4Segment = ({ editSegment, segmentAdded, siteAdded, segmentUpdated, siteRemoved }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { confirm } = Modal;
  const [isSearchStarted, setIsSearchStarted] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
      currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  useEffect(() => {
    (async () => {
      setSubmitted(true);
      await dispatch(loadSegmentById(id));
      setSubmitted(false);
    })();

    if (segmentAdded !== undefined) {
      if (segmentAdded.success === true && segmentAdded.message) {
        notifyUser(segmentAdded.message, "success");
      } else {
        notifyUser(segmentAdded.message, "error");
      }
    }

    if (siteAdded !== undefined) {
      if (siteAdded.success === true && siteAdded.message) {
        notifyUser(siteAdded.message, "success");
      } else {
        notifyUser(siteAdded.message, "error");
      }
    }

    if (siteRemoved !== undefined) {
      if (siteRemoved.success === true && siteRemoved.message) {
        notifyUser(siteRemoved.message, "success");
      } else {
        notifyUser(siteRemoved.message, "error");
      }
    }

    if (segmentUpdated !== undefined) {
      if (segmentUpdated.success === true && segmentUpdated.message) {
        notifyUser(segmentUpdated.message, "success");
      } else {
        notifyUser(segmentUpdated.message, "error");
      }
    }

    return () => {
      console.log("EditPk4Segment Unmounting");
      dispatch(clearEditSegmentStates());
      dispatch(clearSiteStates());
    };
  }, [segmentAdded, siteAdded, segmentUpdated, siteRemoved]);

  const onChange = (e) => {
    setIsSearchStarted(true);

    let res = [];
    if (e.target.value) {
      editSegment && editSegment.sites && editSegment.sites.map((site) =>
        site.domain.toUpperCase().indexOf(e.target.value.toUpperCase()) !== -1
          ? res.push(site)
          : ""
      );
    } else if (e.target.value === "") {
      setIsSearchStarted(false);
    }
    setSearchResult(res);
  };

  function showConfirm(siteId) {
    confirm({
      title: "Are you sure you want to remove this site?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(removeSite(siteId, id, "segment"))
      },
      onCancel() {
      },
    });
  }

  return (
    <section className="white-box-wrapper">
      <Spin size={"large"} spinning={submitted}></Spin>
      <Row className="align-center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/pk4segments">Pk4Segment</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {editSegment && editSegment.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
      
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PageHeader
            className="site-page-header edit-heading"
            title={editSegment && editSegment.name}
            
            subTitle={
            <i className="fas fa-edit" onClick={() => dispatch(openNewSegmentModal())}></i>  
          }
            
            extra={
              <div>
              {currentUserPermissionMap[currentUserRole]["canCreateOrRemoveSite"] ? (
            <Button key="1" type="primary" onClick={() => dispatch(openNewSiteModal())}>
                Add
          </Button>
            ):(
              ""
            )}
            </div>
            }  
            
          />
        </Col>
        
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input className="filter-search" onChange={onChange} style={{ width: "200px" }} placeholder="Filter" />
          <List className="card-box list-type-style"
            dataSource={isSearchStarted === false ? (
              editSegment && editSegment.sites
            ) : (
                searchResult && searchResult
              )}
            bordered
            renderItem={(site) => (
              <List.Item >
                {site.domain}
                {currentUserPermissionMap[currentUserRole]["canCreateOrRemoveSite"] ? (
                <DeleteFilled onClick={() => showConfirm(site.siteId)} />
                ):(
                  ""
                )}
              </List.Item>
            )}
          ></List>
        </Col>
      </Row>

      <AddPk4SegmentModal editSegmentInfo={editSegment} />

      <AddSiteModal siteType={"segment"} />
    </section>

  );
};

function mapStateToProps(state) {
  return {
    editSegment: state.pk4Segment.editSegment,
    segmentAdded: state.pk4Segment.segmentAdded,
    siteAdded: state.site.siteAdded,
    segmentUpdated: state.pk4Segment.segmentUpdated,
    siteRemoved: state.site.siteRemoved,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSegmentById: (id) => dispatch(loadSegmentById(id)),
    openNewSiteModal: () => dispatch(openNewSiteModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPk4Segment);
