import { Types } from "../constants/site-types";
const initialState = {
  siteList: [],
  isAddSiteModalOpen: false,
  siteAdded: undefined,
  errorMessage: undefined,
  siteRemoved: undefined,
};
export default function siteReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_SITE_LIST:
      return {
        ...state,
        siteList: action.payload,
      };
    case Types.GET_EDIT_SITE:
      return {
        ...state,
        editSite: action.payload,
      };
    case Types.OPEN_ADD_SITE_MODAL:
      return {
        ...state,
        isAddSiteModalOpen: action.payload,
      };
    case Types.ADD_SITE:
      return {
        ...state,
        siteAdded: action.payload.siteAdded,
        errorMessage: action.payload.errorMessage
          ? action.payload.errorMessage
          : initialState.errorMessage,
      };
    case Types.REMOVE_SITE:
      return {
        ...state,
        siteRemoved: action.payload,
      };
    case Types.CLEAR_SITE_STATES:
      return {
        ...state,
        siteList: [],
        siteAdded: undefined,
        errorMessage: undefined,
        siteRemoved: undefined,
      };
    default:
      return state;
  }
}
