import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  getDayStartTime,
  getDayEndTime,
  getLocalDate
} from "../../utils/appUtils";
import {
  closeUpdateVideoCreativeModal, closeUpdateTruengageCreativeModal,
  closeUpdateTagVideoCreativeModal
} from "./../../redux/actions/creative-actions";

const EditVideoCreativeForm = (
  form,
  setStartDate,
  setEndDate,
  startAt,
  endAt,
  setStartAt,
  setEndAt,
  videoLine,
  accountId,
  setFlightStartDateType,
  setFlightEndDateType,
  selectedDevices,
  setSelectedDevices,
  creativeType,
  setSourceCode,
) => {
  const dispatch = useDispatch();
  const [isSet, setIsSet] = useState(true);
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const handleCancel = () => {
    form.resetFields();
    if (creativeType === "video-creative") {
      dispatch(closeUpdateVideoCreativeModal());
    } else if (creativeType === "truengage-creative") {
      dispatch(closeUpdateTruengageCreativeModal());
    } else {
      dispatch(closeUpdateTagVideoCreativeModal());
    }
  };
  const onStartDateChange = (value, dateString) => {
    console.log(value, dateString);
    if (new Date(dateString).getTime() > endAt) {
      alert("Start Date must be less than End Date");
      return;
    }
    setStartDate(value);
    setStartAt(new Date(dateString).getTime());
  };

  const onEndDateChange = (value, dateString) => {
    console.log(value, dateString);
    if (new Date(dateString).getTime() < startAt) {
      alert("End Date must be greater than Start Date");
      return;
    }
    setEndDate(value);
    setEndAt(new Date(dateString).getTime());
  };

  const onChangeFlightDateType = (e) => {
    if (e.target.name === "startDate") {
      if (e.target.value === "Custom Start") {
        setFlightStartDateType(e.target.value);
        setStartDate(moment(getDayStartTime(), dateFormat));
        setStartAt(getDayStartTime().getTime());
      } else {
        setFlightStartDateType(e.target.value);
        setStartAt(getLocalDate(videoLine.videoLineVersion.startAt));
      }
    } else {
      if (e.target.value === "Custom End") {
        setFlightEndDateType(e.target.value);
        setEndDate(moment(getDayEndTime(), dateFormat));
        setEndAt(getDayEndTime().getTime());
      } else {
        setFlightEndDateType(e.target.value);
        setEndAt(getLocalDate(videoLine.videoLineVersion.endAt));
      }
    }
  };

  const onDeviceChange = (e) => {
    const devices = selectedDevices;
    if (devices.indexOf(e.target.value) > -1) {
      devices.splice(devices.indexOf(e.target.value), 1);
    } else {
      devices.push(e.target.value);
    }
    setSelectedDevices(devices);
    setIsSet(!isSet);
  };

  const isDeviceExists = (device) => {
    var filtered = [];
    filtered = selectedDevices && selectedDevices.filter(function (obj) {
      return device === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  const onChangeSourceCode = (e) => {
    setSourceCode(e.target.value);
  }

  return [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeFlightDateType,
    onDeviceChange,
    isDeviceExists,
    onChangeSourceCode,
  ];
};

export default EditVideoCreativeForm;
