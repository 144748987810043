import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Select } from "antd";
//import { timezones } from "./../../../constants/defaultValues";
import { closePrimaryContactsModal, addPrimaryContact } from "./../../../redux/actions/primary-contacts-actions";
import { loadUsersForPrimaryContacts } from "../../../redux/actions/user-actions";
//import { loadCampaignById } from "../../../redux/actions/campaign-actions";

const { Option } = Select;
const PrimaryContactsModal = ({ userList, isModalOpen, editCampaignInfo }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [allUsers, setAllUsers] = useState([{}]);
    const [primaryContacts, setPrimaryContacts] = useState(undefined);

    useEffect(() => {
        (async () => {
            await dispatch(loadUsersForPrimaryContacts());
        })();
        setPrimaryContacts(editCampaignInfo && editCampaignInfo.primaryContact && editCampaignInfo.primaryContact[0]);

        var userData = [];
        userList && userList.map((user) => {
            userData.push({
                userId: user.userId,
                userInfo: user.firstName + " " + user.lastName + " <" + user.email + ">"
            });
        });
        setAllUsers(userData);

        if (editCampaignInfo !== undefined) {
            var values = {
                accountExecutive: editCampaignInfo && editCampaignInfo.primaryContact && editCampaignInfo.primaryContact[0] && editCampaignInfo.primaryContact[0].accountExecutiveId,
                accountManager: editCampaignInfo && editCampaignInfo.primaryContact && editCampaignInfo.primaryContact[0] && editCampaignInfo.primaryContact[0].accountManagerId
            }
            form.setFieldsValue(values);
        }


        return () => {
            console.log("AddPrimaryContactModal Unmounting");
        };


    }, [isModalOpen, editCampaignInfo, form]);

    const handleSave = (userIds) => {
        ;
        var data = {
            accountExecutiveId: userIds.accountExecutive,
            accountManagerId: userIds.accountManager,
            campaignId: id,
        };

        dispatch(addPrimaryContact(data));
        dispatch(closePrimaryContactsModal());
        form.resetFields();
    };

    const handleCancel = () => {
        form.resetFields();
        dispatch(closePrimaryContactsModal());
    };

    return (
        <>
            <Modal
                title={"Primary Contacts"}
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    layout={"vertical"}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSave}
                >
                    <Form.Item
                     label="Account Manager"
                        name="accountManager"
                    >
                        <Select style={{ width: '100%' }}
                        >
                            {allUsers && allUsers.map((option) => (
                                (
                                    <Option value={option.userId} key={option}>{option.userInfo}</Option>
                                )
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Account Executive"
                        name="accountExecutive"
                    >
                        <Select style={{ width: '100%' }}>
                            {allUsers && allUsers.map((option) => (
                                (
                                    <Option value={option.userId} key={option}>{option.userInfo}</Option>
                                )
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
function mapStateToProps(state) {
    return {
        userList: state.user.userListForPrimaryContacts,
        isModalOpen: state.primaryContacts.isPrimaryContactsModalOpen,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadUsersForPrimaryContacts: () => dispatch(loadUsersForPrimaryContacts()),
        closePrimaryContactsModal: () => dispatch(closePrimaryContactsModal()),
    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrimaryContactsModal);
