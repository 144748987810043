import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import Chart from "react-apexcharts";
import {
  timezones,
  displayBuyReports,
} from "../../constants/defaultValues";
import { Select, Form, Row, Col, DatePicker, Table, Tooltip, Card } from "antd";
import moment from "moment";
import { exportDisplaySiteBuyReport, loadDisplaySiteBuyReport } from "../../redux/actions/site-buy-actions";
import { DownloadOutlined } from "@ant-design/icons";
import UsaDmaMap from "../map/usaDmaMap";
import UsaMap from "../map/usaMap";
import { isFloat, getDayEndDateTime, getDayStartDateTime, camelCaseToString, getUTCDate } from "../../utils/appUtils";
import userService from "../../services/user-service";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DisplaySiteBuyReporting = ({ siteBuyReport }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  var oneWeekAgo = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
  const [startEndDate, setStartEndDate] = useState([
    moment(oneWeekAgo, dateFormat),
    0,
  ]);
  const [lineSeriesData, setLineSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pieSeriesData, setPieSeriesData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [timezone, setTimezone] = useState("America/Los_Angeles");
  const [graphMetric, setGraphMetric] = useState("impressionUnique");
  const [report, setReport] = useState("Date - Delivery");
  const graphParams = ["Date - Delivery", "Moat", "Date - Requests"];
  const pieChartParams = [
    "Size - Delivery",
    "Size - Requests",
    "Device - Delivery",
    "Device - Requests",
    "Country - Delivery",
    "Country - Requests",
    "Domain - Delivery",
    "Domain - Requests"
  ];
  const usaDmaParams = ["DMA - Delivery", "DMA - Requests"];
  const usaParams = ["State - Delivery", "State - Requests",];
  const [dmaMapData, setDmaMapData] = useState("");
  const { id } = useParams();
  const [lineGraphMetricOptions, setLineGraphMetricOptions] = useState([]);
  const [columns, setColumns] = useState([]);
  const [disableTimezone, setDisableTimezone] = useState(false);

  useEffect(() => {
    var values = {};
    values = {
      date: startEndDate,
      report: report,
      graphMetric: graphMetric,
      timezone: timezone,
    };
    form.setFieldsValue(values);

    if (siteBuyReport && report) {
      if (siteBuyReport.header) {
        setLineGraphMetricOptions(siteBuyReport.header);
      }
      const data = [];
      const mapData = [];
      const categoriesData = [];
      siteBuyReport && siteBuyReport.data && siteBuyReport.data.map((siteBuyReport) => {
        data.push(siteBuyReport.eventData && siteBuyReport.eventData[graphMetric] && siteBuyReport.eventData[graphMetric] != null ? siteBuyReport.eventData[graphMetric] : 0);
        if (report === "DMA - Delivery" || report === "DMA - Requests" || report === "State - Delivery" || report === "State - Requests") {
          categoriesData.push({ code: (siteBuyReport.Id !== null ? siteBuyReport.Id : "unknown") });
          mapData.push({ code: (siteBuyReport.Id === null ? "unknown" : (typeof siteBuyReport.Id === 'string' ? siteBuyReport.Id : JSON.stringify(siteBuyReport.Id))), graphMetric: graphMetric, value: siteBuyReport.eventData && siteBuyReport.eventData[graphMetric] && siteBuyReport.eventData[graphMetric] != null ? siteBuyReport.eventData[graphMetric] : 0 });
        } else {
          categoriesData.push(siteBuyReport.Id === null ? "unknown" : siteBuyReport.Id);
        }
      });

      if (graphParams.includes(report)) {
        setLineSeriesData(data);
        setCategories(categoriesData);
      } else if (pieChartParams.includes(report)) {
        setPieSeriesData(data);
        setLabels(categoriesData);
      } else if (usaDmaParams.includes(report)) {
        setDmaMapData(JSON.stringify(mapData));
      } else if (usaParams.includes(report)) {
        setDmaMapData(JSON.stringify(mapData));
      }
    }

    var columnArray = [];
    if (report === "Creative") {
      columnArray.push({
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        hidden: report !== "Creative" ? true : false,
        render: (text) =>
          text && text !== null
            ? text.includes("-")
              ? text.replace("-", "").replace("-", "")
              : text
            : "unknown"

      },
        {
          title: "Type",
          dataIndex: "Type",
          key: "Type",
          hidden: report !== "Creative" ? true : false,
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        });
    } else {
      columnArray.push({
        title: "Id",
        dataIndex: "Id",
        key: "Id",
        hidden: report !== "Creative" ? true : false,
        render: (text) =>
        text && text !== null
          ? (typeof text === 'string' ? (text.includes("-") ?
            text.replace("-", "").replace("-", "") : text) : (text !== 0 ? text : "unknown"))
          : "unknown"
      });
    }

    siteBuyReport && siteBuyReport.header && siteBuyReport.header.map((header) => {
      columnArray.push({
        title: camelCaseToString(header),
        dataIndex: header,
        key: header,
        render: (text, record) =>
          (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? ((isFloat(record.eventData[header]) ? record.eventData[header].toFixed(2) : record.eventData[header])) : "-")
      });
    });
    setColumns(columnArray);

    return () => {
      console.log("Display Private Buy Reporting unmounting");
    };
  }, [siteBuyReport, graphMetric]);

  const lineSeries = [
    {
      name: graphMetric,
      data: lineSeriesData,
    },
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    markers: {
      size: 5,
      colors: "#007bff",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
      colors: "#007bff",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
    },
  };

  const series = pieSeriesData;
  var options = {
    chart: {
      width: 650,
      type: "pie",
    },
    labels: labels,
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
  };

  const loadReportData = (dateValue, reportValue, timezoneValue) => {
    let data = {};
    // data["startDate"] = dateValue && dateValue !== null && dateValue[0] 
    // ? getDayStartDateTime(new Date(dateValue[0])) : getDayStartDateTime(new Date());
    // data["endDate"] =
    //   dateValue && dateValue !== null && dateValue[1]
    //     ? getDayEndDateTime(new Date(dateValue[1]))
    //     : getDayEndDateTime(new Date());

    data["startDate"] = dateValue && dateValue !== null && dateValue[0]
      ? new Date(getUTCDate(getDayStartDateTime(new Date(dateValue[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    data["endDate"] =
      dateValue && dateValue !== null && dateValue[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(dateValue[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["report"] = reportValue;
    data["id"] = id;
    data["timezone"] = timezoneValue;
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(loadDisplaySiteBuyReport(data));
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const currentUser = userService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  const reportsToExcludeForPublisher = [
    "Moat",
    "Date - Requests",
    "Size - Requests",
    "DMA - Requests",
    "State - Requests",
    "Device - Requests",
    "Country - Requests",
    "Domain - Requests",
  ];

  const filteredBuyReportOptions = displayBuyReports.filter((report) => {
    if (currentUserRole === 'ROLE_PUBLISHER_OBSERVER') {
      console.log("currentUserRole " + currentUserRole);
      return !reportsToExcludeForPublisher.includes(report);
    } else {
      return true;
    }
  });

  const buyReportOptions = filteredBuyReportOptions.map((report) => (
    <Option key={report} value={report}>
      {report}
    </Option>
  ));

  const graphMetricOptions = lineGraphMetricOptions && lineGraphMetricOptions.map((metric) => (
    <Option key={metric} value={metric}>
      {metric}
    </Option>
  ));

  const onDateChange = (value, dateString) => {
    setStartEndDate(value);
    loadReportData(value, report, timezone);
  };

  const onReportChange = (value) => {
    setReport(value);
    if (value === "Moat") {
      setGraphMetric('impressions_analyzed');
      setTimezone("America/New_York");
      setDisableTimezone(true);
    } else if (value.includes("Requests")) {
      setGraphMetric('total');
      setTimezone("America/Los_Angeles");
      setDisableTimezone(false);
    } else {
      setGraphMetric('impressionUnique');
      setTimezone("America/Los_Angeles");
      setDisableTimezone(false);
    }
    loadReportData(startEndDate, value, timezone);
  };

  const onTimezoneChange = (value) => {
    setTimezone(value);
    loadReportData(startEndDate, report, value);
  };

  const onGraphMetricChange = (value) => {
    setGraphMetric(value);
  };

  const exportDisplaySiteBuyReportTable = async () => {
    let data = {};
    // data["startDate"] =
    //   startEndDate && startEndDate !== null && startEndDate[0]
    //     ? getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
    // data["endDate"] =
    //   startEndDate && startEndDate !== null && startEndDate[1]
    //     ? getDayEndDateTime(new Date(startEndDate[1]))
    //     : getDayEndDateTime(new Date());

    data["startDate"] =
      startEndDate && startEndDate !== null && startEndDate[0]
        ? new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    data["endDate"] =
      startEndDate && startEndDate !== null && startEndDate[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["report"] = report;
    data["id"] = id;
    data["timezone"] = timezone;
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var response = await dispatch(exportDisplaySiteBuyReport(data));
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));

    a.download = "Display-Site-Buy-" + report + ".xlsx";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
  }

  return (
    <>
      <Form form={form} layout={"vertical"}>
        <Card>
          <Row gutter={24}>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Report" name="report">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onReportChange}
                >
                  {buyReportOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Graph Metric" name="graphMetric">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onGraphMetricChange}
                >
                  {graphMetricOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Timezone" name="timezone">
                <Select
                  disabled={disableTimezone}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onTimezoneChange}
                >
                  {timezonesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={6} lg={6} xl={6}>
              <Form.Item label="Date" name="date">
                <RangePicker format={dateFormat} onChange={onDateChange} />
              </Form.Item>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={3}
              lg={3}
              xl={3}
              className="upload-right account-form-filter"
              style={{ display: "block" }}
            >
              <Tooltip placement="top" title="Download Table">
                <DownloadOutlined onClick={exportDisplaySiteBuyReportTable} />
              </Tooltip>
            </Col>
          </Row>
        </Card>
      </Form>
      <Card key={report + "Chart"}>
        {siteBuyReport && siteBuyReport.data && siteBuyReport.data.length === 0 ? (
          <h1 style={{ textAlign: "center", color: "black" }}>No Data</h1>
        ) : report && graphParams && graphParams.includes(report) ? (
          <Chart
            options={lineOptions}
            series={lineSeries}
            type="line"
            height={350}
          />
        ) : report && pieChartParams && pieChartParams.includes(report) ? (
          <Chart
            options={options}
            series={series}
            type="pie"
            style={{ padding: 0, width: "400px", margin: "auto" }}
          />
        ) : dmaMapData && usaDmaParams && usaDmaParams.includes(report) ? (
          <div className="fusioncenter">
            <UsaDmaMap selectedDmaRegions={dmaMapData} />
          </div>
        ) : dmaMapData && usaParams && usaParams.includes(report) ? (
          <div className="fusioncenter">
            <UsaMap selectedDmaRegions={dmaMapData} />
          </div>
        ) : (
          ""
        )}
      </Card>
      <Card>
        <Table
          scroll={{ x: 1200 }}
          columns={columns}
          dataSource={siteBuyReport && siteBuyReport.data}
          rowKey="Id"
        />
      </Card>
    </>
  );
};

function mapStateToProps(state) {
  return {
    siteBuyReport: state.siteBuy.siteBuyReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DisplaySiteBuyReporting
);
