import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input } from "antd";
import { closeAddSiteListModal, addSiteList } from "../../../redux/actions/site-list-actions";

const { TextArea } = Input;
const AddSiteListModal = ({ isAddSiteListModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
     
    return () => {
      console.log("AddSiteListModal Unmounting");
    };
  }, [isAddSiteListModalOpen]);

  const handleSave = (values) => {
    values["campaignId"] = id;
    dispatch(addSiteList(values));
    dispatch(closeAddSiteListModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAddSiteListModal());
  };

  return (
    <>
      <Modal
        title={"New Site List"}
        visible={isAddSiteListModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input site list name!',
                whitespace: true
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              placeholder="Add Site List Name"
              autoSize
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
        </Button>
            <Button type="primary" htmlType="submit" >
              Save
        </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddSiteListModalOpen: state.siteList.isAddSiteListModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAddSiteListModal: () => dispatch(closeAddSiteListModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddSiteListModal);
