import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeAssignAccountModal, updateUser } from "./../../../redux/actions/user-actions";
import { useParams } from "react-router-dom";

const AccountPrivilegeModal = ({ accountList, selectedAccountList, isAssignAccountModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const selectValuesTemp = [];
    if (selectedAccountList && selectedAccountList.length > 0) {
      selectedAccountList.filter(function(obj) {
        selectValuesTemp.push(obj.accountId);
      });
      setSelectedValues(selectValuesTemp);
    }
    return () => {
      console.log("AccountPrivilegeModal Unmounting");
    };
  }, [selectedAccountList]);

  const handleSave = (values) => {
    values["isPrivilegesUpdated"] = true;
    dispatch(updateUser(id, values));
    form.resetFields();
    dispatch(closeAssignAccountModal());
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAssignAccountModal());
  };

  const onChange = (e) => {
    const selectValuesTemp = selectedValues;
    if(selectValuesTemp.indexOf(e.target.value) > -1) {
      selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1)      
    } else {      
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  const isAccountExists = (accountId) => {
    var filtered = selectedValues.filter(function(obj) {
      return accountId === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  function _bindAccounts(accounts) {
    return accounts.map((account, index) => {
      return (
        <Row>
          <Col span={24}>
            <Checkbox checked={isAccountExists(account.accountId)} onChange={onChange} value={account.accountId}>{account.name}</Checkbox>
          </Col>
        </Row>
      );
    });
  }

  return (
    <>
      <Modal title="Assign Account Privileges" visible={isAssignAccountModalOpen} onCancel={handleCancel} footer={[]}>
        <Form
          form={form}
          initialValues={{
            accountIds: selectedValues,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item name="accountIds" label="Select Account">
            {accountList && _bindAccounts(accountList)}
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAssignAccountModalOpen: state.user.isAssignAccountModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAssignAccountModal: () => dispatch(closeAssignAccountModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AccountPrivilegeModal);
