import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Tabs, Breadcrumb, PageHeader, Spin } from "antd";
import LineDetailLayout from "../../components/layout/edit-line/video-line-details";
import LineHistoryLayout from "../../components/layout/edit-line/video-line-history";
import LineOverviewLayout from "../../components/layout/edit-line/line-overview";
import {
  loadVideoLineById, loadAllDevices, loadAllGeoNames, loadAllDmaRegions, loadAllExelateSegments,
  clearEditVideoLineStates, loadVideoLineHistoryById, loadVideoLineOverviewData,
} from "../../redux/actions/line-actions";
import AddPrivateBuyModal from "../../components/modals/private-buy/add-private-buy";
import AddVideoCreativeModal from "../../components/modals/creative/add-video-creative";
import EditDevice from "../../components/modals/line/edit-device";
import EditGeoname from "../../components/modals/line/edit-geoname";
import EditDmaRegion from "../../components/modals/line/edit-dma-targeting";
import EditExelateSegments from "../../components/modals/line/edit-exelate-segments";
import { loadPublishers } from "../../redux/actions/publisher-actions";
import { getDayStartDateTime, getDayEndDateTime, getUTCDate } from "../../utils/appUtils";
import {
  loadVideoPrivateBuysByVideoLineId,
  clearPrivateBuyReduxState
} from "../../redux/actions/private-buy-actions";
import { notifyUser } from "../../services/notification-service";
import { clearEditCreativeReduxState } from "../../redux/actions/creative-actions";
import EditSiteList from "../../components/modals/line/edit-site-list";
import EditExportReportModal from "../../components/modals/export-report/edit-export-report";
import VideoLineReporting from "../../components/layout/edit-line/video-line-reporting";
import { loadVideoLineReport } from "../../redux/actions/line-actions";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";
import EditClickUrl from "./../../components/modals/line/edit-click-url";
import { allVideoSiteBuys } from "../../redux/actions/site-buy-actions";
import { clearExportReportStates } from "../../redux/actions/export-report-actions";

const { TabPane } = Tabs;

const EditVideoLine = ({ editVideoLine, publisherList, vedioBuyAdded, videoLineUpdated,
  videoCreativeAdded, truengageCreativeAdded, tagVideoCreativeAdded, videoHistory,
  reportExported, lineOverviewData, videoSiteBuyList }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const lineType = "video";
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadVideoLineById(id));
      await dispatch(loadPublishers());
      await dispatch(loadVideoPrivateBuysByVideoLineId(id));
      await dispatch(loadAllDevices());
      await dispatch(loadAllGeoNames());
      await dispatch(loadAllDmaRegions());
      await dispatch(loadVideoLineHistoryById(id));
      await dispatch(loadAllExelateSegments());
      await dispatch(loadVideoLineOverviewData(id));
      await dispatch(allVideoSiteBuys());
      let data = {};
      data["startDate"] = new Date(getUTCDate(getDayStartDateTime(new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000))))).toUTCString();
      data["endDate"] = new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
      data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      data["report"] = "date";
      data["id"] = id;
      data["timezone"] = "America/Los_Angeles";
      await dispatch(loadVideoLineReport(data));
      if(isMounted ){

      setSubmitted(false);
    }  })();

    if (vedioBuyAdded !== undefined) {
      if (vedioBuyAdded.success === true) {
        notifyUser(vedioBuyAdded.message, "success");
      } else {
        notifyUser(vedioBuyAdded.message, "error");
      }
    }
    if (videoLineUpdated !== undefined) {
      if (videoLineUpdated.success === true) {
        notifyUser(videoLineUpdated.message, "success");
      } else {
        notifyUser(videoLineUpdated.message, "error");
      }
    }
    if (videoCreativeAdded !== undefined) {
      if (videoCreativeAdded.success === true) {
        notifyUser(videoCreativeAdded.message, "success");
      } else {
        notifyUser(videoCreativeAdded.message, "error");
      }
    }
    if (truengageCreativeAdded !== undefined) {
      if (truengageCreativeAdded.success === true) {
        notifyUser(truengageCreativeAdded.message, "success");
      } else {
        notifyUser(truengageCreativeAdded.message, "error");
      }
    }
    if (tagVideoCreativeAdded !== undefined) {
      if (tagVideoCreativeAdded.success === true) {
        notifyUser(tagVideoCreativeAdded.message, "success");
      } else {
        notifyUser(tagVideoCreativeAdded.message, "error");
      }
    }

    if (reportExported !== undefined) {
      console.log("reportExported----", reportExported)
      if (reportExported.success === true) {
        notifyUser(reportExported.message, "success");
      } else {
        notifyUser(reportExported.message, "error");
      }
    }

    return () => {
      isMounted = false;

      console.log("EditVideoLine Unmounting");
      dispatch(clearEditVideoLineStates());
      dispatch(clearPrivateBuyReduxState());
      dispatch(clearEditCreativeReduxState());
      dispatch(clearExportReportStates());
    };
  }, [vedioBuyAdded, videoLineUpdated, videoCreativeAdded, truengageCreativeAdded, tagVideoCreativeAdded, reportExported]);

  function callback(key) {
    console.log(key);
  }

  console.log(editVideoLine);
  return (
    <>
      <section className="white-box-wrapper">
        {editVideoLine ? <><Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/accounts/${editVideoLine.account && editVideoLine.account.accountId}`}>Account</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/campaigns/${editVideoLine.campaign && editVideoLine.campaign.campaignId}`}>Campaign</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/lines/video/${editVideoLine.displayLineId}`}>Video Line</Link>
          </Breadcrumb.Item>
        </Breadcrumb></> : ""}

        <PageHeader
          className="site-page-header"
          title={
            editVideoLine &&
              editVideoLine.videoLineVersion &&
              editVideoLine.videoLineVersion.name ? editVideoLine.videoLineVersion.name : ""
          }
          subTitle={(editVideoLine && editVideoLine.account && editVideoLine.account.name ? editVideoLine.account.name : "") + " - " + (editVideoLine && editVideoLine.campaign && editVideoLine.campaign.name ? editVideoLine.campaign.name : "")}
        />

        <Tabs defaultActiveKey="details" size="large" type="line" onChange={callback}>
          <TabPane tab="OVERVIEW" key="overview">
            <LineOverviewLayout lineOverviewData={lineOverviewData && lineOverviewData} lineType="video" line={editVideoLine} />
          </TabPane>
          <TabPane tab="DETAILS" key="details">
            <LineDetailLayout  editVideoLine={editVideoLine && editVideoLine} videoSiteBuyList={videoSiteBuyList && videoSiteBuyList} />
          </TabPane>
          <TabPane tab="REPORTING" key="reporting">
            <VideoLineReporting editVideoLine={editVideoLine && editVideoLine} />
          </TabPane>
          {currentUserPermissionMap[currentUserRole]["canAccessVideoHistory"] ? (
            <TabPane tab="HISTORY" key="history">
              <LineHistoryLayout videoHistory={videoHistory && videoHistory} />
            </TabPane>
          ) : ("")
          }
        </Tabs>
        <Spin size={"large"} spinning={submitted}></Spin>
        {editVideoLine && <AddVideoCreativeModal siteBuyType={lineType} editVideoLine={editVideoLine} accountId={editVideoLine.accountId} />}
        <AddPrivateBuyModal privateBuyType={lineType} publisherList={publisherList} editPrivateBuy={undefined} />
        <EditDevice lineType={lineType} />
        <EditGeoname lineType={lineType} />
        <EditDmaRegion lineType={lineType} />
        <EditExelateSegments lineType={lineType} />
        <EditSiteList lineType={lineType} />
        <EditExportReportModal type="video" />
        <EditClickUrl lineType={lineType} />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    editVideoLine: state.line.editVideoLine,
    publisherList: state.publisher.publisherList,
    vedioBuyAdded: state.privateBuy.vedioBuyAdded,
    videoLineUpdated: state.line.videoLineUpdated,
    videoCreativeAdded: state.creative.videoCreativeAdded,
    truengageCreativeAdded: state.creative.truengageCreativeAdded,
    tagVideoCreativeAdded: state.creative.tagVideoCreativeAdded,
    videoHistory: state.line.videoHistory,
    lineOverviewData: state.line.lineOverviewData,
    reportExported: state.exportReport.reportExported,
    videoSiteBuyList: state.siteBuy.videoSiteBuyList
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditVideoLine);
