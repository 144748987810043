import { Types } from "../constants/campaign-types";
const initialState = {
  campaignList: [],
  isAddCampaignModalOpen: false,
  campaignAdded: undefined,
  editCampaign: undefined,
  campaignUpdated: undefined,
  isAddCampaignCategoryModalOpen: false,
  campaignCategoryList: undefined,
  isAddCampaignPixelModalOpen: false,
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload,
      };
    case Types.OPEN_ADD_CAMPAIGN_MODAL:
      return {
        ...state,
        isAddCampaignModalOpen: action.payload,
      };
    case Types.ADD_CAMPAIGN:
      return {
        ...state,
        campaignAdded: action.payload,
      };
    case Types.EDIT_CAMPAIGN:
      return {
        ...state,
        editCampaign: action.payload,
      };
    case Types.UPDATE_CAMPAIGN:
      return {
        ...state,
        campaignUpdated: action.payload,
      };
    case Types.OPEN_BLACKLIST_WHITELIST_PUBLISHER_MODAL:
      return {
        ...state,
        isBlacklistWhitelistPublisherModalOpen: action.payload.status,
        publisherType: action.payload.publisherType,
        selectedPublisherList: action.payload.selectedPublisherList,
      };
    case Types.OPEN_BLACKLIST_WHITELIST_PRIVATE_BUY_MODAL:
      return {
        ...state,
        isBlacklistWhitelistPrivateBuyModalOpen: action.payload.status,
        privateBuyType: action.payload.privateBuyType,
        updateType: action.payload.updateType,
        selectedPrivateBuyList: action.payload.selectedPrivateBuyList,
      };
      case Types.OPEN_BLACKLIST_WHITELIST_SITE_BUY_MODAL:
      return {
        ...state,
        isBlacklistWhitelistSiteBuyModalOpen: action.payload.status,
        siteBuyType: action.payload.siteBuyType,
        updateType: action.payload.updateType,
        selectedSiteBuyList: action.payload.selectedSiteBuyList,
      };
    case Types.OPEN_ADD_CAMPAIGN_CATEGORY_MODAL:
      return {
        ...state,
        isAddCampaignCategoryModalOpen: action.payload,
      };
    case Types.GET_ALL_CAMPAIGN_CATEGORIES:
      return {
        ...state,
        campaignCategoryList: action.payload,
      };
    case Types.OPEN_ADD_CAMPAIGN_PIXEL_MODAL:
      return {
        ...state,
        isAddCampaignPixelModalOpen: action.payload.isAddCampaignPixelModalOpen,
        selectedPixelList: action.payload.selectedPixelList,
        pixelList: action.payload.pixelList,
        pixelType: action.payload.pixelType,
      };
    case Types.CLEAR_EDIT_CAMPAIGN_STATES:
      return {
        ...state,
        campaignList: [],
        campaignAdded: undefined,
        editCampaign: undefined,
        campaignUpdated: undefined,
        campaignCategoryList: undefined,
      };

    default:
      return state;
  }
}
