export const Types = {
  OPEN_ADD_CREATIVE_MODAL: "OPEN_ADD_CREATIVE_MODAL",
  UPLOAD_IMAGE_RESOURCE: "UPLOAD_IMAGE_RESOURCE",
  GET_ALL_IMAGE_RESOURCES_BY_ACCOUNT_ID: "GET_ALL_IMAGE_RESOURCES_BY_ACCOUNT_ID",
  ADD_IMAGE_CREATIVE: "ADD_IMAGE_CREATIVE",
  OPEN_ADD_VIDEO_CREATIVE_MODAL: "OPEN_ADD_VIDEO_CREATIVE_MODAL",
  ADD_VIDEO_CREATIVE: "ADD_VIDEO_CREATIVE",
  UPLOAD_VIDEO_RESOURCE: "UPLOAD_VIDEO_RESOURCE",
  GET_ALL_VIDEO_RESOURCES_BY_ACCOUNT_ID: "GET_ALL_VIDEO_RESOURCES_BY_ACCOUNT_ID",
  GET_EDIT_IMAGE_CREATIVE: "GET_EDIT_IMAGE_CREATIVE",
  OPEN_EDIT_CREATIVE_MODAL: "OPEN_EDIT_CREATIVE_MODAL",
  ADD_TRUENGAGE_CREATIVE: "ADD_TRUENGAGE_CREATIVE",
  UPDATE_DISPLAY_CREATIVE: "UPDATE_DISPLAY_CREATIVE",
  ADD_TAG_DISPLAY_CREATIVE: "ADD_TAG_DISPLAY_CREATIVE",
  ADD_TAG_VIDEO_CREATIVE: "ADD_TAG_VIDEO_CREATIVE",
  GET_EDIT_TAG_DISPLAY_CREATIVE: "GET_EDIT_TAG_DISPLAY_CREATIVE",
  OPEN_EDIT_TAG_DISPLAY_CREATIVE_MODAL: "OPEN_EDIT_TAG_DISPLAY_CREATIVE_MODAL",
  UPDATE_TAG_DISPLAY_CREATIVE: "UPDATE_TAG_DISPLAY_CREATIVE",
  GET_EDIT_VIDEO_CREATIVE: "GET_EDIT_VIDEO_CREATIVE",
  GET_EDIT_TRUENGAGE_CREATIVE: "GET_EDIT_TRUENGAGE_CREATIVE",
  UPDATE_VIDEO_CREATIVE: "UPDATE_VIDEO_CREATIVE",
  UPDATE_TRUENGAGE_CREATIVE: "UPDATE_TRUENGAGE_CREATIVE",
  OPEN_EDIT_VIDEO_CREATIVE_MODAL: "OPEN_EDIT_VIDEO_CREATIVE_MODAL",
  OPEN_EDIT_TRUENGAGE_CREATIVE_MODAL: "OPEN_EDIT_TRUENGAGE_CREATIVE_MODAL",
  GET_EDIT_TAG_VIDEO_CREATIVE: "GET_EDIT_TAG_VIDEO_CREATIVE",
  UPDATE_TAG_VIDEO_CREATIVE: "UPDATE_TAG_VIDEO_CREATIVE",
  OPEN_EDIT_TAG_VIDEO_CREATIVE_MODAL: "OPEN_EDIT_TAG_VIDEO_CREATIVE_MODAL",
  CLEAR_EDIT_CREATIVE_STATES: "CLEAR_EDIT_CREATIVE_STATES",
};
