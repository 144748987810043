import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Row, Col, Breadcrumb, PageHeader, Spin } from "antd";
import {
  loadVideoCreativeById,
  loadTruengageCreativeById,
  loadTagVideoCreativeById,
  openUpdateVideoCreativeModal,
  clearEditCreativeReduxState
} from "../../redux/actions/creative-actions";
import EditVideoCreativeModal from "../../components/modals/creative/edit-video-creative";
import EditTruengageCreativeModal from "../../components/modals/creative/edit-truengage-creative";
import EditTagVideoCreativeModal from "../../components/modals/creative/edit-tag-video-creative";
import { loadAllDevices } from "../../redux/actions/line-actions";
import { notifyUser } from "../../services/notification-service";
import VideoCreativeDetailLayout from "./../../components/layout/edit-creative/video-creative-details";
import TruengageCreativeDetailLayout from "../../components/layout/edit-creative/truengage-creative-details";
import TagVideoCreativeDetail from "../../components/layout/edit-creative/tag-video-creative-details";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";

const EditVideoCreative = ({
  editVideoCreative,
  editTruengageCreative,
  editTagVideoCreative,
  videoCreativeUpdated,
  truengageCreativeUpdated,
  tagVideoCreativeUpdated,
}) => {
  const dispatch = useDispatch();
  const { id, videoCreativeType } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";

  useEffect(() => {
    (async () => {
      setSubmitted(true);
      if (videoCreativeType === "video-creative") {
        await dispatch(loadVideoCreativeById(id));
      } else if (videoCreativeType === "truengage-creative") {
        await dispatch(loadTruengageCreativeById(id));
      } else {
        await dispatch(loadTagVideoCreativeById(id));
      }
      await dispatch(loadAllDevices());
      setSubmitted(false);
    })();

    if (videoCreativeUpdated !== undefined) {
      if (videoCreativeUpdated.success === true) {
        notifyUser(videoCreativeUpdated.message, "success");
      } else {
        notifyUser(videoCreativeUpdated.message, "error");
      }
    }
    if (truengageCreativeUpdated !== undefined) {
      if (truengageCreativeUpdated.success === true) {
        notifyUser(truengageCreativeUpdated.message, "success");
      } else {
        notifyUser(truengageCreativeUpdated.message, "error");
      }
    }
    if (tagVideoCreativeUpdated !== undefined) {
      if (tagVideoCreativeUpdated.success === true) {
        notifyUser(tagVideoCreativeUpdated.message, "success");
      } else {
        notifyUser(tagVideoCreativeUpdated.message, "error");
      }
    }

    return () => {
      console.log("EditVideoCreative Unmounting");
      dispatch(clearEditCreativeReduxState());
    };
  }, [videoCreativeUpdated, truengageCreativeUpdated, tagVideoCreativeUpdated]);

  return (
    <section className="white-box-wrapper">
      <Spin size={"large"} spinning={submitted}></Spin>
      {editVideoCreative && videoCreativeType === "video-creative" ? (
        <Row className="align-top">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editVideoCreative.videoLine.accountId}`}>Account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/campaigns/${editVideoCreative.videoLine.campaignId}`}>Campaign</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/video/${editVideoCreative.videoLine.videoLineId}`}>Line</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/creatives/video/${videoCreativeType}/${id}`}>{videoCreativeType}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
              className="site-page-header"
              subTitle={
                editVideoCreative &&
                editVideoCreative.videoLine &&
                editVideoCreative.videoLine.account.name +
                " - " +
                editVideoCreative.videoLine.campaign.name +
                " - " +
                editVideoCreative.videoLine.videoLineVersion.name
              }
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PageHeader
              className="site-page-header justify-right edit-block"
              title={
                editVideoCreative &&
                editVideoCreative.videoCreativeVersion &&
                editVideoCreative.videoCreativeVersion.name
              }
              subTitle={currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <i
                  className="fas fa-edit"
                  onClick={() => dispatch(openUpdateVideoCreativeModal("Name"))}
                ></i>
              ) : ""
              }
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editTruengageCreative && videoCreativeType === "truengage-creative" ? (
        <Row className="align-top">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editTruengageCreative.videoLine.accountId}`}>Account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/campaigns/${editTruengageCreative.videoLine.campaignId}`}>
                  Campaign
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/video/${editTruengageCreative.videoLine.videoLineId}`}>Line</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/creatives/video/${videoCreativeType}/${id}`}>{videoCreativeType}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
              className="site-page-header"
              subTitle={
                editTruengageCreative &&
                editTruengageCreative.videoLine &&
                editTruengageCreative.videoLine.account.name +
                " - " +
                editTruengageCreative.videoLine.campaign.name +
                " - " +
                editTruengageCreative.videoLine.videoLineVersion.name
              }
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PageHeader
              className="site-page-header justify-right edit-block"
              title={
                editTruengageCreative &&
                editTruengageCreative.truengageCreativeVersion &&
                editTruengageCreative.truengageCreativeVersion.name
              }
              subTitle={currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <i
                  className="fas fa-edit"
                  onClick={() => dispatch(openUpdateVideoCreativeModal("Name"))}
                ></i>
              ) : ""
              }
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editTagVideoCreative && videoCreativeType === "tag-video-creative" ? (
        <Row className="align-top">
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/accounts/${editTagVideoCreative.videoLine.accountId}`}>Account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/campaigns/${editTagVideoCreative.videoLine.campaignId}`}>Campaign</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/lines/video/${editTagVideoCreative.videoLine.videoLineId}`}>Line</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/creatives/video/${videoCreativeType}/${id}`}>{videoCreativeType}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
              className="site-page-header"
              subTitle={
                editTagVideoCreative &&
                editTagVideoCreative.videoLine &&
                editTagVideoCreative.videoLine.account.name +
                " - " +
                editTagVideoCreative.videoLine.campaign.name +
                " - " +
                editTagVideoCreative.videoLine.videoLineVersion.name
              }
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PageHeader
              className="site-page-header justify-right edit-block"
              title={
                editTagVideoCreative &&
                editTagVideoCreative.tagVideoCreativeVersion &&
                editTagVideoCreative.tagVideoCreativeVersion.name
              }
              subTitle={currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <i
                  class="fas fa-edit"
                  onClick={() => dispatch(openUpdateVideoCreativeModal("Name"))}
                ></i>
              ) : ""
              }
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      <>
        {videoCreativeType === "video-creative" &&
          editVideoCreative &&
          editVideoCreative.videoCreativeVersion ? (
          <>
            <VideoCreativeDetailLayout editVideoCreative={editVideoCreative} />
            {editVideoCreative.videoLine && (
              <EditVideoCreativeModal
                creativeType={videoCreativeType}
                editCreativeInfo={editVideoCreative}
                videoLine={editVideoCreative.videoLine}
                accountId={editVideoCreative.videoLine.accountId}
              />
            )}
          </>
        ) : videoCreativeType === "truengage-creative" &&
          editTruengageCreative &&
          editTruengageCreative.truengageCreativeVersion ? (
          <>
            <TruengageCreativeDetailLayout editTruengageCreative={editTruengageCreative} />
            {editTruengageCreative.videoLine && (
              <EditTruengageCreativeModal
                creativeType={videoCreativeType}
                editCreativeInfo={editTruengageCreative}
                videoLine={editTruengageCreative.videoLine}
                accountId={editTruengageCreative.videoLine.accountId}
              />
            )}
          </>
        ) : (
          videoCreativeType === "tag-video-creative" &&
          editTagVideoCreative &&
          editTagVideoCreative.tagVideoCreativeVersion && (
            <>
              <TagVideoCreativeDetail editTagVideoCreative={editTagVideoCreative} />
              {editTagVideoCreative.videoLine && (
                <EditTagVideoCreativeModal
                  creativeType={editTagVideoCreative}
                  editCreativeInfo={editTagVideoCreative}
                  videoLine={editTagVideoCreative.videoLine}
                  accountId={editTagVideoCreative.videoLine.accountId}
                />
              )}
            </>
          )
        )}
      </>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    editTruengageCreative: state.creative.editTruengageCreative,
    editVideoCreative: state.creative.editVideoCreative,
    editTagVideoCreative: state.creative.editTagVideoCreative,
    videoCreativeUpdated: state.creative.videoCreativeUpdated,
    truengageCreativeUpdated: state.creative.truengageCreativeUpdated,
    tagVideoCreativeUpdated: state.creative.tagVideoCreativeUpdated,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditVideoCreative);
