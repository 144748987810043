import { Types } from "../constants/primary-contacts";

const initialState = {
  isPrimaryContactsModalOpen: false,
  primaryContactAdded: undefined,

};
export default function primaryContactsReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_PRIMARY_CONTACTS_MODAL:
      var modalValue = action.payload.isModalOpen;
      return {
        ...state,
        isPrimaryContactsModalOpen: modalValue,
      };
    case Types.ADD_PRIMARY_CONTACT:
      return {
        ...state,
        primaryContactAdded: action.payload,
      };
      case Types.CLEAR_PRIMARY_CONTACTS_STATES:
      return {
        ...state,
        primaryContactAdded: undefined
      };

    default:
      return state;
  }
}
