import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select } from "antd";
import { timezones } from "./../../../constants/defaultValues";
import { closeNewCampaignModal, addCampaign, updateCampaign } from "./../../../redux/actions/campaign-actions";
import { checkTimezone } from "./../../../utils/appUtils";

const { TextArea } = Input;
const { Option } = Select;
const AddCampaignModal = ({ isAddCampaignModalOpen, editCampaignInfo }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [isNewCampaign, setIsNewCampaign] = useState(true);

  useEffect(() => {
    if (editCampaignInfo !== undefined) {
      setIsNewCampaign(false);
      var values = {
        name: editCampaignInfo && editCampaignInfo.name ? editCampaignInfo.name : "",
        timezone: editCampaignInfo && editCampaignInfo.timezone 
            ? checkTimezone(editCampaignInfo.timezone)
            : "",
      };
      form.setFieldsValue(values);
    }
    return () => {
      console.log("AddCampaignModal Unmounting");
    };
  }, [isAddCampaignModalOpen, form, editCampaignInfo]);

  const handleSave = (campaignData) => {
    campaignData["isDisplayPrivateBuysUpdated"] = false;
    campaignData["isVideoPrivateBuysUpdated"] = false;
    campaignData["isDisplaySiteBuysUpdated"] = false;
    campaignData["isVideoSiteBuysUpdated"] = false;
    campaignData["isPublishersUpdated"] = false;
    campaignData["isRetargetingPixelsUpdated"] = false;
    campaignData["isConversionPixelsUpdated"] = false;
    if(isNewCampaign){
      dispatch(addCampaign(id, campaignData));
    } else {
      dispatch(updateCampaign(editCampaignInfo.campaignId, campaignData));
    }
    dispatch(closeNewCampaignModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewCampaignModal());
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  return (
    <>
      <Modal
       getContainer={false}
        title={isNewCampaign ? "New Campaign" : "Update Campaign"}
        visible={isAddCampaignModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input campaign name!',
                whitespace: true
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              placeholder="Add Campaign Name"
              autoSize
            />
          </Form.Item>
          <Form.Item
            label="Timezone"
            name="timezone"
            rules={[
              {
                required: true,
                message: 'Please select timezone!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {timezonesOptions}
            </Select>
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
        </Button>
            <Button type="primary" htmlType="submit" >
              Save
        </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddCampaignModalOpen: state.campaign.isAddCampaignModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewCampaignModal: () => dispatch(closeNewCampaignModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCampaignModal);
