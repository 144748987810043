import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const exportReport = (data) => {
    return AxiosAuth.post(
      Config.API + "/api/reports/exportReport",
      JSON.stringify(data),
      undefined
    );
  };

  export default {
    exportReport
  };