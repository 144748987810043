import { Types } from "./../constants/primary-contacts";
import primaryContactApi from "../api/primary-contacts-api";

export const openPrimaryContactsModal = () => async (dispatch) => {
    console.log("primary-contact");
    dispatch({
      type: Types.OPEN_PRIMARY_CONTACTS_MODAL,
      payload: { isModalOpen: true}
    });
  };
  
export const closePrimaryContactsModal = () => async (dispatch) => {
    dispatch({
      type: Types.OPEN_PRIMARY_CONTACTS_MODAL,
      payload: { isModalOpen: false},
    });
  };

  export const addPrimaryContact = (userId, data) => async (dispatch) => {
    try {
      await primaryContactApi.addPrimaryContact(userId, data).then(
        (data) => {
          if (data && data.message && data.message === "Primary Contact updated successfully!") {
            dispatch({
              type: Types.ADD_PRIMARY_CONTACT,
              payload: { success: true, message: data.message },
            });
          }
        },
        (error) => {
          console.log(error.response);
          if (error.response && error.response.data && error.response.data.message) {
            dispatch({
              type: Types.ADD_PRIMARY_CONTACT,
              payload: { success: false, message: error.response.data.message },
            });
          }
        }
      );
    } catch (err) {}
  };

  export const clearPrimaryContactStates = () => async (dispatch) => {
    dispatch({
      type: Types.CLEAR_PRIMARY_CONTACTS_STATES,
      payload: undefined,
    });
  };