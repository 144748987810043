import { Types } from "./../constants/site-buy-types";
import siteBuyApi from "../api/site-buy-api";

// Load all display site buys
export const loadDisplaySiteBuys = (publisherId) => async (dispatch) => {
  try {
    await siteBuyApi.loadAllDisplaySiteBuys(publisherId).then(
      (data) => {
        dispatch(setDisplaySiteBuyList(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// Load all video site buys
export const loadVideoSiteBuys = (publisherId) => async (dispatch) => {
  try {
    await siteBuyApi.loadAllVideoSiteBuys(publisherId).then(
      (data) => {
        dispatch(setVideoSiteBuyList(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

export const allDisplaySiteBuys = () => async (dispatch) => {
  try {
    await siteBuyApi.allDisplaySiteBuys().then(
      (data) => {
        dispatch({
          type: Types.GET_DISPLAY_SITE_BUY_LIST,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

//  all video Site buys
export const allVideoSiteBuys = () => async (dispatch) => {
  try {
    await siteBuyApi.allVideoSiteBuys().then(
      (data) => {
        dispatch({
          type: Types.GET_VIDEO_SITE_BUY_LIST,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To open add new Site Buy Modal
export const openNewSiteBuyModal = (siteBuyType) => async (dispatch) => {
  console.log(siteBuyType);
  dispatch({
    type: Types.OPEN_ADD_SITE_BUY_MODAL,
    payload: { isAddSiteBuyModalOpen: true, siteBuyType: siteBuyType },
  });
};

// To close add new Site Buy Modal
export const closeNewSiteBuyModal = (siteBuyType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SITE_BUY_MODAL,
    payload: { isAddSiteBuyModalOpen: false, siteBuyType: siteBuyType },
  });
};

const setVideoSiteBuyList = (siteBuys) => {
  return {
    type: Types.GET_VIDEO_SITE_BUY_LIST,
    payload: siteBuys,
  };
};

const setDisplaySiteBuyList = (siteBuys) => {
  return {
    type: Types.GET_DISPLAY_SITE_BUY_LIST,
    payload: siteBuys,
  };
};

export const addDisplaySiteBuy = (sitBuyData, publisherId) => async (dispatch) => {
  try {
    await siteBuyApi.addDisplaySiteBuy(sitBuyData, publisherId).then(
      (data) => {
        if (data && data.message && data.message === "DisplaySiteBuy added successfully!") {
          dispatch({
            type: Types.ADD_SITE_BUY,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_SITE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

export const addVideoSiteBuy = (sitBuyData, publisherId) => async (dispatch) => {
  try {
    await siteBuyApi.addVideoSiteBuy(sitBuyData, publisherId).then(
      (data) => {
        if (data && data.message && data.message === "VideoSiteBuy added successfully!") {
          dispatch({
            type: Types.ADD_SITE_BUY,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_SITE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

export const loadDisplaySiteBuyById = (id) => async (dispatch) => {
  try {
    await siteBuyApi.loadDisplaySiteBuyById(id).then(
      (data) => {
        dispatch({type:Types.GET_DISPLAY_SITE_BUY,payload:data});
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

export const loadVideoSiteBuyById = (id) => async (dispatch) => {
  try {
    await siteBuyApi.loadVideoSiteBuyById(id).then(
      (data) => {
        dispatch({type:Types.GET_VIDEO_SITE_BUY,payload:data});
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

export const openEditSiteBuyModal = (siteBuyType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_SITE_BUY_MODAL,
    payload: { isEditSiteBuyModalOpen: true, siteBuyType: siteBuyType },
  });
};

// To close add new Site Buy Modal
export const closeEditSiteBuyModal = (siteBuyType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_SITE_BUY_MODAL,
    payload: { isEditSiteBuyModalOpen: false, siteBuyType: siteBuyType },
  });
};

export const editDisplaySiteBuy = (sitBuyData, siteBuyId) => async (dispatch) => {
  try {
    await siteBuyApi.editDisplaySiteBuy(sitBuyData, siteBuyId).then(
      (data) => {
        if (data && data.message && data.message === "DisplaySiteBuy updated successfully!") {
          dispatch({
            type: Types.EDIT_SITE_BUY,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.EDIT_SITE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

export const editVideoSiteBuy = (sitBuyData, siteBuyId) => async (dispatch) => {
  try {
    await siteBuyApi.editVideoSiteBuy(sitBuyData, siteBuyId).then(
      (data) => {
        if (data && data.message && data.message === "VideoSiteBuy updated successfully!") {
          dispatch({
            type: Types.EDIT_SITE_BUY,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.EDIT_SITE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// load display site buy report data
export const loadDisplaySiteBuyReport = (data) => async (dispatch) => {
  try {
    await siteBuyApi.loadDisplaySiteBuyReport(data).then(
      (data) => {
        dispatch({
          type: Types.SITE_BUY_REPORT,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};


// load video site buy report data
export const loadVideoSiteBuyReport = (data) => async (dispatch) => {
  try {
    await siteBuyApi.loadVideoSiteBuyReport(data).then(
      (data) => {
        dispatch({
          type: Types.SITE_BUY_REPORT,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load site buy overview data
export const loadSiteBuyOverviewData = (siteBuyId, siteBuyType) => async (dispatch) => {
  try {
    await siteBuyApi.loadSiteBuyOverviewData(siteBuyId, siteBuyType).then(
      (data) => {
        dispatch({
          type: Types.SITE_BUY_OVERVIEW_DATA,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To clear states from redux store
export const clearSiteBuyStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_SITE_BUY_STATES,
    payload: undefined,
  });
};



// export display site buy report data
export const exportDisplaySiteBuyReport = (data) => async (dispatch) => {
  try {
    return await siteBuyApi.exportDisplaySiteBuyReport(data);
  } catch (err) { }
};



// export video site buy report data
export const exportVideoSiteBuyReport = (data) => async (dispatch) => {
  try {
    return await siteBuyApi.exportVideoSiteBuyReport(data);
  } catch (err) { }
};
