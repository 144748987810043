import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeBlacklistWhitelistPrivateBuyModal, updateCampaign } from "./../../../redux/actions/campaign-actions";
import { useParams } from "react-router-dom";

const CampaignBlacklistPrivateBuyModal = ({
    displayPrivateBuyList,
    videoPrivateBuyList,
    selectedPrivateBuyList,
    updateType,
    privateBuyType,
    isBlacklistWhitelistPrivateBuyModalOpen,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const { id } = useParams();
    const [privateBuyList, setPrivateBuyList] = useState([]);
    
    useEffect(() => {
        if (privateBuyType && privateBuyType === "Display" && displayPrivateBuyList && displayPrivateBuyList.length > 0) {
            setPrivateBuyList(displayPrivateBuyList);
        } else if (privateBuyType && videoPrivateBuyList && videoPrivateBuyList.length > 0) {
            setPrivateBuyList(videoPrivateBuyList);
        }

        const selectValuesTemp = [];
        if (selectedPrivateBuyList && selectedPrivateBuyList.length > 0) {
            selectedPrivateBuyList.filter(function (obj) {
                if (privateBuyType === "Display") {
                    selectValuesTemp.push(obj.displayPrivateBuyId);
                } else {
                    selectValuesTemp.push(obj.videoPrivateBuyId);
                }

            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("CampaignBlacklistPrivateBuyModal Unmounting");
        };
    }, [selectedPrivateBuyList, isBlacklistWhitelistPrivateBuyModalOpen, privateBuyList]);

    const handleSave = (values) => {
        let campaignData = {};

        campaignData["isPublishersUpdated"] = false;
        campaignData["isDisplaySiteBuysUpdated"] = false;
            campaignData["isVideoSiteBuysUpdated"] = false;
        if (privateBuyType === "Display") {
            campaignData["isDisplayPrivateBuysUpdated"] = true;
            campaignData["isVideoPrivateBuysUpdated"] = false;
            if (updateType === "Blacklist") {
                campaignData["blacklistDisplayPrivateBuyIds"] = selectedValues
            } else {
                campaignData["whitelistDisplayPrivateBuyIds"] = selectedValues
            }
        } else {
            campaignData["isDisplayPrivateBuysUpdated"] = false;
            campaignData["isVideoPrivateBuysUpdated"] = true;
            if (updateType === "Blacklist") {
                campaignData["blacklistVideoPrivateBuyIds"] = selectedValues
            } else {
                campaignData["whitelistVideoPrivateBuyIds"] = selectedValues
            }
        }

        console.log(campaignData);
        dispatch(updateCampaign(id, campaignData));
        setPrivateBuyList([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeBlacklistWhitelistPrivateBuyModal());
    };

    const handleCancel = () => {
        setPrivateBuyList([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeBlacklistWhitelistPrivateBuyModal());
    };

    const onChange = (e) => {
        const selectValuesTemp = selectedValues;
        if (selectValuesTemp.indexOf(e.target.value) > -1) {
            selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
        } else {
            selectValuesTemp.push(e.target.value);
        }
        setSelectedValues(selectValuesTemp);
        setIsSet(!isSet);
    };

    const isPrivateBuyExists = (privateBuyId) => {
        var filtered = selectedValues.filter(function (obj) {
            return privateBuyId === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    function _bindPrivateBuy(privateBuys) {
        return privateBuys.map((privateBuy, index) => {
            return (
                <Row>
                    <Col span={24}>
                        {privateBuyType === "Display" ?
                            <Checkbox
                                checked={isPrivateBuyExists(privateBuy.displayPrivateBuyId)}
                                onChange={onChange}
                                value={privateBuy.displayPrivateBuyId}
                            >
                                {privateBuy.name}
                            </Checkbox>
                            : <Checkbox
                                checked={isPrivateBuyExists(privateBuy.videoPrivateBuyId)}
                                onChange={onChange}
                                value={privateBuy.videoPrivateBuyId}
                            >
                                {privateBuy.name}
                            </Checkbox>
                        }
                    </Col>
                </Row>
            );
        });
    }

    return (
        <>
            <Modal
                title={privateBuyType + " Private Buy List"}
                visible={isBlacklistWhitelistPrivateBuyModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    initialValues={{
                        accountIds: selectedValues,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item name="privateBuyIds" label="Select Private Buy">
                        {privateBuyList && _bindPrivateBuy(privateBuyList)}
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
            </Button>
                        <Button type="primary" htmlType="submit">
                            Save
            </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
function mapStateToProps(state) {
    return {
        isBlacklistWhitelistPrivateBuyModalOpen: state.campaign.isBlacklistWhitelistPrivateBuyModalOpen,
        updateType: state.campaign.updateType,
        selectedPrivateBuyList: state.campaign.selectedPrivateBuyList,
        privateBuyType: state.campaign.privateBuyType,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeBlacklistWhitelistPrivateBuyModal: () => dispatch(closeBlacklistWhitelistPrivateBuyModal()),
    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CampaignBlacklistPrivateBuyModal
);
