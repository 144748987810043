import { Types } from "./../constants/line-types";
import lineApi from "../api/line-api";

// Add new display line
export const addDisplayLine = (campaignId, data) => async (dispatch) => {
  try {
    await lineApi.addDisplayLine(campaignId, data).then(
      (data) => {
        if (data && data.message && data.message === "DisplayLine added successfully!") {
          dispatch({
            type: Types.ADD_DISPLAY_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_DISPLAY_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// Add new video line
export const addVideoLine = (campaignId, data) => async (dispatch) => {
  try {
    await lineApi.addVideoLine(campaignId, data).then(
      (data) => {
        if (data && data.message && data.message === "VideoLine added successfully!") {
          dispatch({
            type: Types.ADD_VIDEO_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_VIDEO_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// Update display line
export const updateDisplayLine = (displayLineId, data) => async (dispatch) => {
  try {
    await lineApi.updateDisplayLine(displayLineId, data).then(
      (data) => {
        if (data && data.message && data.message === "DisplayLine updated successfully!") {
          dispatch({
            type: Types.UPDATE_DISPLAY_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_DISPLAY_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// Update video line
export const updateVideoLine = (videoLineId, data) => async (dispatch) => {
  try {
    await lineApi.updateVideoLine(videoLineId, data).then(
      (data) => {
        if (data && data.message && data.message === "VideoLine updated successfully!") {
          dispatch({
            type: Types.UPDATE_VIDEO_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_VIDEO_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// load display lines by campaign id
export const loadDisplayLinesByCampaignId = (campaignId) => async (dispatch) => {
  try {
    await lineApi.loadDisplayLinesByCampaignId(campaignId).then(
      (data) => {
        dispatch({
          type: Types.GET_DISPLAY_LINE_LIST,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load video lines by campaign id
export const loadVideoLinesByCampaignId = (campaignId) => async (dispatch) => {
  try {
    await lineApi.loadVideoLinesByCampaignId(campaignId).then(
      (data) => {
        dispatch({
          type: Types.GET_VIDEO_LINE_LIST,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To open add new Line Modal
export const openNewLineModal = (lineType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_LINE_MODAL,
    payload: { isAddLineModalOpen: true, lineType: lineType },
  });
};

// To close add new Line Modal
export const closeNewLineModal = (lineType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_LINE_MODAL,
    payload: { isAddLineModalOpen: false, lineType: lineType },
  });
};

// To open update delivery Modal
export const openUpdateDeliveryModal = (lineType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DELIVERY_MODAL,
    payload: { isUpdateDeliveryModalOpen: true, lineType: lineType },
  });
};

// To close update delivery Modal
export const closeUpdateDeliveryModal = (lineType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DELIVERY_MODAL,
    payload: { isUpdateDeliveryModalOpen: false, lineType: lineType },
  });
};

// Load all devices
export const loadAllDevices = () => async (dispatch) => {
  try {
    await lineApi.loadAllDevices().then(
      (data) => {
        dispatch({
          type: Types.LOAD_DEVICES,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all geoNames
export const loadAllGeoNames = () => async (dispatch) => {
  try {
    await lineApi.loadAllGeoNames().then(
      (data) => {
        dispatch({
          type: Types.LOAD_GEONAMES,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all dmaRegions
export const loadAllDmaRegions = () => async (dispatch) => {
  try {
    await lineApi.loadAllDmaRegions().then(
      (data) => {
        dispatch({
          type: Types.LOAD_DMA_REGIONS,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all ExelateSegments
export const loadAllExelateSegments = () => async (dispatch) => {
  try {
    await lineApi.loadAllExelateSegments().then(
      (data) => {
        dispatch({
          type: Types.LOAD_EXELATE_SEGMENTS,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};
// load display line display line id
export const loadDisplayLineById = (displayLineId) => async (dispatch) => {
  try {
    await lineApi.loadDisplayLineById(displayLineId).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_DISPLAY_LINE,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load display line history by id
export const loadDisplayLineHistoryById = (displayLineId) => async (dispatch) => {
  try {
    await lineApi.loadDisplayLineHistoryById(displayLineId).then(
      (data) => {
        dispatch({
          type: Types.LOAD_HISTORY,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

export const loadVideoLineHistoryById = (videoLineId) => async (dispatch) => {
  try {
    await lineApi.loadVideoLineHistoryById(videoLineId).then(
      (data) => {
        dispatch({
          type: Types.LOAD_HISTORY,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};
// load video line by video line id
export const loadVideoLineById = (videoLineId) => async (dispatch) => {
  try {
    await lineApi.loadVideoLineById(videoLineId).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_VIDEO_LINE,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load display line report
export const loadDisplayLineReport = (data) => async (dispatch) => {
  try {
    await lineApi.loadDisplayLineReport(data).then(
      (data) => {
        dispatch({
          type: Types.LOAD_REPORT,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// export display line report
export const exportDisplayLineReport = (data) => async (dispatch) => {
  try {
    return await lineApi.exportDisplayLineReport(data);
    
  } catch (err) { }
};

// load video line report
export const loadVideoLineReport = (data) => async (dispatch) => {
  try {
    await lineApi.loadVideoLineReport(data).then(
      (data) => {
        dispatch({
          type: Types.LOAD_REPORT,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// export video line report
export const exportVideoLineReport = (data) => async (dispatch) => {
  try {
    return await lineApi.exportVideoLineReport(data);
    
  } catch (err) { }
};

// To open update devices Modal
export const openUpdateDeviceModal = (selectedDeviceList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DEVICE_MODAL,
    payload: true,
    selectedDeviceList: selectedDeviceList,
  });
};

// To close update device Modal
export const closeUpdateDeviceModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DEVICE_MODAL,
    payload: false,
    selectedDeviceList: undefined,
  });
};

// To open update creatve size Modal
export const openUpdateCreativeSizeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_CREATIVE_SIZE_MODAL,
    payload: true,
  });
};

// To close update creatve size Modal
export const closeUpdateCreativeSizeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_CREATIVE_SIZE_MODAL,
    payload: false,
  });
};

// To open update siteList Modal
export const openUpdateSiteListModal = (selectedSiteList, siteLists) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_SITE_LIST_MODAL,
    payload: true,
    selectedSiteList: selectedSiteList,
    siteLists: siteLists,
  });
};

// To close update siteList Modal
export const closeUpdateSiteListModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_SITE_LIST_MODAL,
    payload: false,
    selectedSiteList: undefined,
    siteLists: undefined,
  });
};

// To open update geonames Modal
export const openUpdateGeoNameModal = (selectedGeoNameList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_GEONAME_MODAL,
    payload: true,
    selectedGeoNameList: selectedGeoNameList,
  });
};

// To close update geonames Modal
export const closeUpdateGeoNameModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_GEONAME_MODAL,
    payload: false,
    selectedGeoNameList: undefined,
  });
};

// To open update dma regions Modal
export const openUpdateDmaRegionModal = (selectedDmaRegionList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DMA_REGION_MODAL,
    payload: true,
    selectedDmaRegionList: selectedDmaRegionList,
  });
};

// To close update dma regions Modal
export const closeUpdateDmaRegionModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_DMA_REGION_MODAL,
    payload: false,
    selectedDmaRegionList: undefined,
  });
};

// To open update exelate segments  Modal
export const openUpdateExelateSegmentsModal = (selectedExelateSegmentsList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_EXELATE_SEGMENTS_MODAL,
    payload: true,
    selectedExelateSegmentsList: selectedExelateSegmentsList,
  });
};

// To close update Excelate Segments Modal
export const closeUpdateExcelateSegmentsModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_EXELATE_SEGMENTS_MODAL,
    payload: false,
    selectedExelateSegmentsList: undefined,
  });
};

// clear display line redux store
export const clearEditDisplayLineStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_DISPLAY_LINE_STATES,
    payload: undefined
  });
};

// clear video line redux store
export const clearEditVideoLineStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_VIDEO_LINE_STATES,
    payload: undefined
  });
};

export const loadDisplayLineOverviewData = (displayLineId) => async (dispatch) => {
  try {
    await lineApi.loadDisplayLineOverviewData(displayLineId).then(
      (data) => {
        dispatch({
          type: Types.LOAD_OVERVIEW_DISPLAY_LINE,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

export const loadVideoLineOverviewData = (videoLineId) => async (dispatch) => {
  try {
    await lineApi.loadVideoLineOverviewData(videoLineId).then(
      (data) => {
        dispatch({
          type: Types.LOAD_OVERVIEW_VIDEO_LINE,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To open update click-url Modal
export const openUpdateClickUrlModal = (creatives) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_CLICK_URL_MODAL,
    payload: true,
    creatives: creatives,
  });
};

// To close update click-url Modal
export const closeUpdateClickUrlModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_UPDATE_CLICK_URL_MODAL,
    payload: false,
    creatives: undefined,
  });
};

// Update display click url
export const updateDisplayClickUrl = (data) => async (dispatch) => {
  try {
    await lineApi.updateDisplayClickUrl(data).then(
      (data) => {
        if (data && data.message && data.message === "Click url updated successfully!") {
          dispatch({
            type: Types.UPDATE_DISPLAY_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_DISPLAY_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// Update video click url
export const updateVideoClickUrl = (data) => async (dispatch) => {
  try {
    await lineApi.updateVideoClickUrl(data).then(
      (data) => {
        if (data && data.message && data.message === "Click url updated successfully!") {
          dispatch({
            type: Types.UPDATE_VIDEO_LINE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_VIDEO_LINE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};
