import { Types } from "../constants/creative-types";
const initialState = {
  isAddCreativeModalOpen: false,
  isAddVideoCreativeModalOpen: false,
  allImageResources: undefined,
  imageCreativeAdded: undefined,
  videoResourceAdded: undefined,
  allVideoResources: undefined,
  videoCreativeAdded: undefined,
  editImageCreative: undefined,
  isEditCreativeModalOpen: false,
  isEditTagDisplayCreativeModalOpen: false,
  propType: undefined,
  truengageCreativeAdded: undefined,
  displayCreativeUpdated: undefined,
  tagDisplayCreativeAdded: undefined,
  editTagDisplayCreative: undefined,
  editVideoCreative: undefined,
  editTruengageCreative: undefined,
  videoCreativeUpdated: undefined,
  truengageCreativeUpdated: undefined,
  isEditVideoCreativeModalOpen: false,
  isEditTruengageCreativeModalOpen: false,
  editTagVideoCreative: undefined,
  tagVideoCreativeUpdated: undefined,
  isEditTagVideoCreativeModalOpen: false,
  tagVideoCreativeAdded: undefined,
  imageResourceAdded: undefined,
};
export default function creativeReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ADD_CREATIVE_MODAL:
      return {
        ...state,
        isAddCreativeModalOpen: action.payload,
      };
    case Types.UPLOAD_IMAGE_RESOURCE:
      return {
        ...state,
        imageResourceAdded: action.payload,
      };
    case Types.GET_ALL_IMAGE_RESOURCES_BY_ACCOUNT_ID:
      return {
        ...state,
        allImageResources: action.payload,
      };
    case Types.ADD_IMAGE_CREATIVE:
      return {
        ...state,
        imageCreativeAdded: action.payload,
      };
    case Types.OPEN_ADD_VIDEO_CREATIVE_MODAL:
      return {
        ...state,
        isAddVideoCreativeModalOpen: action.payload,
      };
    case Types.GET_ALL_VIDEO_RESOURCES_BY_ACCOUNT_ID:
      return {
        ...state,
        allVideoResources: action.payload,
      };
    case Types.UPLOAD_VIDEO_RESOURCE:
      return {
        ...state,
        videoResourceAdded: action.payload,
      };
    case Types.ADD_VIDEO_CREATIVE:
      return {
        ...state,
        videoCreativeAdded: action.payload,
      };
    case Types.GET_EDIT_IMAGE_CREATIVE:
      return {
        ...state,
        editImageCreative: action.payload,
      };
    case Types.OPEN_EDIT_CREATIVE_MODAL:
      return {
        ...state,
        isEditCreativeModalOpen: action.payload.isEditCreativeModalOpen,
        propType: action.payload.propType,
      };
    case Types.ADD_TRUENGAGE_CREATIVE:
      return {
        ...state,
        truengageCreativeAdded: action.payload,
      };
    case Types.UPDATE_DISPLAY_CREATIVE:
      return {
        ...state,
        displayCreativeUpdated: action.payload,
      };
    case Types.ADD_TAG_DISPLAY_CREATIVE:
      return {
        ...state,
        tagDisplayCreativeAdded: action.payload,
      };
    case Types.ADD_TAG_VIDEO_CREATIVE:
      return {
        ...state,
        tagVideoCreativeAdded: action.payload,
      };
    case Types.GET_EDIT_TAG_DISPLAY_CREATIVE:
      return {
        ...state,
        editTagDisplayCreative: action.payload,
      };
    case Types.OPEN_EDIT_TAG_DISPLAY_CREATIVE_MODAL:
      return {
        ...state,
        isEditTagDisplayCreativeModalOpen: action.payload.isEditTagDisplayCreativeModalOpen,
        propType: action.payload.propType,
      };
    case Types.UPDATE_TAG_DISPLAY_CREATIVE:
      return {
        ...state,
        tagDisplayCreativeUpdated: action.payload,
      };
    case Types.GET_EDIT_VIDEO_CREATIVE:
      return {
        ...state,
        editVideoCreative: action.payload,
      };
    case Types.GET_EDIT_TRUENGAGE_CREATIVE:
      return {
        ...state,
        editTruengageCreative: action.payload,
      };
    case Types.UPDATE_VIDEO_CREATIVE:
      return {
        ...state,
        videoCreativeUpdated: action.payload,
      };
    case Types.UPDATE_TRUENGAGE_CREATIVE:
      return {
        ...state,
        truengageCreativeUpdated: action.payload,
      };
    case Types.OPEN_EDIT_VIDEO_CREATIVE_MODAL:
      return {
        ...state,
        isEditVideoCreativeModalOpen: action.payload.isEditVideoCreativeModalOpen,
        propType: action.payload.propType,
      };
    case Types.OPEN_EDIT_TRUENGAGE_CREATIVE_MODAL:
      return {
        ...state,
        isEditTruengageCreativeModalOpen: action.payload.isEditTruengageCreativeModalOpen,
        propType: action.payload.propType,
      };
    case Types.GET_EDIT_TAG_VIDEO_CREATIVE:
      return {
        ...state,
        editTagVideoCreative: action.payload,
      };
    case Types.UPDATE_TAG_VIDEO_CREATIVE:
      return {
        ...state,
        tagVideoCreativeUpdated: action.payload,
      };
    case Types.OPEN_EDIT_TAG_VIDEO_CREATIVE_MODAL:
      return {
        ...state,
        isEditTagVideoCreativeModalOpen: action.payload.isEditTagVideoCreativeModalOpen,
        propType: action.payload.propType,
      };
    case Types.CLEAR_EDIT_CREATIVE_STATES:
      return {
        ...state,
        allImageResources: undefined,
        imageCreativeAdded: undefined,
        videoResourceAdded: undefined,
        allVideoResources: undefined,
        videoCreativeAdded: undefined,
        editImageCreative: undefined,
        propType: undefined,
        truengageCreativeAdded: undefined,
        displayCreativeUpdated: undefined,
        tagDisplayCreativeAdded: undefined,
        editTagDisplayCreative: undefined,
        editVideoCreative: undefined,
        editTruengageCreative: undefined,
        videoCreativeUpdated: undefined,
        truengageCreativeUpdated: undefined,
        editTagVideoCreative: undefined,
        tagVideoCreativeUpdated: undefined,
        tagVideoCreativeAdded: undefined,
        imageResourceAdded: undefined,
      };
    default:
      return state;
  }
}
