import AxiosAuth from "../../services/axios-service";
import Config from "../../config";


const login = (data) => {
  return AxiosAuth.post(Config.API +"/api/auth/signin", data, undefined);
};

export default {
  login,
};
