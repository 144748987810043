import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadVideoSiteBuyById, clearSiteBuyStates, loadVideoSiteBuyReport, loadSiteBuyOverviewData } from "../../redux/actions/site-buy-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Breadcrumb, PageHeader, Tabs, Spin } from "antd";
import { compose } from "redux";
import SiteBuyDetails from "./site-buy-details";
import SiteBuyOverview from "./site-buy-overview";
import SiteBuyTags from "./video-site-buy-tags";
import SiteBuyReporting from "./video-site-buy-reporting";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";
import { getDayStartDateTime, getDayEndDateTime, getUTCDate } from "../../utils/appUtils";

const { TabPane } = Tabs;

const SiteBuy = ({ videoSiteBuy, siteBuyUpdated, siteBuyReport, siteBuyOverviewData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  useEffect(() => {
    let isMounted = true;

    let data = {};
    // data["startDate"] = getDayStartDateTime(new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000)));
    // data["endDate"] = getDayEndDateTime(new Date());
    data["startDate"] = new Date(getUTCDate(getDayStartDateTime(new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000))))).toUTCString();
    data["endDate"] = new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    data["report"] = "Date - Delivery";
    data["id"] = id;
    data["timezone"] = "America/Los_Angeles";
    (async () => {
      setSubmitted(true);
      await dispatch(loadVideoSiteBuyById(id));
      await dispatch(loadVideoSiteBuyReport(data));
      await dispatch(loadSiteBuyOverviewData(id, "VIDEO"));
      if(isMounted ){

      setSubmitted(false);
  }})();

    return () => {
      isMounted = false;

      console.log("EditSite Buy Unmounting");
      dispatch(clearSiteBuyStates());
    };
  }, [siteBuyUpdated]);

  return (
    <>
      <section className="white-box-wrapper">
        <Spin size={"large"} spinning={submitted}></Spin>
        {videoSiteBuy ? <><Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/publishers/${videoSiteBuy.publisher.publisherId}`}>Publisher</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/siteBuys/display-site-buy/${videoSiteBuy.videoSiteBuyId}`}>Site Buy</Link>
          </Breadcrumb.Item>
        </Breadcrumb></> : ""}
        <PageHeader className="site-page-header" title={videoSiteBuy && ` Video -  ${videoSiteBuy.name}`} />

        <Tabs defaultActiveKey="1" >
          <TabPane tab="Overview" key="overview">
            <SiteBuyOverview siteBuyType="video" siteBuyOverviewData={siteBuyOverviewData} />
          </TabPane>
          {currentUserPermissionMap[currentUserRole]["canAccessSiteBuyDetail"] ? (
            <TabPane tab="Details" key="details">
              <SiteBuyDetails siteBuy={videoSiteBuy} siteBuyType="video" />
              <SiteBuyTags siteBuy={videoSiteBuy} siteBuyType="video" />
            </TabPane>
          ) : (
              ""
            )}
          <TabPane tab="Reporting" key="reporting">
            <SiteBuyReporting siteBuyReport={siteBuyReport} />
          </TabPane>
        </Tabs>


      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    videoSiteBuy: state.siteBuy.videoSiteBuy,
    siteBuyUpdated: state.siteBuy.siteBuyUpdated,
    siteBuyReport: state.siteBuy.siteBuyReport,
    siteBuyOverviewData: state.siteBuy.siteBuyOverviewData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadVideoSiteBuyById: () => dispatch(loadVideoSiteBuyById())
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteBuy);
