import { Types } from "../constants/segment-types";
import pk4SegmentsApi from "../api/segment-api";

export const loadAllSegments = () => async (dispatch) => {
  try {
    await pk4SegmentsApi.loadAllSegments().then(
      (data) => {
        dispatch({
          type: Types.GET_SEGMENT_LIST,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// Add new Segment
export const addSegment = (name) => async (dispatch) => {
  try {
    
    await pk4SegmentsApi.addSegment(name).then(
      (data) => {
        if (data && data.message && data.message === "Segment added successfully!")
          dispatch({
            type: Types.ADD_SEGMENT,
            payload: {
              segmentAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_SEGMENT,
            payload: {
              segmentAdded: { success: false, message: error.response.data.message },
              errorMessage: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};

export const updateSegment = (segmentId,values) => async (dispatch) => {
  try {
    await pk4SegmentsApi.updateSegment(segmentId,values).then(
      (data) => {
        if(data && data.message && data.message === "Segment updated successfully!") {
          dispatch({
            type: Types.UPDATE_SEGMENT,
            payload: {
              success: true, message: data.message}
            ,
          });
        }        
      },
      (error) => {
        console.log(error.response);
        if(error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_SEGMENT,
            payload: {
              success: false, message: error.response.data.message}
            ,
          });
        }        
      }
    );
  } catch (err) {}
};

export const loadSegmentById = (id) => async (dispatch) => {
  try {
    await pk4SegmentsApi.loadSegmentById(id).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_SEGMENT,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// To open add new Modal
export const openNewSegmentModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SEGMENT_MODAL,
    payload: true,
  });
};

// To close add new Modal
export const closeNewSegmentModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SEGMENT_MODAL,
    payload: false,
  });
};

// To clear states from redux store
export const clearEditSegmentStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_EDIT_SEGMENT_STATES,
    payload: undefined,
  });
};
