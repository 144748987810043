import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Progress, Row, Col, Card, Statistic } from "antd";
import { compose } from "redux";
import Chart from "react-apexcharts";

const gridStyle = {
  width: "25%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const LineOverview = ({ lineOverviewData, lineType, line }) => {
  const [uniqueEvents, setUniqueEvents] = useState([]);
  const [uniqueEventLabels, setUniqueEventLabels] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [labels, setLabels] = useState([]);
  const [devices, setDevices] = useState([]);
  const [labelDevices, setLabelDevices] = useState([]);

  useEffect(() => {
    if (lineOverviewData) {
      setUniqueEvents(
        Object.keys(lineOverviewData.uniqueEvents).map(
          (item) => lineOverviewData.uniqueEvents[item]
        )
      );
      setUniqueEventLabels(
        Object.keys(lineOverviewData.uniqueEvents).map((item) => item)
      );
      setCreatives(
        Object.keys(lineOverviewData.creatives).map(
          (item) => lineOverviewData.creatives[item]
        )
      );
      setLabels(Object.keys(lineOverviewData.creatives).map((item) => item));
      setDevices(
        Object.keys(lineOverviewData.devices).map(
          (item) => lineOverviewData.devices[item]
        )
      );
      setLabelDevices(
        Object.keys(lineOverviewData.devices).map((item) => item)
      );
    }
  }, [lineOverviewData]);

  const series = [
    {
      name: lineOverviewData && lineOverviewData.uniqueEventType,
      data: uniqueEvents,
    },
  ];
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: uniqueEventLabels,
    },
  };

  const pieSeries = creatives;
  const pieOptions = {
    chart: {
      width: 650,
      type: "pie",
    },
    title: {
      text: "Creatives - Month to Date",
    },
    labels: labels,
  };

  const pieSeriesDevices = devices;
  const pieOptionsDevices = {
    chart: {
      width: 650,
      type: "pie",
    },
    title: {
      text: "Devices - Month to Date",
    },
    labels: labelDevices,
  };

  const renderDailyCapData = (data, rateType) => {
    debugger
    if (rateType && rateType === "CPM") {
      return (
        <>
          <Row gutter={16}>
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {(data.dailyCapUsed && data.dailyCapUsed !== null
                ? data.dailyCapUsed
                : 0) +
                " / " +
                (data.dailyCapLimit && data.dailyCapLimit !== null
                  ? data.dailyCapLimit
                  : 0)}
              {" impressions"}
            </Col>
          </Row>
          <Progress
            percent={data.dailyCapLimitElapsed}
            strokeWidth="20px"
            showInfo={true}
            status="active"
          />
        </>
      );
    } else if (rateType && rateType === "CPC") {
      return (
        <>
          <Row gutter={16}>
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {(data.dailyCapUsed && data.dailyCapUsed !== null
                ? data.dailyCapUsed
                : 0) +
                " / " +
                (data.dailyCapLimit && data.dailyCapLimit !== null
                  ? data.dailyCapLimit
                  : 0)}
              {" clicks"}
            </Col>
          </Row>
          <Progress
            percent={data.dailyCapLimitElapsed}
            strokeWidth="20px"
            showInfo={true}
            status="active"
          />
        </>
      );
    } else if (rateType && rateType === "CPA") {
      return (
        <>
          <Row gutter={16} className="detail_over">
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {(data.dailyCapUsed && data.dailyCapUsed !== null
                ? data.dailyCapUsed
                : 0) +
                " / " +
                (data.dailyCapLimit && data.dailyCapLimit !== null
                  ? data.dailyCapLimit
                  : 0)}
              {" conversions"}
            </Col>
          </Row>
          <Progress
            percent={data.dailyCapLimitElapsed}
            strokeWidth="20px"
            showInfo={true}
            status="active"
          />
        </>
      );
    }
  };

  const _renderDeliveries = (data, rateType) => {
    console.log("rate type : " + rateType)
    if (rateType && rateType === "CPM") {
      return (
        <>
          <Row gutter={16}>
            <Col xl={8}>
              {"Delivered – " +
                (data.deliverEventPercentage &&
                data.deliverEventPercentage !== null &&
                data.deliverEventPercentage > 0
                  ? data.deliverEventPercentage.toFixed(2)
                  : 0) +
                "%"}
            </Col>
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {data.elapsedDeliveries + " / " + data.totalDeliveries}
              {" impressions "}
            </Col>
          </Row>
        </>
      );
    } else if (rateType && rateType === "CPC") {
      return (
        <>
          <Row gutter={16}>
            <Col xl={8}>
              {"Delivered – " +
                (data.deliverEventPercentage &&
                data.deliverEventPercentage !== null &&
                data.deliverEventPercentage > 0
                  ? data.deliverEventPercentage.toFixed(2)
                  : 0) +
                "%"}
            </Col>
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {data.elapsedDeliveries + " / " + data.totalDeliveries}
              {" clicks"}
            </Col>
          </Row>
        </>
      );
    } else if (rateType && rateType === "CPA") {
      return (
        <>
          <Row gutter={16}>
            <Col xl={8}>
              {"Delivered – " +
                (data.deliverEventPercentage &&
                data.deliverEventPercentage !== null &&
                data.deliverEventPercentage > 0
                  ? data.deliverEventPercentage.toFixed(2)
                  : 0) +
                "%"}
            </Col>
            <Col xl={8}></Col>{" "}
            <Col xl={8}>
              {" "}
              {data.elapsedDeliveries + " / " + data.totalDeliveries}
              {" conversions"}
            </Col>
          </Row>
        </>
      );
    }
  };
console.log("line : ", line)
console.log("lineOverviewData : ", lineOverviewData)
  return (
    <>
      <Row gutter={16}>
        {lineOverviewData ? (
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="white-box ">
              <div className="title">
                <h2>{`${new Date().toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })} Pacing`}</h2>
              </div>
              <div className="card-body inner-space overview-detail">
                <Row gutter={16}>
                  <Col xl={8}>
                    {"Elapsed – " +
                      (lineOverviewData &&
                      lineOverviewData.elapsedDayPercentage &&
                      lineOverviewData.elapsedDayPercentage !== null &&
                      lineOverviewData.elapsedDayPercentage > 0
                        ? lineOverviewData.elapsedDayPercentage.toFixed(2)
                        : 0) +
                      "%"}
                  </Col>
                  <Col xl={8}></Col>{" "}
                  <Col xl={8}>
                    {" "}
                    {lineOverviewData &&
                      lineOverviewData.elapsedDays +
                        " / " +
                        lineOverviewData.totalDays}
                    {" days"}
                  </Col>
                </Row>
                <Progress
                  percent={
                    lineOverviewData && lineOverviewData.elapsedDayPercentage
                  }
                  strokeWidth="20px"
                  showInfo={true}
                  status="active"
                />
                {lineOverviewData &&
                  lineOverviewData != null &&
                  _renderDeliveries(
                    lineOverviewData,
                    lineOverviewData.rateTye
                  )}
                <Progress
                  percent={
                    lineOverviewData && lineOverviewData.deliverEventPercentage
                  }
                  strokeWidth="20px"
                  showInfo={true}
                  status="active"
                />
              </div>
            </div>
            {line &&
            line.displayLineVersion &&
            line.displayLineVersion.deliveryCapType !== "DISABLED" ? (
              <div className="white-box">
                <div className="title">
                  <h2>{"Delivery Cap"}</h2>
                </div>
                <div className="card-body inner-space">
                  {lineOverviewData &&
                    lineOverviewData != null &&
                    renderDailyCapData(
                      lineOverviewData,
                      line.displayLineVersion.rateType
                    )}
                </div>
              </div>
            ) : (
              line &&
            line.videoLineVersion &&
            line.videoLineVersion.deliveryCapType !== "DISABLED" ? (
              <div className="white-box">
                <div className="title">
                  <h2>{"Delivery Cap"}</h2>
                </div>
                <div className="card-body inner-space">
                  {lineOverviewData &&
                    lineOverviewData != null &&
                    renderDailyCapData(
                      lineOverviewData,
                      line.videoLineVersion.rateType
                    )}
                </div>
              </div>
            ) : (
              ""
            )
            )}
            <Row gutter={16}>
              <Col span={lineType && lineType === "display" ? 8 : 6}>
                <Card>
                  <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                    <Statistic
                      title="Impressions"
                      value={
                        lineOverviewData.impressionUnique != null
                          ? lineOverviewData.impressionUnique
                          : 0
                      }
                    />
                  </Card.Grid>
                </Card>
              </Col>
              <Col span={lineType && lineType === "display" ? 8 : 6}>
                <Card>
                  <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                    <Statistic
                      title="CTR"
                      value={
                        lineOverviewData.ctr != null ? lineOverviewData.ctr : 0
                      }
                      precision={1}
                      suffix="%"
                    />
                  </Card.Grid>
                </Card>
              </Col>
              {lineType && lineType === "display" ? (
                <Col span={8}>
                  <Card>
                    <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                      <Statistic
                        title="Clicks"
                        value={
                          lineOverviewData.clickUnique != null
                            ? lineOverviewData.clickUnique
                            : 0
                        }
                      />
                    </Card.Grid>
                  </Card>
                </Col>
              ) : (
                <>
                  <Col span={6}>
                    <Card>
                      <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                        <Statistic
                          title="Completed Views"
                          value={
                            lineOverviewData.completeUnique != null
                              ? lineOverviewData.completeUnique
                              : 0
                          }
                        />
                      </Card.Grid>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                        <Statistic
                          title="Completion Rate"
                          value={
                            lineOverviewData.completionRate != null
                              ? lineOverviewData.completionRate
                              : 0
                          }
                          precision={1}
                          suffix="%"
                        />
                      </Card.Grid>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
            <Card
              title="Last 7 days"
              actions={[
                <h1
                  style={{
                    textAlign: "justify",
                    color: "#6c6f73",
                    fontStyle: "italic",
                    paddingLeft: "4px",
                  }}
                >{`Timezone : ${lineOverviewData.timezone}`}</h1>,
              ]}
            >
              <Chart
                options={options}
                series={series}
                type="line"
                height={350}
              />
            </Card>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                    {creatives && creatives.length > 0 ? (
                      <Chart
                        options={pieOptions}
                        series={pieSeries}
                        type="pie"
                        style={{ padding: 0, width: "300px", margin: "auto" }}
                      />
                    ) : (
                      <Statistic
                        title="Creatives - Month to Date"
                        value={"No Data"}
                      />
                    )}
                  </Card.Grid>
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Card.Grid style={{ ...gridStyle, width: "100%" }}>
                    {devices && devices.length > 0 ? (
                      <Chart
                        options={pieOptionsDevices}
                        series={pieSeriesDevices}
                        type="pie"
                        style={{ padding: 0, width: "300px", margin: "auto" }}
                      />
                    ) : (
                      <Statistic
                        title="Devices - Month to Date"
                        value={"No Data"}
                      />
                    )}
                  </Card.Grid>
                </Card>
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};
function mapStateToProps(state) {
  return {
    lineOverviewData: state.line.lineOverviewData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LineOverview
);
