import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, InputNumber, Space, Select } from "antd";
import { closeNewRetargetingPixelModal, addRetargetingPixel, updateRetargetingPixels } from "./../../../redux/actions/retargeting-pixel-actions";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { retargetingPixelActions } from "./../../../constants/defaultValues";
import { pixelUrl } from "./../../../constants/defaultValues";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";

const { TextArea } = Input;
const { Option } = Select;

const AddRetargetingPixelModal = ({ isAddRetargetingPixelModalOpen, accountId, pixel }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(true);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
  const actionOptions = retargetingPixelActions.map((action) => (
    <Option key={action} value={action}>
      {action}
    </Option>
  ));

  useEffect(() => {
    if (pixel) {
      setIsNew(false);

      var values = {
        retargetingTtl: pixel.retargetingTtl,
        retargetingPixelUrl: '<script src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '" ></script>',
        imageUrl: '<img src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '.gif" width="0" height="0" style="display: hidden;">',
        targetingStates: pixel.targetingStates,
        initialState: pixel.initialState
      }
      form.setFieldsValue(values);
    } else {
      setIsNew(true);
    }
    return () => {
      console.log("AddRetargetingPixelModal Unmounting");
    };
  }, [isAddRetargetingPixelModalOpen, pixel]);

  const handleSave = (values) => {
    if (isNew) {
      values["accountId"] = accountId;
      console.log(values);
      dispatch(addRetargetingPixel(values));

    } else {
      var payload = {
        accountId: accountId,
        name: pixel.name,
        targetingStates: values.targetingStates,
        retargetingTtl: pixel.retargetingTtl,
        weightAdjustment: pixel.weightAdjustment,
      };
      dispatch(updateRetargetingPixels(pixel.retargetingPixelId, payload));
    }
    dispatch(closeNewRetargetingPixelModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewRetargetingPixelModal());
  };

  return (
    <>
      <Modal
        title={isNew ? "New Retargeting Pixel" : "Edit Retargeting Pixel"}
        visible={isAddRetargetingPixelModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={isNew ? { name: "", weightAdjustment: 1, retargetingTtl: 30 } : ""}
          onFinish={handleSave}
        >
          {isNew &&
            <>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input pixel name!",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea showCount maxLength={255} placeholder="Add Pixel Name" autoSize />
              </Form.Item>

              <Form.Item
                label="Weight"
                name="weightAdjustment"
                rules={[
                  {
                    required: true,
                    message: `Please input for weight TTL!`,
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            </>
          }
          <Form.Item
            label={isNew ? "Retargeting TTL" : "Retargeting TTL (days)"}
            name="retargetingTtl"
            disabled={isNew ? false : true}
            rules={[
              {
                required: isNew ? true : false,
                message: `Please input for Retargeting TTL!`,
              },
            ]}
          >
            <InputNumber disabled={isNew ? false : true} />
          </Form.Item>

          {!isNew &&
            <>
              <Form.Item label="Script" name='retargetingPixelUrl'>
                <Input
                  disabled={true}
                />
              </Form.Item>
              <Form.Item label="Image" name='imageUrl'>
                <Input
                  disabled={true}
                />
              </Form.Item>
            </>
          }

          {currentUserRole !== "" ? (
            <>
              <Form.Item
                label="Initial State"
                name="initialState"
                rules={[
                  {
                    required: true,
                    message: `Please enter initial state!`,
                  },
                ]}
              >
                <Input placeholder="Add initial state" />
              </Form.Item>

              <Form.Item className="creative-box" label="Targeting States" name="targetingStates">
                <Form.List name="targetingStates">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "state"]}
                            fieldKey={[fieldKey, "state"]}
                            rules={[{ required: true, message: "Please input state name!" }]}
                          >
                            <Input placeholder="State name" style={{ width: '100%' }} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "action"]}
                            fieldKey={[fieldKey, "action"]}
                            rules={[{ required: true, message: "Please select action type!" }]}
                          >
                            <Select placeholder="Pixel Action Type">{actionOptions}</Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "nextState"]}
                            fieldKey={[fieldKey, "nextState"]}
                            rules={[{ required: true, message: "Please input next state!" }]}
                          >
                            <Input placeholder="Next State" style={{ width: '100%' }} />
                          </Form.Item>
                          {currentUserPermissionMap[currentUserRole][
                            "canEditRetargetingPixel"
                          ] ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : ""}
                        </Space>
                      ))}
                      {currentUserPermissionMap[currentUserRole][
                        "canEditRetargetingPixel"
                      ] ? (
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add
                          </Button>
                        </Form.Item>
                      ) : ""}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </>
          ) : ""}

          <Form.Item className="text-right">
            {currentUserPermissionMap[currentUserRole][
              "canEditRetargetingPixel"
            ] ? (
              <>
                <Button type="primary" htmlType="button" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </>
            ) : (
              <Button type="primary" htmlType="button" onClick={handleCancel}>
                Ok
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddRetargetingPixelModalOpen: state.retargetingPixel.isAddRetargetingPixelModalOpen,
    pixel: state.retargetingPixel.pixel
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewRetargetingPixelModal: () => dispatch(closeNewRetargetingPixelModal()),
    addRetargetingPixel: () => dispatch(addRetargetingPixel()),
    updateRetargetingPixels: (id, payload) => dispatch(updateRetargetingPixels(id, payload)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddRetargetingPixelModal);
