import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadUsers, openNewUserModal, clearEditUserStates } from "../../redux/actions/user-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { notifyUser } from "./../../services/notification-service";
import { List, Avatar, Row, Col, PageHeader, Button, Spin } from "antd";
import AddUserModal from "./../../components/modals/user/add-user";
import { compose } from "redux";

const UserList = ({ userList, userAdded }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadUsers());
      if(isMounted ){

      setSubmitted(false);
  }})();
    
    if (userAdded !== undefined) {
      if (userAdded.success === true) {
        notifyUser(userAdded.message, "success");
      } else {
        notifyUser(userAdded.message, "error");
      }
    }
    return () => {
      isMounted = false;

      console.log("UserList Unmounting");
      dispatch(clearEditUserStates());
    };
  }, [userAdded]);


  return (
    <>
    <Spin size={"large"} spinning={submitted}></Spin>
      <PageHeader
        title="User List"
        extra={[
          <Button key="1" type="primary" onClick={() => dispatch(openNewUserModal())}>
            Add User
          </Button>,
        ]}
      />
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List className="user-list-items"
            dataSource={userList}
            bordered
            renderItem={(user) => (
              <List.Item key={user.userId}>
                <List.Item.Meta
                  avatar={
                    <Avatar>
                      <UserOutlined />
                    </Avatar>
                  }
                  title={
                    <Link to={`/edit-users/${user.userId}`}>
                      {user.firstName} {user.lastName}
                    </Link>
                  }
                  description={user.email}
                />
              </List.Item>
            )}
          ></List>
        </Col>
      </Row>
      <AddUserModal editUserInfo={undefined} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    userList: state.user.userList,
    userAdded: state.user.userAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadUsers: () => dispatch(loadUsers()),
    openNewUserModal: () => dispatch(openNewUserModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserList);
