import React, { useState } from "react";
import { connect } from "react-redux";

import { Row, Col, Card, Typography, Select } from "antd";
import { compose } from "redux";
import { videoTagOptions, videoTagOptionQueryParms } from "../../constants/defaultValues";
import Config from "../../config";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";
import Title from "antd/lib/typography/Title";
const { Option } = Select;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const { Paragraph } = Typography;

const SiteBuyTags = ({ siteBuy, siteBuyType }) => {
  const [queryParms, setQueryParms] = useState(videoTagOptionQueryParms["AdForge"]);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  function handleSelctChange(value) {
    setQueryParms(videoTagOptionQueryParms[value]);
  }

  return (
    <>
      {siteBuy && (
        <Row gutter={16}>
          <Col span={24}>
            <Card title="Tags">
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Select defaultValue="DFP" style={{ width: 120 }} onChange={handleSelctChange}>
                  {videoTagOptions.map((option) => (
                    currentUserPermissionMap[currentUserRole]["canUpdateSiteBuyTag"] ? (
                      <Option key={option}>{option}</Option>
                    ) : (
                      ""
                    )
                  ))
                  }
                </Select>
              </Card.Grid>

              <Card.Grid className="space-sec-15" hoverable={false} style={{ ...gridStyle }}>
                <Title level={5}>VPAID: </Title>
                <Paragraph copyable={{ tooltips: false }} >
                  {`${Config.AdUrl}video/${siteBuy.videoSiteBuyId}`}
                </Paragraph>
                {/* <Title level={5}>VAST: </Title>
                <Paragraph copyable={{ tooltips: false }} >
                  {`${Config.AdUrl}video/${siteBuy.videoSiteBuyId}?formats=mp4`}
                </Paragraph> */}
                <Title level={5}>VAST MOAT Wrapper: </Title>
                <Paragraph copyable={{ tooltips: false }} >
                  {`${Config.AdUrl}video/${siteBuy.videoSiteBuyId}?vastMoatSupported=true&formats=mp4`}
                </Paragraph>
              </Card.Grid>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteBuyTags);
