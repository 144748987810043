import React, { useEffect, useState } from "react";
import { Modal, Button, Form, InputNumber, Radio, Space } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { Switch } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  closeUpdateDeliveryModal,
  updateDisplayLine,
  updateVideoLine,
} from "../../../redux/actions/line-actions";

const EditDelivery = ({ lineType, isUpdateDeliveryModalOpen, editLineInfo }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dailyCapValue, setDailyCapValue] = useState(1);
  const { confirm } = Modal;
  const [pauseValue, setPauseValue] = useState(false);
  const [isFrequencyCapped, setIsFrequencyCapped] = useState(false);
  const [isDailyCapUpdated, setIsDailyCapUpdated] = useState(false);
  const [changedValues, setChangedValues] = useState({
    percentDailyDeliveryCap: 0,
    fixedDailyDeliveryCap: 0,
  });
  const { id } = useParams();
  var values = {};
  var changedValuesTemp = {};
  
  useEffect(() => {
    console.log("EditLineInfo: ", editLineInfo);
    if (editLineInfo !== undefined && lineType) {
      if (
        lineType === "Display" &&
        editLineInfo.displayLineVersion
      ) {
        values = {
          clickweight: editLineInfo.displayLineVersion.clickWeight
            ? editLineInfo.displayLineVersion.clickWeight
            : 0,
          paused: editLineInfo.displayLineVersion.paused
            ? editLineInfo.displayLineVersion.paused
            : false,
          deliveryCapType: editLineInfo.displayLineVersion.deliveryCapType
            ? editLineInfo.displayLineVersion.deliveryCapType
            : 1,
          frequencyCapDuration: editLineInfo.displayLineVersion.frequencyCapDuration
            ? editLineInfo.displayLineVersion.frequencyCapDuration
            : 0,
          frequencyCapLimit: editLineInfo.displayLineVersion.frequencyCapLimit
            ? editLineInfo.displayLineVersion.frequencyCapLimit
            : 0,
          fixedDailyDeliveryCap: editLineInfo.displayLineVersion.fixedDailyDeliveryCap 
            ? editLineInfo.displayLineVersion.fixedDailyDeliveryCap : 0,
          percentDailyDeliveryCap: editLineInfo.displayLineVersion.percentDailyDeliveryCap
            ? editLineInfo.displayLineVersion.percentDailyDeliveryCap : 20,
        };
        if (values && values.frequencyCapLimit && values.frequencyCapLimit > 0) {
          setIsFrequencyCapped(true)
        }

        changedValuesTemp = changedValues;
        changedValuesTemp["percentDailyDeliveryCap"] = editLineInfo.displayLineVersion.percentDailyDeliveryCap
          ? editLineInfo.displayLineVersion.percentDailyDeliveryCap * 100 : 20;
        changedValuesTemp["fixedDailyDeliveryCap"] = editLineInfo.displayLineVersion.fixedDailyDeliveryCap
          ? editLineInfo.displayLineVersion.fixedDailyDeliveryCap : 0;

        setChangedValues(changedValuesTemp);
        setDailyCapValue(values.deliveryCapType);
        setPauseValue(values.paused);
        form.setFieldsValue(values);
      } else if (
        lineType && lineType === "Video" &&
        editLineInfo.videoLineVersion
      ) {
        values = {
          clickweight: editLineInfo.videoLineVersion.clickWeight
            ? editLineInfo.videoLineVersion.clickWeight
            : 0,
          paused: editLineInfo.videoLineVersion.paused
            ? editLineInfo.videoLineVersion.paused
            : false,
          deliveryCapType: editLineInfo.videoLineVersion.deliveryCapType
            ? editLineInfo.videoLineVersion.deliveryCapType
            : 1,
          frequencyCapDuration: editLineInfo.videoLineVersion.frequencyCapDuration
            ? editLineInfo.videoLineVersion.frequencyCapDuration
            : 0,
          frequencyCapLimit: editLineInfo.videoLineVersion.frequencyCapLimit
            ? editLineInfo.videoLineVersion.frequencyCapLimit
            : 0,
            fixedDailyDeliveryCap: editLineInfo.videoLineVersion.fixedDailyDeliveryCap
            ? editLineInfo.videoLineVersion.fixedDailyDeliveryCap
            : 0,
            percentDailyDeliveryCap: editLineInfo.videoLineVersion.percentDailyDeliveryCap
            ? editLineInfo.videoLineVersion.percentDailyDeliveryCap
            : 0,
        };
        if (values && values.frequencyCapLimit && values.frequencyCapLimit > 0) {
          setIsFrequencyCapped(true)
        }

        changedValuesTemp = changedValues;
        changedValuesTemp["percentDailyDeliveryCap"] = editLineInfo.videoLineVersion.percentDailyDeliveryCap
          ? editLineInfo.videoLineVersion.percentDailyDeliveryCap * 100 : 20;
        changedValuesTemp["fixedDailyDeliveryCap"] = editLineInfo.videoLineVersion.fixedDailyDeliveryCap
          ? editLineInfo.videoLineVersion.fixedDailyDeliveryCap : 0;

        setChangedValues(changedValuesTemp);
        setDailyCapValue(values.deliveryCapType);
        setPauseValue(values.paused);
        form.setFieldsValue(values);
      }
    }
    return () => {
      console.log("EditDeliveryModal Unmounting");
    };
  }, [isUpdateDeliveryModalOpen, form, editLineInfo, isFrequencyCapped]);

  const handleSave = (values) => {
    values["percentDailyDeliveryCap"] = changedValues["percentDailyDeliveryCap"] / 100;
    values["fixedDailyDeliveryCap"] = changedValues["fixedDailyDeliveryCap"];
    values["paused"] = pauseValue;
    values["deliveryCapType"] = dailyCapValue;
    values["isDailyCapUpdated"] = isDailyCapUpdated;
    if (lineType === "Display") {
      dispatch(updateDisplayLine(id, values));
    } else {
      dispatch(updateVideoLine(id, values));
    }
    dispatch(closeUpdateDeliveryModal());
    form.resetFields();
    setIsDailyCapUpdated(false);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeUpdateDeliveryModal());
    setIsDailyCapUpdated(false);
  };

  const onChange = (e) => {
    setDailyCapValue(e.target.value);
    setIsDailyCapUpdated(true);
  };

  const onValueChange = (value, name) => {
    console.log("value", value, name);
    const changedValueTemp = changedValues;
    if (name in changedValueTemp) {
      changedValueTemp[name] = value;
    }
    setChangedValues(changedValueTemp);
    setIsDailyCapUpdated(true);
  };

  function showConfirm() {
    if (!pauseValue) {
      confirm({
        title: "Are you sure you want to pause?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          const pausedValueTemp = pauseValue;
          setPauseValue(!pausedValueTemp);
          console.log("OK", pausedValueTemp);
        },
        onCancel() {
          setPauseValue(pauseValue);
          console.log("Cancel", pauseValue);
        },
      });
    } else {
      setPauseValue(false);
      return;
    }
  }

  return (
    <>
      <Modal
        title={"Update Delivery"}
        visible={isUpdateDeliveryModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} layout={"vertical"} onFinish={handleSave}>
          <Form.Item
            label="Click Weight"
            name="clickWeight"
          >
            <InputNumber min={1} />
          </Form.Item>
          <div className="card-cnt-sec grid-col-2">
            <Form.Item
              label="Daily Cap"
              name="deliveryCapType"
              rules={[
                {
                  required: true,
                  message: "Please input last name!",
                },
              ]}
            >
              <Radio.Group onChange={onChange} value={dailyCapValue}>
                <Radio value={"DISABLED"}>Disabled</Radio>
                <Radio value={"PERCENT"}>Percent Cap</Radio>
                <Radio value={"FIXED"}>Fixed Cap</Radio>
              </Radio.Group>

              <div className="col-items">
                {dailyCapValue === "PERCENT" ? (
                  <>
                    <Form.Item rules={[{ required: true, message: "Please input!" }]}>
                      <InputNumber
                        key={changedValues && changedValues["percentDailyDeliveryCap"]}
                        defaultValue={changedValues && changedValues["percentDailyDeliveryCap"]}
                        min={0}
                        onChange={(e) => onValueChange(e, "percentDailyDeliveryCap")}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {dailyCapValue === "FIXED" ? (
                  <>
                    <Form.Item rules={[{ required: true, message: "Please input!" }]}>
                      <InputNumber
                        key={changedValues && changedValues["fixedDailyDeliveryCap"]}
                        defaultValue={changedValues && changedValues["fixedDailyDeliveryCap"]}
                        min={0}
                        onChange={(e) => onValueChange(e, "fixedDailyDeliveryCap")}
                      />
                    </Form.Item>
                  </>
                ) : null}
              </div>
            </Form.Item>
          </div>
          <Space style={{ display: "flex", marginBottom: 4 }} align="baseline">
            <Form.Item
              label="Frequency Cap Limit"
              name="frequencyCapLimit"
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Duration(days)"
              rules={[{ required: isFrequencyCapped, message: "Please input!" }]}
              name="frequencyCapDuration"
            >
              <InputNumber min={0} />
            </Form.Item>
          </Space>
          <Form.Item name="paused" label="Paused">
            <Switch checked={pauseValue} onClick={showConfirm} />
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isUpdateDeliveryModalOpen: state.line.isUpdateDeliveryModalOpen,
    lineType: state.line.lineType,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditDelivery);
