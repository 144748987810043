import CryptoJS from "crypto-js"
import { secretKey } from "../constants/defaultValues"

const setUserData = (user) => {
  return new Promise((resolve) => {
    if (user && user.token) {      
      localStorage.setItem("authToken", user.token);
      var encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey).toString();
      localStorage.setItem("user", encryptedUser);
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

const isUserLoggedIn = () => {
  if ("user" in localStorage && "authToken" in localStorage) {
    const decryptedUser = CryptoJS.AES.decrypt(localStorage.getItem("user"), secretKey).toString(CryptoJS.enc.Utf8);
    const user = JSON.parse(decryptedUser);
    const authToken = localStorage.getItem("authToken");
    if (user.token !== "" && authToken !== "") {
      return user.token === authToken ? true : false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getCurrentUser = () => {
  if ("user" in localStorage && "authToken" in localStorage) {
    const decryptedUser = CryptoJS.AES.decrypt(localStorage.getItem("user"), secretKey).toString(CryptoJS.enc.Utf8);
    const user = JSON.parse(decryptedUser);
    const authToken = localStorage.getItem("authToken");
    if (user.token !== "" && authToken !== "") {
      return user;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};


const signOut = () => {
  return new Promise((resolve) => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    resolve(true);
  });
};

export default {
  setUserData,
  isUserLoggedIn,
  signOut,
  getCurrentUser,
};
