import { combineReducers } from "redux";
import authReducer from "./auth-reducer";
import accountReducer from "./account-reducer";
import publisherReducer from "./publisher-reducer";
import campaignReducer from "./campaign-reducer";
import userReducer from "./user-reducer";
import siteBuyReducer from "./site-buy-reducer";
import lineReducer from "./line-reducer";
import retargetingPixelReducer from "./retargeting-pixel-reducer";
import conversionPixelReducer from "./conversion-pixel-reducer";
import privateBuyReducer from "./private-buy-reducer";
import creativeReducer from "./creative-reducer";
import pk4SegmentReducer from "./segment-reducer";
import siteListReducer from "./site-list-reducer";
import siteReducer from "./site-reducer";
import exportReportReducer from "./export-report-reducer";
import headerReducer from "./header-reducer";
import forecastingReducer from "./forecasting-reducer";
import primaryContactsReducer from "./primary-contacts-reducer";

const reducers = combineReducers({
  auth: authReducer,
  account: accountReducer,
  publisher: publisherReducer,
  campaign: campaignReducer,
  user: userReducer,
  siteBuy: siteBuyReducer,
  line: lineReducer,
  retargetingPixel: retargetingPixelReducer,
  conversionPixel: conversionPixelReducer,
  privateBuy: privateBuyReducer,
  creative: creativeReducer,
  pk4Segment: pk4SegmentReducer,
  siteList: siteListReducer,
  site: siteReducer,
  exportReport : exportReportReducer,
  header: headerReducer,
  forecasting: forecastingReducer,
  primaryContacts: primaryContactsReducer,
});
export default reducers;
