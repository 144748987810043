import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { Avatar } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";

import Logo from "../../../assets/images/pk4media-logo-white.svg";
import { useDispatch } from "react-redux";
import { loadCurrentLoggedInUser } from "./../../../redux/actions/user-actions";
const { SubMenu } = Menu;

const Sidebar = ({ options, currentLoggedInUser }) => {
  const dispatch = useDispatch();
  const [currentURI, setCurrentURI] = useState(window.location.pathname);
  useEffect(() => {
    dispatch(loadCurrentLoggedInUser());
    return () => {
      console.log("SideBar Unmounting");
    };
  }, []);

  if (currentLoggedInUser) {
    currentLoggedInUser.avatar =
      currentLoggedInUser.firstName.charAt(0) + "" + currentLoggedInUser.lastName.charAt(0);
  }

  const handleClick = (values) => {
    setCurrentURI(values.key);
  };

  function getMenuItem(singleOption) {
    const { key, label, leftIcon, children, canAccess } = singleOption;
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder">
              {leftIcon}
              <span className="nav-text">{label}</span>
            </span>
          }
        >
          {children.map((child) => {
            return child.canAccess ? (
              <Menu.Item key={child.key}>
                <Link to={child.key}>
                  {child.leftIcon}
                  {child.label}
                  {child.alertsCount}
                </Link>
              </Menu.Item>
            ) : (
              ""
            );
          })}
        </SubMenu>
      );
    } else {
      return canAccess ? (
        <Menu.Item key={key} defaultselectedkeys={["1"]}>
          <Link to={`${key}`}>
            <span className="isoMenuHolder">
              {leftIcon}
              <span className="nav-text">{label}</span>
            </span>
          </Link>
        </Menu.Item>
      ) : (
        ""
      );
    }
  }

  return (
    <React.Fragment>
      <div className="sidebar-menu">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="nav-menu-items">
          {currentLoggedInUser ? (
            <div className="doctor-avtar">
              <Avatar>
                {currentLoggedInUser && currentLoggedInUser.avatar
                  ? currentLoggedInUser.avatar
                  : ""}
              </Avatar>
              <div className="doctor-detail">
                <div className="name-icon">
                  {currentLoggedInUser.firstName} {currentLoggedInUser.lastName}
                </div>
                <div className="email"> {currentLoggedInUser.email}</div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Menu
            theme="light"
            mode="inline"
            onClick={handleClick}
            defaultselectedkeys="/"
            selectedKeys={currentURI}
          >
            {options &&
              Object.keys(options).length > 0 &&
              options.map((singleOption) => getMenuItem(singleOption))}
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
};
function mapStateToProps(state) {
  return {
    currentLoggedInUser: state.user.currentLoggedInUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCurrentLoggedInUser: () => dispatch(loadCurrentLoggedInUser()),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Sidebar);
