export const timezones = ["UTC", "America/Los_Angeles", "America/New_York", "America/Chicago"];

export const systemUserRoles = [
  { name: "ADMIN", role: "ROLE_ADMIN" },
  { name: "ACCOUNT_OBSERVER", role: "ROLE_ACCOUNT_OBSERVER" },
  { name: "PUBLISHER_OBSERVER", role: "ROLE_PUBLISHER_OBSERVER" },
  { name: "ACCOUNT_MANAGER", role: "ROLE_ACCOUNT_MANAGER" },
  { name: "ACCOUNT_MANAGER_LEAD", role: "ROLE_ACCOUNT_MANAGER_LEAD" },
  { name: "PARTNER_DEVELOPMENT", role: "ROLE_PARTNER_DEVELOPMENT" },
  { name: "PARTNER_DEVELOPMENT_LEAD", role: "ROLE_PARTNER_DEVELOPMENT_LEAD" },
  { name: "TRAFFICKING_LEAD", role: "ROLE_TRAFFICKING_LEAD" },
  { name: "OBSERVER", role: "ROLE_OBSERVER" },
  { name: "DISABLED", role: "ROLE_DISABLED" },
];

export const secretKey = "my-secret-key@123";
export const testVideoAdUrl = "https://storage.googleapis.com/xps_upgrade_ads_staging/video/test.html";
export const testDisplayAdUrl = "https://storage.googleapis.com/xps_upgrade_ads_staging/display/test.html";

export const adminCanAccess = {
  accounts: ["All accounts available"],
  publishers: ["All publishers available"],
};

export const displayRateTypes = ["CPA", "CPM", "CPC"];
export const videoRateTypes = ["CPA", "CPM", "CPC", "CPE", "CPV"];

export const billingServerTypes = ["XPS", "DFA/DCM", "Double Verify", "MOAT", "Sizmek"];
export const displayBillingProfileTypes = ["Standard"];
export const videoBillingProfileTypes = ["Standard", "GroupM"];

export const desktopOptions = ["300x250", "728x90", "160x600", "300x600", "970x90", "970x250"];
export const mobileOptions = ["200x200", "250x250", "320x100", "320x50", "300x50"];
export const pixelOptions = ["1x1"];
export const displayTagOptions = ["DFP", "AdForge", "AppNexus", "Media Math", "OpenX/Revive", "Acuity", "AdSupply"];
export const videoTagOptions = ["DFP", "LiveRail", "LKQD", "Adap.tv", "AdForge"];
export const displayTagOptionQueryParms = {
  "DFP": "ct=$$CLICK_URL_ESC_ESC$$&cb=$$CACHEBUSTER$$&site[url]=$$PATTERN:url$$",
  "AdForge": "ct={clickurl}&cb={random}&site[url]={referer}",
  "AppNexus": "ct=${CLICK_URL_ENC}&cb=null&site[url]=${REFERER_URL_ENC}",
  "Media Math": "ct=[BID_ATTR.3pas_clk]&cb=[RANDOM_NUMBER]&site[url]=[BID_ATTR.page_url]",
  "OpenX/Revive": "ct={clickurl_esc}&cb={random}&site[url]={techno.referrer_url_enc}",
  "Acuity": "ct=[CLICK_URL_ENCODED]&cb=[CACHEBUSTER]&site[url]=[SITE_DOMAIN_ENCODED_URL]",
  "AdSupply": "ct={clickpixelurl}&cb={randomvar}&site[url]=null",
  "Winstar": "ct={click_tracking_url};&cb={cachebuster}&site[url]=null"
};

export const videoTagOptionQueryParms = {
  DFP: "",//"cb=%%CACHEBUSTER%%&site[url]=%%REFERRER_URL_ESC%%",
  LiveRail: "",//"cb=null&site[url]=[LR_URL]",
  LKQD: "",//"cb=$$cachebuster$$&site[url]=$$pageurl$$",
  "Adap.tv": "",//"cb=${AdPlayer.cachebreaker}&site[url]=${embeddingPageUrl}",
  AdForge: "",//"cb={random}&site[url]={referrer}",
};

export const runningStatus = [
  { color: "#ffd54f", name: "Pending" },
  { color: "#81c784", name: "Active" },
  { color: "#e57373", name: "Inactive" },
  { color: "#4fc3f7", name: "Finished" },
];

export const creativeImages = {
  "300x250": "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
  "728x90": "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
  "160x600": "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
  "970x250": "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
};
export const piggybackPixelFormats = ["PIXEL", "HTML"];

export const defaultCreativeSizes = {
  Desktop: ["300x250", "728x90", "160x600", "300x600", "970x90", "970x250"],
  Mobile: ["200x200", "250x250", "320x100", "320x50", "300x50"],
  Pixel: ["1x1"],
};

export const pixelUrl = {
  conversion_url: "https://staging-pixel.xpsads.com/action/centaur/",
  retargeting_url: "https://staging-pixel.xpsads.com/action/target/"
};

export const metrics = [
  "clickTotal",
  "clickUnique",
  "completeTotal",
  "completeUnique",
  "ctr",
  "date",
  "errorTotal",
  "errorUnique",
  "firstQuartileTotal",
  "firstQuartileUnique",
  "impressionTotal",
  "impressionUnique",
  "midpointTotal",
  "midpointUnique",
  "startTotal",
  "startUnique",
  "thirdQuartileTotal",
  "thirdQuartileUnique",
];

export const displayBuyReports = [
  "Date - Delivery", "Moat", "Date - Requests", "Size - Delivery",
  "Size - Requests", "DMA - Delivery", "DMA - Requests", "State - Delivery", "State - Requests",
  "Device - Delivery", "Device - Requests", "Country - Delivery", "Country - Requests",
  "Domain - Delivery", "Domain - Requests"
];

export const videoBuyReports = [
  "Date - Delivery", "Moat", "Date - Requests", "DMA - Delivery", "DMA - Requests", "State - Delivery", "State - Requests",
  "Device - Delivery", "Device - Requests", "Country - Delivery", "Country - Requests",
  "Domain - Delivery", "Domain - Requests"
];


export const siteBuyGraphMetrics = ["rejected", "rejectionRate", "served", "total"];

export const lineReports = [
  "Date", "Moat", "Device", "Creative", "Geo - DMA", "Geo - State"
];

export const displayLineGraphMetrics = ["click.total", "click.unique", "ctr", "impression.total", "impression.unique"];

export const videoLineGraphMetrics = ["click.total", "click.unique", "complete.total", "complete.unique", "ctr", "error.total", "error.unique", "first_quratile.total", "first_quratile.unique", "impression.total", "impression.unique", "midpoint.total", "midpoint.unique", "start.total", "start.unique", "third_quartile.total", "third_quartile.unique", "vcr"];

export const retargetingPixelActions = ["Exposed"];

export const displayAdServers = 
["AdForge", "AppNexus", "DFP", "Media Math", "OpenX/Revive", "Acuity", "AdSupply", "Winstar"];

export const videoAdServers = 
["DFP", "LiveRail", "LKQD", "Adap.tv", "AdForge", "AllScreen", "Datablocks", "OpenX/Revive", "Orca", "Vidible"];
