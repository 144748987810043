export const currentUserPermissionMap = {
  ROLE_ADMIN: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: true,
    canAccessPublisherList: true,
    canCreateOrUpdatePublisherInfo: true,
    canAccessEditAccount: true,
    canAccessEditPublisher: true,
    canCreateAccountCampaign: true,
    canCreateAccountPixels: true,
    canUpdateCampaign: true,
    canAccessCampaignCategories: true,
    canCreateLine: true,
    canAccessCampaignSiteList: true,
    canUpdateCampaignBlacklistWhiteListProp: true,
    canAccessRetargetingPixel: true,
    canAccessConversionPixel: true,
    canCreateRetargetingConversionPixel: true,
    canEditRetargetingPixel: true,
    canAccessCampaignPadding: true,
    canAccessCampaignTimezone: true,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: true,
    canAccessDisplayHistory: true,
    canAccessVideoHistory: true,
    canUpdateLineBilling: true,
    canUpdateLineDelivery: true,
    canUpdateCreativeSizes: true,
    canCreateLineCreative: true,
    canUpdateLineDevices: true,
    canUpdateLineSiteList: true,
    canUpdateLineGeoTargeting: true,
    canUpdateLineDmaTargeting: true,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: true,
    canCreateLinePrivateBuy: true,
    canUpdateCreative: true,
    canCreateOrUpdatePk4Segments: true,
    canCreateOrRemoveSite: true,
    canCreateSiteBuy: true,
    canUpdatePublisherBlacKlistWhitelistAccount: true,
    canCreatePublisherPiggyBackPixel: true,
    canUpdatePublisherTimezone: true,
    canUpdatePrivateBuy: true,
    canUpdatePrivateBuyDetail: true,
    canUpdatePrivateBuyTag: true,
    canAccessSiteBuyDetail: true,
    canUpdateSiteBuyInfo: true,
    canUpdateSiteBuyTag: true,
    canUpdateUser: true,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: true,
    canAccessForeCasting: true,
    canAccessPk4Segments: true,
    canAccessAllUsers: true,
    canTestAd: true,
  },
  ROLE_ACCOUNT_MANAGER: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: false,
    canCreateOrUpdatePublisherInfo: false,
    canAccessEditAccount: false,
    canAccessEditPublisher: false,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: false,
    canCreateLine: false,
    canAccessCampaignSiteList: false,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: false,
    canAccessConversionPixel: false,
    canCreateRetargetingConversionPixel: false,
    canEditRetargetingPixel: false,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: false,
    canUpdateLineDelivery: false,
    canUpdateCreativeSizes: false,
    canCreateLineCreative: false,
    canUpdateLineDevices: false,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: false,
    canUpdateLineDmaTargeting: false,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: false,
    canCreateLinePrivateBuy: false,
    canUpdateCreative: false,
    canCreateOrUpdatePk4Segments: false,
    canCreateOrRemoveSite: false,
    canCreateSiteBuy: false,
    canUpdatePublisherBlacKlistWhitelistAccount: false,
    canCreatePublisherPiggyBackPixel: false,
    canUpdatePublisherTimezone: false,
    canUpdatePrivateBuy: false,
    canUpdatePrivateBuyDetail: false,
    canUpdatePrivateBuyTag: false,
    canAccessSiteBuyDetail: false,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: false,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: false,
    canAccessForeCasting: false,
    canAccessPk4Segments: true,
    canAccessAllUsers: false,
    canTestAd: true,
  },
  ROLE_ACCOUNT_OBSERVER: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: false,
    canCreateOrUpdatePublisherInfo: false,
    canAccessEditAccount: false,
    canAccessEditPublisher: false,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: false,
    canCreateLine: false,
    canAccessCampaignSiteList: false,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: false,
    canAccessConversionPixel: false,
    canCreateRetargetingConversionPixel: false,
    canEditRetargetingPixel: false,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: false,
    canUpdateLineDelivery: false,
    canUpdateCreativeSizes: false,
    canCreateLineCreative: false,
    canUpdateLineDevices: false,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: false,
    canUpdateLineDmaTargeting: false,
    canAccessExelateSegments: false,
    canUpdateLineExelateSegments: false,
    canCreateLinePrivateBuy: false,
    canUpdateCreative: false,
    canCreateOrUpdatePk4Segments: false,
    canCreateOrRemoveSite: false,
    canCreateSiteBuy: false,
    canUpdatePublisherBlacKlistWhitelistAccount: false,
    canCreatePublisherPiggyBackPixel: false,
    canUpdatePublisherTimezone: false,
    canUpdatePrivateBuy: false,
    canUpdatePrivateBuyDetail: false,
    canUpdatePrivateBuyTag: false,
    canAccessSiteBuyDetail: false,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: false,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: false,
    canAccessForeCasting: false,
    canAccessPk4Segments: false,
    canAccessAllUsers: false,
    canTestAd: false,
  },
  ROLE_ACCOUNT_MANAGER_LEAD: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: false,
    canCreateOrUpdatePublisherInfo: false,
    canAccessEditAccount: false,
    canAccessEditPublisher: false,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: false,
    canCreateLine: false,
    canAccessCampaignSiteList: false,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: true,
    canAccessConversionPixel: true,
    canCreateRetargetingConversionPixel: false,
    canEditRetargetingPixel: false,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: false,
    canUpdateLineDelivery: false,
    canUpdateCreativeSizes: false,
    canCreateLineCreative: false,
    canUpdateLineDevices: false,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: false,
    canUpdateLineDmaTargeting: false,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: false,
    canCreateLinePrivateBuy: false,
    canUpdateCreative: false,
    canCreateOrUpdatePk4Segments: false,
    canCreateOrRemoveSite: false,
    canCreateSiteBuy: false,
    canUpdatePublisherBlacKlistWhitelistAccount: false,
    canCreatePublisherPiggyBackPixel: false,
    canUpdatePublisherTimezone: false,
    canUpdatePrivateBuy: false,
    canUpdatePrivateBuyDetail: false,
    canUpdatePrivateBuyTag: false,
    canAccessSiteBuyDetail: false,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: false,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: false,
    canAccessForeCasting: false,
    canAccessPk4Segments: true,
    canAccessAllUsers: false,
    canTestAd: false,
  },
  ROLE_PARTNER_DEVELOPMENT: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: true,
    canCreateOrUpdatePublisherInfo: false,
    canAccessEditAccount: false,
    canAccessEditPublisher: false,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: false,
    canCreateLine: false,
    canAccessCampaignSiteList: false,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: false,
    canAccessConversionPixel: false,
    canCreateRetargetingConversionPixel: false,
    canEditRetargetingPixel: false,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,   
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: false,
    canUpdateLineDelivery: false,
    canUpdateCreativeSizes: false,
    canCreateLineCreative: false,
    canUpdateLineDevices: false,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: false,
    canUpdateLineDmaTargeting: false,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: false,
    canCreateLinePrivateBuy: true,
    canUpdateCreative: false,
    canCreateOrUpdatePk4Segments: true,
    canCreateOrRemoveSite: true,
    canCreateSiteBuy: false,
    canUpdatePublisherBlacKlistWhitelistAccount: false,
    canCreatePublisherPiggyBackPixel: false,
    canUpdatePublisherTimezone: false,
    canUpdatePrivateBuy: true,
    canUpdatePrivateBuyDetail: false,
    canUpdatePrivateBuyTag: false,
    canAccessSiteBuyDetail: false,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: false,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: false,
    canAccessForeCasting: false,
    canAccessPk4Segments: true,
    canAccessAllUsers: false,
    canTestAd: false,
  },
  ROLE_PARTNER_DEVELOPMENT_LEAD: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: true,
    canCreateOrUpdatePublisherInfo: true,
    canAccessEditAccount: false,
    canAccessEditPublisher: true,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: true,
    canCreateLine: false,
    canAccessCampaignSiteList: true,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: true,
    canAccessConversionPixel: true,
    canCreateRetargetingConversionPixel: true,
    canEditRetargetingPixel: true,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: true,
    canUpdateLineDelivery: true,
    canUpdateCreativeSizes: true,
    canCreateLineCreative: false,
    canUpdateLineDevices: true,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: true,
    canUpdateLineDmaTargeting: true,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: true,
    canCreateLinePrivateBuy: true,
    canUpdateCreative: true,
    canCreateOrUpdatePk4Segments: false,
    canCreateOrRemoveSite: true,
    canCreateSiteBuy: true,
    canUpdatePublisherBlacKlistWhitelistAccount: true,
    canCreatePublisherPiggyBackPixel: true,
    canUpdatePublisherTimezone: true,
    canUpdatePrivateBuy: true,
    canUpdatePrivateBuyDetail: true,
    canUpdatePrivateBuyTag: true,
    canAccessSiteBuyDetail: true,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: true,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: true,
    canAccessForeCasting: false,
    canAccessPk4Segments: true,
    canAccessAllUsers: false,
    canTestAd: true,
  },
  ROLE_TRAFFICKING_LEAD: {
    canAccessAccountList: true,
    canCreateOrUpdateAccountInfo: true,
    canAccessPublisherList: true,
    canCreateOrUpdatePublisherInfo: true,
    canAccessEditAccount: false,
    canAccessEditPublisher: true,
    canCreateAccountCampaign: true,
    canUpdateCampaign: true,
    canCreateAccountPixels: true,
    canAccessCampaignCategories: true,
    canCreateLine: true,
    canAccessCampaignSiteList: true,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: true,
    canAccessConversionPixel: true,
    canCreateRetargetingConversionPixel: true,
    canEditRetargetingPixel: true,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: true,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: true,
    canUpdateLineDelivery: true,
    canUpdateCreativeSizes: true,
    canCreateLineCreative: true,
    canUpdateLineDevices: true,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: true,
    canUpdateLineDmaTargeting: true,
    canAccessExelateSegments: true,
    canUpdateLineExelateSegments: true,
    canCreateLinePrivateBuy: true,
    canUpdateCreative: true,
    canCreateOrUpdatePk4Segments: true,
    canCreateOrRemoveSite: true,
    canCreateSiteBuy: true,
    canUpdatePublisherBlacKlistWhitelistAccount: true,
    canCreatePublisherPiggyBackPixel: true,
    canUpdatePublisherTimezone: true,
    canUpdatePrivateBuy: true,
    canUpdatePrivateBuyDetail: true,
    canUpdatePrivateBuyTag: true,
    canAccessSiteBuyDetail: true,
    canUpdateSiteBuyInfo: true,
    canUpdateSiteBuyTag: true,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: true,
    canAccessPublisherDelivery: false,
    canAccessForeCasting: false,
    canAccessPk4Segments: true,
    canAccessAllUsers: false,
    canTestAd: true,
  },
  ROLE_PUBLISHER_OBSERVER: {
    canAccessAccountList: false,
    canCreateOrUpdateAccountInfo: false,
    canAccessPublisherList: true,
    canCreateOrUpdatePublisherInfo: true,
    canAccessEditAccount: false,
    canAccessEditPublisher: true,
    canCreateAccountCampaign: false,
    canUpdateCampaign: false,
    canCreateAccountPixels: false,
    canAccessCampaignCategories: false,
    canCreateLine: false,
    canAccessCampaignSiteList: false,
    canUpdateCampaignBlacklistWhiteListProp: false,
    canAccessRetargetingPixel: false,
    canAccessConversionPixel: false,
    canCreateRetargetingConversionPixel: false,
    canEditRetargetingPixel: false,
    canAccessCampaignPadding: false,
    canAccessCampaignTimezone: false,
    canAccessExportReportTestPage: false,
    canAccessExportReportRadio: false,
    canAccessDisplayHistory: false,
    canAccessVideoHistory: false,
    canUpdateLineBilling: false,
    canUpdateLineDelivery: false,
    canUpdateCreativeSizes: false,
    canCreateLineCreative: false,
    canUpdateLineDevices: false,
    canUpdateLineSiteList: false,
    canUpdateLineGeoTargeting: false,
    canUpdateLineDmaTargeting: false,
    canAccessExelateSegments: false,
    canUpdateLineExelateSegments: false,
    canCreateLinePrivateBuy: true,
    canUpdateCreative: false,
    canCreateOrUpdatePk4Segments: false,
    canCreateOrRemoveSite: false,
    canCreateSiteBuy: false,
    canUpdatePublisherBlacKlistWhitelistAccount: false,
    canCreatePublisherPiggyBackPixel: false,
    canUpdatePublisherTimezone: false,
    canUpdatePrivateBuy: true,
    canUpdatePrivateBuyDetail: false,
    canUpdatePrivateBuyTag: false,
    canAccessSiteBuyDetail: false,
    canUpdateSiteBuyInfo: false,
    canUpdateSiteBuyTag: false,
    canUpdateUser: false,

    //perminssions for sidebar
    canAccessDashboard: true,
    canAccessAccountDelivery: false,
    canAccessPublisherDelivery: true,
    canAccessForeCasting: false,
    canAccessPk4Segments: false,
    canAccessAllUsers: false,
    canTestAd: false,
  },

};
