import { Types } from "../constants/forecasting-types";
const initialState = {
  getForecastingData: undefined,
  isNew: false
};
export default function forecastingReducer(state = initialState, action) {
  switch (action.type) {
    case Types.LOAD_FORECASTING_DATA:
      return {

        ...state,
        getForecastingData: action.payload.data,
        isNew: action.payload.isNew
      };
    default:
      return state;
  }
}
