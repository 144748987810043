import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllCampaignsByAccountId = (accountId) => {
  return AxiosAuth.get(
    Config.API + "/api/campaigns/getAllCampaigns/" + accountId,
    undefined,
    undefined
  );
};

const addCampaign = (accountId, data) => {
  return AxiosAuth.post(
    Config.API + "/api/campaigns/addCampaign/" + accountId,
    JSON.stringify(data),
    undefined
  );
};

const loadCampaignById = (campaignId) => {
  return AxiosAuth.get(
    Config.API + "/api/campaigns/getCampaign/" + campaignId,
    undefined,
    undefined
  );
};

const updateCampaign = (campaignId, data) => {
  return AxiosAuth.put(
    Config.API + "/api/campaigns/editCampaign/" + campaignId,
    JSON.stringify(data),
    undefined
  );
};

const loadAllCampaignCategories = () => {
  return AxiosAuth.get(Config.API + "/api/categories/getAllCategories", undefined, undefined);
};

export default {
  loadAllCampaignsByAccountId,
  addCampaign,
  loadCampaignById,
  updateCampaign,
  loadAllCampaignCategories,
};
