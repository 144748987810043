import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeBlacklistWhitelistSiteBuyModal, updateCampaign } from "../../../redux/actions/campaign-actions";
import { useParams } from "react-router-dom";

const CampaignBlacklistSiteBuyModal = ({
    displaySiteBuyList,
    videoSiteBuyList,
    selectedSiteBuyList,
    updateType,
    siteBuyType,
    isBlacklistWhitelistSiteBuyModalOpen,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState([]);
    const [isSet, setIsSet] = useState(false);
    const { id } = useParams();
    const [siteBuyList, setSiteBuyList] = useState([]);
    
    useEffect(() => {
        if (siteBuyType && siteBuyType === "Display" && displaySiteBuyList && displaySiteBuyList.length > 0) {
            setSiteBuyList(displaySiteBuyList);
        } else if (siteBuyType && videoSiteBuyList && videoSiteBuyList.length > 0) {
            setSiteBuyList(videoSiteBuyList);
        }

        const selectValuesTemp = [];
        if (selectedSiteBuyList && selectedSiteBuyList.length > 0) {
            selectedSiteBuyList.filter(function (obj) {
                if (siteBuyType === "Display") {
                    selectValuesTemp.push(obj.displaySiteBuyId);
                } else {
                    selectValuesTemp.push(obj.videoSiteBuyId);
                }

            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("CampaignBlacklistSiteBuyModal Unmounting");
        };
    }, [selectedSiteBuyList, isBlacklistWhitelistSiteBuyModalOpen, siteBuyList]);

    const handleSave = (values) => {
        let campaignData = {};

        campaignData["isPublishersUpdated"] = false;
        if (siteBuyType === "Display") {
            campaignData["isDisplaySiteBuysUpdated"] = true;
            campaignData["isVideoSiteBuysUpdated"] = false;
            if (updateType === "Blacklist") {
                campaignData["blacklistDisplaySiteBuyIds"] = selectedValues
            } else {
                campaignData["whitelistDisplaySiteBuyIds"] = selectedValues
            }
        } else {
            campaignData["isDisplaySiteBuysUpdated"] = false;
            campaignData["isVideoSiteBuysUpdated"] = true;
            if (updateType === "Blacklist") {
                campaignData["blacklistVideoSiteBuyIds"] = selectedValues
            } else {
                campaignData["whitelistVideoSiteBuyIds"] = selectedValues
            }
        }

        console.log(campaignData);
        dispatch(updateCampaign(id, campaignData));
        setSiteBuyList([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeBlacklistWhitelistSiteBuyModal());
    };

    const handleCancel = () => {
        setSiteBuyList([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeBlacklistWhitelistSiteBuyModal());
    };

    const onChange = (e) => {
        const selectValuesTemp = selectedValues;
        if (selectValuesTemp.indexOf(e.target.value) > -1) {
            selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
        } else {
            selectValuesTemp.push(e.target.value);
        }
        setSelectedValues(selectValuesTemp);
        setIsSet(!isSet);
    };

    const isSiteBuyExists = (siteBuyId) => {
        var filtered = selectedValues.filter(function (obj) {
            return siteBuyId === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    function _bindSiteBuy(siteBuys) {
        return siteBuys.map((siteBuy, index) => {
            return (
                <Row>
                    <Col span={24}>
                        {siteBuyType === "Display" ?
                            <Checkbox
                                checked={isSiteBuyExists(siteBuy.displaySiteBuyId)}
                                onChange={onChange}
                                value={siteBuy.displaySiteBuyId}
                            >
                                {siteBuy.name}
                            </Checkbox>
                            : <Checkbox
                                checked={isSiteBuyExists(siteBuy.videoSiteBuyId)}
                                onChange={onChange}
                                value={siteBuy.videoSiteBuyId}
                            >
                                {siteBuy.name}
                            </Checkbox>
                        }
                    </Col>
                </Row>
            );
        });
    }

    return (
        <>
            <Modal
                title={siteBuyType + " Site Buy List"}
                visible={isBlacklistWhitelistSiteBuyModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    form={form}
                    initialValues={{
                        accountIds: selectedValues,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item name="siteBuyIds" label="Select Site Buy">
                        {siteBuyList && _bindSiteBuy(siteBuyList)}
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
            </Button>
                        <Button type="primary" htmlType="submit">
                            Save
            </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
function mapStateToProps(state) {
    return {
        isBlacklistWhitelistSiteBuyModalOpen: state.campaign.isBlacklistWhitelistSiteBuyModalOpen,
        updateType: state.campaign.updateType,
        selectedSiteBuyList: state.campaign.selectedSiteBuyList,
        siteBuyType: state.campaign.siteBuyType,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeBlacklistWhitelistSiteBuyModal: () => dispatch(closeBlacklistWhitelistSiteBuyModal()),
    };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CampaignBlacklistSiteBuyModal
);
