import { Types } from "../constants/export-report-types";
const initialState = {
  isExportReportModalOpen: false,
  reportExported: undefined,
};
export default function exportReportReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_EXPORT_REPORT_MODAL:
      return {
        ...state,
        isExportReportModalOpen: action.payload,
      };
    case Types.EXPORT_REPORT:
      return {
        ...state,
        reportExported: action.payload,
      };
    case Types.CLEAR_EXPORT_REPORT_STATES:
      return {
        ...state,
        reportExported: undefined
      };
    default:
      return state;
  }
}
