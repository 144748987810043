import { Types } from "./../constants/export-report-types";
import reportAPI from "../api/export-report-api";

export const openExportReportModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EXPORT_REPORT_MODAL,
    payload: true,
  });
};

// To close update siteList Modal
export const closeExportReportModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EXPORT_REPORT_MODAL,
    payload: false,
  });
};

// export report
export const exportDataReport = (reportData) => async (dispatch) => {
  try {
    await reportAPI.exportReport(reportData).then(
      (data) => {
        if (data && data.message && data.message === "Email will be sent shortly") {
          dispatch({
            type: Types.EXPORT_REPORT,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.EXPORT_REPORT,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};


// To clear states from redux store
export const clearExportReportStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_EXPORT_REPORT_STATES,
    payload: undefined,
  });
};