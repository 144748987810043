import { Types } from "./../constants/site-list-types";
import siteListApi from "../api/site-list-api";

// To open add new site list Modal
export const openAddSiteListModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SITE_LIST_MODAL,
    payload: true,
  });
};

// To close add new site list Modal
export const closeAddSiteListModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_SITE_LIST_MODAL,
    payload: false,
  });
};

// Add new site list
export const addSiteList = (data) => async (dispatch) => {
  try {
    await siteListApi.addSiteList(data).then(
      (data) => {
        if (data && data.message && data.message === "SiteList added successfully!") {
          dispatch({
            type: Types.ADD_SITE_LIST,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_SITE_LIST,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

export const loadSiteListById = (id) => async (dispatch) => {
  try {
    await siteListApi.loadSiteListById(id).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_SITE_LIST,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To clear states from redux store
export const clearSiteListStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_SITE_LIST_STATES,
    payload: undefined,
  });
};
