import React from "react";
import Icon from "@ant-design/icons";
import { HomeSvg } from "../../shared/svg/icons";
import { DashboardSvg } from "../../shared/svg/dashboard";
import { PatientSvg } from "../../shared/svg/patient";
import {   UsergroupAddOutlined , MonitorOutlined,  DeliveredProcedureOutlined, AccountBookOutlined, FileSyncOutlined } from "@ant-design/icons";
import { currentUserPermissionMap } from "./../../../constants/permissions";
const HomeIcon = (props) => <Icon component={HomeSvg} {...props} />;
const DashboardIcon = (props) => <Icon component={DashboardSvg} {...props} />;
const PatientIcon = (props) => <Icon component={PatientSvg} {...props} />;

export default function dashBoardOptions(role) {
  return [
    {
      key: "/",
      label: "Home",
      leftIcon: <HomeIcon />,
      canAccess: role && role !== "" ? currentUserPermissionMap[role]["canAccessDashboard"] : false,
    },
    {
      key: "/viewDashboard",
      label: "Delivery",
      leftIcon: <DeliveredProcedureOutlined />,
      children: [
        {
          key: "/account-dashboard",
          label: "Account",
          leftIcon: <AccountBookOutlined />,
          canAccess:
            role && role !== ""
              ? currentUserPermissionMap[role]["canAccessAccountDelivery"]
              : false,
        },
        {
          key: "/publisher-dashboard",
          label: "Publisher",
          leftIcon: <FileSyncOutlined />,
          canAccess:
            role && role !== ""
              ? currentUserPermissionMap[role]["canAccessPublisherDelivery"]
              : false,
        },
      ],
    },
    {
      key: "/forecasting",
      label: "Forecasting",
      leftIcon: <PatientIcon />,
      canAccess:
        role && role !== "" ? currentUserPermissionMap[role]["canAccessForeCasting"] : false,
    },
    {
      key: "/pk4segments",
      label: "Pk4 Segments",
      leftIcon: <MonitorOutlined />,
      canAccess:
        role && role !== "" ? currentUserPermissionMap[role]["canAccessPk4Segments"] : false,
    },
    {
      key: "/users",
      label: "Users",
     leftIcon: <UsergroupAddOutlined />,
      canAccess: role && role !== "" ? currentUserPermissionMap[role]["canAccessAllUsers"] : false,
    },
  ];
}
