import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col, } from "antd";
import { useParams } from "react-router-dom";
import { closeUpdateCreativeSizeModal, updateDisplayLine } from "../../../redux/actions/line-actions";
import { defaultCreativeSizes } from "./../../../utils/appUtils";

const EditCreativeSize = ({ editDisplayLine, isUpdateCreativeSizeModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();

    const [selectedValues, setSelectedValues] = useState([]);
    const [isSet, setIsSet] = useState(false);

    useEffect(() => {
        if (editDisplayLine &&
            editDisplayLine.displayLineVersion &&
            editDisplayLine.displayLineVersion.creativeSizes &&
            editDisplayLine.displayLineVersion.creativeSizes !== null) {
            var values = {
                creativeSizes: JSON.parse(editDisplayLine.displayLineVersion.creativeSizes)
            };
            form.setFieldsValue(values);
            console.log(values.creativeSizes)
            setSelectedValues(values.creativeSizes)

        }
        return () => {
            console.log("EditCreativeSize Unmounting");
        };
    }, [editDisplayLine, isUpdateCreativeSizeModalOpen]);

    const handleSave = (values) => {
        values["creativeSizes"] = JSON.stringify(selectedValues);
        dispatch(updateDisplayLine(id, values));
        form.resetFields();
        dispatch(closeUpdateCreativeSizeModal());
    };

    const handleCancel = () => {
        form.resetFields();
        dispatch(closeUpdateCreativeSizeModal());
    };


    const isCreativeSizeExists = (size) => {
        var filtered = selectedValues.filter(function (obj) {
            return JSON.stringify(size) === JSON.stringify(obj);
        });
        return filtered.length > 0 ? true : false;
    };

    function _bindCreativeSizes(sizes) {

        return sizes.map((size, index) => {
            return (
                <Row key={sizes}>
                    <Col md={4}>
                        <Checkbox
                            checked={isCreativeSizeExists(size)}
                            onChange={onCreativeSizeChange}
                            key={sizes}
                            value={size}
                        >
                            {size.width+"x"+size.height}
                        </Checkbox>
                    </Col>
                </Row>
            );
        });
    }

    const onCreativeSizeChange = (e) => {
        console.log(e.target.value)
        const selectValuesTemp = selectedValues;
        var filtered = selectedValues.filter(function (obj) {
            return JSON.stringify(e.target.value) === JSON.stringify(obj);
        });
        
        if (filtered && filtered.length > 0) {
            selectValuesTemp.splice(selectValuesTemp.indexOf(filtered[0]), 1);
        }
        else {
            selectValuesTemp.push(e.target.value);
        }
        setSelectedValues(selectValuesTemp);
        setIsSet(!isSet);
    };
    return (
        <>
            <Modal
                title={"Creative Sizes"}
                visible={isUpdateCreativeSizeModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form
                    className="new-display-line"
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    layout={"vertical"}
                    onFinish={handleSave}
                >
                    <Form.Item className="creative-box" label="Creative Sizes" name="creativeSizes">
                        <Form.Item name="creativeSizes" label="Select Size">
                            {defaultCreativeSizes && _bindCreativeSizes(defaultCreativeSizes)}
                        </Form.Item>
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );

};

function mapStateToProps(state) {
    return {
        isUpdateCreativeSizeModalOpen: state.line.isUpdateCreativeSizeModalOpen
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditCreativeSize);
