import { Types } from "../constants/account-types";
const initialState = {
  accountList: [],
  isAddAccountModalOpen: false,
  accountAdded: undefined,
  errorMessage: undefined,
  editAccount: undefined,
  getDashboardData: undefined,
  isNew: false
};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload,
      };
    case Types.GET_EDIT_ACCOUNT:
      return {
        ...state,
        editAccount: action.payload,
      };
    case Types.OPEN_ADD_ACCOUNT_MODAL:
      return {
        ...state,
        isAddAccountModalOpen: action.payload,
      };
    case Types.ADD_ACCOUNT:
      return {
        ...state,
        accountAdded: action.payload.accountAdded,
        errorMessage: action.payload.errorMessage
          ? action.payload.errorMessage
          : initialState.errorMessage,
      };
      case Types.LOAD_VIEW_DASHBOARD:
      return {
        ...state,
        getDashboardData: action.payload.data,
        isNew: action.payload.isNew
      };
    default:
      return state;
  }
}
