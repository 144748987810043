export const Types = {
  GET_USER_LIST: "GET_USER_LIST",
  OPEN_ADD_USER_MODAL: "OPEN_ADD_USER_MODAL",
  GET_EDIT_USER: "GET_EDIT_USER",
  GET_LOGGED_IN_USER: "GET_LOGGED_IN_USER",
  ADD_USER: "ADD_USER",
  UPDATE_USER: "UPDATE_USER",
  OPEN_ASSIGN_ACCOUNT_MODAL: "OPEN_ASSIGN_ACCOUNT_MODAL",
  OPEN_ASSIGN_PUBLISHER_MODAL: "OPEN_ASSIGN_PUBLISHER_MODAL",
  CLEAR_EDIT_USER_STATES: "CLEAR_EDIT_USER_STATES",
  GET_USER_LIST_FOR_PRIMARY_CONTACTS: "GET_USER_LIST_FOR_PRIMARY_CONTACTS",
};
