import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select } from "antd";
import {
  closeNewConversionPiggybackPixelModal,
  addConversionPiggybackPixel,
  getAllConversionPixels,
} from "../../../redux/actions/conversion-pixel-actions";
import { piggybackPixelFormats } from "./../../../constants/defaultValues";
const { Option } = Select;
const AddConversionPiggybackPixelModal = ({
  isAddConversionPiggybackPixelModalOpen,
  publisherId,
  conversionPixelList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllConversionPixels());
    return () => {
      console.log("AddConversionPiggybackPixelModal Unmounting");
    };
  }, []);

  const handleSave = (values) => {
    values["publisherId"] = publisherId;
    console.log(values);
    dispatch(addConversionPiggybackPixel(values));
    dispatch(closeNewConversionPiggybackPixelModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewConversionPiggybackPixelModal());
  };

  const formatOptions = piggybackPixelFormats.map((format) => (
    <Option key={format} value={format}>
      {format}
    </Option>
  ));

  const conversionPixelOptions =
    conversionPixelList &&
    conversionPixelList.map((pixel) => (
      <Option key={pixel.conversionPixelId} value={pixel.conversionPixelId}>
        {pixel.name}
      </Option>
    ));

  return (
    <>
      <Modal
        title={"New Piggyback Pixel"}
        visible={isAddConversionPiggybackPixelModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{ conversionPixelId: "", format: "", source: "" }}
          onFinish={handleSave}
        >
          <Form.Item
            label="Pixel"
            name="conversionPixelId"
            rules={[
              {
                required: true,
                message: `Please input pixel!`,
              },
            ]}
          >
            <Select>{conversionPixelOptions}</Select>
          </Form.Item>
          <Form.Item
            label="Format"
            name="format"
            rules={[
              {
                required: true,
                message: `Please input pixel format!`,
              },
            ]}
          >
            <Select>{formatOptions}</Select>
          </Form.Item>
          <Form.Item
            label="Source"
            name="source"
            rules={[
              {
                required: true,
                message: "Please input pixel source!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Add Source" />
          </Form.Item>

          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddConversionPiggybackPixelModalOpen:
      state.conversionPixel.isAddConversionPiggybackPixelModalOpen,
    conversionPixelList: state.conversionPixel.conversionPixelList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewConversionPiggybackPixelModal: () => dispatch(closeNewConversionPiggybackPixelModal()),
    getAllConversionPixels: () => dispatch(getAllConversionPixels()),
    AddConversionPiggybackPixelModal: (values) =>
      dispatch(AddConversionPiggybackPixelModal(values)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddConversionPiggybackPixelModal
);
