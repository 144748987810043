import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Space } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { loadCampaignById } from "../../../redux/actions/campaign-actions";
import {
  Input,
  InputNumber,
  Radio,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Typography,
  Select,
} from "antd";
import {
  closeUpdateTruengageCreativeModal,
  updateTruengageCreative,
} from "./../../../redux/actions/creative-actions";
import moment from "moment";
import {
  getDayStartTime,
  getDayEndTime,
  getLocalDate,
  getUTCDate
} from "../../../utils/appUtils";
import EditVideoCreativeForm from "./../../forms/edit-video-creative-form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TruengagePreview from "./../../TruengagePreview";

const { TextArea } = Input;
const { Title } = Typography;

const flightStartDateOptions = ["Start when line starts", "Custom Start"];
const flightEndDateOptions = ["End when line ends", "Custom End"];
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const EditVideoCreativeModal = ({
  editCreativeInfo,
  isEditTruengageCreativeModalOpen,
  videoLine,
  accountId,
  devices,
  propType,
  creativeType,
  editCampaign,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [flightStartDateType, setFlightStartDateType] = useState("Start when line starts");
  const [flightEndDateType, setFlightEndDateType] = useState("End when line ends");
  const [startDate, setStartDate] = useState(moment(getDayStartTime(), dateFormat));
  const [endDate, setEndDate] = useState(moment(getDayEndTime(), dateFormat));
  const [startAt, setStartAt] = useState(getDayStartTime().getTime());
  const [endAt, setEndAt] = useState(getDayEndTime().getTime());
  const [sourceCode, setSourceCode] = useState("");
  const { id } = useParams();
  const { Option } = Select;

  useEffect(() => {
    (async () => {
      await dispatch(loadCampaignById(editCreativeInfo.videoLine.campaignId));

    })();
    console.log(videoLine);
    if (editCreativeInfo !== undefined) {

      setFlightStartDateType(editCreativeInfo && editCreativeInfo.imageCreativeVersion && editCreativeInfo.imageCreativeVersion.startDateType);
      setFlightEndDateType(editCreativeInfo && editCreativeInfo.imageCreativeVersion && editCreativeInfo.imageCreativeVersion.endDateType);
      if (editCreativeInfo.videoLine.videoLineVersion.startAt === editCreativeInfo.truengageCreativeVersion.startAt) {
        setStartAt(getLocalDate(editCreativeInfo.videoLine.videoLineVersion.startAt));
        setStartDate(moment(new Date(getLocalDate(editCreativeInfo.videoLine.videoLineVersion.startAt)), dateFormat));
      } else {
        setStartAt(getLocalDate(editCreativeInfo.truengageCreativeVersion.startAt));
        setStartDate(moment(new Date(getLocalDate(editCreativeInfo.truengageCreativeVersion.startAt)), dateFormat));
      }
      if (editCreativeInfo.videoLine.videoLineVersion.endAt === editCreativeInfo.truengageCreativeVersion.endAt) {
        setEndAt(getLocalDate(editCreativeInfo.videoLine.videoLineVersion.endAt));
        setEndDate(moment(new Date(getLocalDate(editCreativeInfo.videoLine.videoLineVersion.endAt)), dateFormat));
      } else {
        setEndAt(getLocalDate(editCreativeInfo.truengageCreativeVersion.endAt));
        setEndDate(moment(new Date(getLocalDate(editCreativeInfo.truengageCreativeVersion.endAt)), dateFormat));
      }

      if (editCreativeInfo && editCreativeInfo.truengageCreativeVersion && editCreativeInfo.videoLine) {
        var values = {
          name: editCreativeInfo.truengageCreativeVersion.name !== null ? editCreativeInfo.truengageCreativeVersion.name : "",
          weight: editCreativeInfo.truengageCreativeVersion.weight,
          targetingState: editCreativeInfo.truengageCreativeVersion.targetingState !== null ?
            editCreativeInfo.truengageCreativeVersion.targetingState : "",
          retargetingPixelId: editCreativeInfo.truengageCreativeVersion.retargetingPixelId !== null ?
            editCreativeInfo.truengageCreativeVersion.retargetingPixelId : "",

          duration: editCreativeInfo.truengageCreativeVersion.duration,
          truengageImpressionPixels: editCreativeInfo.truengageCreativeVersion.truengageImpressionPixels !== null
            ? JSON.parse(editCreativeInfo.truengageCreativeVersion.truengageImpressionPixels) : [],
          truengageEventPixels: editCreativeInfo.truengageCreativeVersion.truengageEventPixels !== null
            ? JSON.parse(editCreativeInfo.truengageCreativeVersion.truengageEventPixels) : [],
          source: JSON.stringify(JSON.parse(editCreativeInfo.truengageCreativeVersion.source), undefined, 4),
        }
        form.setFieldsValue(values);
        setSourceCode(editCreativeInfo.truengageCreativeVersion.source);

        const selectValuesTemp = [];
        const selectedDeviceList = JSON.parse(editCreativeInfo.truengageCreativeVersion.devices);
        if (selectedDeviceList && selectedDeviceList.length > 0) {
          selectedDeviceList.filter(function (obj) {
            selectValuesTemp.push(obj);
          });
          setSelectedDevices(selectValuesTemp);
        }
      }
    }

    return () => {
      console.log("EditVideoCreativeModal Unmounting");
    };
  }, [isEditTruengageCreativeModalOpen]);

  const handleSave = (values) => {
    values["devices"] = JSON.stringify(selectedDevices);
    values["startDateType"] = flightStartDateType;
    values["startAt"] = getUTCDate(startAt);
    values["endDateType"] = flightEndDateType;
    values["endAt"] = getUTCDate(endAt);
    if (values["truengageImpressionPixels"] && values["truengageImpressionPixels"] !== null) {
      values["truengageImpressionPixels"] = JSON.stringify(values["truengageImpressionPixels"]);
    }
    if (values["truengageEventPixels"] && values["truengageEventPixels"] !== null) {
      values["truengageEventPixels"] = JSON.stringify(values["truengageEventPixels"]);
    }
    form.resetFields();

    dispatch(updateTruengageCreative(id, values));
    dispatch(closeUpdateTruengageCreativeModal());
    resetFormStates();
  };
  const resetFormStates = () => {
    setFlightStartDateType("Start when line starts");
    setFlightEndDateType("End when line ends");
    setStartDate(moment(getDayStartTime(), dateFormat));
    setEndDate(moment(getDayEndTime(), dateFormat));
    setStartAt(getDayStartTime().getTime());
    setEndAt(getDayEndTime().getTime());
  };

  const [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeFlightDateType,
    onDeviceChange,
    isDeviceExists,
    onChangeSourceCode,
  ] = EditVideoCreativeForm(
    form,
    setStartDate,
    setEndDate,
    startAt,
    endAt,
    setStartAt,
    setEndAt,
    editCreativeInfo.videoLine,
    accountId,
    setFlightStartDateType,
    setFlightEndDateType,
    selectedDevices,
    setSelectedDevices,
    creativeType,
    setSourceCode
  );

  return (
    <>
      {propType !== undefined &&
        <Modal
          title={`Update ${propType}`}
          visible={isEditTruengageCreativeModalOpen}
          onCancel={handleCancel}
          footer={[]}
        >

          <Form form={form} layout={"vertical"} onFinish={handleSave}>
            {propType === "Name" && (
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input creative name!",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea showCount maxLength={255} placeholder="Untitled Creative" autoSize />
              </Form.Item>
            )}

            {propType === "Flight" && (
              <div className="card-cnt-sec grid-col-2">
                <Title level={3}>{"Flight Dates"}</Title>
                <Form.Item label="Start Date" name="flighStartDateType">
                  <Radio.Group
                    name={"startDate"}
                    defaultValue={"Start when line starts"}
                    value={flightStartDateType}
                    onChange={onChangeFlightDateType}
                    options={flightStartDateOptions}
                  />
                  {flightStartDateType === "Start when line starts" ? (
                    ""
                  ) : (
                    <DatePicker
                      defaultValue={startDate}
                      value={startDate}
                      showTime
                      format={dateFormat}
                      onChange={onStartDateChange}
                    />
                  )}
                </Form.Item>
                <Form.Item label="End Date" name="flighEndDateType">
                  <Radio.Group
                    name={"endDate"}
                    defaultValue={"End when line ends"}
                    value={flightEndDateType}
                    onChange={onChangeFlightDateType}
                    options={flightEndDateOptions}
                  />
                  {flightEndDateType === "End when line ends" ? (
                    ""
                  ) : (
                    <DatePicker
                      defaultValue={endDate}
                      value={endDate}
                      showTime
                      format={dateFormat}
                      onChange={onEndDateChange}
                    />
                  )}
                </Form.Item>
              </div>
            )}

            {propType === "Weight" && (
              <Form.Item
                label="Weight"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: "Please input weight!",
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            )}

            {propType === "Duration" && (
              <Form.Item
                label="Duration"
                name="duration"
              >
                <Input />
              </Form.Item>
            )}

            {propType === "Source" && (
              <>
                <Form.Item
                  label="Source"
                  name="source"
                  rules={[
                    {
                      required: true,
                      message: "Please input Source!",
                    },
                  ]}
                >
                  <TextArea
                    rows={10}
                    placeholder="Truengage Source Code"
                    onChange={onChangeSourceCode}
                  />
                </Form.Item>
                <div className="ad-content">
                  <h2>Ad Preview</h2>

                  {sourceCode !== "" ? (
                    <TruengagePreview
                      sourceCode={sourceCode}
                      creativeName={"Untitled Creative"}
                      width={640}
                      height={360}
                      versionURL={"https://assets.xpsads.com/jynx/v2.2.1.js"}
                      truengageCreativeType={"Draggable"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}

            {propType === "Event Pixels" && (
              <Form.Item className="creative-box" label="Event Pixels" name="truengageEventPixels">
                <Form.List name="truengageEventPixels">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "eventName"]}
                            fieldKey={[fieldKey, "eventName"]}
                            rules={[{ required: true, message: "Please input event name!" }]}
                          >
                            <Input placeholder="Event Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "url"]}
                            fieldKey={[fieldKey, "url"]}
                            rules={[{ required: true, message: "Please input url!" }]}
                          >
                            <Input placeholder="Url" style={{ width: "400px" }} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          New Event Pixel
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}

            {propType === "Retargeting Pixel" && (
              <Form.Item
                label="Retargeting Pixel"
                name="retargetingPixelId"
              >
                <Select defaultValue="" style={{ width: '100%' }}
                  value={editCreativeInfo.truengageCreativeVersion.retargetingPixelId ?
                    editCreativeInfo.truengageCreativeVersion.retargetingPixelId : ""}>
                  {editCampaign && editCampaign.assignedRetargetingPixels.map((option) => (
                    (
                      <Option value={option.retargetingPixelId} key={option.retargetingPixelId}>{option.name}</Option>
                    )
                  ))}
                </Select>
              </Form.Item>
            )}

            {propType === "Target State" && (
              <Form.Item
                label="Target State"
                name="targetingState"

              >
                <Input />
              </Form.Item>
            )}

            {propType === "Impression Pixels" && (
              <Form.Item className="creative-box" label="Impression Pixels" name="truengageImpressionPixels">
                <Form.List name="truengageImpressionPixels">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "source"]}
                            fieldKey={[fieldKey, "source"]}
                            rules={[{ required: true, message: "Please input source!" }]}
                          >
                            <Input placeholder="Source" style={{ width: "400px" }} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}

            {propType === "Devices" && (
              <Form.Item label="Devices" name="devices">
                <Row>
                  {devices &&
                    devices.map((device) => (
                      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Checkbox checked={isDeviceExists(device.name)} value={device.name} onChange={onDeviceChange}>
                          {device.name}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Form.Item>
            )}
            <Form.Item className="text-right">
              <Button type="primary" htmlType="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};
function mapStateToProps(state) {
  return {
    isEditTruengageCreativeModalOpen: state.creative.isEditTruengageCreativeModalOpen,
    devices: state.line.devices,
    propType: state.creative.propType,
    editCampaign: state.campaign.editCampaign,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditVideoCreativeModal);
