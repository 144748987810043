export const Types = {
  GET_DISPLAY_PRIVATE_BUY_LIST: "GET_DISPLAY_PRIVATE_BUY_LIST",
  GET_VIDEO_PRIVATE_BUY_LIST: "GET_VIDEO_PRIVATE_BUY_LIST",
  OPEN_ADD_PRIVATE_BUY_MODAL: "OPEN_ADD_PRIVATE_BUY_MODAL",
  ADD_DISPLAY_PRIVATE_BUY: "ADD_DISPLAY_PRIVATE_BUY",
  ADD_VIDEO_PRIVATE_BUY: "ADD_VIDEO_PRIVATE_BUY",
  GET_DISPLAY_PRIVATE_BUYS: "GET_DISPLAY_PRIVATE_BUYS",
  GET_VIDEO_PRIVATE_BUYS: "GET_VIDEO_PRIVATE_BUYS",
  GET_EDIT_DISPLAY_PRIVATE_BUY:"GET_EDIT_DISPLAY_PRIVATE_BUY",
  GET_EDIT_VIDEO_PRIVATE_BUY:"GET_EDIT_VIDEO_PRIVATE_BUY",
  UPDATE_DISPLAY_PRIVATE_BUY: "UPDATE_DISPLAY_PRIVATE_BUY",
  UPDATE_VIDEO_PRIVATE_BUY: "UPDATE_VIDEO_PRIVATE_BUY",
  CLEAR_PRIVATE_BUY_STATES: "CLEAR_PRIVATE_BUY_STATES",
  PRIVATE_BUY_REPORT: "PRIVATE_BUY_REPORT",
  PRIVATE_BUY_OVERVIEW_DATA: "PRIVATE_BUY_OVERVIEW_DATA",
};
