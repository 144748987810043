import { Types } from "./../constants/private-buy-types";
import privateBuyApi from "../api/private-buy-api";

// Load all display private buys
export const loadAllDisplayPrivateBuys = () => async (dispatch) => {
  try {
    await privateBuyApi.loadAllDisplayPrivateBuys().then(
      (data) => {
        dispatch({
          type: Types.GET_DISPLAY_PRIVATE_BUY_LIST,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all video private buys
export const loadAllVideoPrivateBuys = () => async (dispatch) => {
  try {
    await privateBuyApi.loadAllVideoPrivateBuys().then(
      (data) => {
        dispatch({
          type: Types.GET_VIDEO_PRIVATE_BUY_LIST,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all display private buys by displayLineId
export const loadDisplayPrivateBuysByDisplayLineId = (displayLineId) => async (dispatch) => {
  try {
    await privateBuyApi.loadDisplayPrivateBuysByDisplayLineId(displayLineId).then(
      (data) => {
        dispatch({
          type: Types.GET_DISPLAY_PRIVATE_BUYS,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// Load all video private buys by videoLineId
export const loadVideoPrivateBuysByVideoLineId = (videoLineId) => async (dispatch) => {
  try {
    await privateBuyApi.loadVideoPrivateBuysByVideoLineId(videoLineId).then(
      (data) => {
        dispatch({
          type: Types.GET_VIDEO_PRIVATE_BUYS,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// add new display private buys
export const addDisplayPrivateBuy = (displayLineId, privateBuyData) => async (dispatch) => {
  try {
    await privateBuyApi.addDisplayPrivateBuy(displayLineId, privateBuyData).then(
      (data) => {
        if(data && data.message && data.message === "DisplayPrivateBuy added successfully!") {
          dispatch({
            type: Types.ADD_DISPLAY_PRIVATE_BUY,
            payload: { success: true, message: data.message }
          });
        }       
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_DISPLAY_PRIVATE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// add new video private buys
export const addVideoPrivateBuy = (videoLineId, privateBuyData) => async (dispatch) => {
  try {
    await privateBuyApi.addVideoPrivateBuy(videoLineId, privateBuyData).then(
      (data) => {
        if(data && data.message && data.message === "VideoPrivateBuy added successfully!") {
          dispatch({
            type: Types.ADD_VIDEO_PRIVATE_BUY,
            payload: { success: true, message: data.message }
          });
        }       
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_VIDEO_PRIVATE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// update display private buy
export const updateDisplayPrivateBuy = (privateBuyId, privateBuyData) => async (dispatch) => {
  try {
    await privateBuyApi.updateDisplayPrivateBuy(privateBuyId, privateBuyData).then(
      (data) => {
        if(data && data.message && data.message === "DisplayPrivateBuy updated successfully!") {
          dispatch({
            type: Types.UPDATE_DISPLAY_PRIVATE_BUY,
            payload: { success: true, message: data.message }
          });
        }       
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_DISPLAY_PRIVATE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};

// update video private buy
export const updateVideoPrivateBuy = (privateBuyId, privateBuyData) => async (dispatch) => {
  try {
    await privateBuyApi.updateVideoPrivateBuy(privateBuyId, privateBuyData).then(
      (data) => {
        if(data && data.message && data.message === "VideoPrivateBuy updated successfully!") {
          dispatch({
            type: Types.UPDATE_VIDEO_PRIVATE_BUY,
            payload: { success: true, message: data.message }
          });
        }       
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_VIDEO_PRIVATE_BUY,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) { }
};


// get Private buy by id
export const loadDisplayPrivateBuyById = (privateBuyId) => async (dispatch) => {
  try {
    await privateBuyApi.loadDisplayPrivateBuyById(privateBuyId).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_DISPLAY_PRIVATE_BUY,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};


// get Private buy by id
export const loadVideoPrivateBuyById = (privateBuyId) => async (dispatch) => {
  try {
    await privateBuyApi.loadVideoPrivateBuyById(privateBuyId).then(
      (data) => {
        dispatch({
          type: Types.GET_EDIT_VIDEO_PRIVATE_BUY,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load display private buy report data
export const loadDisplayPrivateBuyReport = (data) => async (dispatch) => {
  try {
    await privateBuyApi.loadDisplayPrivateBuyReport(data).then(
      (data) => {
        dispatch({
          type: Types.PRIVATE_BUY_REPORT,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};


// export display private buy report data
export const exportDisplayPrivateBuyReport = (data) => async (dispatch) => {
  try {
    return await privateBuyApi.exportDisplayPrivateBuyReport(data);
  } catch (err) { }
};



// export video private buy report data
export const exportVideoPrivateBuyReport = (data) => async (dispatch) => {
  try {
    return await privateBuyApi.exportVideoPrivateBuyReport(data);
  } catch (err) { }
};


// load video private buy report data
export const loadVideoPrivateBuyReport = (data) => async (dispatch) => {
  try {
    await privateBuyApi.loadVideoPrivateBuyReport(data).then(
      (data) => {
        dispatch({
          type: Types.PRIVATE_BUY_REPORT,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// load private buy overview data
export const loadPrivateBuyOverviewData = (privateBuyId, privateBuyType) => async (dispatch) => {
  try {
    await privateBuyApi.loadPrivateBuyOverviewData(privateBuyId, privateBuyType).then(
      (data) => {
        dispatch({
          type: Types.PRIVATE_BUY_OVERVIEW_DATA,
          payload: data
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) { }
};

// To open add new Private Buy Modal
export const openAddPrivateBuyModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_PRIVATE_BUY_MODAL,
    payload: true,
  });
};

// To close add new Private Buy Modal
export const closeAddPrivateBuyModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_PRIVATE_BUY_MODAL,
    payload: false,
  });
};

// To clear states from redux store
export const clearPrivateBuyReduxState = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_PRIVATE_BUY_STATES,
    payload: undefined,
  });
};
