import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllDisplaySiteBuys = (id) => {
  return AxiosAuth.get(
    Config.API + "/api/displaySiteBuys/getAllDisplaySiteBuys/" + id,
    undefined,
    undefined
  );
};

const loadAllVideoSiteBuys = (id) => {
  return AxiosAuth.get(
    Config.API + "/api/videoSiteBuys/getAllVideoSiteBuys/" + id,
    undefined,
    undefined
  );
};

const allDisplaySiteBuys = () => {
  return AxiosAuth.get(Config.API + "/api/displaySiteBuys/getAllDisplaySiteBuys", undefined, undefined);
};

const allVideoSiteBuys = () => {
  return AxiosAuth.get(Config.API + "/api/videoSiteBuys/getAllVideoSiteBuys", undefined, undefined);
};

const addDisplaySiteBuy = (data, publisherId) => {
  return AxiosAuth.post(
    Config.API + "/api/displaySiteBuys/addDisplaySiteBuy/" + publisherId,
    data,
    undefined
  );
};
const addVideoSiteBuy = (data, publisherId) => {
  return AxiosAuth.post(
    Config.API + "/api/videoSiteBuys/addVideoSiteBuy/" + publisherId,
    data,
    undefined
  );
};

const loadVideoSiteBuyById = (id) => {
  return AxiosAuth.get(
    Config.API + "/api/videoSiteBuys/getVideoSiteBuyById/" + id,
    undefined,
    undefined
  );
};

const loadDisplaySiteBuyById = (id) => {
  return AxiosAuth.get(
    Config.API + "/api/displaySiteBuys/getDisplaySiteBuyById/" + id,
    undefined,
    undefined
  );
};

const editDisplaySiteBuy = (data, siteBuyId) => {
  return AxiosAuth.put(
    Config.API + "/api/displaySiteBuys/editDisplaySiteBuy/" + siteBuyId,
    data,
    undefined
  );
};

const editVideoSiteBuy = (data, siteBuyId) => {
  return AxiosAuth.put(
    Config.API + "/api/videoSiteBuys/editVideoSiteBuy/" + siteBuyId,
    data,
    undefined
  );
};

const loadDisplaySiteBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/displaySiteBuy", data, undefined);
}

const loadVideoSiteBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/videoSiteBuy", data, undefined);
}

const loadSiteBuyOverviewData = (siteBuyId, siteBuyType) => {
  return AxiosAuth.get(Config.API + "/api/overviewTab/siteBuy/" + siteBuyId + "/" + siteBuyType, undefined, undefined);
}

const exportDisplaySiteBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/displaySiteBuy/export", data, undefined, true);
}

const exportVideoSiteBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/videoSiteBuy/export", data, undefined, true);
}

export default {
  loadAllDisplaySiteBuys,
  loadAllVideoSiteBuys,
  allDisplaySiteBuys,
  allVideoSiteBuys,
  addDisplaySiteBuy,
  addVideoSiteBuy,
  loadVideoSiteBuyById,
  loadDisplaySiteBuyById,
  editDisplaySiteBuy,
  editVideoSiteBuy,
  loadDisplaySiteBuyReport,
  loadVideoSiteBuyReport,
  loadSiteBuyOverviewData,
  exportVideoSiteBuyReport,
  exportDisplaySiteBuyReport
};
