import { Types } from "../constants/line-types";

const initialState = {
  isAddLineModalOpen: false,
  lineType: undefined,
  devices: undefined,
  geonames: undefined,
  displayHistory: undefined,
  videoHistory: undefined,
  displayLineAdded: undefined,
  videoLineAdded: undefined,
  displayLineList: undefined,
  videoLineList: undefined,
  editDisplayLine: undefined,
  editVideoLine: undefined,
  isUpdateDeviceModalOpen: false,
  isUpdateGeoNameModalOpen: false,
  displayLineUpdated: undefined,
  isUpdateDeliveryModalOpen: false,
  videoLineUpdated: undefined,
  dmaRegions: undefined,
  isUpdateDmaRegionModalOpen: undefined,
  exelateSegments: undefined,
  isUpdateExelateSegmentsModalOpen: false,
  isUpdateSiteListModalOpen: false,
  lineReport: undefined,
  lineOverviewData: undefined,
  isUpdateCreativeSizeModalOpen: undefined,
  isUpdateClickUrlModalOpen: undefined
};
export default function lineReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ADD_LINE_MODAL:
      return {
        ...state,
        isAddLineModalOpen: action.payload.isAddLineModalOpen,
        lineType: action.payload.lineType,
      };
    case Types.LOAD_DEVICES:
      return {
        ...state,
        devices: action.payload,
      };
    case Types.LOAD_GEONAMES:
      return {
        ...state,
        geonames: action.payload,
      };
    case Types.LOAD_EXELATE_SEGMENTS:
      return {
        ...state,
        exelateSegments: action.payload,
      };
    case Types.LOAD_HISTORY:
      return {
        ...state,
        displayHistory: action.payload,
        videoHistory: action.payload,
      };
    case Types.LOAD_OVERVIEW_DISPLAY_LINE:
      return {
        ...state,
        lineOverviewData: action.payload,
      };
    case Types.LOAD_OVERVIEW_VIDEO_LINE:
      return {
        ...state,
        lineOverviewData: action.payload,
      };
    case Types.ADD_DISPLAY_LINE:
      return {
        ...state,
        displayLineAdded: action.payload,
      };
    case Types.ADD_VIDEO_LINE:
      return {
        ...state,
        videoLineAdded: action.payload,
      };
    case Types.GET_DISPLAY_LINE_LIST:
      return {
        ...state,
        displayLineList: action.payload,
      };
    case Types.GET_VIDEO_LINE_LIST:
      return {
        ...state,
        videoLineList: action.payload,
      };
    case Types.GET_EDIT_DISPLAY_LINE:
      return {
        ...state,
        editDisplayLine: action.payload,
      };
    case Types.GET_EDIT_VIDEO_LINE:
      return {
        ...state,
        editVideoLine: action.payload,
      };
    case Types.OPEN_UPDATE_DEVICE_MODAL:
      return {
        ...state,
        isUpdateDeviceModalOpen: action.payload,
        selectedDeviceList: action.selectedDeviceList,
      };
    case Types.OPEN_UPDATE_SITE_LIST_MODAL:
      return {
        ...state,
        isUpdateSiteListModalOpen: action.payload,
        selectedSiteList: action.selectedSiteList,
        siteLists: action.siteLists,
      };
    case Types.OPEN_UPDATE_GEONAME_MODAL:
      return {
        ...state,
        isUpdateGeoNameModalOpen: action.payload,
        selectedGeoNameList: action.selectedGeoNameList,
      };
    case Types.OPEN_UPDATE_EXELATE_SEGMENTS_MODAL:
      return {
        ...state,
        isUpdateExelateSegmentsModalOpen: action.payload,
        selectedExelateSegmentsList: action.selectedExelateSegmentsList,
      };
    case Types.UPDATE_DISPLAY_LINE:
      return {
        ...state,
        displayLineUpdated: action.payload,
      };
    case Types.UPDATE_VIDEO_LINE:
      return {
        ...state,
        videoLineUpdated: action.payload,
      };
    case Types.OPEN_UPDATE_DELIVERY_MODAL:
      return {
        ...state,
        isUpdateDeliveryModalOpen: action.payload.isUpdateDeliveryModalOpen,
        lineType: action.payload.lineType,
      };
    case Types.LOAD_DMA_REGIONS:
      return {
        ...state,
        dmaRegions: action.payload,
      };
    case Types.OPEN_UPDATE_DMA_REGION_MODAL:
      return {
        ...state,
        isUpdateDmaRegionModalOpen: action.payload,
        selectedDmaRegionList: action.selectedDmaRegionList,
      };
    case Types.CLEAR_DISPLAY_LINE_STATES:
      return {
        ...state,
        displayLineAdded: undefined,
        editDisplayLine: undefined,
        displayLineUpdated: undefined,
        displayHistory: undefined,
      };
    case Types.CLEAR_VIDEO_LINE_STATES:
      return {
        ...state,
        videoLineAdded: undefined,
        editVideoLine: undefined,
        videoLineUpdated: undefined,
        videoHistory: undefined,
      };
    case Types.LOAD_REPORT:
      return {
        ...state,
        lineReport: action.payload,
      };
    case Types.OPEN_UPDATE_CREATIVE_SIZE_MODAL:
      return {
        ...state,
        isUpdateCreativeSizeModalOpen: action.payload,
      };
      case Types.OPEN_UPDATE_CLICK_URL_MODAL:
      return {
        ...state,
        isUpdateClickUrlModalOpen: action.payload,
        creatives: action.creatives,
      };
    default:
      return state;
  }
}
