import { useDispatch } from "react-redux";
import { closeAddCreativeModal, uploadImageResource } from "./../../redux/actions/creative-actions";
import { getDayStartTime, getDayEndTime, getLocalDate } from "../../utils/appUtils";
import moment from "moment";

const DisplayCreativeForm = (
  form,
  setSelectImageResourceId,
  setStartDate,
  setEndDate,
  setStartAt,
  setEndAt,
  accountId,
  displayLineId,
  displayLine,
  setFlightStartDateType,
  setFlightEndDateType,
  selectedDevices,
  setSelectedDevices,
  setCreativeType,
  setSubmitted,
  setIsAddTagDisabled
) => {
  const dispatch = useDispatch();
  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const handleCancel = () => {
    form.resetFields();
    setCreativeType("Image Creative")
    setFlightStartDateType("Start when line starts");
    setFlightEndDateType("End when line ends");
    setStartDate(moment(getDayStartTime(), dateFormat));
    setEndDate(moment(getDayEndTime(), dateFormat));
    setStartAt(getDayStartTime().getTime());
    setEndAt(getDayEndTime().getTime());
    setSelectImageResourceId("");
    setIsAddTagDisabled(false);
    dispatch(closeAddCreativeModal());
  };

  const onStartDateChange = (value, dateString) => {
    console.log(value);
    console.log(dateString);
    setStartDate(value);
    setStartAt(new Date(dateString).getTime());
  };

  const onEndDateChange = (value, dateString) => {
    console.log(value);
    console.log(dateString);
    setEndDate(value);
    setEndAt(new Date(dateString).getTime());
  };

  const onChangeImageSrc = (e, id) => {
    setSelectImageResourceId(id);
  };

  const handleFileChange = async (e) => {
    let fileObj = e.target.files[0];
    if (fileObj.type.indexOf("image/") === -1) {
      alert("Only Images are allowed");
      return;
    }
    setSubmitted(true);
    await dispatch(uploadImageResource(fileObj, accountId, displayLineId));
    setSubmitted(false);
  };

  const onChangeFlightDateType = (e) => {
    if (e.target.name === "startDate") {
      if(e.target.value === "Custom Start"){
         setFlightStartDateType(e.target.value);
         setStartDate(moment(getDayStartTime(), dateFormat));
         setStartAt(getDayStartTime().getTime());
      }else{
        setFlightStartDateType(e.target.value);
        setStartAt(getLocalDate(displayLine.displayLineVersion.startAt));
      }
    } else {
      if(e.target.value === "Custom End"){
        setFlightEndDateType(e.target.value);
        setEndDate(moment(getDayEndTime(), dateFormat));
        setEndAt(getDayEndTime().getTime());
     }else{
       setFlightEndDateType(e.target.value);
       setEndAt(getLocalDate(displayLine.displayLineVersion.endAt));
     }
    }
  };

  const onDeviceChange = (e) => {
    const devices = selectedDevices;
    if (devices.indexOf(e.target.value) > -1) {
      devices.splice(devices.indexOf(e.target.value), 1);
    } else {
      devices.push(e.target.value);
    }
    setSelectedDevices(devices);
  };

  const onChangeCreativeType = (e) => {
    setCreativeType(e.target.value);
  };

  return [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeImageSrc,
    handleFileChange,
    onChangeFlightDateType,
    onDeviceChange,
    onChangeCreativeType,
  ];
};

export default DisplayCreativeForm;
