import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const uploadImageResource = async (file, accountId, displayLineId) => {
  const formData = new FormData();
  formData.append("file", file);
  return AxiosAuth.post(
    Config.API + "/api/imageResources/addImageResource/" + accountId + "/" + displayLineId,
    formData,
    false
  );
};

const loadAllImageResourcesByAccountId = (accountId) => {
  return AxiosAuth.get(
    Config.API + "/api/imageResources/getAllImageResources/" + accountId,
    undefined,
    undefined
  );
};

const addImageCreative = (creativeData) => {
  return AxiosAuth.post(
    Config.API + "/api/imageCreatives/addImageCreative",
    creativeData,
    undefined
  );
};

const loadImageCreativeById = (creativeId) => {
  return AxiosAuth.get(
    Config.API + "/api/imageCreatives/getImageCreative/" + creativeId,
    undefined,
    undefined
  );
};

const uploadVideoResource = async (file, accountId) => {
  const formData = new FormData();
  formData.append("file", file);
  return AxiosAuth.post(
    Config.API + "/api/videoResources/addVideoResource/" + accountId,
    formData,
    false
  );
};

const loadAllVideoResourcesByAccountId = (accountId) => {
  return AxiosAuth.get(
    Config.API + "/api/videoResources/getAllVideoResources/" + accountId,
    undefined,
    undefined
  );
};

const addVideoCreative = (creativeData) => {
  return AxiosAuth.post(
    Config.API + "/api/videoCreatives/addVideoCreative",
    creativeData,
    undefined
  );
};

const addTruengageCreative = (creativeData) => {
  return AxiosAuth.post(
    Config.API + "/api/truengageCreative/addTruengageCreative",
    creativeData,
    undefined
  );
};

const updateDisplayCreative = (creativeId, creativeData) => {
  return AxiosAuth.put(
    Config.API + "/api/imageCreatives/editImageCreative/" + creativeId,
    creativeData,
    undefined
  );
};


const addTagDisplayCreative = (creativeData) => {
  return AxiosAuth.post(
    Config.API + "/api/tagDisplayCreatives/addTagDisplayCreative",
    creativeData,
    undefined
  );
};

const addTagVideoCreative = (creativeData) => {
  return AxiosAuth.post(
    Config.API + "/api/tagVideoCreatives/addTagVideoCreative",
    creativeData,
    undefined
  );
};

const loadTagDisplayCreativeById = (creativeId) => {
  return AxiosAuth.get(
    Config.API + "/api/tagDisplayCreatives/getTagDisplayCreative/" + creativeId,
    undefined,
    undefined
  );
};

const updateTagDisplayCreative = (creativeId, creativeData) => {
  return AxiosAuth.put(
    Config.API + "/api/tagDisplayCreatives/editTagDisplayCreative/" + creativeId,
    creativeData,
    undefined
  );
};

const loadVideoCreativeById = (creativeId) => {
  return AxiosAuth.get(
    Config.API + "/api/videoCreatives/getVideoCreative/" + creativeId,
    undefined,
    undefined
  );
};

const loadTruengageCreativeById = (creativeId) => {
  return AxiosAuth.get(
    Config.API + "/api/truengageCreative/getTruengageCreativeById/" + creativeId,
    undefined,
    undefined
  );
};

const loadTagVideoCreativeById = (creativeId) => {
  return AxiosAuth.get(
    Config.API + "/api/tagVideoCreatives/getTagVideoCreative/" + creativeId,
    undefined,
    undefined
  );
};

const updateVideoCreative = (creativeId, creativeData) => {
  return AxiosAuth.put(
    Config.API + "/api/videoCreatives/editVideoCreative/" + creativeId,
    creativeData,
    undefined
  );
};

const updateTruengageCreative = (creativeId, creativeData) => {
  return AxiosAuth.put(
    Config.API + "/api/truengageCreative/editTruengageCreative/" + creativeId,
    creativeData,
    undefined
  );
};

const updateTagVideoCreative = (creativeId, creativeData) => {
  return AxiosAuth.put(
    Config.API + "/api/tagVideoCreatives/editTagVideoCreative/" + creativeId,
    creativeData,
    undefined
  );
};

export default {
  uploadImageResource,
  loadAllImageResourcesByAccountId,
  addImageCreative,
  loadImageCreativeById,
  uploadVideoResource,
  loadAllVideoResourcesByAccountId,
  updateDisplayCreative,
  addVideoCreative,
  addTruengageCreative,
  addTagDisplayCreative,
  addTagVideoCreative,
  loadTagDisplayCreativeById,
  updateTagDisplayCreative,
  loadVideoCreativeById,
  loadTruengageCreativeById,
  updateTruengageCreative,
  updateVideoCreative,
  loadTagVideoCreativeById,
  updateTagVideoCreative,
};
