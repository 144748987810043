import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import Chart from "react-apexcharts";
import {
  timezones,
  lineReports,
} from "./../../../constants/defaultValues";
import { Select, Form, Row, Col, DatePicker, Table, Tooltip, Card } from "antd";
import moment from "moment";
import { loadDisplayLineReport, exportDisplayLineReport } from "../../../redux/actions/line-actions";
import { DownloadOutlined } from "@ant-design/icons";
import UsaDmaMap from "../../../pages/map/usaDmaMap";
import UsaMap from "../../../pages/map/usaMap";
import { getDayEndDateTime, getDayStartDateTime, camelCaseToString, getUTCDate } from "../../../utils/appUtils";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DisplayLineReporting = ({ lineReport }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  var oneWeekAgo = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
  const [startEndDate, setStartEndDate] = useState([
    moment(oneWeekAgo, dateFormat),
    0,
  ]);
  const [lineSeriesData, setLineSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pieSeriesData, setPieSeriesData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [timezone, setTimezone] = useState("America/Los_Angeles");
  const [graphMetric, setGraphMetric] = useState("impressionUnique");
  const [report, setReport] = useState("Date");
  const [hidden, setHidden] = useState(false);
  const [dmaMapData, setDmaMapData] = useState([]);
  const [lineGraphMetricOptions, setLineGraphMetricOptions] = useState([]);
  const [disableTimezone, setDisableTimezone] = useState(false);
  const { id } = useParams();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    var values = {};
    values = {
      date: startEndDate,
      report: report,
      graphMetric: graphMetric,
      timezone: timezone,
    };
    form.setFieldsValue(values);

    if (lineReport && report) {
      if (lineReport.header) {
        setLineGraphMetricOptions(lineReport.header);
      }
      const data = [];
      const categoriesData = [];
      const mapData = [];
      lineReport && lineReport.data && lineReport.data.map((lineReport) => {

        data.push(lineReport.eventData && lineReport.eventData[graphMetric] && lineReport.eventData[graphMetric] != null ? lineReport.eventData[graphMetric] : 0);

        if (report === "Date" || report === "Device" || report === "Moat") {
          categoriesData.push(lineReport.Id === null ? "unknown" : lineReport.Id);
        }
        if (report === "Creative") {
          categoriesData.push(lineReport.Name === null ? "unknown" : lineReport.Name);
        }
        if (report === "Geo - DMA" || report === "Geo - State") {
          categoriesData.push({ code: (lineReport.Id === null ? "unknown" : lineReport.Id) });
          mapData.push({ code: (lineReport.Id === null ? "unknown" : (typeof lineReport.Id === 'string' ? lineReport.Id : JSON.stringify(lineReport.Id))), graphMetric: graphMetric, value: lineReport.eventData && lineReport.eventData[graphMetric] && lineReport.eventData[graphMetric] != null ? lineReport.eventData[graphMetric] : 0 });
        }
      });
      if (report === "Date" || report === "Moat") {
        setLineSeriesData(data);
        setCategories(categoriesData);
        setHidden(false);
      } else if (report === "Device") {
        setPieSeriesData(data);
        setLabels(categoriesData);
        setHidden(false);
      } else if (report === "Creative") {
        setPieSeriesData(data);
        setLabels(categoriesData);
        setHidden(true);
      } else if (report === "Geo - DMA" || report === "Geo - State") {
        setDmaMapData(JSON.stringify(mapData));
        setHidden(false);
      }
      var columnArray = [];
      if (report === "Creative") {
        columnArray.push({
          title: "Name",
          dataIndex: "Name",
          key: "Name",
          hidden: hidden,
          render: (text) =>
            text && text !== null
              ? text.includes("-")
                ? text.replace("-", "").replace("-", "")
                : text
              : "unknown"

        },
          {
            title: "Type",
            dataIndex: "Type",
            key: "Type",
            hidden: hidden,
            render: (text) =>
              text && text !== null
                ? text.includes("-")
                  ? text.replace("-", "").replace("-", "")
                  : text
                : "unknown"

          });
      } else {
        columnArray.push({
          title: "Id",
          dataIndex: "Id",
          key: "Id",
          hidden: hidden,
          render: (text) =>
            text && text !== null
              ? (typeof text === 'string' ? (text.includes("-") ?
                text.replace("-", "").replace("-", "") : text) : (text !== 0 ? text : "unknown"))
              : "unknown"
        });
      }

      lineReport && lineReport.header && lineReport.header.map((header) => {
        columnArray.push({
          title: camelCaseToString(header),
          dataIndex: header,
          key: header,
          render: (text, record) =>
            (record.eventData && record.eventData[header] && record.eventData[header] !== null && record.eventData[header] !== 0 ? record.eventData[header] : "-")

        });
      });
      setColumns(columnArray);
    }

    return () => {
      console.log("Display Line Reporting unmounting");
    };
  }, [lineReport, graphMetric, report]);

  const lineSeries = [
    {
      name: graphMetric,
      data: lineSeriesData,
    },
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    markers: {
      size: 5,
      colors: "#007bff",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "straight",
      colors: "#007bff",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
    },
  };
  const series = pieSeriesData;
  var options = {
    chart: {
      width: 650,
      type: "pie",
    },
    labels: labels,
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
  };

  const loadReportData = (dateValue, reportValue, timezoneValue) => {
    if (reportValue === "Moat") {
      setGraphMetric("impressions_analyzed");
    } else {
      setGraphMetric("impressionUnique");
    }

    let data = {};
    // data["startDate"] = dateValue && dateValue !== null && dateValue[0] ? getDayStartDateTime(new Date(dateValue[0])) : getDayStartDateTime(new Date());
    // data["endDate"] =
    //   dateValue && dateValue !== null && dateValue[1]
    //     ? getDayEndDateTime(new Date(dateValue[1]))
    //     : getDayEndDateTime(new Date());

    data["startDate"] = dateValue && dateValue !== null && dateValue[0] ? new Date(getUTCDate(getDayStartDateTime(new Date(dateValue[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    data["endDate"] =
      dateValue && dateValue !== null && dateValue[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(dateValue[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["report"] = reportValue;
    data["id"] = id;
    data["timezone"] = timezoneValue;
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(loadDisplayLineReport(data));
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const lineReportOptions = lineReports.map((report) => (
    <Option key={report} value={report}>
      {report}
    </Option>
  ));

  const graphMetricOptions = (lineGraphMetricOptions && lineGraphMetricOptions.map((metric) => (
    <Option key={metric} value={metric}>
      {metric}
    </Option>
  )));

  const onDateChange = (value, dateString) => {
    setStartEndDate(value);
    loadReportData(value, report, timezone);
  };

  const onReportChange = (value) => {
    setReport(value);
    setLineSeriesData([]);
    setCategories([]);
    setPieSeriesData([]);
    setLabels([]);
    if(value === "Moat") {
      setTimezone("America/New_York");
      setDisableTimezone(true);
    } else {
      setTimezone("America/Los_Angeles");
      setDisableTimezone(false);
    }
    loadReportData(startEndDate, value, timezone);
  };

  const onTimezoneChange = (value) => {
    setTimezone(value);
    loadReportData(startEndDate, report, value);
  };

  const onGraphMetricChange = (value) => {
    setGraphMetric(value);
  };

  const exportTableReport = async () => {
    let data = {};

    // data["startDate"] = startEndDate && startEndDate !== null && startEndDate[0] ? getDayStartDateTime(new Date(startEndDate[0])) : getDayStartDateTime(new Date());
    // data["endDate"] =
    //   startEndDate && startEndDate !== null && startEndDate[1]
    //     ? getDayEndDateTime(new Date(startEndDate[1]))
    //     : getDayEndDateTime(new Date());

    data["startDate"] = startEndDate && startEndDate !== null && startEndDate[0] ? new Date(getUTCDate(getDayStartDateTime(new Date(startEndDate[0])))).toUTCString() : new Date(getUTCDate(getDayStartDateTime(new Date()))).toUTCString();
    data["endDate"] =
      startEndDate && startEndDate !== null && startEndDate[1]
        ? new Date(getUTCDate(getDayEndDateTime(new Date(startEndDate[1])))).toUTCString()
        : new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["report"] = report;
    data["id"] = id;
    data["timezone"] = timezone;
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;;
    var response = await dispatch(exportDisplayLineReport(data));
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));

    a.download = "Display" + report + ".xlsx";

    document.body.appendChild(a)
    a.click();
    document.body.removeChild(a)
  }

  return (
    <>
      <Form form={form} layout={"vertical"}>
        <Card>
          <Row gutter={24}>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Report" name="report">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onReportChange}
                >
                  {lineReportOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Graph Metric" name="graphMetric">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onGraphMetricChange}
                >
                  {graphMetricOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={5} lg={5} xl={5}>
              <Form.Item label="Timezone" name="timezone">
                <Select
                  disabled={disableTimezone}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={onTimezoneChange}
                >
                  {timezonesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10} sm={10} md={6} lg={6} xl={6}>
              <Form.Item label="Date" name="date">
                <RangePicker format={dateFormat} onChange={onDateChange} />
              </Form.Item>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={3}
              lg={3}
              xl={3}
              className="upload-right"
              style={{ display: "block" }}
            >
              <Tooltip placement="top" title="Download Table">
                <DownloadOutlined onClick={exportTableReport} />
              </Tooltip>
            </Col>
          </Row>
        </Card>
      </Form>
      <Card key={report + "Chart"}>
        {/* {console.log(" series: ")} */}
        {lineReport && lineReport.data && lineReport.data.length === 0 ? (
          <h1 style={{ textAlign: "center", color: "black" }}>No Data</h1>
        ) : report && (report === "Date" || report === "Moat") ? (
          <Chart
            options={lineOptions}
            series={lineSeries}
            type="line"
            height={350}
          />
        ) : report && (report === "Device" || report === "Creative") ? (
          <>
            <Chart
              options={options}
              series={series}
              type="pie"
              style={{ padding: 0, width: "400px", margin: "auto" }}
            />
          </>
        ) : report && report === "Geo - DMA" ? (
          <div className="fusioncenter">
            <UsaDmaMap selectedDmaRegions={dmaMapData} />
          </div>
        ) : report && report === "Geo - State" ? (
          <div className="fusioncenter">
            <UsaMap selectedDmaRegions={dmaMapData} />
          </div>
        ) : (
          ""
        )}
      </Card>
      <Card >
        <Table  rowKey="Id" 
          scroll={{ x: 1200 }}
          columns={columns}
          dataSource={lineReport && lineReport.data}
        />
      </Card>
    </>
  );
};

function mapStateToProps(state) {
  return {
    lineReport: state.line.lineReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DisplayLineReporting
);
