import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input } from "antd";
import { closeNewAccountModal, addAccount } from "./../../../redux/actions/account-actions";
import { clearEditPublisherStates } from "./../../../redux/actions/publisher-actions";

const { TextArea } = Input;
const AddAccountModal = ({ isAddAccountModalOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [form] = Form.useForm();

  const handleSave = () => {
    dispatch(addAccount(name));
    dispatch(closeNewAccountModal());
    setName("");
    form.resetFields();
    dispatch(clearEditPublisherStates());
  };

  const handleCancel = () => {
    setName("");
    form.resetFields();
    dispatch(closeNewAccountModal());
  };

  const onChange = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <Modal
        title="New Account"
        visible={isAddAccountModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="Name"
            rules={[
              {
                required: true,
                message: "Please input account name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              onChange={onChange}
              placeholder="Add Account Name"
              autoSize
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddAccountModalOpen: state.account.isAddAccountModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewAccountModal: () => dispatch(closeNewAccountModal()),
    addAccount: (name) => dispatch(addAccount(name)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddAccountModal);
