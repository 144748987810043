import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select } from "antd";
import { timezones, displayAdServers, videoAdServers } from "./../../../constants/defaultValues";
import { closeNewPublisherModal, addPublisher, updatePublisher } from "./../../../redux/actions/publisher-actions";
import { clearEditAccountStates } from "./../../../redux/actions/account-actions";

const { TextArea } = Input;
const { Option } = Select;
const AddPublisherModal = ({ isAddPublisherModalOpen, editInfo }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isNewPublisher, setIsNewPublisher] = useState(true);

  useEffect(() => {
    if (editInfo !== undefined) {
      setIsNewPublisher(false);
      var values = {
        name: editInfo.name,
        displayAdServer: editInfo.displayAdServer,
        videoAdServer: editInfo.videoAdServer,
        timezone: editInfo.timezone,
      }
      form.setFieldsValue(values);
    }
  }, [editInfo]);

  const handleSave = (data) => {
    if (isNewPublisher) {
      dispatch(addPublisher(data));
    } else {
      data["isAccountsUpdated"] = false;
      dispatch(updatePublisher(editInfo.publisherId, data));
    }

    dispatch(clearEditAccountStates());
    form.resetFields();
    dispatch(closeNewPublisherModal());
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const displayAdServerOptions = displayAdServers.map((selected) => (
    <Option key={selected} value={selected}>
      {selected}
    </Option>
  ));

  const videoAdServerOptions = videoAdServers.map((selected) => (
    <Option key={selected} value={selected}>
      {selected}
    </Option>
  ));

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewPublisherModal());
  };

  return (
    <>
      <Modal
        title={isNewPublisher ? "New Publisher" : "Update Publisher"}
        visible={isAddPublisherModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            initialValue={!isNewPublisher ? editInfo && editInfo.name : ""}
            rules={[
              {
                required: true,
                message: "Please input publisher name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              placeholder="Add Publisher Name"
              autoSize
            />
          </Form.Item>
          <Form.Item
            label="Display Ad Server"
            name="displayAdServer"
            initialValue={!isNewPublisher ? editInfo && editInfo.displayAdServer : ""}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {displayAdServerOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label="Video Ad Server"
            name="videoAdServer"
            initialValue={!isNewPublisher ? editInfo && editInfo.videoAdServer : ""}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {videoAdServerOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label="Timezone"
            name="timezone"
            initialValue={!isNewPublisher ? editInfo && editInfo.timezone : ""}
          >
            <Select
              showSearch
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {timezonesOptions}
            </Select>
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {isNewPublisher ? "Save" : "Update"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddPublisherModalOpen: state.publisher.isAddPublisherModalOpen,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewPublisherModal: () => dispatch(closeNewPublisherModal()),
    addPublisher: () => dispatch(addPublisher()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddPublisherModal);
