export const Types = {
  OPEN_ADD_LINE_MODAL: "OPEN_ADD_LINE_MODAL",
  LOAD_DEVICES: "LOAD_DEVICES",
  LOAD_GEONAMES: "LOAD_GEONAMES",
  ADD_DISPLAY_LINE: "ADD_DISPLAY_LINE",
  GET_DISPLAY_LINE_LIST: "GET_DISPLAY_LINE_LIST",
  GET_VIDEO_LINE_LIST: "GET_VIDEO_LINE_LIST",
  ADD_VIDEO_LINE: "ADD_VIDEO_LINE",
  GET_EDIT_DISPLAY_LINE: "GET_EDIT_DISPLAY_LINE",
  GET_EDIT_VIDEO_LINE: "GET_EDIT_VIDEO_LINE",
  OPEN_UPDATE_DEVICE_MODAL: "OPEN_UPDATE_DEVICE_MODAL",
  OPEN_UPDATE_GEONAME_MODAL: "OPEN_UPDATE_GEONAME_MODAL",
  UPDATE_DISPLAY_LINE: "UPDATE_DISPLAY_LINE",
  UPDATE_VIDEO_LINE: "UPDATE_VIDEO_LINE",
  OPEN_UPDATE_DELIVERY_MODAL: "OPEN_UPDATE_DELIVERY_MODAL",
  LOAD_DMA_REGIONS: "LOAD_DMA_REGIONS",
  OPEN_UPDATE_DMA_REGION_MODAL: "OPEN_UPDATE_DMA_REGION_MODAL",
  CLEAR_DISPLAY_LINE_STATES: "CLEAR_DISPLAY_LINE_STATES",
  CLEAR_VIDEO_LINE_STATES: "CLEAR_VIDEO_LINE_STATES",
  LOAD_HISTORY: "LOAD_HISTORY",
  LOAD_EXELATE_SEGMENTS: "LOAD_EXELATE_SEGMENTS",
  OPEN_UPDATE_EXELATE_SEGMENTS_MODAL: "OPEN_UPDATE_EXELATE_SEGMENTS_MODAL",
  OPEN_UPDATE_SITE_LIST_MODAL: "OPEN_UPDATE_SITE_LIST_MODAL",
  LOAD_REPORT: "LOAD_REPORT",
  LOAD_OVERVIEW_DISPLAY_LINE: "LOAD_OVERVIEW_DISPLAY_LINE",
  LOAD_OVERVIEW_VIDEO_LINE: "LOAD_OVERVIEW_VIDEO_LINE",
  OPEN_UPDATE_CREATIVE_SIZE_MODAL: "OPEN_UPDATE_CREATIVE_SIZE_MODAL",
  OPEN_UPDATE_CLICK_URL_MODAL: "OPEN_UPDATE_CLICK_URL_MODAL",
};
