import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input } from "antd";
import { closeNewSegmentModal, addSegment,updateSegment } from "../../../redux/actions/segment-actions";

const { TextArea } = Input;
const AddPk4SegmentModal = ({ isAddSegmentModalOpen, segmentAdded,editSegmentInfo }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isNewSegment, setIsNewSegment] = useState(true);

  useEffect(() => {
    console.log(editSegmentInfo);
    if (editSegmentInfo !== undefined) {
      setIsNewSegment(false);
      var values = {
        name: editSegmentInfo && editSegmentInfo.name ? editSegmentInfo.name : "",
      };
      form.setFieldsValue(values);
    }
    return () => {
      console.log("AddCampaignModal Unmounting");
    };
  }, [isAddSegmentModalOpen, form, editSegmentInfo]);

  const handleSave = (values) => {
    if(isNewSegment){
      dispatch(addSegment(values));
    } else {
      dispatch(updateSegment(editSegmentInfo.segmentId,values));
    }
    dispatch(closeNewSegmentModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewSegmentModal());
  };


  return (
    <>
      <Modal
        title={isNewSegment ? "New Segment" : "Update Segment"}
        visible={isAddSegmentModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={255}
              placeholder="Add Name"
              autoSize
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddSegmentModalOpen: state.pk4Segment.isAddSegmentModalOpen,
    segmentAdded: state.pk4Segment.segmentAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewSegmentModal: () => dispatch(closeNewSegmentModal()),
    addSegment: (name) => dispatch(addSegment(name)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddPk4SegmentModal);
