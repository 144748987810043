import { Types } from "../constants/publisher-types";
const initialState = {
  publisherList: [],
  isAddPublisherModalOpen: false,
  publisherAdded: undefined,
  editPublisher: undefined,
  accountType: undefined,
  isBlacklistWhitelistAccountModalOpen: false,
  publisherUpdated: undefined,
  getPublisherDashboardData: undefined,
  isNew: false
};
export default function publisherReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PUBLISHER_LIST:
      return {
        ...state,
        publisherList: action.payload,
      };
    case Types.GET_EDIT_PUBLISHER:
      return {
        ...state,
        editPublisher: action.payload,
      };
    case Types.OPEN_ADD_PUBLISHER_MODAL:
      return {
        ...state,
        isAddPublisherModalOpen: action.payload,
      };
    case Types.ADD_PUBLISHER:
      return {
        ...state,
        publisherAdded: action.payload,
      };
    case Types.OPEN_BLACKLIST_WHITELIST_ACCOUNT_MODAL:
      return {
        ...state,
        isBlacklistWhitelistAccountModalOpen: action.payload.status,
        accountType: action.payload.accountType,
        selectedAccountList: action.payload.selectedAccountList,
      };
    case Types.UPDATE_PUBLISHER:
      return {
        ...state,
        publisherUpdated: action.payload,
      };
    case Types.CLEAR_EDIT_PUBLISHER_STATES:
      return {
        ...state,
        publisherAdded: undefined,
        editPublisher: undefined,
        accountType: undefined,
        publisherUpdated: undefined,
        selectedAccountList: undefined,
      };
      case Types.LOAD_PUBLISHER_VIEW_DASHBOARD:
      return {
        ...state,
        getPublisherDashboardData: action.payload.data,
        isNew: action.payload.isNew
      };
    default:
      return state;
  }
}
