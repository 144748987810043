import { Types } from "../constants/retargeting-pixei-types";
const initialState = {
  isAddRetargetingPixelModalOpen: false,
  retargetingPixelAdded: undefined,
  retargetingPixelUpdated: undefined,
  errorMessage: undefined,
  isAddRetargetingPiggybackPixelModalOpen: false,
  retargetingPiggybackPixelAdded: undefined,
  retargetingPixelList: undefined,
};
export default function retargetingPixelReducer(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ADD_RETARGETING_PIXEL_MODAL:
      return {
        ...state,
        isAddRetargetingPixelModalOpen: action.payload.isModalOpen,
        pixel: action.payload.pixel
      };
    case Types.ADD_RETARGETING_PIXEL:
      return {
        ...state,
        retargetingPixelAdded: action.payload.retargetingPixelAdded,
      };

    case Types.UPDATE_RETARGETING_PIXEL:
      return {
        ...state,
        retargetingPixelUpdated: action.payload.retargetingPixelUpdated,
      };
    case Types.OPEN_ADD_RETARGETING_PIGGYBACK_PIXEL_MODAL:
      return {
        ...state,
        isAddRetargetingPiggybackPixelModalOpen: action.payload,
      };
    case Types.ADD_RETARGETING_PIGGYBACK_PIXEL:
      return {
        ...state,
        retargetingPiggybackPixelAdded: action.payload.retargetingPiggybackPixelAdded,
      };
    case Types.GET_ALL_RETARGETING_PIXELS:
      return {
        ...state,
        retargetingPixelList: action.payload,
      };
    case Types.CLEAR_RETARGETING_PIXEL_STATES:
      return {
        ...state,
        retargetingPixelAdded: undefined,
        retargetingPixelUpdated: undefined,
        errorMessage: undefined,
        retargetingPiggybackPixelAdded: undefined,
        retargetingPixelList: undefined,
      };
    default:
      return state;
  }
}
