import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import {
  List,
  Avatar,
  Row,
  Col,
  Breadcrumb,
  Button,
  PageHeader,
  Modal,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography
} from "antd";
import {
  loadCampaignById,
  openNewCampaignModal,
  openBlacklistWhitelistPublisherModal,
  openBlacklistWhitelistPrivateBuyModal,
  openBlacklistWhitelistSiteBuyModal,
  openAddCampaignCategoryModal,
  openAddCampaignPixelModal,
  updateCampaign,
  clearEditCampaignStates,
} from "./../../redux/actions/campaign-actions";
import { clearPrimaryContactStates, openPrimaryContactsModal } from "../../redux/actions/primary-contacts-actions";
import { openExportReportModal, clearExportReportStates } from "../../redux/actions/export-report-actions";
import AddCampaignModal from "./../../components/modals/campaign/add-campaign";
import { notifyUser } from "./../../services/notification-service";
import {
  openNewLineModal,
  loadAllDevices,
  loadAllGeoNames,
  loadAllExelateSegments,
  clearEditDisplayLineStates,
  clearEditVideoLineStates
} from "./../../redux/actions/line-actions";
import AddLineModal from "./../../components/modals/line/add-line";
import AddCampaignCategoryModal from "./../../components/modals/campaign/add-campaign-category";
import { runningStatus } from "./../../constants/defaultValues";
import { checkRunningStatus, getLocalDate } from "./../../utils/appUtils";
import moment from "moment";
import CampaignBlacklistPublisherModal from "../../components/modals/campaign-blacklist-publisher/campaign-blacklist-publisher";
import { loadPublishers } from "../../redux/actions/publisher-actions";
import CampaignBlacklistPrivateBuyModal from "../../components/modals/campaign-blacklist-private-buys/campaign-blacklist-private-buys";
import CampaignBlacklistSiteBuyModal from "../../components/modals/campaign-blacklist-site-buys/campaign-blacklist-site-buys";
import CampaignPixelModal from "../../components/modals/campaign-pixel/add-campaign-pixel";
import {
  loadAllVideoPrivateBuys,
  loadAllDisplayPrivateBuys,
} from "../../redux/actions/private-buy-actions";
import {
  allVideoSiteBuys,
  allDisplaySiteBuys,
} from "../../redux/actions/site-buy-actions";
import { pixelUrl } from "../../constants/defaultValues";
import AddSiteListModal from "../../components/modals/site-list/add-site-list";
import { openAddSiteListModal, clearSiteListStates } from "../../redux/actions/site-list-actions";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";
import EditExportReportModal from "../../components/modals/export-report/edit-export-report";
import PrimaryContactsModal from '../../components/modals/campaign/primary-contacts';
const { Text, Title } = Typography;

const EditCampaign = ({
  editCampaign,
  campaignUpdated,
  displayLineAdded,
  videoLineAdded,
  publisherList,
  displayPrivateBuyList,
  videoPrivateBuyList,
  displaySiteBuyList,
  videoSiteBuyList,
  siteListAdded,
  reportExported,
  primaryContactUpdated,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isPaddingEdit, setIsPaddingEdit] = useState(false);
  const [padding, setPadding] = useState(null);
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const oldDisplayLines = [];
  const recentDisplayLines = [];
  const oldVideoLines = [];
  const recentVideoLines = [];
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  const [viewOldDisplayLine, setViewOldDisplayLine] = useState(false);
  const [viewOldVideoLine, setViewOldVideoLine] = useState(false);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadCampaignById(id));
      await dispatch(loadAllDevices());
      await dispatch(loadAllGeoNames());
      await dispatch(loadAllExelateSegments());
      await dispatch(loadPublishers());
      await dispatch(loadAllDisplayPrivateBuys());
      await dispatch(loadAllVideoPrivateBuys());
      await dispatch(allDisplaySiteBuys());
      await dispatch(allVideoSiteBuys());
      if (isMounted) {

        setSubmitted(false);
      }
    })();

    if (editCampaign && editCampaign.padding) {
      var values = { padding: editCampaign.padding };
      form.setFieldsValue(values);
      setPadding(editCampaign.padding);
    }

    if (primaryContactUpdated !== undefined) {
      if (primaryContactUpdated.success === true) {
        notifyUser(primaryContactUpdated.message, "success");
      } else {
        notifyUser(primaryContactUpdated.message, "error");
      }
    }

    if (campaignUpdated !== undefined) {
      if (campaignUpdated.success === true) {
        notifyUser(campaignUpdated.message, "success");
      } else {
        notifyUser(campaignUpdated.message, "error");
      }
    }
    if (displayLineAdded !== undefined) {
      if (displayLineAdded.success === true) {
        notifyUser(displayLineAdded.message, "success");
      } else {
        notifyUser(displayLineAdded.message, "error");
      }
    }
    if (videoLineAdded !== undefined) {
      if (videoLineAdded.success === true) {
        notifyUser(videoLineAdded.message, "success");
      } else {
        notifyUser(videoLineAdded.message, "error");
      }
    }
    if (siteListAdded !== undefined) {
      if (siteListAdded.success === true) {
        notifyUser(siteListAdded.message, "success");
      } else {
        notifyUser(siteListAdded.message, "error");
      }
    }
    if (reportExported !== undefined) {
      console.log("reportExported----", reportExported)
      if (reportExported.success === true) {
        notifyUser(reportExported.message, "success");
      } else {
        notifyUser(reportExported.message, "error");
      }
    }
    return () => {
      isMounted = false;

      console.log("EditCampaign Unmounting");
      dispatch(clearEditCampaignStates());
      dispatch(clearEditDisplayLineStates());
      dispatch(clearEditVideoLineStates());
      dispatch(clearSiteListStates());
      dispatch(clearExportReportStates());
      dispatch(clearPrimaryContactStates());
    };
  }, [campaignUpdated, displayLineAdded, videoLineAdded, siteListAdded, reportExported, primaryContactUpdated]);

  if (editCampaign && editCampaign.displayLines && editCampaign.displayLines.length > 0) {
    editCampaign.displayLines.map((line, index) => {
      if (line && line.displayLineVersion && line.displayLineVersion.age && line.displayLineVersion.age === "RECENT") {
        recentDisplayLines.push(line);
      } else {
        oldDisplayLines.push(line);
      }
    });
  }

  if (editCampaign && editCampaign.videoLines && editCampaign.videoLines.length > 0) {
    editCampaign.videoLines.map((line, index) => {
      if (line && line.videoLineVersion && line.videoLineVersion.age && line.videoLineVersion.age === "RECENT") {
        recentVideoLines.push(line);
      } else {
        oldVideoLines.push(line);
      }
    });
  }

  const handleSave = (values) => {
    var payload = {};
    payload["isDisplayPrivateBuysUpdated"] = false;
    payload["isVideoPrivateBuysUpdated"] = false;
    payload["isDisplaySiteBuysUpdated"] = false;
    payload["isVideoSiteBuysUpdated"] = false;
    payload["isPublishersUpdated"] = false;
    payload["isRetargetingPixelsUpdated"] = false;
    payload["isConversionPixelsUpdated"] = false;
    payload["padding"] = padding;
    console.log(payload);
    dispatch(updateCampaign(editCampaign.campaignId, payload));
    form.resetFields();
    setPadding(padding);
    setIsPaddingEdit(!isPaddingEdit);
  };

  const onPaddingChange = (value) => {
    console.log("Checked !" + value);
    setPadding(value);
  };

  function _bindLineList(lineType, lineList) {
    // const header =
    //   lineList.length > 1
    //     ? lineList.length + " " + lineType + " Lines"
    //     : lineList.length + " " + lineType + " Line";

    return (
      <List
        bordered
        dataSource={lineList}
        renderItem={(line) => (
          <List.Item className="list-style-n">
            {lineType === "Video" ? (
              <Link to={"/lines/video/" + line.videoLineId}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: checkRunningStatus(line.videoLineVersion.runningState) }}
                      size="large"
                    ></Avatar>
                  }
                  description={
                    <>
                      <ul >
                        <li className="no-list-style">{line.videoLineVersion.name}</li>
                        <li className="no-list-style">${line.videoLineVersion.rate + " " + line.videoLineVersion.rateType}</li>
                        <li className="no-list-style">
                          {moment.unix(getLocalDate(line.videoLineVersion.startAt) / 1000).format("DD MMM YYYY HH:mm")} {" - "}
                          {moment.unix(getLocalDate(line.videoLineVersion.endAt) / 1000).format("DD MMM YYYY HH:mm")}
                        </li>
                      </ul>
                    </>
                  }
                />
              </Link>
            ) : (
              <Link to={"/lines/display/" + line.displayLineId}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: checkRunningStatus(line.displayLineVersion.runningState) }}
                      size="large"
                    ></Avatar>
                  }
                  description={
                    <>
                      <ul className="no-list-style">
                        <li className="no-list-style">{line.displayLineVersion.name}</li>
                        <li className="no-list-style">${line.displayLineVersion.rate + " " + line.displayLineVersion.rateType}</li>
                        <li className="no-list-style">
                          {moment.unix(getLocalDate(line.displayLineVersion.startAt) / 1000).format("DD MMM YYYY HH:mm")} {" - "}
                          {moment.unix(getLocalDate(line.displayLineVersion.endAt) / 1000).format("DD MMM YYYY HH:mm")}
                        </li>
                      </ul>
                    </>
                  }
                />
              </Link>
            )}
          </List.Item>
        )}
      />
    );
  }

  function _bindPublishersList(publisherType, publisherList) {
    const header =
      publisherList.length > 1
        ? publisherList.length + " " + publisherType + " Publishers"
        : publisherList.length + " " + publisherType + " Publisher";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={publisherList}
            renderItem={(publisher) => (
              <List.Item key={publisher.publisherId}>
                {publisher.name}
              </List.Item>
            )}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCampaignBlacklistWhiteListProp"] ? (
                <div>
                  {publisherType && (
                    <Button
                      onClick={() =>
                        dispatch(openBlacklistWhitelistPublisherModal(publisherType, publisherList))
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
    );
  }

  function _bindDisplayBuysList(displayBuyType, updateType, displayBuyList) {
    const header =
      displayBuyList.length > 1
        ? displayBuyList.length + " " + updateType + " " + displayBuyType + " Private Buys"
        : displayBuyList.length + " " + updateType + " " + displayBuyType + " Private Buy";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={displayBuyList}
            renderItem={(displayBuy) => (
              <List.Item key={displayBuy.displayPrivateBuyId}>
                {displayBuy.name}
              </List.Item>
            )}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCampaignBlacklistWhiteListProp"] ? (
                <div>
                  {displayBuyType && (
                    <Button
                      onClick={() =>
                        dispatch(
                          openBlacklistWhitelistPrivateBuyModal(
                            displayBuyType,
                            updateType,
                            displayBuyList
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
    );
  }

  function _bindVideoBuysList(videoBuyType, updateType, videoBuyList) {
    const header =
      videoBuyList.length > 1
        ? videoBuyList.length + " " + updateType + " " + videoBuyType + " Private Buys"
        : videoBuyList.length + " " + updateType + " " + videoBuyType + " Private Buy";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={videoBuyList}
            renderItem={(videoBuy) => (
              <List.Item key={videoBuy.videoPrivateBuyId}>
                {videoBuy.name}
              </List.Item>
            )}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCampaignBlacklistWhiteListProp"] ? (
                <div>
                  {videoBuyType && (
                    <Button
                      onClick={() =>
                        dispatch(
                          openBlacklistWhitelistPrivateBuyModal(
                            videoBuyType,
                            updateType,
                            videoBuyList
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
    );
  }

  function _bindDisplaySiteBuysList(displayBuyType, updateType, displaySiteBuyList) {
    const header =
      displaySiteBuyList.length > 1
        ? displaySiteBuyList.length + " " + updateType + " " + displayBuyType + " Site Buys"
        : displaySiteBuyList.length + " " + updateType + " " + displayBuyType + " Site Buy";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={displaySiteBuyList}
            renderItem={(displaySiteBuy) => (
              <List.Item key={displaySiteBuy.displaySiteBuyId}>
                {displaySiteBuy.name}
              </List.Item>
            )}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCampaignBlacklistWhiteListProp"] ? (
                <div>
                  {displayBuyType && (
                    <Button
                      onClick={() =>
                        dispatch(
                          openBlacklistWhitelistSiteBuyModal(
                            displayBuyType,
                            updateType,
                            displaySiteBuyList
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
    );
  }

  function _bindVideoSiteBuysList(videoBuyType, updateType, videoSiteBuyList) {
    const header =
      videoSiteBuyList.length > 1
        ? videoSiteBuyList.length + " " + updateType + " " + videoBuyType + " Site Buys"
        : videoSiteBuyList.length + " " + updateType + " " + videoBuyType + " Site Buy";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={videoSiteBuyList}
            renderItem={(videoSiteBuy) => (
              <List.Item key={videoSiteBuy.videoSiteBuyId}>
                {videoSiteBuy.name}
              </List.Item>
            )}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCampaignBlacklistWhiteListProp"] ? (
                <div>
                  {videoBuyType && (
                    <Button
                      onClick={() =>
                        dispatch(
                          openBlacklistWhitelistSiteBuyModal(
                            videoBuyType,
                            updateType,
                            videoSiteBuyList
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
          />
        </Col>
      </Row>
    );
  }

  function _bindPixelList(pixelType, pixelList, selectedPixelList) {
    const header =
      selectedPixelList.length > 1
        ? selectedPixelList.length + " Assigned " + pixelType + " Pixels"
        : selectedPixelList.length + " Assigned " + pixelType + " Pixel";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              currentUserPermissionMap[currentUserRole]["canCreateRetargetingConversionPixel"] ? (
                <div>
                  {pixelType === "Conversion" ? (
                    <Button
                      onClick={() =>
                        dispatch(openAddCampaignPixelModal(selectedPixelList, pixelList, pixelType))
                      }
                      type="link"
                    >
                      {"Add " + pixelType + " Pixel"}
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        dispatch(openAddCampaignPixelModal(selectedPixelList, pixelList, pixelType))
                      }
                      type="link"
                    >
                      {"Add " + pixelType + " Pixel"}
                    </Button>
                  )}
                </div>
              ) : ("")
            }
            bordered
            dataSource={selectedPixelList}
            renderItem={(pixel) => (
              <Link onClick={() => pixelInfo(pixel, pixelType)}>
                <List.Item key={pixel.Id}>
                  {pixel.name}
                </List.Item>
              </Link>
            )}
          />
        </Col>
      </Row>
    );
  }

  function pixelInfo(pixel, pixelType) {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <div className="card-body">
          <Form layout={"vertical"}>
            {pixelType === "Conversion" ? (
              <>
                <Form.Item label="Post-Impression TTL (days)">
                  <Input defaultValue={pixel.postClickTtl} />
                </Form.Item>
                <Form.Item label="Post-Click TTL (days)">
                  <Input defaultValue={pixel.postImpressionTtl} />
                </Form.Item>
                <Form.Item label="Script">
                  <Input
                    defaultValue={
                      '<script src="' + pixelUrl.conversion_url + pixel.conversionPixelId + '.js" ></script>'
                    }
                  />
                </Form.Item>
                <Form.Item label="Image">
                  <Input
                    disabled={true}
                    defaultValue={
                      '<img src="' +
                      pixelUrl.conversion_url +
                      pixel.conversionPixelId +
                      '.gif" width="0" height="0" style="display: hidden;">'
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item label="Retargeting TTL (days)">
                  <Input defaultValue={pixel.retargetingTtl} disabled={true} />
                </Form.Item>
                <Form.Item label="Script">
                  <Input
                    disabled={true}
                    defaultValue={
                      '<script src="' + pixelUrl.retargeting_url + pixel.retargetingPixelId + '" ></script>'
                    }
                  />
                </Form.Item>
                <Form.Item label="Image">
                  <Input
                    disabled={true}
                    defaultValue={
                      '<img src="' +
                      pixelUrl.retargeting_url +
                      pixel.retargetingPixelId +
                      '" width="0" height="0" style="display: hidden;">'
                    }
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      ),
      onOk() { },
    });
  }

  function _bindCampaignProps(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType === "Padding" ? (
                !isPaddingEdit ? (
                  <ul id={propType}>
                    <li className="no-list-style">
                      {propValue && propValue !== null ? propValue + "%" : "Not Specified"}
                    </li>
                  </ul>
                ) : (
                  <Form
                    form={form}
                    layout={"vertical"}
                    initialValues={{ padding: padding }}
                    onFinish={handleSave}
                  >
                    <Form.Item className="card-space" name="padding">
                      <InputNumber
                        onChange={onPaddingChange}
                        placeholder="Padding"
                        style={{ width: "200px" }}
                      />
                    </Form.Item>
                    <div className="card-footer">
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => {
                          setIsPaddingEdit(!isPaddingEdit);
                          setPadding(propValue);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit" onClick={handleSave}>
                        Save
                      </Button>
                    </div>
                  </Form>
                )
              ) : propType !== "Primary Contacts" ? (
                <ul id={propType}>
                  <li className="no-list-style">
                    {propValue && propValue !== null ? propValue : "Not Specified"}
                  </li>
                </ul>
              ) : (
                <ul id={propType}>
                  <li className="no-list-style">
                    <Title level={5}>
                      {editCampaign && editCampaign.primaryContact && editCampaign.primaryContact[0] && editCampaign.primaryContact[0].accountManager ?
                        (editCampaign.primaryContact[0].accountManager.firstName ? editCampaign.primaryContact[0].accountManager.firstName : "")
                        + " " +
                        (editCampaign.primaryContact[0].accountManager.lastName ? editCampaign.primaryContact[0].accountManager.lastName : "") : "N/A"}
                    </Title>
                    <Text type="secondary">Account Manager</Text></li>
                  <br />
                  <li className="no-list-style"><Title level={5}>
                    {editCampaign && editCampaign.primaryContact && editCampaign.primaryContact[0] && editCampaign.primaryContact[0].accountExecutive ?
                      (editCampaign.primaryContact[0].accountExecutive.firstName ? editCampaign.primaryContact[0].accountExecutive.firstName : "")
                      + " " +
                      (editCampaign.primaryContact[0].accountExecutive.lastName ? editCampaign.primaryContact[0].accountExecutive.lastName : "") : "N/A"}
                  </Title>
                    <Text type="secondary">Account Executive</Text></li>
                </ul>
              )
              }
              {propType === "Padding" ? (
                !isPaddingEdit ? (
                  <div className="card-footer">
                    <Button type="link" onClick={() => setIsPaddingEdit(!isPaddingEdit)}>
                      Edit
                    </Button>
                  </div>
                ) : (
                  ""
                )
              ) : propType === "Primary Contacts" ? (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openPrimaryContactsModal())}>
                    Edit
                  </Button>
                </div>
              ) : (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openNewCampaignModal())}>
                    Edit
                  </Button>
                </div>
              )
              }
            </div>
          </div>
        </Col>
      </Row >
    );
  }

  return (
    <section className="white-box-wrapper">
      <Spin size={"large"} spinning={submitted}></Spin>

      <Row className="align-center">
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/accounts/${editCampaign && editCampaign.account.accountId}`}>
                Account
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/campaigns/${id}`}>Campaign</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <PageHeader
            className="site-page-header justify-right edit-block"
            title={editCampaign && editCampaign.name}
            subTitle={currentUserPermissionMap[currentUserRole]["canUpdateCampaign"] ? (<i className="fas fa-edit" onClick={() => dispatch(openNewCampaignModal())}></i>) : ""}
          />
        </Col>
      </Row>

      <Row className="border-bottom align-center">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PageHeader
            className="site-page-header"
            title={editCampaign && editCampaign.account.name}
          />
        </Col>
        <Col className="text-right" xs={24} sm={24} md={12} lg={12} xl={12}>
          <Button className="btn-style" type="primary"
            onClick={() =>
              dispatch(
                openExportReportModal()
              )
            }
          >
            Export Report
          </Button>
        </Col>
      </Row>
      {
        <Row gutter={30}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              className="status-sign"
              grid={{ gutter: 12, column: 6 }}
              dataSource={runningStatus}
              renderItem={(type) => (
                <List.Item>
                  <Avatar style={{ backgroundColor: type.color }} size="large"></Avatar>
                  <span style={{ margin: "15px" }}>{type.name}</span>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      }

      {editCampaign ? (
        <>
          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole]["canAccessCampaignCategories"] ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <List
                  header={
                    (editCampaign && editCampaign.categories && editCampaign.categories.length > 0 ? editCampaign.categories.length > 0 ? (editCampaign.categories.length + " Categories") : "0 Category" : "") +
                    " Categories"
                  }
                  footer={
                    <div>
                      <Button onClick={() => dispatch(openAddCampaignCategoryModal())} type="link">
                        Edit Campaign Categories
                      </Button>
                    </div>
                  }
                  bordered
                  dataSource={editCampaign && editCampaign.categories ? editCampaign.categories : []}
                  renderItem={(category) =>
                    (<List.Item>{category.name}</List.Item>)}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>


          {/* {editCampaign && _bindLineList("Display", editCampaign.displayLines)}
          {editCampaign && _bindLineList("Video", editCampaign.videoLines)} */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="white-box old_line">
                <div className="title">
                  <h2>
                    {editCampaign && editCampaign.displayLines.length > 1
                      ? editCampaign.displayLines.length + " Display Lines"
                      : editCampaign.displayLines.length + " Display Line"}
                  </h2>
                </div>
                <div className="card-body">
                  <ul id="recentDisplayLines" className="display-hover">
                    {recentDisplayLines && recentDisplayLines.length > 0 ? _bindLineList("Display", recentDisplayLines) : <span className="leftSpace">There is no recent Display Line</span>}
                  </ul>
                  {editCampaign && oldDisplayLines && oldDisplayLines.length > 0 ? <span
                    className="view-campaign"
                    onClick={() => setViewOldDisplayLine(!viewOldDisplayLine)}
                  >
                    {editCampaign && oldDisplayLines && oldDisplayLines.length > 1
                      ? oldDisplayLines.length + " Old Display Lines"
                      : oldDisplayLines.length + " Old Display Line"}
                  </span> : ""}
                  {viewOldDisplayLine ? (
                    <ul id="oldDisplayLine">{oldDisplayLines ? _bindLineList("Display", oldDisplayLines) : ""}</ul>
                  ) : (
                    ""
                  )}
                </div>
                {currentUserPermissionMap[currentUserRole]["canCreateLine"] ? (
                  <div className="card-footer">
                    <Button type="link" onClick={() => dispatch(openNewLineModal("Display"))}>
                      {"New Display Line"}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="white-box old_line">
                <div className="title">
                  <h2>
                    {editCampaign && editCampaign.videoLines.length > 1
                      ? editCampaign.videoLines.length + " Video Lines"
                      : editCampaign.videoLines.length + " Video Line"}
                  </h2>
                </div>
                <div className="card-body">
                  <ul id="recentVideoLines" className="display-hover">
                    {recentVideoLines && recentVideoLines.length > 0 ? _bindLineList("Video", recentVideoLines) : <span className="leftSpace">There is no recent Video Line</span>}
                  </ul>
                  {editCampaign && oldVideoLines && oldVideoLines.length > 0 ? <span
                    className="view-campaign"
                    onClick={() => setViewOldVideoLine(!viewOldVideoLine)}
                  >
                    {editCampaign && oldVideoLines && oldVideoLines.length > 1
                      ? oldVideoLines.length + " Old Video Lines"
                      : oldVideoLines.length + " Old Video Line"}
                  </span> : ""}
                  {viewOldVideoLine ? (
                    <ul id="oldVideoLine">{oldVideoLines ? _bindLineList("Video", oldVideoLines) : ""}</ul>
                  ) : (
                    ""
                  )}
                </div>
                {currentUserPermissionMap[currentUserRole]["canCreateLine"] ? (
                  <div className="card-footer">
                    <Button type="link" onClick={() => dispatch(openNewLineModal("Video"))}>
                      {"New Video Line"}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole]["canAccessCampaignSiteList"] ? (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <List
                  header={
                    editCampaign.siteLists && editCampaign.siteLists.length > 1
                      ? editCampaign.siteLists.length + " Site Lists"
                      : editCampaign.siteLists.length + " Site List"
                  }
                  footer={
                    <div>
                      <Button onClick={() => dispatch(openAddSiteListModal())} type="link">
                        New Site List
                      </Button>
                    </div>
                  }
                  bordered
                  dataSource={editCampaign && editCampaign.siteLists}
                  renderItem={(siteList) => (
                    <List.Item>
                      <Link to={`/site-list/${siteList.siteListId}`}>
                        <ul>
                          <li>{siteList.name}</li>
                          <li>{siteList.sites.length + " sites"}</li>
                        </ul>
                      </Link>
                    </List.Item>
                  )}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>


          {editCampaign.whitelistPublishers &&
            _bindPublishersList("Whitelist", editCampaign.whitelistPublishers)}
          {editCampaign.blacklistPublishers &&
            _bindPublishersList("Blacklist", editCampaign.blacklistPublishers)}

          {editCampaign.whitelistDisplayPrivateBuys &&
            _bindDisplayBuysList("Display", "Whitelist", editCampaign.whitelistDisplayPrivateBuys)}
          {editCampaign.blacklistDisplayPrivateBuys &&
            _bindDisplayBuysList("Display", "Blacklist", editCampaign.blacklistDisplayPrivateBuys)}

          {editCampaign.whitelistVideoPrivateBuys &&
            _bindVideoBuysList("Video", "Whitelist", editCampaign.whitelistVideoPrivateBuys)}
          {editCampaign.blacklistVideoPrivateBuys &&
            _bindVideoBuysList("Video", "Blacklist", editCampaign.blacklistVideoPrivateBuys)}

          {editCampaign.whitelistDisplaySiteBuys &&
            _bindDisplaySiteBuysList("Display", "Whitelist", editCampaign.whitelistDisplaySiteBuys)}
          {editCampaign.blacklistDisplaySiteBuys &&
            _bindDisplaySiteBuysList("Display", "Blacklist", editCampaign.blacklistDisplaySiteBuys)}

          {editCampaign.whitelistVideoSiteBuys &&
            _bindVideoSiteBuysList("Video", "Whitelist", editCampaign.whitelistVideoSiteBuys)}
          {editCampaign.blacklistVideoSiteBuys &&
            _bindVideoSiteBuysList("Video", "Blacklist", editCampaign.blacklistVideoSiteBuys)}

          {currentUserPermissionMap[currentUserRole]["canAccessRetargetingPixel"] ? (
            editCampaign.account &&
            editCampaign.account.retargetingPixels &&
            editCampaign.assignedRetargetingPixels &&
            _bindPixelList(
              "Retargeting",
              editCampaign.account.retargetingPixels,
              editCampaign.assignedRetargetingPixels
            )
          ) : (
            ""
          )}
          {currentUserPermissionMap[currentUserRole]["canAccessConversionPixel"] ? (
            editCampaign.account &&
            editCampaign.account.conversionPixels &&
            editCampaign.assignedConversionPixels &&
            _bindPixelList(
              "Conversion",
              editCampaign.account.conversionPixels,
              editCampaign.assignedConversionPixels
            )
          ) : (
            ""
          )}
          {currentUserPermissionMap[currentUserRole]["canAccessCampaignPadding"] ? (
            editCampaign && _bindCampaignProps("Padding", editCampaign.padding)
          ) : (
            ""
          )}
          {currentUserPermissionMap[currentUserRole]["canAccessCampaignTimezone"] ? (
            editCampaign && _bindCampaignProps("Timezone", editCampaign.timezone)
          ) : (
            ""
          )}
          {currentUserPermissionMap[currentUserRole]["canAccessCampaignTimezone"] ? (
            editCampaign && _bindCampaignProps("Primary Contacts", editCampaign.timezone)
          ) : (
            ""
          )}

        </>
      ) : (
        ""
      )}

      <AddCampaignModal editCampaignInfo={editCampaign} />
      <AddLineModal editLineInfo={undefined} />
      <CampaignBlacklistPublisherModal publisherList={publisherList} />
      <CampaignBlacklistPrivateBuyModal
        displayPrivateBuyList={displayPrivateBuyList}
        videoPrivateBuyList={videoPrivateBuyList}
      />
      <CampaignBlacklistSiteBuyModal
        displaySiteBuyList={displaySiteBuyList}
        videoSiteBuyList={videoSiteBuyList}
      />
      <AddCampaignCategoryModal selectedCategories={editCampaign && editCampaign.categories} />
      <CampaignPixelModal />
      <AddSiteListModal />
      <EditExportReportModal type="campaign" />
      <PrimaryContactsModal editCampaignInfo={editCampaign && editCampaign} />
    </section>
  );
};

function mapStateToProps(state) {
  return {
    editCampaign: state.campaign.editCampaign,
    campaignUpdated: state.campaign.campaignUpdated,
    displayLineAdded: state.line.displayLineAdded,
    videoLineAdded: state.line.videoLineAdded,
    publisherList: state.publisher.publisherList,
    displayPrivateBuyList: state.privateBuy.displayPrivateBuyList,
    videoPrivateBuyList: state.privateBuy.videoPrivateBuyList,
    displaySiteBuyList: state.siteBuy.displaySiteBuyList,
    videoSiteBuyList: state.siteBuy.videoSiteBuyList,
    siteListAdded: state.siteList.siteListAdded,
    reportExported: state.exportReport.reportExported,
    primaryContactUpdated: state.primaryContacts.primaryContactAdded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCampaignById: (id) => dispatch(loadCampaignById(id)),
    openPrimaryContactsModal: () => dispatch(openPrimaryContactsModal()),
    openNewCampaignModal: () => dispatch(openNewCampaignModal()),
    loadAllGeoNames: () => dispatch(loadAllGeoNames()),
    loadAllExelateSegments: () => dispatch(loadAllExelateSegments()),
    loadAllDevices: () => dispatch(loadAllDevices()),
    loadPublishers: () => dispatch(loadPublishers()),
    openBlacklistWhitelistPublisherModal: () => dispatch(openBlacklistWhitelistPublisherModal()),
    openBlacklistWhitelistPrivateBuyModal: () => dispatch(openBlacklistWhitelistPrivateBuyModal()),
    openBlacklistWhitelistSiteBuyModal: () => dispatch(openBlacklistWhitelistSiteBuyModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditCampaign);
