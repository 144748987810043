import { Types } from "./../constants/retargeting-pixei-types";
import retargetingPixelApi from "../api/retargeting-pixel-api";

// Add new Retargeting Pixel
export const addRetargetingPixel = (pixelData) => async (dispatch) => {
  try {
    await retargetingPixelApi.addRetargetingPixel(pixelData).then(
      (data) => {
        if (data && data.message && data.message === "Retargeting Pixel added successfully!")
          dispatch({
            type: Types.ADD_RETARGETING_PIXEL,
            payload: {
              retargetingPixelAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_RETARGETING_PIXEL,
            payload: {
              retargetingPixelAdded: { success: false, message: error.response.data.message },
            },
          });
        }
      }
    );
  } catch (err) { }
};

export const updateRetargetingPixels = (id, payload) => async (dispatch) => {
  try {
    await retargetingPixelApi.updateRetargetingPixel(id, payload).then(
      (data) => {
        if (data && data.message && data.message === "Retargeting Pixel updated successfully!") {
          dispatch({
            type: Types.UPDATE_RETARGETING_PIXEL,
            payload: { retargetingPixelUpdated: { success: true, message: data.message } },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_RETARGETING_PIXEL,
            payload: { retargetingPixelUpdated: { success: false, message: error.response.data.message } },
          });
        }
      }
    );
  } catch (err) { }
};

// Add new Retargeting Piggyback Pixel
export const addRetargetingPiggybackPixel = (pixelData) => async (dispatch) => {
  try {
    await retargetingPixelApi.addRetargetingPiggybackPixel(pixelData).then(
      (data) => {
        if (data && data.message && data.message === "Retargeting Piggyback Pixel added successfully!")
          dispatch({
            type: Types.ADD_RETARGETING_PIGGYBACK_PIXEL,
            payload: {
              retargetingPiggybackPixelAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_RETARGETING_PIGGYBACK_PIXEL,
            payload: {
              retargetingPiggybackPixelAdded: { success: false, message: error.response.data.message },
            },
          });
        }
      }
    );
  } catch (err) { }
};

// get all Retargeting Pixels by AccountId
export const getAllRetargetingPixels = () => async (dispatch) => {
  try {
    await retargetingPixelApi.getAllRetargetingPixels().then((data) => {
      dispatch({
        type: Types.GET_ALL_RETARGETING_PIXELS,
        payload: data,
      });
    });
  } catch (err) { }
};

// To open add new Retargeting Pixel Modal
export const openNewRetargetingPixelModal = (pixel) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_RETARGETING_PIXEL_MODAL,
    payload: { isModalOpen: true, pixel: pixel }
  });
};

// To close add new Retargeting Pixel Modal
export const closeNewRetargetingPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_RETARGETING_PIXEL_MODAL,
    payload: false,
  });
};

// To open add new Retargeting Piggyback Pixel Modal
export const openNewRetargetingPiggybackPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_RETARGETING_PIGGYBACK_PIXEL_MODAL,
    payload: true,
  });
};

// To close add new Retargeting Piggyback Pixel Modal
export const closeNewRetargetingPiggybackPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_RETARGETING_PIGGYBACK_PIXEL_MODAL,
    payload: false,
  });
};

// To clear states from redux store
export const clearRetartgetingPixelStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_RETARGETING_PIXEL_STATES,
    payload: undefined,
  });
};
