import Login from "../pages/login/login";
import Dashboard from "../pages/dashboard/dashboard";
import EditAccountComponent from "../pages/account/edit-account";
import EditPublisherComponent from "../pages/publisher/edit-publisher";
import EditCampaignComponent from "../pages/campaign/edit-campaign";
import UserList from "../pages/user/user-list";
import EditUserComponent from "../pages/user/edit-user";
import PageNotFoundError from "../pages/error/404";
import UnauthorizedError from "../pages/error/403";
import BackendServerError from "../pages/error/500";
import EditDisplaySiteBuy from "../pages/site-buy/edit-display-site-buy";
import EditVideoSiteBuy from "../pages/site-buy/edit-video-site-buy";
import EditDisplayLine from "../pages/line/edit-display-line";
import EditPrivateBuy from "../pages/private-buy/edit-private-buy";
import EditVideoLine from "../pages/line/edit-video-line";
import TruengagePreview from "../pages/truengage-preview/truengage-preview-frame"
import EditDisplayCreative from "../pages/creative/edit-display-creative";
import Pk4SegmentList from "../pages/segments/segments-list";
import EditVideoCreative from "../pages/creative/edit-video-creative";
import EditPk4SegmentComponent from "../pages/segments/edit-segment";
import EditSiteList from "../pages/site-list/edit-site-list";
import EditAccountDashboard from "../pages/dashboard/account-dashboard";
import EditPublisherDashboard from "../pages/dashboard/publisher-dashboard";
import ForecastingDashboard from "../pages/forecasting/forecasting-dashboard";

export default {
  routes: [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
  ],
  privateRoutes: [
    {
      path: "/",
      component: Dashboard,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER', 'ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/accounts/:id",
      component: EditAccountComponent,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_MANAGER_LEAD', 'ROLE_ACCOUNT_OBSERVER'
      ,'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/account-dashboard",
      component: EditAccountDashboard,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_MANAGER_LEAD', 'ROLE_ACCOUNT_OBSERVER'
      ,'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/campaigns/:id",
      component: EditCampaignComponent,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_MANAGER_LEAD','ROLE_ACCOUNT_OBSERVER',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/pk4segments",
      component: Pk4SegmentList,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_MANAGER_LEAD', 'ROLE_PARTNER_DEVELOPMENT',
      'ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/pk4segments/:id",
      component: EditPk4SegmentComponent,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_MANAGER_LEAD','ROLE_PARTNER_DEVELOPMENT',
      'ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/publishers/:id",
      component: EditPublisherComponent,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/publisher-dashboard",
      component: EditPublisherDashboard,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/forecasting",
      component: ForecastingDashboard,
      exact: true,
      roles: ['ROLE_ADMIN']
    },
    {
      path: "/users",
      component: UserList,
      exact: true,
      roles: ['ROLE_ADMIN']
    },
    {
      path: "/edit-users/:id",
      component: EditUserComponent,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER', 'ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/siteBuys/display-site-buy/:id",
      component: EditDisplaySiteBuy,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/siteBuys/video-site-buy/:id",
      component: EditVideoSiteBuy,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/lines/display/:id",
      component: EditDisplayLine,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/lines/video/:id",
      component: EditVideoLine,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/privateBuys/:buyType/:id",
      component: EditPrivateBuy,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD','ROLE_PUBLISHER_OBSERVER']
    },
    {
      path: "/truengage_preview",
      component: TruengagePreview,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER']
    },
    {
      path: "/creatives/:creativeType/:id",
      component: EditDisplayCreative,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/creatives/video/:videoCreativeType/:id",
      component: EditVideoCreative,
      exact: true,
      roles: ['ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER','ROLE_ACCOUNT_OBSERVER','ROLE_ACCOUNT_MANAGER_LEAD',
      'ROLE_PARTNER_DEVELOPMENT','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "/site-list/:id",
      component: EditSiteList,
      exact: true,
      roles: ['ROLE_ADMIN','ROLE_PARTNER_DEVELOPMENT_LEAD','ROLE_TRAFFICKING_LEAD']
    },
    {
      path: "*",
      component: PageNotFoundError,
      exact: true,
    },
    {
      path: "/notfound",
      component: PageNotFoundError,
      exact: true,
    },
    {
      path: "/unauthorized",
      component: UnauthorizedError,
      exact: true,
    },
    {
      path: "/server-error",
      component: BackendServerError,
      exact: true,
    },
  ],
  redirects: [
    {
      from: "*",
      to: "404",
      status: 301,
    },
  ],
};
