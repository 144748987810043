import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { closeUpdateGeoNameModal, updateDisplayLine, updateVideoLine } from "../../../redux/actions/line-actions";

const { Option } = Select;

const EditGeoname = ({ lineType, selectedGeoNameList, geonames, isUpdateGeoNameModalOpen }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { id } = useParams();
    const [result, setResult] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        const selectValuesTemp = [];
        if (selectedGeoNameList && selectedGeoNameList.length > 0) {
            selectedGeoNameList.filter(function (obj) {
                selectValuesTemp.push(obj.name);
            });
            setSelectedValues(selectValuesTemp);
        }
        return () => {
            console.log("EditGeoname Unmounting");
        };
    }, [selectedGeoNameList, isUpdateGeoNameModalOpen, geonames]);

    const handleSave = (values) => {
        console.log(values);
        let selectedGeoNames = [];
        if (values){
            values.geoNames.map((value)=>
            geonames && geonames.map((geoName)=>
            geoName.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
            ? selectedGeoNames.push({name: geoName.name, geoNameId:geoName.geoNameId})
            : ""
            )
            );
        }
        
        values["geoNames"] = JSON.stringify(selectedGeoNames);
        if (lineType === "display") {
            dispatch(updateDisplayLine(id, values));
        } else {
            dispatch(updateVideoLine(id, values));
        }
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateGeoNameModal());
    };

    const handleCancel = () => {
        setResult([]);
        setSelectedValues([]);
        form.resetFields();
        dispatch(closeUpdateGeoNameModal());
    };

    const handleSearch = (value) => {
        let res = [];
        if (value) {
            geonames &&
                geonames.map((geoOption) =>
                    geoOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
                        ? res.push(geoOption)
                        : ""
                );
        }
        setResult(res);
    };

    return (
        <>
            <Modal
                title="Geo - Country/US States"
                visible={isUpdateGeoNameModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Form form={form} layout={"vertical"} onFinish={handleSave}>
                    <Form.Item label="Add Geo - Country/US States" name="geoNames">
                        <Select
                            mode="multiple"
                            dropdownStyle={{ display: result.length > 0 ? "" : "none" }}
                            onSearch={handleSearch}
                            key={selectedValues}
                            defaultValue={selectedValues}
                        >
                            {result.map((geoName) => (
                                <Option key={geoName.name}>{geoName.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item className="text-right">
                        <Button type="primary" htmlType="button" onClick={handleCancel}>
                            Cancel
                </Button>
                        <Button type="primary" htmlType="submit">
                            Save
                </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

function mapStateToProps(state) {
    return {
        geonames: state.line.geonames,
        isUpdateGeoNameModalOpen: state.line.isUpdateGeoNameModalOpen,
        selectedGeoNameList: state.line.selectedGeoNameList,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditGeoname);
