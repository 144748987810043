import forecastingApi from "../api/forecasting-api";
import { Types } from "../constants/forecasting-types";

export const loadForecastingData = (date, isNew) => async (dispatch) => {
    try {
     return await forecastingApi.loadForecastingData(date).then(
        (data) => {
          dispatch({
            type: Types.LOAD_FORECASTING_DATA,
            payload: { data: data, isNew: isNew }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (err) { }
  };

  export const exportForecastingData = (values) => async (dispatch) => {
    try {
      return await forecastingApi.exportForecastingData(values);
    } catch (err) { }
  };