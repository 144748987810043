import * as R from "ramda";

export function fontSizeArray() {
  var sizeArray = [];
  for (var i = 1; i <= 40; i++) {
    sizeArray.push(i);
  }
  return sizeArray;
}

export function checkUserRole(userRoles) {
  if (R.includes({ name: "ROLE_ADMIN" }, userRoles)) {
    return "ROLE_ADMIN";
  } else if (R.includes({ name: "ROLE_PARTNER_DEVELOPMENT" }, userRoles)) {
    return "ROLE_PARTNER_DEVELOPMENT";
  } else if (R.includes({ name: "ROLE_PUBLISHER_OBSERVER" }, userRoles)) {
    return "ROLE_PUBLISHER_OBSERVER";
  } else if (R.includes({ name: "ROLE_ACCOUNT_OBSERVER" }, userRoles)) {
    return "ROLE_ACCOUNT_OBSERVER";
  } else if (R.includes({ name: "ROLE_ACCOUNT_MANAGER" }, userRoles)) {
    return "ROLE_ACCOUNT_MANAGER";
  } else if (R.includes({ name: "ROLE_ACCOUNT_MANAGER_LEAD" }, userRoles)) {
    return "ROLE_ACCOUNT_MANAGER_LEAD";
  } else if (R.includes({ name: "ROLE_TRAFFICKING_LEAD" }, userRoles)) {
    return "ROLE_TRAFFICKING_LEAD";
  } else if (R.includes({ name: "ROLE_PARTNER_DEVELOPMENT_LEAD" }, userRoles)) {
    return "ROLE_PARTNER_DEVELOPMENT_LEAD";
  } else if (R.includes({ name: "ROLE_OBSERVER" }, userRoles)) {
    return "ROLE_OBSERVER";
  } else {
    return "ROLE_ACCOUNT_MANAGER";
  }
}

export function checkTimezone(timezone) {
  if (timezone === "UTC") {
    return "UTC";
  } else if (timezone === "American_Los_Angeles") {
    return "American_Los_Angeles";
  } else if (timezone === "American_New_York") {
    return "American_New_York";
  } else {
    return "American_Chicago";
  }
}

export function checkRunningStatus(runningStatus) {
  if (runningStatus === "PENDING") {
    return "rgb(255, 213, 79)";
  } else if (runningStatus === "ACTIVE") {
    return "rgb(129, 199, 132)";
  } else if (runningStatus === "INACTIVE") {
    return "rgb(229, 115, 115)";
  } else {
    return "rgb(79, 195, 247)";
  }
}

export function getDayStartTime() {
  var date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  //let diff = date.getTimezoneOffset() * 60000;
  //var utcDate = date.getTime() - (diff);
  return date;
}

export function getDayEndTime() {
  var date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(0);
  //let diff = date.getTimezoneOffset() * 60000;
  //var utcDate = date.getTime() - (diff);
  return date;
}

export function setImagePath(imageResource) {
  return (
    // "https://s3." +
    // imageResource.region +
    // ".amazonaws.com/" +
    // "/image-resources/" +
    "https://storage.googleapis.com/"+
    imageResource.bucket +"/"+
    imageResource.fileName+
    "?authuser=1"
  );
}

export function setVideoPath(videoResource) {
  return (
    // "https://s3." +
    // videoResource.region +
    // ".amazonaws.com/" +
    // videoResource.bucket +
    // "/video-resources/" +
    // videoResource.fileName

    "https://storage.googleapis.com/"+
    videoResource.bucket+"/"+
    // "/video-resources/"+
    videoResource.fileName+
    "?authuser=1"
  );
}

export function generateColorCode(prefix, length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  console.log(prefix + result);
  return prefix + result;
}

export const creativeWidthSizes = {
  728: [90],
  160: [600],
  300: [600, 250],
  970: [90, 250],
  200: [200],
  250: [250],
  320: [100, 50, 50],
}

export const creativeHeightSizes = {
  90: [728, 970],
  600: [160, 300],
  250: [970, 300, 250],
  200: [200],
  100: [320],
  50: [320]
}

export const defaultCreativeSizes = [
  { width: 300, height: 600 }, { width: 300, height: 250 }, { width: 728, height: 90 }, { width: 160, height: 600 }, { width: 970, height: 90 }, { width: 970, height: 250 }, { width: 200, height: 200 }, { width: 250, height: 250 }, { width: 320, height: 100 }, { width: 320, height: 50 }, { width: 300, height: 50 }, { width: 300, height: 200 }
]

export function isFloat(n) {
  return n === +n && n !== (n | 0);
}

export function isInteger(n) {
  return n === +n && n === (n | 0);
}

export function getDayStartDateTime(date) {
  var month = date.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = date.getDate();
  day = day > 9 ? day : "0" + day;
  var dateStr = date.getFullYear() + "-" + month + "-" + day;
  var timeStr = "00:00:00";
  var result = dateStr + " " + timeStr;
  return new Date(result).getTime();
}


export function getDayEndDateTime(date) {
  var month = date.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = date.getDate();
  day = day > 9 ? day : "0" + day;
  var dateStr = date.getFullYear() + "-" + month + "-" + day;
  var timeStr = "23:59:59";
  var result = dateStr + " " + timeStr;
  return new Date(result).getTime();
}

export function getUTCDate(longValue) {
  // var date = new Date(longValue);
  // let diff = date.getTimezoneOffset() * 60000;
  // var utcDate = date.getTime() + (diff);
  // return utcDate
  var date = new Date(longValue);
  var now_utc = Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), date.getHours(),
    date.getMinutes(), date.getSeconds());
  return now_utc;
}


export function getUTCStartDate(longValue) {
  var date = new Date(longValue);
  var now_utc = Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), date.getHours(),
    date.getMinutes(), date.getSeconds());
  return now_utc;
}

export function getUTCEndDate(longValue) {
  var date = new Date(longValue);
  var now_utc = Date.UTC(date.getFullYear(), date.getMonth(),
    date.getDate(), date.getHours(),
    date.getMinutes(), date.getSeconds());
  return now_utc;
}

export function getLocalDate2(longValue) {
  var date = new Date(longValue);
  let diff = date.getTimezoneOffset() * 60000;
  var utcDate = date.getTime() - (diff);
  return utcDate
}


export function getLocalDate(longValue) {
  var date = new Date(longValue);
  var now_utc = new Date(date.getUTCFullYear(), date.getUTCMonth(),
  date.getUTCDate(), date.getUTCHours(),
  date.getUTCMinutes(), date.getUTCSeconds());
  return now_utc;
}
export function camelCaseToString(camelCaseStr) {
  var repacedStr = camelCaseStr.replace( /([A-Z])/g, " $1" );
  var finalString = repacedStr.charAt(0).toUpperCase() + repacedStr.slice(1);
  return finalString;
}

export function getExportStartDateTime(date) {
  var month = date.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = date.getDate();
  day = day > 9 ? day : "0" + day;
  var dateStr = date.getFullYear() + "-" + month + "-" + day;
  var timeStr = "00:00:00";
  var result = dateStr + " " + timeStr;
  return result;
}


export function getExportEndDateTime(date) {
  var month = date.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = date.getDate();
  day = day > 9 ? day : "0" + day;
  var dateStr = date.getFullYear() + "-" + month + "-" + day;
  var timeStr = "23:59:59";
  var result = dateStr + " " + timeStr;
  return result;
}
