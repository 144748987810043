import React, { useState } from "react";
import { Switch } from "react-router-dom";
import routeOptions from "../app/routes/route";
import "./App.less";
import { PrivateRoute } from "./private-route.jsx";
import PublicRoute from "./public-route.jsx";
import { Layout } from "antd";

import SideBar from "./components/layout/sidebar/sidebar";
import DashboardHeader from "./components/layout/header";
import dashboardOptions from "./components/layout/sidebar/dashboard-options";
import UserService from "./services/user-service";
import { connect } from "react-redux";
import { compose } from "redux";
import RedirectWithStatus from "./redirect-w-status.jsx";
const { Header, Sider, Content } = Layout;
const App = ({ isLoggedIn }) => {
  const uri = window.location.pathname;
  const [collapsed, setCollapsed] = useState(false);
  const loggedInUser = UserService.isUserLoggedIn();
  const currentUser = UserService.getCurrentUser();
  const [isSideBarCollapse, setIsSideBarCollapse] = useState(false);
  function toggleSidebar() {
    setCollapsed(!collapsed);
  }

  let routes = routeOptions.routes.map(({ path, component, exact }, i) => (
    <PublicRoute key={Math.random() + "ROUTE_"} exact={exact} path={path} component={component} />
  ));

  let privateRoutes = routeOptions.privateRoutes.map(({ path, component, exact, roles }, i) => (
    <PrivateRoute
      key={Math.random() + "ROUTE_"}
      exact={exact}
      path={path}
      component={component}
      roles={roles}
    />
  ));
  let redirects = routeOptions.redirects.map(({ from, to, status }, i) => (
    <RedirectWithStatus key={Math.random() + "REDIRECT_"} from={from} to={to} status={status} />
  ));
  return (
    <div className="app-container App">
      <Layout>
        {loggedInUser && !uri.includes("/truengage_preview") ? (
          <Sider
            breakpoint="xl"
            collapsedWidth="0"
            width="230px"
            className="sidebar"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              setIsSideBarCollapse(collapsed);
              console.log(collapsed, type);
            }}
          >
            <SideBar
              options={dashboardOptions(
                currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : ""
              )}
            />
          </Sider>
        ) : (
          ""
        )}
        <Layout className="site-layout">
          {loggedInUser && !uri.includes("/truengage_preview") ? (
            <Header
              className="site-layout-background"
              style={{
                background: "#f2f5f9",
                zIndex: 100,
                top: 0,
                width: "100%",
                padding: 0,
              }}
            >
              <DashboardHeader
                loggedInUser={loggedInUser}
                isCollapsed={collapsed}
                toggleSidebar={toggleSidebar}
                isSideBarCollapse={isSideBarCollapse}
              />
            </Header>
          ) : (
            ""
          )}
          <Content className="site-layout-background">
            {loggedInUser && !uri.includes("/truengage_preview") ? (
              <div className="container-wrapper">
                <Switch>
                  {routes}
                  {privateRoutes}
                  {redirects}
                </Switch>
              </div>
            ) : (
              <Switch>
                {routes}
                {privateRoutes}
                {redirects}
              </Switch>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
