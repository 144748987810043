import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getDayStartDateTime, getDayEndDateTime, getUTCDate } from "../../utils/appUtils";
import {
  Breadcrumb,
  Spin,
  Tabs,
  PageHeader
} from "antd";
import { compose } from "redux";
import {
  loadDisplayPrivateBuyById,
  loadVideoPrivateBuyById,
  clearPrivateBuyReduxState,
  loadDisplayPrivateBuyReport,
  loadVideoPrivateBuyReport,
  loadPrivateBuyOverviewData
} from "./../../redux/actions/private-buy-actions";
import {  loadPublishers } from "../../redux/actions/publisher-actions";
import AddPrivateBuyModal from "../../components/modals/private-buy/add-private-buy";
import { notifyUser } from "../../services/notification-service";
import PrivateBuyDetails from "../../components/layout/edit-private-buy/private-buy-details";
import PrivateBuyOverview from "../../components/layout/edit-private-buy/private-buy-overview";
import DisplayPrivateBuyReport from "../../components/layout/edit-private-buy/display-private-buy-reporting";
import VideoPrivateBuyReport from "../../components/layout/edit-private-buy/video-private-buy-reporting";
import UserService from "./../../services/user-service";
import { currentUserPermissionMap } from "./../../constants/permissions";

const { TabPane } = Tabs;

const EditPrivateBuy = ({ editDisplayPrivateBuy, editVideoPrivateBuy, publisherList, displayBuyUpdated, videoBuyUpdated, privateBuyReport, privateBuyOverviewData }) => {
  const dispatch = useDispatch();
  const { id, buyType } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
      
  useEffect(() => {
    let isMounted = true;

    let data = {};
    data["startDate"] = new Date(getUTCDate(getDayStartDateTime(new Date(new Date().getTime() - (60 * 60 * 24 * 7 * 1000))))).toUTCString();
    data["endDate"] = new Date(getUTCDate(getDayEndDateTime(new Date()))).toUTCString();
    data["systemTimezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    data["report"] = "Date - Delivery";
    data["id"] = id;
    data["timezone"] = "America/Los_Angeles";
    (async () => {
      setSubmitted(true);
      if (buyType === "display") {
        await dispatch(loadDisplayPrivateBuyById(id));
        await dispatch(loadDisplayPrivateBuyReport(data));
        await dispatch(loadPrivateBuyOverviewData(id, "DISPLAY"));
        //await dispatch(loadPublisherById(editDisplayPrivateBuy && editDisplayPrivateBuy.publisherId));
      } else {
        await dispatch(loadVideoPrivateBuyById(id));
        await dispatch(loadVideoPrivateBuyReport(data));
        await dispatch(loadPrivateBuyOverviewData(id, "VIDEO"));
       // await dispatch(loadPublisherById(editVideoPrivateBuy && editVideoPrivateBuy.publisherId));
      }
      await dispatch(loadPublishers());
      if(isMounted ){

      setSubmitted(false);
  }})();

    if (displayBuyUpdated !== undefined) {
      if (displayBuyUpdated.success === true) {
        notifyUser(displayBuyUpdated.message, "success");
      } else {
        notifyUser(displayBuyUpdated.message, "error");
      }
    }
    if (videoBuyUpdated !== undefined) {
      if (videoBuyUpdated.success === true) {
        notifyUser(videoBuyUpdated.message, "success");
      } else {
        notifyUser(videoBuyUpdated.message, "error");
      }
    }
    return () => {
      isMounted = false;

      console.log("EditPrivateBuy Unmounting");
      dispatch(clearPrivateBuyReduxState());
    };
  }, [displayBuyUpdated, videoBuyUpdated]);

  function callback(key) {
    console.log(key);
  }

  return (
    <>
      <section className="white-box-wrapper">
        {/* <Spin size={"large"} spinning={submitted}></Spin> */}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
          {buyType ===  "display" ? 
            editDisplayPrivateBuy && <Link to={`/publishers/${editDisplayPrivateBuy.publisher.publisherId}`}>Publisher</Link> 
          : editVideoPrivateBuy && <Link to={`/publishers/${editVideoPrivateBuy.publisher.publisherId}`}>Publisher</Link>
          }
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {buyType === "display" ? (
              <Link to="/">Display Private Buy</Link>
            ) : (
                <Link to="/">Video Private Buy</Link>
              )}
          </Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
          className="site-page-header"
          title={
            buyType === "display"
              ? editDisplayPrivateBuy && editDisplayPrivateBuy.name
              : editVideoPrivateBuy && editVideoPrivateBuy.name
          }
        />

        {editDisplayPrivateBuy || editVideoPrivateBuy ? (
          <>
            <Tabs defaultActiveKey={"details"} size="large" type="line" onChange={callback}>
              <TabPane tab="OVERVIEW" key="overview">
                <PrivateBuyOverview privateBuyOverviewData={privateBuyOverviewData} publisherId={buyType === "display" ? (editDisplayPrivateBuy && editDisplayPrivateBuy.publisherId) :(editVideoPrivateBuy && editVideoPrivateBuy.publisherId) }/>
              </TabPane>
              {currentUserPermissionMap[currentUserRole]["canUpdatePrivateBuyDetail"] && currentUserRole !== 'ROLE_PUBLISHER_OBSERVER'? (
              <TabPane tab="DETAILS" key="details">
                <PrivateBuyDetails editDisplayPrivateBuy={editDisplayPrivateBuy} editVideoPrivateBuy={editVideoPrivateBuy} />
              </TabPane>
              ):("")
              }
              <TabPane tab="REPORTING" key="reporting">
                {buyType && buyType === "display" ?
                  <DisplayPrivateBuyReport privateBuyReport={privateBuyReport} />
                  : <VideoPrivateBuyReport privateBuyReport={privateBuyReport} />}
              </TabPane>
            </Tabs>
            <Spin size={"large"} spinning={submitted}></Spin>
          </>
        ) : (
            ""
          )}

        <AddPrivateBuyModal
          privateBuyType={buyType}
          publisherList={publisherList}
          editPrivateBuy={buyType === "display" ? editDisplayPrivateBuy : editVideoPrivateBuy}
        />
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    editDisplayPrivateBuy: state.privateBuy.editDisplayPrivateBuy,
    editVideoPrivateBuy: state.privateBuy.editVideoPrivateBuy,
    publisherList: state.publisher.publisherList,
    displayBuyUpdated: state.privateBuy.displayBuyUpdated,
    videoBuyUpdated: state.privateBuy.videoBuyUpdated,
    privateBuyReport: state.privateBuy.privateBuyReport,
    privateBuyOverviewData: state.privateBuy.privateBuyOverviewData,
    
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadDisplayPrivateBuyById: () => dispatch(loadDisplayPrivateBuyById()),
    loadVideoPrivateBuyById: () => dispatch(loadVideoPrivateBuyById()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPrivateBuy);
