import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllAccounts = () => {
  return AxiosAuth.get(Config.API + "/api/accounts/getAllAccounts", undefined, undefined);
};

const addAccount = (data) => {
  return AxiosAuth.post(Config.API + "/api/accounts/addAccount", data, undefined);
};

const loadAccountById = (accountId) => {
  return AxiosAuth.get(
    Config.API + "/api/accounts/getAccountById/" + accountId,
    undefined,
    undefined
  );
};

const loadViewDashboard = (data) => {
  return AxiosAuth.post(Config.API + "/api/accounts/viewDashboard", data, undefined);
};

const exportDashboardData = (data) => {
  return AxiosAuth.post(Config.API + "/api/accounts/viewDashboard/export", data, undefined, true);
};

export default {
  loadAllAccounts,
  addAccount,
  loadAccountById,
  loadViewDashboard,
  exportDashboardData
};
