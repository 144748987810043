import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import {
   List,
    Row,
    Col,
    Button,
    Switch,
    Modal,
} from "antd";
import {
    openUpdateTagVideoCreativeModal,
    updateTagVideoCreative,
} from "../../../redux/actions/creative-actions";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import { getLocalDate } from "../../../utils/appUtils";

const { confirm } = Modal;

const EditTagVideoCreativeDetail = ({ editTagVideoCreative }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [pauseValue, setPauseValue] = useState(false);
    const currentUser = UserService.getCurrentUser();
    const currentUserRole =
        currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

    useEffect(() => {
        if (editTagVideoCreative && editTagVideoCreative.tagVideoCreativeVersion && editTagVideoCreative.videoLine) {
            setPauseValue(
                editTagVideoCreative.tagVideoCreativeVersion.paused !== null
                    ? editTagVideoCreative.tagVideoCreativeVersion.paused
                    : false
            );
        }
    }, []);

    function _bindCreativeProps(propType, propValue) {
        return (
            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="white-box">
                        <div className="title">
                            <h2>{propType}</h2>
                        </div>
                        <div className="card-body">
                            {propType !== "Retargeting Pixel" ?
                                <ul id={propType}>
                                    <li className="no-list-style">{propType && propValue}</li>
                                </ul>
                                : <ul>
                                    <li className="no-list-style">
                                        {editTagVideoCreative.tagVideoCreativeVersion.retargetingPixel ?
                                            editTagVideoCreative.tagVideoCreativeVersion.retargetingPixel.name : ""}
                                    </li></ul>}
                            {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                                <div className="card-footer">
                                    <Button type="link" onClick={() => dispatch(openUpdateTagVideoCreativeModal(propType))}>
                                        Edit
                                    </Button>
                                    {propType === "Retargeting Pixel" ?
                                        <Button onClick={() => removeValues(propType)}>
                                            Remove
                                        </Button>
                                        : ""}
                                    {propType === "Target State" ?
                                        <Button onClick={() => removeValues(propType)}>
                                            Remove
                                        </Button>
                                        : ""}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }


    function removeValues(propType) {
        if (propType === "Target State") {
            dispatch(updateTagVideoCreative(id, { "targetingState": "" }))
        } else if (propType === "Retargeting Pixel") {
            dispatch(updateTagVideoCreative(id, { "retargetingPixelId": "" }))
        }
    }

    function showConfirm() {
        if (pauseValue === false) {
            confirm({
                title: "Are you sure you want to pause?",
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    setPauseValue(true);
                    dispatch(updateTagVideoCreative(id, { "paused": true }))
                },
                onCancel() {
                    setPauseValue(pauseValue);
                },
            });
        } else {
            setPauseValue(false);
            dispatch(updateTagVideoCreative(id, { "paused": false }))
            return;
        }
    }

    return (
        <>
            {_bindCreativeProps("Source", editTagVideoCreative.tagVideoCreativeVersion.source)}
            {_bindCreativeProps(
                "Flight",
                moment.unix(getLocalDate(editTagVideoCreative.tagVideoCreativeVersion.startAt) / 1000).format("DD MMM, YYYY") +
                " - " +
                moment.unix(getLocalDate(editTagVideoCreative.tagVideoCreativeVersion.endAt) / 1000).format("DD MMM, YYYY")
            )}
            {_bindCreativeProps("Weight", editTagVideoCreative.tagVideoCreativeVersion.weight)}

            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <List
                        header={"Impression Pixels"}
                        footer={
                            currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                                <div>
                                    <Button type="link" onClick={() => dispatch(openUpdateTagVideoCreativeModal("Impression Pixels"))}>
                                        Edit
                                    </Button>
                                </div>
                            ) : (
                                ""
                            )
                        }
                        bordered
                        dataSource={editTagVideoCreative.tagVideoCreativeVersion.videoImpressionPixels !== null ?
                            JSON.parse(editTagVideoCreative.tagVideoCreativeVersion.videoImpressionPixels) : []}
                        renderItem={(pixel) => <List.Item>{pixel.source}</List.Item>}
                    />
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <List
                        header={"Devices"}
                        footer={
                            currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                                <div>
                                    <Button type="link" onClick={() => dispatch(openUpdateTagVideoCreativeModal("Devices"))}>
                                        Edit
                                    </Button>
                                </div>
                            ) : (
                                ""
                            )
                        }
                        bordered
                        dataSource={editTagVideoCreative.tagVideoCreativeVersion.devices !== null ?
                            JSON.parse(editTagVideoCreative.tagVideoCreativeVersion.devices) : []}
                        renderItem={(device) => <List.Item>{device}</List.Item>}
                    />
                </Col>
            </Row>

            {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="white-box">
                            <div className="title">
                                <h2>Paused</h2>
                            </div>
                            <div className="card-body">
                                <ul id="paused">
                                    <li className="no-list-style">
                                        <Switch
                                            defaultChecked={pauseValue}
                                            key={pauseValue}
                                            checked={pauseValue}
                                            onClick={showConfirm}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : (
                ""
            )}

            {editTagVideoCreative &&
                _bindCreativeProps("Retargeting Pixel",
                    editTagVideoCreative.tagVideoCreativeVersion.retargetingPixelId ||
                        editTagVideoCreative.tagVideoCreativeVersion.retargetingPixelId !== "" ?
                        editTagVideoCreative.tagVideoCreativeVersion.retargetingPixel : "NA")}
            {editTagVideoCreative &&
                _bindCreativeProps("Target State",
                    editTagVideoCreative.tagVideoCreativeVersion.targetingState !== null ||
                        editTagVideoCreative.tagVideoCreativeVersion.targetingState !== "" ?
                        editTagVideoCreative.tagVideoCreativeVersion.targetingState : "NA")}
        </>
    );
};

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTagVideoCreativeDetail);
