import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllSegments = () => {
  return AxiosAuth.get(Config.API + "/api/segments/getAllSegments", undefined, undefined);
};

const addSegment = (data) => {
    return AxiosAuth.post(Config.API + "/api/segments/addSegment", data, undefined);
  };

  const loadSegmentById = (segmentId) => {
    return AxiosAuth.get(
      Config.API + "/api/segments/getSegmentById/" + segmentId,
      undefined,
      undefined
    );
  };

  const updateSegment = (segmentId, data) => {
    return AxiosAuth.put(Config.API+"/api/segments/updateSegment/"+ segmentId, JSON.stringify(data), undefined);
  };

export default {
    loadAllSegments,
    addSegment,
    loadSegmentById,
    updateSegment,
};
