import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { UploadOutlined } from "@ant-design/icons";
import {
  Input,
  Select,
  InputNumber,
  Radio,
  Row,
  Col,
  Card,
  Image,
  Checkbox,
  DatePicker,
  Typography,
  Spin,
} from "antd";
import {
  defaultCreativeSizes,
} from "./../../../constants/defaultValues";
import {
  closeAddCreativeModal,
  uploadImageResource,
  loadAllImageResourcesByAccountId,
  addImageCreative,
  addTagDisplayCreative,
  clearEditCreativeReduxState
} from "./../../../redux/actions/creative-actions";
import {
  loadCampaignById,
} from "./../../../redux/actions/campaign-actions";
import { notifyUser } from "./../../../services/notification-service";
import moment from "moment";
import { getDayStartTime, getDayEndTime, setImagePath, getLocalDate, getUTCDate } from "../../../utils/appUtils";
import DisplayCreativeForm from "./../../forms/display-creative-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Meta } = Card;
const { Option, OptGroup } = Select;
const { Title } = Typography;
const creativeTypeOptions = ["Tag Display Creative", "Image Creative"];
const flightStartDateOptions = ["Start when line starts", "Custom Start"];
const flightEndDateOptions = ["End when line ends", "Custom End"];
const dateFormat = "YYYY-MM-DD HH:mm:ss";

const AddCreativeModal = ({
  siteBuyType,
  isAddCreativeModalOpen,
  editDisplayLine,
  accountId,
  allImageResources,
  devices,
  imageResourceAdded,
  editCampaign
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDevices, setSelectedDevices] = useState(devices);
  const [creativeType, setCreativeType] = useState("Image Creative");
  const [flightStartDateType, setFlightStartDateType] = useState("Start when line starts");
  const [flightEndDateType, setFlightEndDateType] = useState("End when line ends");
  const [selectImageResourceId, setSelectImageResourceId] = useState("");
  const [startDate, setStartDate] = useState(moment(getDayStartTime(), dateFormat));
  const [endDate, setEndDate] = useState(moment(getDayEndTime(), dateFormat));
  const [startAt, setStartAt] = useState(getDayStartTime().getTime());
  const [endAt, setEndAt] = useState(getDayEndTime().getTime());
  const [submitted, setSubmitted] = useState(false);
  const [displayLineId, setDisplayLineId] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isAddTagDisabled, setIsAddTagDisabled] = useState(false);
  const fileRef = useRef();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadCampaignById(editDisplayLine.campaignId));
      await dispatch(loadAllImageResourcesByAccountId(accountId));
      if(isMounted ){

      setSubmitted(false);
  }})();

    if (editDisplayLine) {
      setDisplayLineId(editDisplayLine.displayLineId);
    }
    const deviceNames = [];
    if (devices && devices.length > 0) {
      devices.filter(function (obj) {
        deviceNames.push(obj.name);
      });
      setSelectedDevices(deviceNames);
    }
    if (imageResourceAdded !== undefined) {
      if (imageResourceAdded.success === true && imageResourceAdded.message) {
        notifyUser(imageResourceAdded.message, "success");
      } else {
        notifyUser(imageResourceAdded.message, "error");
      }
    }
    if (
      editDisplayLine &&
      editDisplayLine.displayLineVersion &&
      editDisplayLine.displayLineVersion.startAt
    ) {
      setStartAt(getLocalDate(editDisplayLine.displayLineVersion.startAt));
      setStartDate(moment(new Date(getLocalDate(editDisplayLine.displayLineVersion.startAt)), dateFormat));
    }
    if (
      editDisplayLine &&
      editDisplayLine.displayLineVersion &&
      editDisplayLine.displayLineVersion.endAt
    ) {
      setEndAt(getLocalDate(editDisplayLine.displayLineVersion.endAt));
      setEndDate(moment(new Date(getLocalDate(editDisplayLine.displayLineVersion.endAt)), dateFormat));
    }
    return () => {
      isMounted = false;

      console.log("AddPrivateBuyModal Unmounting");
      dispatch(clearEditCreativeReduxState());
    };
  }, [isAddCreativeModalOpen, imageResourceAdded]);

  const handleSave = (values) => {
    values["devices"] = JSON.stringify(selectedDevices);
    values["startDateType"] = flightStartDateType;
    values["startAt"] = getUTCDate(startAt);
    values["endDateType"] = flightEndDateType;
    values["endAt"] = getUTCDate(endAt);
    values["accountId"] = editDisplayLine.accountId;
    values["displayLineId"] = editDisplayLine.displayLineId;
    values["paused"] = false;
    if(!values["weight"]) {
      values["weight"] = 1;
    }

    if (creativeType === "Image Creative") {
      values["imageResourceId"] = selectImageResourceId;
      if (selectImageResourceId === "") {
        alert("Please select one of image resource before proceed!");
        return;
      }
      dispatch(addImageCreative(values));
    } else {
      var size = values["size"];
      values["width"] = Number(size.substr(0, size.indexOf("x")));
      values["height"] = Number(size.substr(size.indexOf("x") + 1, size.length - 1));
      dispatch(addTagDisplayCreative(values));
    }
    form.resetFields();
    dispatch(closeAddCreativeModal());
    resetFormStates();
  };
  const resetFormStates = () => { };

  const [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeImageSrc,
    handleFileChange,
    onChangeFlightDateType,
    onDeviceChange,
    onChangeCreativeType,
  ] = DisplayCreativeForm(
    form,
    setSelectImageResourceId,
    setStartDate,
    setEndDate,
    setStartAt,
    setEndAt,
    accountId,
    displayLineId,
    editDisplayLine && editDisplayLine,
    setFlightStartDateType,
    setFlightEndDateType,
    selectedDevices,
    setSelectedDevices,
    setCreativeType,
    setSubmitted,
    setIsAddTagDisabled
  );

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const checkboxSettings = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      form.setFieldsValue({ retargetingPixelId: null, targetingState: null })
    }
  };

  const onAddingTag = (e) => {
    if (e.target.value) {
      setIsAddTagDisabled(true);
      var values = {};
      values["source"] = e.target.value;
      values["macros"] = e.target.value;
      // values ["source"] = JSON.stringify(e.target.value).slice(1, -1);
      // values["macros"] = JSON.stringify(e.target.value).slice(1, -1);
      console.log("Values: ", values);
      form.setFieldsValue(values);
    }
  };

  const clearTag = () => {
    setIsAddTagDisabled(false);
    var values = {};
    values["macros"] = "";
    values["source"] = "";
    form.setFieldsValue(values);
  }

  const creativeSizeOptions = Object.keys(defaultCreativeSizes).map((title) => (
    <OptGroup label={title}>
      {defaultCreativeSizes[title].map((size) => (
        <Option key={size} value={size}>
          {size}
        </Option>
      ))}
    </OptGroup>
  ));

  function _bindTagImageCreativeProps() {
    return (
      <>
        <div className="card-cnt-sec">
          <Title level={3}>{"Setup Resource"}</Title>{" "}
          <Form.Item label="Size" name="size">
            <Select onChange={handleChange}>{creativeSizeOptions}</Select>
          </Form.Item>
          <Form.Item
            label="Add Tag"
            // name="originalSource"
            name="source"
            rules={[
              {
                required: true,
                message: "Please input tag url!",
                whitespace: true,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Paste tag here" onChange={(e) => onAddingTag(e)} disabled={isAddTagDisabled} />
          </Form.Item>
          {isAddTagDisabled && isAddTagDisabled === true &&
            <Form.Item>
              <Button onClick={clearTag}>Clear Tag</Button>
            </Form.Item>
          }
          <Form.Item
            label="Add Macros"
            name="macros"
            rules={[
              {
                required: true,
                message: "Please input macros!",
                whitespace: true,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Add Macros here" />
          </Form.Item>
        </div>
      </>
    );
  }

  function _bindImageCreativeProps() {
    return (
      <div className="card-cnt-sec">
        <Title level={3}>{"Setup Resource"}</Title>
        <Form.Item name="resource">
          <div className="add-image-div">
            <input
              type="file"
              id="addImageResource"
              ref={fileRef}
              accept="image/*"
              onChange={handleFileChange}
            />
            <label htmlFor="addImageResource">
              <UploadOutlined />
              <span className="plus-icon">+</span>Add Image
            </label>
          </div>
          <Row className="overflow-v" gutter={16}>
            {images}
          </Row>
        </Form.Item>
      </div>
    );
  }

  const images = [];
  if (allImageResources) {
    Object.entries(allImageResources).forEach(([key, value]) => {
      images.push(
        <Col key={key} xs={24} sm={12} md={8} lg={6} xl={6}>
          <Card

            style={{ padding: "10px" }}
            onClick={(e) => onChangeImageSrc(e, value.imageResourceId)}
            className={
              selectImageResourceId === value.imageResourceId
                ? "selected-image-resource"
                : "image-resource"
            }
            bordered={false}
            cover={<Image preview={true} src={setImagePath(value)} />}
          >
            <Meta description={value.width + "x" + value.height}></Meta>
            <CopyToClipboard text={setImagePath(value)} onCopy={() => console.log(`copied`)}>
              <CopyOutlined />
            </CopyToClipboard>
          </Card>
        </Col>
      );
    });
  }

  return (
    <>


      <Modal
        title="New Creative"
        visible={isAddCreativeModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Spin className="spin-loader" style={{ zIndex: 9999 }} size={"large"} spinning={submitted}></Spin>
        <Form form={form} layout={"vertical"} onFinish={handleSave}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input creative name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Untitled Creative" autoSize />
          </Form.Item>

          <Form.Item label="Resource Type" name="resourceType">
            <Radio.Group
              // defaultValue={creativeType}
              defaultValue="Image Creative"
              options={creativeTypeOptions}
              onChange={onChangeCreativeType}
            />
          </Form.Item>

          {creativeType === "Image Creative"
            ? _bindImageCreativeProps()
            : _bindTagImageCreativeProps()}
          <div className="grid-col-2 card-cnt-sec">
            <Title level={3}>{"Flight Dates"}</Title>
            <Form.Item label="Start Date" name="flighStartDateType">
              <Radio.Group
                name={"startDate"}
                defaultValue={"Start when line starts"}
                onChange={onChangeFlightDateType}
                options={flightStartDateOptions}
              />
              {flightStartDateType === "Start when line starts" ? (
                ""
              ) : (
                <DatePicker
                  defaultValue={startDate}
                  value={startDate}
                  showTime
                  format={dateFormat}
                  onChange={onStartDateChange}
                />
              )}
            </Form.Item>
            <Form.Item label="End Date" name="flighEndDateType">
              <Radio.Group
                name={"endDate"}
                defaultValue={"End when line ends"}
                onChange={onChangeFlightDateType}
                options={flightEndDateOptions}
              />
              {flightEndDateType === "End when line ends" ? (
                ""
              ) : (
                <DatePicker
                  defaultValue={endDate}
                  value={endDate}
                  showTime
                  format={dateFormat}
                  onChange={onEndDateChange}
                />
              )}
            </Form.Item>
          </div>
          <Form.Item
            label="Weight"
            name="weight"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input weight!",
            //   },
            // ]}
          >
            <InputNumber defaultValue={1} min={1}/>
          </Form.Item>

          <Form.Item label="Devices" name="devices">
            <Row>
              {devices &&
                devices.map((device) => (
                  <Col key={device} xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Checkbox defaultChecked value={device.name} onChange={onDeviceChange}>
                      {device.name}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Form.Item>
          <Form.Item>
            <Checkbox
              label="Do you want to set as sequential?"
              checked={isChecked}
              onChange={checkboxSettings} >Do you want to set as sequential?</Checkbox>
          </Form.Item>

          {isChecked ? (<><Form.Item label="Retargeting Pixel" name="retargetingPixelId">
            <Select defaultValue="" style={{ width: '100%' }}>
              {editCampaign && editCampaign.assignedRetargetingPixels.map((option) => (
                (
                  <Option value={option.retargetingPixelId} key={option.retargetingPixelId}>{option.name}</Option>
                )
              ))}
            </Select>
          </Form.Item>
            <Form.Item
              label="Target State"
              name="targetingState"
            >
              <Input />
            </Form.Item></>) : ""
          }

          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddCreativeModalOpen: state.creative.isAddCreativeModalOpen,
    allImageResources: state.creative.allImageResources,
    devices: state.line.devices,
    imageResourceAdded: state.creative.imageResourceAdded,
    editCampaign: state.campaign.editCampaign
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadImageResource: () => dispatch(uploadImageResource()),
    loadAllImageResourcesByAccountId: (accountId) =>
      dispatch(loadAllImageResourcesByAccountId(accountId)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddCreativeModal);
