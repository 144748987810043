import React from "react";
import {Route, Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const PublicRoute = ({component: Component, ...rest}) => {
  return <Route
      {...rest}
      render={
          props => {
              return !localStorage.getItem(`authToken`) ?
                  (
                      <Component {...props} />
                  )
                  :
                  (
                      <Redirect
                          to={{
                              pathname: "/",
                              state: {from: props.location}
                          }}
                      />
                  )
          }
      }
  />
};

const mapStateToProps = state => (
  {
  }
);

export default withRouter(connect(
  mapStateToProps, null, null, {pure: false}
)(PublicRoute));

