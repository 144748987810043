import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const addRetargetingPixel = (data) => {
  return AxiosAuth.post(Config.API + "/api/retargetingPixels/addRetargetingPixel", data, undefined);
};

const updateRetargetingPixel = (id, data) => {
  return AxiosAuth.put(
    Config.API + "/api/retargetingPixels/updateRetargetingPixel/" + id,
    JSON.stringify(data),
    undefined
  );
};

const getAllRetargetingPixels = () => {
  return AxiosAuth.get(Config.API + "/api/retargetingPixels/getAllRetargetingPixels", undefined, undefined);
};

const addRetargetingPiggybackPixel = (data) => {
  return AxiosAuth.post(Config.API + "/api/piggybackPixels/addRetargetingPiggybackPixel", data, undefined);
};

export default {
  addRetargetingPixel,
  getAllRetargetingPixels,
  addRetargetingPiggybackPixel,
  updateRetargetingPixel
};
