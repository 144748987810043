import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Space, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import {
  Input,
  InputNumber,
  Radio,
  Row,
  Col,
  Checkbox,
  DatePicker,
} from "antd";
import {
  closeUpdateCreativeModal, updateDisplayCreative,
} from "../../../redux/actions/creative-actions";
import { loadCampaignById } from "../../../redux/actions/campaign-actions";
import moment from "moment";
import { getDayStartTime, getDayEndTime, getUTCDate, getLocalDate } from "../../../utils/appUtils";
import EditDisplayCreativeForm from "../../forms/edit-display-creative-form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { piggybackPixelFormats } from "./../../../constants/defaultValues";

const { TextArea } = Input;
const { Option } = Select;
const flightStartDateOptions = ["Start when line starts", "Custom Start"];
const flightEndDateOptions = ["End when line ends", "Custom End"];
const dateFormat = "YYYY-MM-DD HH:mm:ss";

const EditCreativeModal = ({
  isEditCreativeModalOpen,
  displayLine,
  accountId,
  devices,
  propType,
  editCreativeInfo,
  creativeType,
  editCampaign
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedDevices, setSelectedDevices] = useState(devices);
  const [flightStartDateType, setFlightStartDateType] = useState("Start when line starts");
  const [flightEndDateType, setFlightEndDateType] = useState("End when line ends");
  const [startDate, setStartDate] = useState(moment(getDayStartTime(), dateFormat));
  const [endDate, setEndDate] = useState(moment(getDayEndTime(), dateFormat));
  const [startAt, setStartAt] = useState(getDayStartTime().getTime());
  const [endAt, setEndAt] = useState(getDayEndTime().getTime());
  const { id } = useParams();

  const formatOptions = piggybackPixelFormats.map((format) => (
    <Option key={format} value={format}>
      {format}
    </Option>
  ));

  useEffect(() => {
    (async () => {
      await dispatch(loadCampaignById(editCreativeInfo.displayLine.campaignId));

    })();
    if (editCreativeInfo !== undefined) {

      setFlightStartDateType(editCreativeInfo && editCreativeInfo.imageCreativeVersion && editCreativeInfo.imageCreativeVersion.startDateType);
      setFlightEndDateType(editCreativeInfo && editCreativeInfo.imageCreativeVersion && editCreativeInfo.imageCreativeVersion.endDateType);
      if (editCreativeInfo.displayLine.displayLineVersion.startAt === editCreativeInfo.imageCreativeVersion.startAt) {
        // setFlightStartDateType("Start when line starts");
        setStartAt(getLocalDate(editCreativeInfo.displayLine.displayLineVersion.startAt));
        setStartDate(moment(new Date(getLocalDate(editCreativeInfo.displayLine.displayLineVersion.startAt)), dateFormat));
      } else {
        // setFlightStartDateType("Custom Start");
        setStartAt(getLocalDate(editCreativeInfo.imageCreativeVersion.startAt));
        setStartDate(moment(new Date(getLocalDate(editCreativeInfo.imageCreativeVersion.startAt)), dateFormat));
      }
      if (editCreativeInfo.displayLine.displayLineVersion.endAt === editCreativeInfo.imageCreativeVersion.endAt) {
        // setFlightEndDateType("End when line ends");
        setEndAt(getLocalDate(editCreativeInfo.displayLine.displayLineVersion.endAt));
        setEndDate(moment(new Date(getLocalDate(editCreativeInfo.displayLine.displayLineVersion.endAt)), dateFormat));
      } else {
        // setFlightEndDateType("Custom End");
        setEndAt(getLocalDate(editCreativeInfo.imageCreativeVersion.endAt));
        setEndDate(moment(new Date(getLocalDate(editCreativeInfo.imageCreativeVersion.endAt)), dateFormat));
      }

      if (editCreativeInfo && editCreativeInfo.imageCreativeVersion && editCreativeInfo.displayLine) {
        var values = {
          name: editCreativeInfo.imageCreativeVersion.name !== null ? editCreativeInfo.imageCreativeVersion.name : "",
          clickUrl: editCreativeInfo.imageCreativeVersion.clickUrl !== null ? editCreativeInfo.imageCreativeVersion.clickUrl : "",
          targetingState: editCreativeInfo.imageCreativeVersion.targetingState !== null ? editCreativeInfo.imageCreativeVersion.targetingState : "",
          retargetingPixelId: editCreativeInfo.imageCreativeVersion.retargetingPixelId !== null ? editCreativeInfo.imageCreativeVersion.retargetingPixelId : "",
          weight: editCreativeInfo.imageCreativeVersion.weight,
          displayImpressionPixels: editCreativeInfo.imageCreativeVersion.displayImpressionPixels !== null
            ? JSON.parse(editCreativeInfo.imageCreativeVersion.displayImpressionPixels) : [],
        }
        form.setFieldsValue(values);

        const selectValuesTemp = [];
        const selectedDeviceList = JSON.parse(editCreativeInfo.imageCreativeVersion.devices);
        if (selectedDeviceList && selectedDeviceList.length > 0) {
          selectedDeviceList.filter(function (obj) {
            selectValuesTemp.push(obj);
          });
          setSelectedDevices(selectValuesTemp);
        }
      }
    }

    return () => {
      console.log("EditCreativeModal Unmounting");
    };
  }, [isEditCreativeModalOpen, creativeType]);

  const handleSave = (values) => {
    if (!values.clickUrl) {
      values["clickUrl"] = null;
    }
    values["devices"] = JSON.stringify(selectedDevices);
    values["startDateType"] = flightStartDateType;
    values["startAt"] = getUTCDate(startAt);
    values["endDateType"] = flightEndDateType;
    values["endAt"] = getUTCDate(endAt);
    if (values["displayImpressionPixels"] && values["displayImpressionPixels"] !== null) {
      values["displayImpressionPixels"] = JSON.stringify(values["displayImpressionPixels"]);
    }
    dispatch(updateDisplayCreative(id, values));
    form.resetFields();
    dispatch(closeUpdateCreativeModal());
    resetFormStates();
  };

  const resetFormStates = () => { };

  const [
    handleCancel,
    onStartDateChange,
    onEndDateChange,
    onChangeFlightDateType,
    onDeviceChange,
    isDeviceExists,
  ] = EditDisplayCreativeForm(
    form,
    setStartDate,
    setEndDate,
    startAt,
    endAt,
    setStartAt,
    setEndAt,
    editCreativeInfo.displayLine,
    setFlightStartDateType,
    setFlightEndDateType,
    selectedDevices,
    setSelectedDevices,
    creativeType
  );

  return (
    <>
      {propType !== undefined &&
        <Modal
          title={`Update ${propType}`}
          visible={isEditCreativeModalOpen}
          onCancel={handleCancel}
          footer={[]}
        >
          <Form form={form} layout={"vertical"} onFinish={handleSave}>
            {propType === "Name" && (
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input creative name!",
                    whitespace: true,
                  },
                ]}
              >
                <TextArea showCount maxLength={255} placeholder="Untitled Creative" autoSize />
              </Form.Item>
            )}

            {propType === "Click URL" && (
              <Form.Item
                label="Click URL"
                name="clickUrl"
              >
                <Input />
              </Form.Item>
            )}

            {propType === "Retargeting Pixel" && (
              <Form.Item
                label="Retargeting Pixel"
                name="retargetingPixelId"
              >
                <Select style={{ width: '100%' }}
                  value={editCreativeInfo.imageCreativeVersion.retargetingPixelId ?
                    editCreativeInfo.imageCreativeVersion.retargetingPixelId : ""}>
                  {editCampaign && editCampaign.assignedRetargetingPixels && editCampaign.assignedRetargetingPixels.map((option) => (
                    (
                      <Option value={option.retargetingPixelId} key={option.retargetingPixelId}>{option.name}</Option>
                    )
                  ))}
                </Select>
              </Form.Item>
            )}

            {propType === "Target State" && (
              <Form.Item
                label="Target State"
                name="targetingState"
              >
                <Input />
              </Form.Item>
            )}

            {propType === "Flight" && (
              <div className="card-cnt-sec grid-col-2">
                <Form.Item label="Start Dates" name="flighStartDateType">
                  <Radio.Group
                    name={"startDate"}
                    value={flightStartDateType}
                    defaultValue={"Start when line starts"}
                    onChange={onChangeFlightDateType}
                    options={flightStartDateOptions}
                  />
                  {flightStartDateType === "Start when line starts" ? (
                    ""
                  ) : (
                    <DatePicker
                      defaultValue={startDate}
                      value={startDate}
                      showTime
                      format={dateFormat}
                      onChange={onStartDateChange}
                    />
                  )}
                </Form.Item>
                <Form.Item label="End Date" name="flighEndDateType">
                  <Radio.Group
                    name={"endDate"}
                    value={flightEndDateType}
                    defaultValue={"End when line ends"}
                    onChange={onChangeFlightDateType}
                    options={flightEndDateOptions}
                  />
                  {flightEndDateType === "End when line ends" ? (
                    ""
                  ) : (
                    <DatePicker
                      defaultValue={endDate}
                      value={endDate}
                      showTime
                      format={dateFormat}
                      onChange={onEndDateChange}
                    />
                  )}
                </Form.Item>
              </div>
            )}

            {propType === "Weight" && (
              <Form.Item
                label="Weight"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: "Please input weight!",
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
            )}

            {propType === "Impression Pixels" && (
              <Form.Item className="creative-box" label="Impression Pixels" name="displayImpressionPixels">
                <Form.List name="displayImpressionPixels">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "format"]}
                            fieldKey={[fieldKey, "format"]}
                            rules={[{ required: true, message: "Please select Format!" }]}
                          >
                            <Select placeholder="Format">{formatOptions}</Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "source"]}
                            fieldKey={[fieldKey, "source"]}
                            rules={[{ required: true, message: "Please input source!" }]}
                          >
                            <Input placeholder="Source" style={{ width: "400px" }} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}

            {propType === "Devices" && (
              <Form.Item label="Devices" name="devices">
                <Row>
                  {devices &&
                    devices.map((device) => (
                      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Checkbox checked={isDeviceExists(device.name)} value={device.name} onChange={onDeviceChange}>
                          {device.name}
                        </Checkbox>
                      </Col>
                    ))}
                </Row>
              </Form.Item>
            )}

            <Form.Item className="text-right">
              <Button type="primary" htmlType="button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
    </>
  );
};
function mapStateToProps(state) {
  return {
    isEditCreativeModalOpen: state.creative.isEditCreativeModalOpen,
    devices: state.line.devices,
    propType: state.creative.propType,
    editCampaign: state.campaign.editCampaign
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditCreativeModal);
