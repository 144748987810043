import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, InputNumber } from "antd";
import { DatePicker, Space, Checkbox, Row, Col, List } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import {
  closeNewLineModal,
  addDisplayLine,
  addVideoLine,
  updateDisplayLine,
  updateVideoLine,
} from "./../../../redux/actions/line-actions";
import {
  DollarOutlined,
} from "@ant-design/icons";
import {
  displayRateTypes,
  videoRateTypes,
  billingServerTypes,
  displayBillingProfileTypes,
  videoBillingProfileTypes,
} from "./../../../constants/defaultValues";
import * as R from "ramda";
import moment from "moment";
import { defaultCreativeSizes, getLocalDate, getUTCDate} from "./../../../utils/appUtils";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

const AddLineModal = ({
  isAddLineModalOpen,
  lineType,
  devices,
  geonames,
  editLineInfo,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const rateOptions =
    lineType === "Display" ? displayRateTypes : videoRateTypes;
  const billingServerOptions = billingServerTypes;
  const billingProfileOptions =
    lineType === "Display"
      ? displayBillingProfileTypes
      : videoBillingProfileTypes;
  const [result, setResult] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(devices);
  const [total, setTotal] = useState(0);
  const [startEndDate, setStartEndDate] = useState([
    new Date().toDateString(),
    new Date().toDateString(),
  ]);
  const currentMonth = moment(
    new Date(new Date().getFullYear(), new Date().getMonth())
  ).format("YYYYMM");
  const currentMonthAllocations = {};
  currentMonthAllocations[currentMonth] = {
    billing: 0,
    quantity: 0,
    discrepancy: 0,
    isBudgetIncreased: true
  };
  const [budgetMonths, setbudgetMonths] = useState([currentMonthAllocations]);
  const [budgetBackup, setBudgetBackup] = useState([]);
  const [isNewLine, setIsNewLine] = useState(true);
  const { id } = useParams();

  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);

  var allocations = [];
  var values = {};
  var dates = [];
  useEffect(() => {
    if (editLineInfo !== undefined) {
      setIsNewLine(false);
      if (
        lineType === "Display" &&
        editLineInfo &&
        editLineInfo.displayLineVersion
      ) {
        allocations = JSON.parse(editLineInfo.displayLineVersion.allocations);
        if (allocations !== null) {
          setbudgetMonths(allocations);
          setBudgetBackup(JSON.parse(JSON.stringify(allocations)));
          calculateBudgetTotal(allocations);
        }
        values = {
          name: editLineInfo.displayLineVersion.name,
          rate: editLineInfo.displayLineVersion.rate,
          rateType: editLineInfo.displayLineVersion.rateType,
          billingServer: editLineInfo.displayLineVersion.billingServer,
          billingProfile: editLineInfo.displayLineVersion.billingProfile,
          date: [
            moment(
              new Date(getLocalDate(editLineInfo.displayLineVersion.startAt)),
              dateFormat
            ),
            moment(new Date(getLocalDate(editLineInfo.displayLineVersion.endAt)), dateFormat),
          ],
          total: total,
        };

        dates.push(new Date(getLocalDate(editLineInfo.displayLineVersion.startAt)));
        dates.push(new Date(getLocalDate(editLineInfo.displayLineVersion.endAt)));
        setStartEndDate(dates);
        form.setFieldsValue(values);
      } else if (lineType && editLineInfo && editLineInfo.videoLineVersion) {
        allocations = JSON.parse(editLineInfo.videoLineVersion.allocations);
        if (allocations !== null) {
          setbudgetMonths(allocations);
          setBudgetBackup(JSON.parse(JSON.stringify(allocations)));
          calculateBudgetTotal(allocations);
        }
        values = {
          name: editLineInfo.videoLineVersion.name,
          rate: editLineInfo.videoLineVersion.rate,
          rateType: editLineInfo.videoLineVersion.rateType,
          billingServer: editLineInfo.videoLineVersion.billingServer,
          billingProfile: editLineInfo.videoLineVersion.billingProfile,
          date: [
            moment(new Date(getLocalDate(editLineInfo.videoLineVersion.startAt)), dateFormat),
            moment(new Date(getLocalDate(editLineInfo.videoLineVersion.endAt)), dateFormat),
          ],
          total: total,
        };

        dates.push(new Date(getLocalDate(editLineInfo.videoLineVersion.startAt)));
        dates.push(new Date(getLocalDate(editLineInfo.videoLineVersion.endAt)));
        setStartEndDate(dates);
        form.setFieldsValue(values);
      }
    } else {
      const values = {
        date: [moment(new Date(), dateFormat), moment(new Date(), dateFormat)],
      };
      form.setFieldsValue(values);
    }

    const deviceNames = [];
    if (devices && devices.length > 0) {
      devices.filter(function (obj) {
        deviceNames.push(obj.name);
      });
      setSelectedDevices(deviceNames);
    }
    return () => {
      console.log("AddLineModal Unmounting");
    };
  }, [devices, editLineInfo, isAddLineModalOpen]);

  useEffect(() => {
    return () => {
      console.log("AddLineModal Unmounting-2");
    };
  }, [budgetMonths]);

  const handleSave = async (values) => {

    let selectedGeoNames = [];
    if (values) {
      values.geoNames &&
        values.geoNames.map(
          (value) =>
            geonames &&
            geonames.map((geoName) =>
              geoName.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
                ? selectedGeoNames.push({
                  name: geoName.name,
                  geoNameId: geoName.geoNameId,
                })
                : ""
            )
        );
    }

    var start = new Date();
    start.setUTCHours(0, 0, 0);

    var end = new Date();
    end.setUTCHours(23, 59, 59);

    values["startAt"] = startEndDate && startEndDate[0] ? getUTCDate(new Date(startEndDate[0])) : getUTCDate(start.getTime());
    values["endAt"] = startEndDate && startEndDate[1] ? getUTCDate(new Date(startEndDate[1])) : getUTCDate(end.getTime());
    values["allocations"] = JSON.stringify(budgetMonths);
    values["totalBudget"] = total;
    values["geoNames"] = JSON.stringify(selectedGeoNames);
    if(!values["billingServer"]) {
      values["billingServer"] = "XPS";
    }
    if(!values["billingProfile"]) {
      values["billingProfile"] = "Standard";
    }
    
    if (isNewLine) {
      values["creativeSizes"] = JSON.stringify(selectedValues);
      values["devices"] = JSON.stringify(selectedDevices);
      
      if (lineType === "Display") {
        dispatch(addDisplayLine(id, values));
      } else {
        dispatch(addVideoLine(id, values));
      }
    } else {
      if (lineType === "Display") {
        dispatch(updateDisplayLine(editLineInfo.displayLineId, values));
      } else {
        dispatch(updateVideoLine(editLineInfo.videoLineId, values));
      }
    }

    setTotal(0);
    setStartEndDate([]);
    if (isNewLine) {
      setbudgetMonths([currentMonthAllocations]);
    }
    form.resetFields();
    dispatch(closeNewLineModal());

  };

  const handleCancel = () => {
    setTotal(0);
    setStartEndDate([]);
    if (isNewLine) {
      setbudgetMonths([currentMonthAllocations]);
    }
    form.resetFields();
    dispatch(closeNewLineModal());
    if (isNewLine) {
      const values = {
        date: [moment(new Date(), dateFormat), moment(new Date(), dateFormat)],
      };
      form.setFieldsValue(values);
    }
  };

  const onChange = (value, dateString) => {
    setStartEndDate(dateString);
    var startDate = moment(dateString[0]).startOf("month");
    var endDate = moment(dateString[1]);

    var tempMonths = budgetMonths;

    while (startDate.isBefore(endDate)) {
      if (isValueExists(startDate.format("YYYYMM"), tempMonths) === undefined) {
        const selectedMonthBudget = {};
        selectedMonthBudget[startDate.format("YYYYMM")] = {
          billing: 0,
          quantity: 0,
          discrepancy: 0,
          isBudgetIncreased: true
        };
        tempMonths.push(selectedMonthBudget);
      }
      startDate.add(1, "month");
    }

    tempMonths &&
      tempMonths.length > 0 &&
      tempMonths.map((month) => {
        if (
          Object.keys(month) &&
          Object.keys(month)[0] &&
          !moment(Object.keys(month)[0]).isBefore(endDate)
        ) {
          tempMonths.splice(tempMonths.indexOf(month), 1);
        }
      });

    var tempMonths2 = tempMonths.sort((a, b) => {
      const a_key = Object.keys(a);
      const b_key = Object.keys(b);
      if (a_key === b_key) return 0;
      return a_key > b_key ? 1 : -1;
    });
    setbudgetMonths(tempMonths2);
  };

  function isValueExists(props, tempMonths) {
    return R.find(R.prop(props))(tempMonths);
  }

  const onBudgetValueChange = (e, i, month, name) => {
    const budgetMonthsTemp = budgetMonths;
    if (name === "billing") {
      if (
        budgetMonthsTemp &&
        budgetMonthsTemp[i] &&
        budgetMonthsTemp[i][month]
      ) {
        if (budgetBackup && budgetBackup[i] && budgetMonthsTemp[i][month]) {
          if (e > budgetBackup[i][month]["billing"]) {
            budgetMonthsTemp[i][month]["isBudgetIncreased"] = true;
          } else {
            budgetMonthsTemp[i][month]["isBudgetIncreased"] = false;
          }
        }
        budgetMonthsTemp[i][month]["billing"] = e;
      }
    } else if (name === "quantity") {
      if (
        budgetMonthsTemp &&
        budgetMonthsTemp[i] &&
        budgetMonthsTemp[i][month]
      ) {
        if (budgetBackup && budgetBackup[i] && budgetMonthsTemp[i][month]) {
          if (e > budgetBackup[i][month]["quantity"]) {
            budgetMonthsTemp[i][month]["isBudgetIncreased"] = true;
          } else {
            budgetMonthsTemp[i][month]["isBudgetIncreased"] = false;
          }
        }
        budgetMonthsTemp[i][month]["quantity"] = e;
      }
    } else if (name === "discrepancy") {
      if (
        budgetMonthsTemp &&
        budgetMonthsTemp[i] &&
        budgetMonthsTemp[i][month]
      ) {
        budgetMonthsTemp[i][month]["discrepancy"] = e;
      }
    }

    setbudgetMonths(budgetMonthsTemp);
    calculateBudgetTotal(budgetMonthsTemp);
  };

  const calculateBudgetTotal = (budgetMonths) => {
    var budgetSum = 0;
    if (budgetMonths) {
      Object.keys(budgetMonths).map((item, index) => {
        Object.keys(budgetMonths[item]).map((item2, index) => {
          budgetSum += budgetMonths[item][item2]["billing"];
        });
      });
      setTotal(budgetSum);
    }
  };

  const onDeviceChange = (e) => {
    const devices = selectedDevices;
    if (devices.indexOf(e.target.value) > -1) {
      devices.splice(devices.indexOf(e.target.value), 1);
    } else {
      devices.push(e.target.value);
    }
    setSelectedDevices(devices);
  };


  const handleSearch = (value) => {
    let res = [];
    if (value) {
      geonames &&
        geonames.map((geoOption) =>
          geoOption.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
            ? res.push(geoOption)
            : ""
        );
    }
    setResult(res);
  };

  function disabledDate(current) {
    // Can not select days before today
    return current && current < moment().endOf("day");
  }

  const onCreativeSizeChange = (e) => {

    const selectValuesTemp = selectedValues;
    if (selectValuesTemp.indexOf(e.target.value) > -1) {
    } else {
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  function _bindCreativeSizes(sizes) {
    return (
      <>
        <Row>
          {sizes.map(val =>
            <Col md={6} key={sizes}>
              <Checkbox
           key={sizes}
                onChange={onCreativeSizeChange}
                value={val}
              >
                {val.width + "x" + val.height}
              </Checkbox>
            </Col>
          )}
        </Row>
      </>);
  }

  return (
    <>
      <Modal
        title={isNewLine ? `New ${lineType} Line` : `Update ${lineType} Line`}
        visible={isAddLineModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          className="new-display-line"
          form={form}
          initialValues={{
            remember: true,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: `Please input ${lineType} Line name!`,
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Name" autoSize />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                className="left-space"
                label="Rate"
                name="rate"
                rules={[
                  {
                    required: true,
                    message: `Please input rate!`,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Rate Type"
                name="rateType"
                rules={[
                  {
                    required: true,
                    message: `Please select Rate Type!`,
                  },
                ]}
              >
                <Select>
                  {rateOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Billing Server" name="billingServer">
                <Select defaultValue="XPS">
                  {billingServerOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item label="Billing Profile" name="billingProfile">
                <Select defaultValue="Standard">
                  {billingProfileOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {lineType === "Display" && isNewLine && (
            <Form.Item
              className="creative-box"
              label="Creative Sizes"
              name="creativeSizes"
            >

              <Form.Item name="creativeSizes" label="Select Creative Size">
                {defaultCreativeSizes && _bindCreativeSizes(defaultCreativeSizes)}
              </Form.Item>

            </Form.Item>
          )}

          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: `Please select start and end date!`,
              },
            ]}
          >
            {/* <RangePicker showTime format={dateFormat} onChange={onChange} disabledDate={disabledDate}/> */}
            <RangePicker
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment("00:00:00", "HH:mm:ss"),
                  moment("11:59:59", "HH:mm:ss"),
                ],
              }}
              format={dateFormat}
              onChange={onChange}
              // disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item name="budgets">
            <List
              itemLayout="horizontal"
              dataSource={budgetMonths && budgetMonths}
              renderItem={(month, index) => (
                <List.Item>
                  <Space
                    key={index}
                    style={{ display: "flex", marginBottom: 4 }}
                    align="baseline"
                  >
                    <Form.Item
                      label={
                        <>
                          {month && Object.keys(month) && Object.keys(month)[0]
                            ? moment(Object.keys(month)[0]).format("MMMM, YYYY")
                            : ""}
                          {" Budget"}
                        </>
                      }
                      name={[index, "budget"]}
                    >
                      <InputNumber
                        // key={
                        //   Object.keys(month) &&
                        //     Object.keys(month)[0] &&
                        //     Object.values(month)[0]["billing"]
                        //     ? Object.values(month)[0]["billing"]
                        //     : 0
                        // }
                        defaultValue={
                          Object.keys(month) &&
                            Object.keys(month)[0] &&
                            Object.values(month)[0]["billing"]
                            ? Object.values(month)[0]["billing"]
                            : 0
                        }
                        min={0}
                        onChange={(e) =>
                          onBudgetValueChange(
                            e,
                            index,
                            Object.keys(month)[0],
                            "billing"
                          )
                        }
                        prefix={<DollarOutlined />}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Aditional Quantity"
                      name={[index, "aditionalQuantity"]}
                    >
                      <InputNumber
                        // key={
                        //   Object.keys(month) &&
                        //     Object.keys(month)[0] &&
                        //     Object.values(month)[0]["quantity"]
                        //     ? Object.values(month)[0]["quantity"]
                        //     : 0
                        // }
                        defaultValue={
                          Object.keys(month) &&
                            Object.keys(month)[0] &&
                            Object.values(month)[0]["quantity"]
                            ? Object.values(month)[0]["quantity"]
                            : 0
                        }
                        min={0}
                        onChange={(e) =>
                          onBudgetValueChange(
                            e,
                            index,
                            Object.keys(month)[0],
                            "quantity"
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Discrepancy(%)"
                      name={[index, "discrepancy"]}
                    >
                      <InputNumber
                        // key={
                        //   Object.keys(month) &&
                        //     Object.keys(month)[0] &&
                        //     Object.values(month)[0]["discrepancy"]
                        //     ? Object.values(month)[0]["discrepancy"]
                        //     : 0
                        // }
                        defaultValue={
                          Object.keys(month) &&
                            Object.keys(month)[0] &&
                            Object.values(month)[0]["discrepancy"]
                            ? Object.values(month)[0]["discrepancy"]
                            : 0
                        }
                        min={0}
                        onChange={(e) =>
                          onBudgetValueChange(
                            e,
                            index,
                            Object.keys(month)[0],
                            "discrepancy"
                          )
                        }
                      />
                    </Form.Item>
                  </Space>
                </List.Item>
              )}
            />
          </Form.Item>
          <Form.Item label="Total" name="total">
            <span>${total}.00</span>
          </Form.Item>
          {isNewLine && (
            <>
              <Form.Item className="flex-box" label="Devices" name="devices">
                {devices &&
                  devices.map((device) => (
                    <Checkbox key={device.name}
                      defaultChecked
                      value={device.name}
                      onChange={onDeviceChange}
                    >
                      {device.name}
                    </Checkbox>
                  ))}
              </Form.Item>
              <Form.Item label="Add Geo - Country/US States" name="geoNames">
                <Select
                  mode="multiple"
                  dropdownStyle={{ display: result.length > 0 ? "" : "none" }}
                  onSearch={handleSearch}
                >
                  {result.map((geoName) => (
                    <Option key={geoName.name}>{geoName.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isAddLineModalOpen: state.line.isAddLineModalOpen,
    lineType: state.line.lineType ? state.line.lineType : "Display",
    devices: state.line.devices,
    geonames: state.line.geonames,
    exelateSegments: state.line.exelateSegments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewLineModal: () => dispatch(closeNewLineModal()),
    addDisplayLine: (id, values) => dispatch(addDisplayLine(id, values)),
    addVideoLine: (id, values) => dispatch(addVideoLine(id, values)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddLineModal
);
