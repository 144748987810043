import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const addConversionPixel = (data) => {
  return AxiosAuth.post(Config.API + "/api/conversionPixels/addConversionPixel", data, undefined);
};

const getAllConversionPixels = () => {
  return AxiosAuth.get(
    Config.API + "/api/conversionPixels/getAllConversionPixels",
    undefined,
    undefined
  );
};

const addConversionPiggybackPixel = (data) => {
  return AxiosAuth.post(Config.API + "/api/piggybackPixels/addConversionPiggybackPixel", data, undefined);
};

const updateConversionPiggybackPixel = (data) => {
  return AxiosAuth.put(Config.API + "/api/piggybackPixels/updateConversionPiggybackPixel", data, undefined);
};

export default {
  addConversionPixel,
  getAllConversionPixels,
  addConversionPiggybackPixel,
  updateConversionPiggybackPixel
};
