import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllPublishers = () => {
  return AxiosAuth.get(Config.API + "/api/publishers/getAllPublishers", undefined, undefined);
};

const addPublisher = (data) => {
  return AxiosAuth.post(Config.API + "/api/publishers/addPublisher", data, undefined);
};

const loadPublisherById = (id) => {
  return AxiosAuth.get(Config.API + "/api/publishers/getPublisherById/" + id, undefined, undefined);
};

const updatePublisher = (publisherId, data) => {
  return AxiosAuth.put(Config.API + "/api/publishers/updatePublisher/"+publisherId, data, undefined);
};

const loadPublisherViewDashboard = (data) => {
  return AxiosAuth.post(Config.API + "/api/publishers/viewDashboard", data, undefined);
};

const exportPublisherDashboardData = (data) => {
  return AxiosAuth.post(Config.API + "/api/publishers/viewDashboard/export", data, undefined, true);
};

export default {
  loadAllPublishers,
  addPublisher,
  loadPublisherById,
  updatePublisher,
  loadPublisherViewDashboard,
  exportPublisherDashboardData
};
