import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Button,
  Typography,
  Divider,
  Switch,
  Select,
  Checkbox,
  List,
} from "antd";
import {
  desktopOptions,
  mobileOptions,
  pixelOptions,
  displayTagOptions,
  displayTagOptionQueryParms,
} from "../../../constants/defaultValues";
import Config from "../../../config";
import { compose } from "redux";
import {
  clearPrivateBuyReduxState,
  openAddPrivateBuyModal
} from "./../../../redux/actions/private-buy-actions";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import UserService from "./../../../services/user-service";

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};
const { Option } = Select;
const { Text, Title, Paragraph } = Typography;

const PrivateBuyDetails = ({ editDisplayPrivateBuy, editVideoPrivateBuy }) => {
  const dispatch = useDispatch();
  const { buyType } = useParams();
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  useEffect(() => {

    return () => {
      console.log("EditPrivateBuy Unmounting");
      dispatch(clearPrivateBuyReduxState());
    };
  }, []);

  const [queryParms, setQueryParms] = useState(displayTagOptionQueryParms["DFP"]);
  const [desktopValues, setDesktopValues] = useState([]);
  const [mobileValues, setMobileValues] = useState([]);
  const [pixelValues, setPixelValues] = useState([]);
  const [scriptList, setScriptList] = useState([]);

  function onChangeDesktopOptions(checkedValues) {
    setDesktopValues(checkedValues);
    setScriptList([...checkedValues, ...mobileValues, ...pixelValues]);
  }
  function onChangeMobileOptions(checkedValues) {
    setMobileValues(checkedValues);
    setScriptList([...desktopValues, ...checkedValues, ...pixelValues]);
  }
  function onChangePixelOptions(checkedValues) {
    setPixelValues(checkedValues);
    setScriptList([...desktopValues, ...mobileValues, ...checkedValues]);
  }
  function handleSelectChange(value) {
    setQueryParms(displayTagOptionQueryParms[value]);
    setDesktopValues([]);
    setMobileValues([]);
    setPixelValues([]);
    setScriptList([]);
  }

  return (
    <>
      <section className="white-box-wrapper">

        {editDisplayPrivateBuy || editVideoPrivateBuy ? (
          <Row gutter={16}>
            <Col span={24}>
              <Card title="Basic Information">
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>
                    {buyType === "display" ? editDisplayPrivateBuy.name : editVideoPrivateBuy.name}
                  </Title>
                  <Text type="secondary">Name</Text>
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>
                    ${buyType === "display" ? editDisplayPrivateBuy.rate : editVideoPrivateBuy.rate}{" "}
                    {buyType === "display"
                      ? editDisplayPrivateBuy.rateType
                      : editVideoPrivateBuy.rateType}
                  </Title>
                  <Text type="secondary">Rate</Text>
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>
                    {buyType === "display"
                      ? editDisplayPrivateBuy.publisher.name
                      : editVideoPrivateBuy.publisher.name}
                  </Title>
                  <Text type="secondary">Publisher</Text>
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Switch
                    disabled={true}
                    checked={
                      buyType === "display"
                        ? editDisplayPrivateBuy.useNetworkFill
                        : editVideoPrivateBuy.useNetworkFill
                    }
                  />
                  {" Network Fill"}
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Switch
                    disabled={true}
                    checked={
                      buyType === "display"
                        ? editDisplayPrivateBuy.ignoreSiteList
                        : editVideoPrivateBuy.ignoreSiteList
                    }
                  />
                  {" Ignore Site List"}
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Switch
                    disabled={true}
                    checked={
                      buyType === "display"
                        ? editDisplayPrivateBuy.ignoreDailyCap
                        : editVideoPrivateBuy.ignoreDailyCap
                    }
                  />
                  {" Ignore Daily Cap"}
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Switch
                    disabled={true}
                    checked={
                      buyType === "display"
                        ? editDisplayPrivateBuy.ignoreDeliveryCap
                        : editVideoPrivateBuy.ignoreDeliveryCap
                    }
                  />
                  {" Ignore Delivery Cap"}
                </Card.Grid>
                <Divider />
                <div style={{ margin: "20px" }}>

                  <Button onClick={() => dispatch(openAddPrivateBuyModal())} type="link">Edit</Button>

                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}

        {buyType === "display" && editDisplayPrivateBuy ? (
          <Row gutter={16}>
            <Col span={24}>
              <Card title="Tags">
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Select defaultValue="DFP" style={{ width: 120 }} onChange={handleSelectChange}>
                    {displayTagOptions.map((option) => (
                      currentUserPermissionMap[currentUserRole]["canUpdatePrivateBuyTag"] ? (
                        <Option key={option}>{option}</Option>
                      ) : (
                        ""
                      )
                    ))
                    }

                  </Select>
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>Desktop</Title>
                  <Checkbox.Group
                    options={desktopOptions}
                    value={desktopValues}
                    onChange={onChangeDesktopOptions}
                  />
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>Mobile</Title>
                  <Checkbox.Group
                    options={mobileOptions}
                    value={mobileValues}
                    onChange={onChangeMobileOptions}
                  />
                </Card.Grid>
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Title level={4}>Pixel</Title>
                  <Checkbox.Group
                    options={pixelOptions}
                    value={pixelValues}
                    onChange={onChangePixelOptions}
                  />
                </Card.Grid>
                <Divider />
                <List
                  dataSource={scriptList}
                  renderItem={(item) => {
                    let itemArr = item.split("x");
                    return (
                      <>
                        <List.Item style={{ display: "block" }}>
                          <Paragraph
                            copyable={{ tooltips: false }}
                          >{`<script src="${Config.AdUrl}tag/p/${editDisplayPrivateBuy.displayPrivateBuyId}.js?w=${itemArr[0]}&h=${itemArr[1]}&${queryParms}"></script>`}</Paragraph>
                          <Paragraph
                            copyable={{ tooltips: false }}
                          >{`<iframe src="${Config.AdUrl}iframe/p/${editDisplayPrivateBuy.displayPrivateBuyId}.js?w=${itemArr[0]}&h=${itemArr[1]}&${queryParms}" width=${itemArr[0]} height=${itemArr[1]}></iframe>`}</Paragraph>
                        </List.Item>
                        <Divider />
                      </>
                    );
                  }}
                />
              </Card>
            </Col>
          </Row>
        ) : editVideoPrivateBuy ? (
          <Row gutter={16}>
            <Col span={20}>
              <Card title="Tags">
                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                  <Select defaultValue="DFP" style={{ width: 120 }} onChange={handleSelectChange}>
                    {displayTagOptions.map((option) => (
                      currentUserPermissionMap[currentUserRole]["canUpdatePrivateBuyTag"] ? (
                        <Option key={option}>{option}</Option>
                      ) : (
                        ""
                      )
                    ))
                    }
                  </Select>
                </Card.Grid>
                <Divider />

                <Card.Grid className="space-sec-15" hoverable={false} style={{ ...gridStyle }}>
                  <Title level={5}>VPAID: </Title>
                  <Paragraph copyable={{ tooltips: false }} >
                    {`${Config.AdUrl}video/p/${editVideoPrivateBuy.videoPrivateBuyId}`}
                  </Paragraph>
                  {/* <Title level={5}>VAST: </Title>
                  <Paragraph copyable={{ tooltips: false }} >
                    {`${Config.AdUrl}video/p/${editVideoPrivateBuy.videoPrivateBuyId}?formats=mp4`}
                  </Paragraph> */}
                  <Title level={5}>VAST MOAT Wrapper: </Title>
                  <Paragraph copyable={{ tooltips: false }} >
                    {`${Config.AdUrl}video/p/${editVideoPrivateBuy.videoPrivateBuyId}?vastMoatSupported=true&formats=mp4`}
                  </Paragraph>
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrivateBuyDetails);
