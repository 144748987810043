import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const addPrimaryContact = (data) => {
    return AxiosAuth.post(
      Config.API + "/api/campaigns/addPrimaryContact",
      JSON.stringify(data),
      undefined
    );
  };

  export default {
      addPrimaryContact,
  };