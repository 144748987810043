import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, InputNumber } from "antd";
import { closeNewConversionPixelModal, addConversionPixel } from "../../../redux/actions/conversion-pixel-actions";

const { TextArea } = Input;
const AddConversionPixelModal = ({ isAddConversionPixelModalOpen, accountId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      console.log("AddConversionPixelModal Unmounting");
    };
  }, []);

  const handleSave = (values) => {
    values["accountId"] = accountId;
    dispatch(addConversionPixel(values))
    dispatch(closeNewConversionPixelModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewConversionPixelModal());
  };

  return (
    <>
      <Modal
        title={"New Conversion Pixel"}
        visible={isAddConversionPixelModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{ name: "", postImpressionTtl: 1, postClickTtl: 30 }}
          onFinish={handleSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input pixel name!",
                whitespace: true,
              },
            ]}
          >
            <TextArea showCount maxLength={255} placeholder="Add Pixel Name" autoSize />
          </Form.Item>
          <Form.Item
            label="Post-Impression TTL"
            name="postImpressionTtl"
            rules={[
              {
                required: true,
                message: `Please input for Post-Impression TTL!`,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Post-Click TTL"
            name="postClickTtl"
            rules={[
              {
                required: true,
                message: `Please input for Post-Click TTL!`,
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddConversionPixelModalOpen: state.conversionPixel.isAddConversionPixelModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewConversionPixelModal: () => dispatch(closeNewConversionPixelModal()),
    addConversionPixel: (values) => dispatch(addConversionPixel(values)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddConversionPixelModal);
