import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeBlacklistWhitelistPublisherModal, updateCampaign } from "./../../../redux/actions/campaign-actions";
import { useParams } from "react-router-dom";

const CampaignBlacklistPublisherModal = ({
  publisherList,
  selectedPublisherList,
  publisherType,
  isBlacklistWhitelistPublisherModalOpen,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const selectValuesTemp = [];
    if (selectedPublisherList && selectedPublisherList.length > 0) {
      selectedPublisherList.filter(function (obj) {
        selectValuesTemp.push(obj.publisherId);
      });
      setSelectedValues(selectValuesTemp);
    }
    return () => {
      console.log("CampaignBlacklistPublisherModal Unmounting");
    };
  }, [selectedPublisherList, isBlacklistWhitelistPublisherModalOpen]);

  const handleSave = (values) => {
    let campaignData = {};
    campaignData["isDisplayPrivateBuysUpdated"] = false;
    campaignData["isVideoPrivateBuysUpdated"] = false;
    campaignData["isDisplaySiteBuysUpdated"] = false;
    campaignData["isVideoSiteBuysUpdated"] = false;
    campaignData["isPublishersUpdated"] = true;
    if (publisherType === "Blacklist") {
      campaignData["blacklistPublisherIds"] = selectedValues
    } else {
      campaignData["whitelistPublisherIds"] = selectedValues
    }
    console.log(campaignData);
    dispatch(updateCampaign(id, campaignData));
    form.resetFields();
    dispatch(closeBlacklistWhitelistPublisherModal());
    setSelectedValues([]);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeBlacklistWhitelistPublisherModal());
    setSelectedValues([]);
  };

  const onChange = (e) => {
    const selectValuesTemp = selectedValues;
    if (selectValuesTemp.indexOf(e.target.value) > -1) {
      selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
    } else {
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  const isPublisherExists = (publisherId) => {
    var filtered = selectedValues.filter(function (obj) {
      return publisherId === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  function _bindPublishers(publishers) {
    return publishers.map((publisher, index) => {
      return (
        <Row  key={publishers}>

          <Col span={24}>
            <Checkbox
              checked={isPublisherExists(publisher.publisherId)}
              onChange={onChange}
              key={publisher.publisherId}
              value={publisher.publisherId}
            >
              {publisher.name}
            </Checkbox>
          </Col>
        </Row>
      );
    });
  }

  return (
    <>
      <Modal
        title={"Publisher List"}
        visible={isBlacklistWhitelistPublisherModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            accountIds: selectedValues,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item name="publisherIds" label="Select Publisher">
            {publisherList && _bindPublishers(publisherList)}
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isBlacklistWhitelistPublisherModalOpen: state.campaign.isBlacklistWhitelistPublisherModalOpen,
    publisherType: state.campaign.publisherType,
    selectedPublisherList: state.campaign.selectedPublisherList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeBlacklistWhitelistPublisherModal: () => dispatch(closeBlacklistWhitelistPublisherModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CampaignBlacklistPublisherModal
);
