import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { Row, Col, Input, Badge, Menu, Dropdown, AutoComplete } from "antd";
import { SettingOutlined, LogoutOutlined, ProfileOutlined,  } from "@ant-design/icons";
import { connect } from "react-redux";
import { signOut } from "./../../redux/actions/auth-actions";
import { useDispatch } from "react-redux";
import Logo from "../../../app/assets/images/pk4media-logo.svg";
import { searchByKeyword } from "../../redux/actions/header-actions";
import { Link } from "react-router-dom";
import UserService from "../../services/user-service";

const Header = ({ loggedInUser, isSideBarCollapse, searchResult }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const currentUser = UserService.getCurrentUser();

  useEffect(() => {

    if (searchText === undefined || searchText === "") {
      setOptions([]);
    } else {
      if (searchResult && searchResult.length > 0) {
        var tempOptions = [];
        searchResult.map((result) => {
          tempOptions.push({
            key: result.id,
            value: result.name,
            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>
                  <Link to={getRedirectUrl(result)}>{result.name}</Link>
                </span>
                <span>{result.type}</span>
              </div>
            ),
          });
        });
        setOptions(tempOptions);
      } else {
        setOptions([]);
      }
    }

    return () => {
      console.log("Header unmounting");
    };
  }, [searchResult,]);

  const menu = (
    <Menu className="notification-menu">
      <h3>Account Settings</h3>
      <Menu.Item
        key="1">
        <ProfileOutlined />
        <Link to={currentUser && ('/edit-users/' + currentUser['id'])}>
          View Profile
        </Link>
      </Menu.Item>
      <Menu.Item onClick={(e) => handleSignOut(e)} key="2">
        <LogoutOutlined />
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const handleSignOut = (event) => {
    dispatch(signOut());
  };

  function getRedirectUrl(result) {
    var url = "";
    if (result && result.type && result.id) {
      if (result.type === "Account") {
        url = `/accounts/${result.id}`;
      } else if (result.type === "Publisher") {
        url = `/publishers/${result.id}`;
      } else if (result.type === "Campaign") {
        url = `/campaigns/${result.id}`;
      } else if (result.type === "Display Line") {
        url = `/lines/display/${result.id}`;
      } else if (result.type === "Video Line") {
        url = `/lines/video/${result.id}`;
      } else if (result.type === "Display Private Buy") {
        url = `/privateBuys/display/${result.id}`;
      } else if (result.type === "Video Private Buy") {
        url = `/privateBuys/video/${result.id}`;
      } else if (result.type === "Display Site Buy") {
        url = `/siteBuys/display-site-buy/${result.id}`;
      } else if (result.type === "Video Site Buy") {
        url = `/siteBuys/video-site-buy/${result.id}`;
      }
    }
    return url;
  }

  async function handleSearch(value) {
    setSearchText(value);
    if (value) {
      await dispatch(searchByKeyword(value));
    } else {
      setOptions([]);
    }
  };

  return (
    <div id={"header"} className={isSideBarCollapse ? "fixed" : "collapsed-fixed"}>
      <Row className="header-row">
        {loggedInUser ? (
          <Col md={2} lg={2} xl={2}>
            <span className="sidemenu-trigger"></span>
          </Col>
        ) : (
          <span className="sidemenu-trigger">
            <img style={{ maxWidth: "200px", maxHeight: "50px" }} src={Logo} alt="Logo" />
          </span>
        )}
        {loggedInUser ? (
          <Col md={12} lg={10} xl={10} className="search-sec">
            <AutoComplete
              style={{
                width: 500,
              }}
              options={options}
              onSearch={handleSearch}
            >
              <Input.Search size="large" placeholder="Search" enterButton />
            </AutoComplete>
          </Col>
        ) : (
          ""
        )}

        <Col md={2} lg={2} xl={2} className="header-right">
          {loggedInUser ? (
            <Dropdown overlay={menu} className="account-setting">
              <Badge>
                <a href="setting" onClick={(e) => e.preventDefault()}>
                  <SettingOutlined />
                </a>
              </Badge>
            </Dropdown>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    searchResult: state.header.searchResult,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
