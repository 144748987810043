import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeBlacklistWhitelistAccountModal, updatePublisher } from "./../../../redux/actions/publisher-actions";
import { useParams } from "react-router-dom";

const PublisherBlacklistAccountModal = ({
  accountList,
  selectedAccountList,
  accountType,
  isBlacklistWhitelistAccountModalOpen,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const selectValuesTemp = [];
    if (selectedAccountList && selectedAccountList.length > 0) {
      selectedAccountList.filter(function(obj) {
        selectValuesTemp.push(obj.accountId);
      });
      setSelectedValues(selectValuesTemp);
    }
    return () => {
      console.log("PublisherBlacklistAccountModal Unmounting");
    };
  }, [selectedAccountList, isBlacklistWhitelistAccountModalOpen]);

  const handleSave = (values) => {
    let publisherData = {};
    publisherData["isAccountsUpdated"] = true
    if(accountType === "Blacklist"){
      publisherData["blacklistAccountIds"] = selectedValues
    } else {
      publisherData["whitelistAccountIds"] = selectedValues
    }
    console.log(publisherData);
    dispatch(updatePublisher(id, publisherData));
    form.resetFields();
    dispatch(closeBlacklistWhitelistAccountModal());
    setSelectedValues([]);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeBlacklistWhitelistAccountModal());
    setSelectedValues([]);
  };

  const onChange = (e) => {
    const selectValuesTemp = selectedValues;
    if (selectValuesTemp.indexOf(e.target.value) > -1) {
      selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1);
    } else {
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  const isAccountExists = (accountId) => {
    var filtered = selectedValues.filter(function(obj) {
      return accountId === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  function _bindAccounts(accounts) {
    return accounts.map((account, index) => {
      return (
        <Row key={accounts}>
          <Col span={24}>
            <Checkbox
              checked={isAccountExists(account.accountId)}
              onChange={onChange}
             // key={account.accountId}
              value={account.accountId}
            >
              {account.name}
            </Checkbox>
          </Col>
        </Row>
      );
    });
  }

  return (
    <>
      <Modal
        title={"Account List  " + accountType}
        visible={isBlacklistWhitelistAccountModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={{
            accountIds: selectedValues,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item name="accountIds" label="Select Account">
            {accountList && _bindAccounts(accountList)}
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isBlacklistWhitelistAccountModalOpen: state.publisher.isBlacklistWhitelistAccountModalOpen,
    accountType: state.publisher.accountType,
    selectedAccountList:state.publisher.selectedAccountList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeBlacklistWhitelistAccountModal: () => dispatch(closeBlacklistWhitelistAccountModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublisherBlacklistAccountModal
);
