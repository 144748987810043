import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Checkbox, Row, Col } from "antd";
import { closeAssignPublisherModal, updateUser  } from "./../../../redux/actions/user-actions";
import { useParams } from "react-router-dom";

const PublisherPrivilegeModal = ({ publisherList, selectedPublisherList, isAssignPublisherModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedValues, setSelectedValues] = useState([]);
  const [isSet, setIsSet] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const selectValuesTemp = [];
    if (selectedPublisherList && selectedPublisherList.length > 0) {
        selectedPublisherList.filter(function(obj) {
        selectValuesTemp.push(obj.publisherId);
      });
      setSelectedValues(selectValuesTemp);
    }
    return () => {
      console.log("PublisherPrivilegeModal Unmounting");
    };
  }, [selectedPublisherList]);

  const handleSave = (values) => {
    values["isPrivilegesUpdated"] = true;
    dispatch(updateUser(id, values));
    form.resetFields();
    dispatch(closeAssignPublisherModal());
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeAssignPublisherModal());
  };

  const onChange = (e) => {
    const selectValuesTemp = selectedValues;
    if(selectValuesTemp.indexOf(e.target.value) > -1) {
      selectValuesTemp.splice(selectValuesTemp.indexOf(e.target.value), 1)      
    } else {      
      selectValuesTemp.push(e.target.value);
    }
    setSelectedValues(selectValuesTemp);
    setIsSet(!isSet);
  };

  const isPublisherExists = (publisherId) => {
    var filtered = selectedValues.filter(function(obj) {
      return publisherId === obj;
    });
    return filtered.length > 0 ? true : false;
  };

  function _bindPublishers(publihsers) {
    return publihsers.map((publisher, index) => {
      return (
        <Row>
          <Col span={24}>
            <Checkbox checked={isPublisherExists(publisher.publisherId)} value={publisher.publisherId} onChange={onChange}>{publisher.name}</Checkbox>
          </Col>
        </Row>
      );
    });
  }

  return (
    <>
      <Modal title="Assign Publisher Privileges" visible={isAssignPublisherModalOpen} onCancel={handleCancel} footer={[]}>
        <Form
          form={form}
          initialValues={{
            publisherIds: selectedValues,
          }}
          layout={"vertical"}
          onFinish={handleSave}
        >
          <Form.Item name="publisherIds" label="Select Publisher">
            {publisherList && _bindPublishers(publisherList)}
          </Form.Item>
          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAssignPublisherModalOpen: state.user.isAssignPublisherModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeAssignPublisherModal: () => dispatch(closeAssignPublisherModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(PublisherPrivilegeModal);
