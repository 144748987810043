import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Space, Input, DatePicker, Select } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Radio, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import {
  exportDataReport,
  closeExportReportModal,
} from "../../../redux/actions/export-report-actions";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { timezones } from "../../../constants/defaultValues";
import { loadCurrentLoggedInUser } from "../../../redux/actions/user-actions";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import { getDayEndDateTime, getDayStartDateTime, getExportEndDateTime, getExportStartDateTime, getUTCDate } from "../../../utils/appUtils";

const { Option } = Select;
const EditExportReport = ({
  isExportReportModalOpen,
  currentLoggedInUser,
  type,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
  const [reportType, setReportType] = useState("Date");
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const [startEndDate, setStartEndDate] = useState([
    new Date().toDateString(),
    new Date().toDateString(),
  ]);

  useEffect(() => {
    dispatch(loadCurrentLoggedInUser());

    if (currentLoggedInUser) {
      var temp = [];
      var values = {};
      temp.push(currentLoggedInUser.email);
      values = {
        mailRecipients: temp,
      };
      form.setFieldsValue(values);
    }
    return () => {
      console.log("Edit Export report Unmounting");
    };
  }, [isExportReportModalOpen]);

  const handleSave = (values) => {
    values["startDate"] = new Date(getUTCDate(new Date(startEndDate[0]).getTime())).toUTCString();
    values["endDate"] = new Date(getUTCDate(new Date(startEndDate[1]).getTime())).toUTCString();
    values["filter"] = reportType;
    values["id"] = id;
    values["type"] = type;
    if(!values["timezone"]) {
      values["timezone"] = "America/Los_Angeles";
    }
    
    dispatch(exportDataReport(values));
    form.resetFields();
    setReportType("Date");
    dispatch(closeExportReportModal());
  };

  const handleCancel = () => {
    setStartEndDate([]);
    form.resetFields();
    dispatch(closeExportReportModal());
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  const onChange = (value, dateString) => {
    var startTime = dateString[0] && dateString[0] !== null ? getExportStartDateTime(new Date(dateString[0])) : getDayStartDateTime(new Date());
    var endTime = dateString[1] && dateString[1] !== null ? getExportEndDateTime(new Date(dateString[1])) : getDayEndDateTime(new Date());
    var dateArray = [];
    dateArray.push(startTime);
    dateArray.push(endTime);

    setStartEndDate(dateArray);
    var startDate = moment(dateArray[0]).startOf("month");
    var endDate = moment(dateArray[1]);

    while (startDate.isBefore(endDate)) {
      startDate.add(1, "month");
    }
    var mStartDate = moment();

    var myStartDate = new Date(startTime);
    var newStartDate = moment(myStartDate);

    mStartDate.set(newStartDate.toObject());


    var mEndDate = moment();

    var myEndDate = new Date(endTime);
    var newEndDate = moment(myEndDate);

    mEndDate.set(newEndDate.toObject());
    var valueArray = [];
    valueArray.push(mStartDate);
    valueArray.push(mEndDate);

    form.setFieldsValue({ date: valueArray });
  };

  const onChangeRadio = (e) => {
    setReportType(e.target.value);
  };

  return (
    <>
      <Modal
        title={"Export Report"}
        visible={isExportReportModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form form={form} layout={"vertical"} onFinish={handleSave}>
          <Form.Item name="filter" label="Report Type">
            <Row gutter={16}>
              <Col xs={16} sm={16} md={16} lg={8} xl={8}>
                {currentUserPermissionMap[currentUserRole]["canAccessExportReportRadio"] ? (
                  <Radio.Group defaultValue={"Date"} onChange={onChangeRadio}>
                    {type && type === "publisher" ? (
                      <Radio value={"Date"}>Delivery</Radio>
                    ) : type ? (
                      <Radio value={"Date"}>Date Report</Radio>
                    ) : (
                      ""
                    )}
                    <Radio value={"Geo"}>Geo Report</Radio>
                    <Radio value={"Device"}>Device Report</Radio>
                    <Radio value={"Domain"}>Domain Report</Radio>
                    <Radio value={"Site"}>Site Report</Radio>
                  </Radio.Group>
                ) : (
                  <Radio.Group defaultValue={"Date"} onChange={onChangeRadio}>
                    {type && type === "publisher" ? (
                      <Radio value={"Date"}>Delivery</Radio>
                    ) : type ? (
                      <Radio value={"Date"}>Date Report</Radio>
                    ) : (
                      ""
                    )}
                  </Radio.Group>
                )
                }
              </Col></Row>
          </Form.Item>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: `Please select start and end date!`,
                  },
                ]}
              >
                <RangePicker format={dateFormat} onChange={onChange}
                  disabledDate={(date) => date > new Date()}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                label="Timezone"
                name="timezone"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select timezone!",
                //   },
                // ]}
              >
                <Select
                  defaultValue={"America/Los_Angeles"}
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {timezonesOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {currentLoggedInUser && (
            <Form.Item
              className="creative-box"
              label="Recipients"
              name="mailRecipients"
            >
              <Form.List name="mailRecipients">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name]}
                          fieldKey={[fieldKey, "recipients"]}
                          rules={[
                            {
                              required: true,
                              message: "Please add atleast one recipient",
                            },
                          ]}
                        >
                          <Input placeholder="recipients" />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          )}

          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Export
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    isExportReportModalOpen: state.exportReport.isExportReportModalOpen,
    currentLoggedInUser: state.user.currentLoggedInUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCurrentLoggedInUser: () => dispatch(loadCurrentLoggedInUser()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditExportReport
);
