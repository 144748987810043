import { Form, Input, Button, Checkbox, Row, Col, Typography, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { signin } from "../../redux/actions/auth-actions";
import { useDispatch } from "react-redux";
import { notifyUser } from "../../services/notification-service";
import Logo from "../../../app/assets/images/pk4media-logo.svg";
import CryptoJS from "crypto-js";
import { secretKey } from "../../constants/defaultValues";

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("loggedUser")) {
      var values = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("loggedUser"), secretKey).toString(CryptoJS.enc.Utf8));
      form.setFieldsValue(values);
      setIsChecked(values.remember);
    } 

    return () => {
      console.log("Login Unmounting");
    };
  }, []);

  const onFinish = async (values) => {
    setSubmitted(true);
    console.log({ values })
    const response = await dispatch(signin(values.email, values.password));
    if (response.error && response.error !== "") {
      if (response.error === "Error: User disabled") {
        notifyUser(response.error, "error");
        setSubmitted(false);
      } else {
        notifyUser("Invalid Credentials", "error");
        setSubmitted(false);
      }
    } else {
      if (response.redirect && response.redirect !== "" && response.redirect === "/") {
        if(isChecked) {
          localStorage.setItem("loggedUser", CryptoJS.AES.encrypt(JSON.stringify(values), secretKey).toString());
        }
        notifyUser("Logged In successfully", "success");
        history.push(response.redirect);
      } else {
        notifyUser("InValid Credentials", "error");
        setSubmitted(false);
      }
    }
  };

  const onRememberMeChange = (e) => {
    setIsChecked(e.target.checked)
    if(!e.target.checked) {
      localStorage.removeItem("loggedUser");
    }
  }

  return (
    <>
      <Row gutter={30} className="login-page">
        <Col xs={24} sm={24} md={12} className="pull-right">
          <div className="login-form">
            <div className="sidemenu-trigger logo-login">
              <img style={{ maxWidth: "200px" }} src={Logo} alt="Logo" />
            </div>
            <Row gutter={24}>
              <Col xs={24}>
                <Typography.Title level={4}>Login</Typography.Title>
              </Col>
            </Row>
            <Spin spinning={submitted}></Spin>
            <Form
              form={form}
              name="normal_login"
              className="login_form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="unchecked" noStyle>
                  <Checkbox checked={isChecked} onChange={onRememberMeChange}>Remember me</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button btn-style">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signin: (email, password) => dispatch(signin(email, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
