import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { List, Row, Col, Button, Switch, Modal } from "antd";
import { openUpdateTagDisplayCreativeModal, updateTagDisplayCreative } from "../../../redux/actions/creative-actions";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import { getLocalDate } from "../../../utils/appUtils";

const { confirm } = Modal;

const EditTagDisplayCreativeDetail = ({ editTagDisplayCreative }) => {
  const dispatch = useDispatch();
  const [pauseValue, setPauseValue] = useState(false);
  const { id } = useParams();
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  useEffect(() => {
    if (editTagDisplayCreative && editTagDisplayCreative.tagDisplayCreativeVersion) {
      setPauseValue(
        editTagDisplayCreative.tagDisplayCreativeVersion.paused !== null
          ? editTagDisplayCreative.tagDisplayCreativeVersion.paused
          : false
      );
    }
  }, []);

  function _bindCreativeProps(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType !== "Retargeting Pixel" ?
                <ul id={propType}>
                  <li className="no-list-style click-url">{propType && propValue}</li>
                </ul> :
                <ul>
                  <li className="no-list-style">
                    {editTagDisplayCreative.tagDisplayCreativeVersion.retargetingPixel ?
                      editTagDisplayCreative.tagDisplayCreativeVersion.retargetingPixel.name : ""}
                  </li></ul>
              }
              {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div class="card-footer">
                  <Button
                    type="link"
                    onClick={() => dispatch(openUpdateTagDisplayCreativeModal(propType))}
                  >
                    Edit
                  </Button>
                  {propType === "Retargeting Pixel" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                  {propType === "Target State" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button> : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function removeValues(propType) {
    if (propType === "Target State") {
      dispatch(updateTagDisplayCreative(id, { "targetingState": "" }))
    } else if (propType === "Retargeting Pixel") {
      dispatch(updateTagDisplayCreative(id, { "retargetingPixelId": "" }))
    }
  }

  function showConfirm() {
    if (pauseValue === false) {
      confirm({
        title: "Are you sure you want to pause?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setPauseValue(true);
          dispatch(updateTagDisplayCreative(id, { "paused": true }))
        },
        onCancel() {
          setPauseValue(pauseValue);
        },
      });
    } else {
      setPauseValue(false);
      dispatch(updateTagDisplayCreative(id, { "paused": false }))
      return;
    }
  }

  return (
    <>
      {/* {_bindCreativeProps("Source", editTagDisplayCreative.tagDisplayCreativeVersion.source && JSON.parse('"' + editTagDisplayCreative.tagDisplayCreativeVersion.source + '"'))} */}
      {_bindCreativeProps("Source", editTagDisplayCreative.tagDisplayCreativeVersion.source)}

      {_bindCreativeProps(
        "Flight",
        moment
          .unix(getLocalDate(editTagDisplayCreative.tagDisplayCreativeVersion.startAt) / 1000)
          .format("DD MMM, YYYY") +
        " - " +
        moment
          .unix(getLocalDate(editTagDisplayCreative.tagDisplayCreativeVersion.endAt) / 1000)
          .format("DD MMM, YYYY")
      )}
      {_bindCreativeProps("Weight", editTagDisplayCreative.tagDisplayCreativeVersion.weight)}
      {_bindCreativeProps(
        "Click URL",
        editTagDisplayCreative.tagDisplayCreativeVersion.clickUrl !== null && editTagDisplayCreative.tagDisplayCreativeVersion.clickUrl !== ""
          ? editTagDisplayCreative.tagDisplayCreativeVersion.clickUrl
          : "N/A"
      )}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Impression Pixels"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button
                    type="link"
                    onClick={() => dispatch(openUpdateTagDisplayCreativeModal("Impression Pixels"))}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editTagDisplayCreative &&
                editTagDisplayCreative.tagDisplayCreativeVersion.displayImpressionPixels !== null
                ? JSON.parse(
                  editTagDisplayCreative.tagDisplayCreativeVersion.displayImpressionPixels
                )
                : []
            }
            renderItem={(pixel) => <List.Item>{pixel.format + " : " + pixel.source}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button
                    type="link"
                    onClick={() => dispatch(openUpdateTagDisplayCreativeModal("Devices"))}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editTagDisplayCreative &&
                editTagDisplayCreative.tagDisplayCreativeVersion.devices !== null
                ? JSON.parse(editTagDisplayCreative.tagDisplayCreativeVersion.devices)
                : []
            }
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="white-box">
              <div className="title">
                <h2>Paused</h2>
              </div>
              <div className="card-body">
                <ul id="paused">
                  <li className="no-list-style">
                    <Switch
                      key={pauseValue}
                      value={pauseValue}
                      defaultChecked={pauseValue}
                      onClick={showConfirm}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editTagDisplayCreative &&
        _bindCreativeProps("Retargeting Pixel",
          editTagDisplayCreative.tagDisplayCreativeVersion.retargetingPixelId ||
            editTagDisplayCreative.tagDisplayCreativeVersion.retargetingPixelId != null ?
            editTagDisplayCreative.tagDisplayCreativeVersion.retargetingPixel : "N/A")}
      {editTagDisplayCreative &&
        _bindCreativeProps("Target State",
          editTagDisplayCreative.tagDisplayCreativeVersion.targetingState !== null ||
            editTagDisplayCreative.tagDisplayCreativeVersion.targetingState !== "" ?
            editTagDisplayCreative.tagDisplayCreativeVersion.targetingState : "N/A")}
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditTagDisplayCreativeDetail);
