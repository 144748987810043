import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { openNewSiteBuyModal, clearSiteBuyStates } from "../../redux/actions/site-buy-actions";
import {
  loadPublisherById,
  openBlacklistWhitelistAccountModal,
  updatePublisher,
  openNewPublisherModal,
  clearEditPublisherStates,
} from "../../redux/actions/publisher-actions";
import { loadAccounts } from "../../redux/actions/account-actions";
import { openNewConversionPiggybackPixelModal, clearConversionPixelStates, updateConversionPiggybackPixel } from "../../redux/actions/conversion-pixel-actions";
import { openNewRetargetingPiggybackPixelModal, clearRetartgetingPixelStates } from "../../redux/actions/retargeting-pixel-actions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { piggybackPixelFormats, timezones } from "./../../constants/defaultValues";
import { List, Row, Col, Breadcrumb, Button, PageHeader, Form, Select, Spin, Modal, Input } from "antd";
import { compose } from "redux";
import AddCampaignModal from "./../../components/modals/campaign/add-campaign";
import AddSiteBuyModal from "./../../components/modals/site-buy/add-site-buy";
import { notifyUser } from "./../../services/notification-service";
import PublisherBlacklistAccountModal from "../../components/modals/publisher-blacklist-account/publisher-blacklist-account";
import AddConversionPiggybackPixelModal from "../../components/modals/conversion-piggyback-pixel/add-conversion-piggyback-pixel";
import AddRetargetingPiggybackPixelModal from "../../components/modals/retargeting-piggyback-pixel/add-retargeting-piggyback-pixel";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";
import { openExportReportModal, clearExportReportStates } from "../../redux/actions/export-report-actions";
import EditExportReportModal from "../../components/modals/export-report/edit-export-report";
import AddPublisherModal from "../../components/modals/publisher/add-publisher";
const { Option } = Select;

const EditPublisher = ({
  editPublisher,
  siteBuyAdded,
  siteBuyMessageOnAdd,
  accountList,
  publisherUpdated,
  conversionPiggybackPixelAdded,
  conversionPiggybackPixelUpdated,
  retargetingPiggybackPixelAdded,
  reportExported,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isTimezoneEdit, setIsTimezoneEdit] = useState(false);
  const [timezone, setTimezone] = useState(null);
  const [form] = Form.useForm();
  const [pixelForm] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";
  useEffect(() => {
    let isMounted = true;

    (async () => {
      setSubmitted(true);
      await dispatch(loadPublisherById(id));
      await dispatch(loadAccounts());
      if (isMounted) {

        setSubmitted(false);
      }
    })();

    if (siteBuyAdded !== undefined) {
      if (siteBuyAdded === true) {
        notifyUser(siteBuyMessageOnAdd, "success");
      } else {
        notifyUser(siteBuyMessageOnAdd, "error");
      }
    }

    if (publisherUpdated !== undefined) {
      if (publisherUpdated.success === true) {
        notifyUser(publisherUpdated.message, "success");
      } else {
        notifyUser(publisherUpdated.message, "error");
      }
    }
    if (conversionPiggybackPixelAdded !== undefined) {
      if (conversionPiggybackPixelAdded.success === true) {
        notifyUser(conversionPiggybackPixelAdded.message, "success");
      } else {
        notifyUser(conversionPiggybackPixelAdded.message, "error");
      }
    }
    if (conversionPiggybackPixelUpdated !== undefined) {
      if (conversionPiggybackPixelUpdated.success === true) {
        notifyUser(conversionPiggybackPixelUpdated.message, "success");
      } else {
        notifyUser(conversionPiggybackPixelUpdated.message, "error");
      }
    }

    if (retargetingPiggybackPixelAdded !== undefined) {
      if (retargetingPiggybackPixelAdded.success === true) {
        notifyUser(retargetingPiggybackPixelAdded.message, "success");
      } else {
        notifyUser(retargetingPiggybackPixelAdded.message, "error");
      }
    }
    if (reportExported !== undefined) {
      console.log("reportExported----", reportExported)
      if (reportExported.success === true) {
        notifyUser(reportExported.message, "success");
      } else {
        notifyUser(reportExported.message, "error");
      }
    }

    return () => {
      isMounted = false;

      console.log("EditPublisher Unmounting");
      dispatch(clearEditPublisherStates());
      dispatch(clearConversionPixelStates());
      dispatch(clearRetartgetingPixelStates());
      dispatch(clearSiteBuyStates());
      dispatch(clearExportReportStates());
    };
  }, [
    siteBuyAdded,
    siteBuyMessageOnAdd,
    publisherUpdated,
    conversionPiggybackPixelAdded,
    conversionPiggybackPixelUpdated,
    retargetingPiggybackPixelAdded,
    reportExported,
  ]);

  const handleSave = (values) => {
    var payload = {};
    payload["name"] = editPublisher.name;
    payload["isAccountsUpdated"] = false;
    payload["timezone"] = timezone;
    dispatch(updatePublisher(editPublisher.publisherId, payload));
    form.resetFields();
    setTimezone(timezone);
    setIsTimezoneEdit(!isTimezoneEdit);
  };

  const onTimezoneChange = (value) => {
    console.log("Checked !" + value);
    setTimezone(value);
  };

  const timezonesOptions = timezones.map((timezone) => (
    <Option key={timezone} value={timezone}>
      {timezone}
    </Option>
  ));

  function _bindSiteBuyList(siteBuyType, siteBuyList) {
    const header = siteBuyList.length + " " + (siteBuyType === "display" ? "Display" : "Video") + " Site Buys";
    const buttonLink = "New " + siteBuyType + " Site Buy";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              <div>
                {currentUserPermissionMap[currentUserRole]["canCreateSiteBuy"] ? (
                  <Button onClick={() => dispatch(openNewSiteBuyModal(siteBuyType))} type="link">
                    {buttonLink}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            }
            bordered
            dataSource={siteBuyList}
            renderItem={(siteBuy) => (
              <List.Item>
                {siteBuyType === "video" ? (
                  <Link to={`/siteBuys/video-site-buy/${siteBuy.videoSiteBuyId}`}>
                    {siteBuy.name}
                  </Link>
                ) : (
                  <Link to={`/siteBuys/display-site-buy/${siteBuy.displaySiteBuyId}`}>
                    {siteBuy.name}
                  </Link>
                )}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  }

  function _bindPrivateBuyList(privateBuyType, privateBuyList) {
    const header = privateBuyList.length + " " + privateBuyType + " Private Buys";
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            bordered
            dataSource={privateBuyList}
            renderItem={(privateBuy) => (
              <List.Item>
                {currentUserPermissionMap[currentUserRole]["canUpdatePrivateBuy"] ? (
                  privateBuyType === "video" ? (
                    <Link to={`/privateBuys/${privateBuyType}/${privateBuy.videoPrivateBuyId}`}>
                      {privateBuy.name}
                    </Link>
                  ) : (
                    <Link to={`/privateBuys/${privateBuyType}/${privateBuy.displayPrivateBuyId}`}>
                      {privateBuy.name}
                    </Link>
                  )
                ) : (
                  privateBuy.name

                )}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    );
  }

  function _bindAccountList(accountType, accountList) {
    const header = accountList.length + " " + accountType + " Accounts";
    return  currentUserRole !== 'ROLE_PUBLISHER_OBSERVER' ?(
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdatePublisherBlacKlistWhitelistAccount"] ? (
                <div>
                  {accountType ? (
                    <Button
                      onClick={() =>
                        dispatch(openBlacklistWhitelistAccountModal(accountType, accountList))
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        dispatch(openBlacklistWhitelistAccountModal(accountType, accountList))
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={accountList}
            renderItem={(account) => <List.Item>{account.name}</List.Item>}
          />
        </Col>
      </Row>
    ):" ";
  }

  function _bindPiggybackPixelList(pixelType, piggybackPixels) {
    const header =
      piggybackPixels.length > 0
        ? piggybackPixels.length + " " + pixelType + " Piggyback Pixels"
        : piggybackPixels.length + " " + pixelType + " Piggyback Pixel";
    return currentUserRole !== 'ROLE_PUBLISHER_OBSERVER' ?(
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={header}
            footer={
              currentUserPermissionMap[currentUserRole]["canCreatePublisherPiggyBackPixel"] ? (
                <div>
                  {pixelType === "Conversion" ? (
                    <Button
                      onClick={() => dispatch(openNewConversionPiggybackPixelModal())}
                      type="link"
                    >
                      {"New " + pixelType + " Piggyback Pixel"}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => dispatch(openNewRetargetingPiggybackPixelModal())}
                      type="link"
                    >
                      {"New " + pixelType + " Piggyback Pixel"}
                    </Button>
                  )}
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={piggybackPixels}
            renderItem={(pixel) => (
              <List.Item>
                <Link onClick={() => pixelInfo(pixel, pixelType)}>
                  {pixel.publisher.name}
                  {" - "}
                  {pixelType === "Conversion"
                    ? pixel.conversionPixel.name
                    : pixel.retargetingPixel.name}
                </Link>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    ):" ";
  }

  function pixelInfo(pixel, pixelType) {
    const formatOptions = piggybackPixelFormats.map((format) => (
      <Option key={format} value={format}>
        {format}
      </Option>
    ));

    const updatePiggybackPixel = (values, pixel, pixelType) => {
      console.log({values})
      if(pixelType === "Conversion") {
        values.conversionPiggybackPixelId = pixel.conversionPiggybackPixelId;
        dispatch(updateConversionPiggybackPixel(values));
      } else {
        values.retargetingPiggybackPixelId = pixel.retargetingPiggybackPixelId;
        // dispatch(updateRetargetingPiggybackPixel(values));
      }
      pixelForm.resetFields();
      Modal.destroyAll();
    };

    Modal.info({
      title: null,
      icon: null,
      content: (
        <div className="card-body">
          <Form form={pixelForm} layout={"vertical"} onFinish={handleSave} initialValues={pixel}>
            <>
              {pixelType === "Conversion" ? (
                <Form.Item label="Pixel" name={["conversionPixel", "name"]}>
                  <Input disabled={true} />
                </Form.Item>
              ) : (
                <Form.Item label="Pixel" name={["retargetingPixel", "name"]}>
                  <Input disabled={true} />
                </Form.Item>
              )}
              <Form.Item label="Format" name="format">
                <Select >{formatOptions}</Select>
              </Form.Item>
              <Form.Item label="Source" name="source">
                <Input />
              </Form.Item>
            </>
          </Form>
        </div>
      ),
      onOk() { },
      okText: "Save",
      okButtonProps: {
        onClick: () => {
          pixelForm.validateFields().then(values => {
            updatePiggybackPixel(values, pixel, pixelType); 
          });
        }
      },
      okCancel() { },
    });
  }

  function _bindTimezone(propType, propValue) {
    return currentUserRole !== 'ROLE_PUBLISHER_OBSERVER' ?(
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {!isTimezoneEdit ? (
                <ul id={propType}>
                  <li className="no-list-style">{propValue && propValue !== null ? propValue : "No timezone specified"}</li>
                </ul>
              ) : (
                <Form className="card-form"
                  form={form}
                  layout={"vertical"}
                  initialValues={{ timezone: timezone }}
                  onFinish={handleSave}
                >
                  <Form.Item name="timezone">
                    <Select key={timezone} onChange={onTimezoneChange}>
                      {timezonesOptions}
                    </Select>
                  </Form.Item >
                  <div className="card-footer">
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => {
                        setIsTimezoneEdit(!isTimezoneEdit);
                        setTimezone(propValue);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button type="primary" htmlType="submit" onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                </Form>
              )}
              {!isTimezoneEdit ? (
                <div className="card-footer">
                  {currentUserPermissionMap[currentUserRole]["canUpdatePublisherTimezone"] ? (
                    <Button type="link" onClick={() => setIsTimezoneEdit(!isTimezoneEdit)}>
                      Edit
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
    ):" ";
  }

  function _bindAdServer(propType, displayAdServer, videoAdServer) {
    return currentUserRole !== 'ROLE_PUBLISHER_OBSERVER' ?(
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              <>
                <br />
                <ul id={propType}>
                  <li className="no-list-style">{displayAdServer && displayAdServer !== null ? displayAdServer : "N/A"}</li>
                </ul>
                <div className="heading-space">Display</div>
                <br /><br />
                <ul>
                  <li className="no-list-style">{videoAdServer && videoAdServer !== null ? videoAdServer : "N/A"}</li>
                </ul>
                <div className="heading-space">Video</div>

                <div className="card-footer">
                  {currentUserPermissionMap[currentUserRole]["canCreateOrUpdatePublisherInfo"] ? (
                    <Button type="link" onClick={() => dispatch(openNewPublisherModal())}>
                      Edit
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </>

            </div>
          </div>
        </Col>
      </Row>
    ):" ";
  }

  return (

    <>
      {currentUserPermissionMap[currentUserRole]["canAccessEditPublisher"] ? (
        <section className="white-box-wrapper">
          <Spin size={"large"} spinning={submitted}></Spin>
          {editPublisher ? <><Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/publishers/${editPublisher.publisherId}`}>Publisher</Link>
            </Breadcrumb.Item>
          </Breadcrumb></> : ""}
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <PageHeader className="site-page-header" title={editPublisher && editPublisher.name} />
            </Col>
            <Col className="text-right" xs={24} sm={24} md={12} lg={12} xl={12}>
              <Button className="btn-style" type="primary"
                onClick={() =>
                  dispatch(
                    openExportReportModal()
                  )
                }
              >
                Export Report
              </Button>
            </Col></Row>
          {editPublisher &&
            editPublisher.displaySiteBuys &&
            _bindSiteBuyList("display", editPublisher.displaySiteBuys)}
          {editPublisher &&
            editPublisher.videoSiteBuys &&
            _bindSiteBuyList("video", editPublisher.videoSiteBuys)}
          {editPublisher &&
            editPublisher.displayPrivateBuys &&
            _bindPrivateBuyList("display", editPublisher.displayPrivateBuys)}
          {editPublisher &&
            editPublisher.videoPrivateBuys &&
            _bindPrivateBuyList("video", editPublisher.videoPrivateBuys)}
          {editPublisher && _bindAccountList("Blacklist", editPublisher.blacklistAccounts)}
          {editPublisher && _bindAccountList("Whitelist", editPublisher.whitelistAccounts)}
          {editPublisher &&
            _bindPiggybackPixelList("Retargeting", editPublisher.retargetingPiggybackPixels)}
          {editPublisher &&
            _bindPiggybackPixelList("Conversion", editPublisher.conversionPiggybackPixels)}
          {editPublisher && _bindTimezone("Timezone", editPublisher.timezone)}
          {editPublisher && _bindAdServer("Ad Servers", editPublisher.displayAdServer, editPublisher.videoAdServer)}
          <AddCampaignModal />
          <AddPublisherModal editInfo={editPublisher} />
          <AddSiteBuyModal publisherID={id} />
          <AddConversionPiggybackPixelModal publisherId={id} />
          <AddRetargetingPiggybackPixelModal publisherId={id} />
          <PublisherBlacklistAccountModal
            accountList={accountList}
            selectedAccountList={editPublisher && editPublisher.blacklistAccounts}
          />
          <EditExportReportModal type="publisher" />
        </section>) : "You do not have permission to edit publisher."}
    </>
  );
};

function mapStateToProps(state) {
  return {
    videoSiteBuyList: state.siteBuy.videoSiteBuyList,
    displaySiteBuyList: state.siteBuy.displaySiteBuyList,
    editPublisher: state.publisher.editPublisher,
    siteBuyAdded: state.siteBuy.siteBuyAdded,
    siteBuyMessageOnAdd: state.siteBuy.siteBuyMessageOnAdd,
    accountList: state.account.accountList,
    publisherUpdated: state.publisher.publisherUpdated,
    conversionPiggybackPixelAdded: state.conversionPixel.conversionPiggybackPixelAdded,
    conversionPiggybackPixelUpdated: state.conversionPixel.conversionPiggybackPixelUpdated,
    retargetingPiggybackPixelAdded: state.retargetingPixel.retargetingPiggybackPixelAdded,
    reportExported: state.exportReport.reportExported,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openNewPublisherModal: () => dispatch(openNewPublisherModal()),
    openNewSiteBuyModal: () => dispatch(openNewSiteBuyModal()),
    openBlacklistWhitelistAccountModal: () => dispatch(openBlacklistWhitelistAccountModal()),
    loadPublisherById: () => dispatch(loadPublisherById()),
    loadAccounts: () => dispatch(loadAccounts()),
    openNewConversionPiggybackPixelModal: () => dispatch(openNewConversionPiggybackPixelModal()),
    openNewRetargetingPiggybackPixelModal: () => dispatch(openNewRetargetingPiggybackPixelModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditPublisher);
