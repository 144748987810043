import { Types } from "./../constants/header-types";
import headerApi from "../api/header-api";

// Search by keyword
export const searchByKeyword = (value) => async (dispatch) => {
    try {
        await headerApi.searchByKeyword(value).then(
            (data) => {
                if (data)
                    dispatch({
                        type: Types.SEARCH_RESULT,
                        payload: data
                    });
            },
            (error) => {
                console.log(error);
            }
        );
    } catch (err) { }
};
