import React, { useEffect } from "react";
import { Modal, Button, Form } from "antd";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { Input, Select } from "antd";
import {
  closeNewRetargetingPiggybackPixelModal,
  getAllRetargetingPixels,
  addRetargetingPiggybackPixel
} from "../../../redux/actions/retargeting-pixel-actions";
import { piggybackPixelFormats } from "./../../../constants/defaultValues";
const { Option } = Select;
const AddRetargetingPiggybackPixelModal = ({
  isAddRetargetingPiggybackPixelModalOpen,
  publisherId,
  retargetingPixelList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getAllRetargetingPixels());
    return () => {
      console.log("AddRetargetingPiggybackPixelModal Unmounting");
    };
  }, []);

  const handleSave = (values) => {
    values["publisherId"] = publisherId;
    console.log(values);
    dispatch(addRetargetingPiggybackPixel(values));
    dispatch(closeNewRetargetingPiggybackPixelModal());
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeNewRetargetingPiggybackPixelModal());
  };

  console.log(retargetingPixelList);
  const formatOptions = piggybackPixelFormats.map((format) => (
    <Option key={format} value={format}>
      {format}
    </Option>
  ));

  const conversionPixelOptions =
    retargetingPixelList &&
    retargetingPixelList.map((pixel) => (
      <Option key={pixel.retargetingPixelId} value={pixel.retargetingPixelId}>
        {pixel.name}
      </Option>
    ));

  return (
    <>
      <Modal
        title={"New Retargeting Piggyback Pixel"}
        visible={isAddRetargetingPiggybackPixelModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{ retargetingPixelId: "", format: "", source: "" }}
          onFinish={handleSave}
        >
          <Form.Item
            label="Pixel"
            name="retargetingPixelId"
            rules={[
              {
                required: true,
                message: `Please input pixel!`,
              },
            ]}
          >
            <Select>{conversionPixelOptions}</Select>
          </Form.Item>
          <Form.Item
            label="Format"
            name="format"
            rules={[
              {
                required: true,
                message: `Please input pixel format!`,
              },
            ]}
          >
            <Select>{formatOptions}</Select>
          </Form.Item>
          <Form.Item
            label="Source"
            name="source"
            rules={[
              {
                required: true,
                message: "Please input pixel source!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Add Source" />
          </Form.Item>

          <Form.Item className="text-right">
            <Button type="primary" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
function mapStateToProps(state) {
  return {
    isAddRetargetingPiggybackPixelModalOpen:
      state.retargetingPixel.isAddRetargetingPiggybackPixelModalOpen,
    retargetingPixelList: state.retargetingPixel.retargetingPixelList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeNewRetargetingPiggybackPixelModal: () =>
      dispatch(closeNewRetargetingPiggybackPixelModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddRetargetingPiggybackPixelModal
);
