import AxiosAuth from "../../services/axios-service";
import Config from "../../config";

const loadAllDisplayPrivateBuys = () => {
  return AxiosAuth.get(Config.API + "/api/displayPrivateBuys/getAllDisplayPrivateBuys", undefined, undefined);
};

const loadAllVideoPrivateBuys = () => {
  return AxiosAuth.get(Config.API + "/api/videoPrivateBuys/getAllVideoPrivateBuys", undefined, undefined);
};

const loadDisplayPrivateBuysByDisplayLineId = (displayLineId) => {
  return AxiosAuth.get(Config.API + "/api/displayPrivateBuys/getDisplayPrivateBuys/" + displayLineId, undefined, undefined);
};

const loadVideoPrivateBuysByVideoLineId = (videoLineId) => {
  return AxiosAuth.get(Config.API + "/api/videoPrivateBuys/getVideoPrivateBuys/" + videoLineId, undefined, undefined);
};

const addDisplayPrivateBuy = (displayLineId, data) => {
  return AxiosAuth.post(
    Config.API + "/api/displayPrivateBuys/addDisplayPrivateBuy/" + displayLineId,
    JSON.stringify(data),
    undefined
  );
};

const addVideoPrivateBuy = (videoLineId, data) => {
  return AxiosAuth.post(
    Config.API + "/api/videoPrivateBuys/addVideoPrivateBuy/" + videoLineId,
    JSON.stringify(data),
    undefined
  );
};

const updateDisplayPrivateBuy = (privateBuyId, data) => {
  return AxiosAuth.put(
    Config.API + "/api/displayPrivateBuys/editDisplayPrivateBuy/" + privateBuyId,
    JSON.stringify(data),
    undefined
  );
};

const updateVideoPrivateBuy = (privateBuyId, data) => {
  return AxiosAuth.put(
    Config.API + "/api/videoPrivateBuys/editVideoPrivateBuy/" + privateBuyId,
    JSON.stringify(data),
    undefined
  );
};

const loadDisplayPrivateBuyById = (privateBuyId) => {
  return AxiosAuth.get(Config.API + "/api/displayPrivateBuys/getDisplayPrivateBuyById/" + privateBuyId, undefined, undefined);
}

const loadVideoPrivateBuyById = (privateBuyId) => {
  return AxiosAuth.get(Config.API + "/api/videoPrivateBuys/getVideoPrivateBuyById/" + privateBuyId, undefined, undefined);
}

const loadDisplayPrivateBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/displayPrivateBuy", data, undefined);
}

const exportDisplayPrivateBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/displayPrivateBuy/export", data, undefined, true);
}

const exportVideoPrivateBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/videoPrivateBuy/export", data, undefined, true);
}

const loadVideoPrivateBuyReport = (data) => {
  return AxiosAuth.post(Config.API + "/api/reportingTab/videoPrivateBuy", data, undefined);
}

const loadPrivateBuyOverviewData = (privateBuyId, privateBuyType) => {
  return AxiosAuth.get(Config.API + "/api/overviewTab/privateBuy/" + privateBuyId + "/" + privateBuyType, undefined, undefined);
}

export default {
  loadAllDisplayPrivateBuys,
  loadAllVideoPrivateBuys,
  loadDisplayPrivateBuysByDisplayLineId,
  loadVideoPrivateBuysByVideoLineId,
  addDisplayPrivateBuy,
  addVideoPrivateBuy,
  loadDisplayPrivateBuyById,
  loadVideoPrivateBuyById,
  updateDisplayPrivateBuy,
  updateVideoPrivateBuy,
  loadDisplayPrivateBuyReport,
  loadVideoPrivateBuyReport,
  loadPrivateBuyOverviewData,
  exportDisplayPrivateBuyReport,
  exportVideoPrivateBuyReport
};
