import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Statistic } from "antd";
import { compose } from "redux";
import Chart from "react-apexcharts";

const gridStyle = {
    width: "25%",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
};

const SiteBuyOverview = ({ siteBuyType, siteBuyOverviewData }) => {
    const [uniqueEvents, setUniqueEvents] = useState([]);
    const [uniqueEventLabels, setUniqueEventLabels] = useState([]);
    const [pieSeriesData, setPieSeriesData] = useState([]);
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        let isMounted = true;

     
        if(isMounted ){

        if (siteBuyOverviewData) {
            if (siteBuyOverviewData.uniqueEvents) {
                setUniqueEvents(Object.keys(siteBuyOverviewData.uniqueEvents).map(item => siteBuyOverviewData.uniqueEvents[item]));
                setUniqueEventLabels(Object.keys(siteBuyOverviewData.uniqueEvents).map(item => item));
            }
            if (siteBuyOverviewData.devices) {
                setPieSeriesData(Object.keys(siteBuyOverviewData.devices).map(item => siteBuyOverviewData.devices[item]));
                setLabels(Object.keys(siteBuyOverviewData.devices).map(item => item));
            }
        }}

        return () => {
            isMounted = false;

            console.log("Site Buy Overview unmounting");
        };
    }, [siteBuyOverviewData]);

    const lineSeries = [{
        name: "impression.unique",
        data: uniqueEvents
    }]

    const lineOptions = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            }
        },
        markers: {
            size: 5,
            colors: '#007bff'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: 'straight',
            colors: '#007bff'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        xaxis: {
            categories: uniqueEventLabels
        }
    }

    const series = pieSeriesData;
    const options = {
        chart: {
            width: 650,
            type: 'pie',
        },
        labels: labels,
        title: {
            text: "Impressions By Device",
        }
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    {siteBuyOverviewData ?
                        <Card
                            title="Month to date"
                            actions={[
                                <h1 style={{ textAlign: "justify", color: "#6c6f73", fontStyle: "italic", paddingLeft: "4px" }}>{`Timezone : ${siteBuyOverviewData.timezone ? siteBuyOverviewData.timezone : "unknown"}`}</h1>
                            ]}
                        >
                            <Card.Grid hoverable={false} style={{ ...gridStyle, width: "60%" }}>
                                <Card.Grid hoverable={false} style={{ ...gridStyle, width: "50%" }}>
                                    <Statistic title="Impressions" value={siteBuyOverviewData.impressionUnique != null ? siteBuyOverviewData.impressionUnique : 0} />
                                </Card.Grid>
                                <Card.Grid hoverable={false} style={{ ...gridStyle, width: "50%" }}>
                                    <Statistic title="CTR" value={siteBuyOverviewData.ctr != null ? siteBuyOverviewData.ctr : 0} precision={1} suffix="%" />
                                </Card.Grid>
                                {siteBuyType && siteBuyType === "display" ?
                                    <Card.Grid hoverable={false} style={{ ...gridStyle, width: "100%" }}>
                                        <Statistic title="Clicks" value={siteBuyOverviewData.clickUnique != null ? siteBuyOverviewData.clickUnique : 0} />
                                    </Card.Grid>
                                    : <>
                                        <Card.Grid hoverable={false} style={{ ...gridStyle, width: "50%" }}>
                                            <Statistic title="Completed Views" value={siteBuyOverviewData.completeUnique != null ? siteBuyOverviewData.completeUnique : 0} />
                                        </Card.Grid>
                                        <Card.Grid hoverable={false} style={{ ...gridStyle, width: "50%" }}>
                                            <Statistic title="Completion Rate" value={siteBuyOverviewData.completionRate != null ? siteBuyOverviewData.completionRate : 0} precision={1} suffix="%" />
                                        </Card.Grid>
                                    </>
                                }
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ ...gridStyle, width: "40%" }}>
                                {siteBuyOverviewData.devices ?
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="pie"
                                        style={{ padding: 0, width: "300px", margin: "auto" }}
                                    />
                                    : <Statistic title="Impressions By Device" value={"No Data"} />
                                }
                            </Card.Grid>
                        </Card>
                        : ""}
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    {siteBuyOverviewData ?
                        <Card
                            title="Traffic"
                            actions={[
                                <h1 style={{ textAlign: "justify", color: "#6c6f73", fontStyle: "italic", paddingLeft: "4px" }}>{`Timezone : ${siteBuyOverviewData.timezone ? siteBuyOverviewData.timezone : "unknown"}`}</h1>
                            ]}
                        >
                            <Chart
                                options={lineOptions}
                                series={lineSeries}
                                type="line"
                                height={350}
                            />
                        </Card>
                        : ""}
                </Col>
            </Row>
        </>
    );
};

function mapStateToProps(state) {
    return {
        siteBuyOverviewData: state.siteBuy.siteBuyOverviewData,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteBuyOverview);
