import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import {
  List,
  Row,
  Col,
  Button,
  Switch,
  Modal,
  Image,
  Card,
} from "antd";
import {
  openUpdateCreativeModal,
  updateDisplayCreative,
} from "../../../redux/actions/creative-actions";
import { setImagePath, getLocalDate } from "../../../utils/appUtils";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";

const { confirm } = Modal;
const { Meta } = Card;

const EditDisplayCreativeDetail = ({ editImageCreative }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pauseValue, setPauseValue] = useState(false);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  useEffect(() => {
    if (editImageCreative && editImageCreative.imageCreativeVersion) {
      setPauseValue(
        editImageCreative.imageCreativeVersion.paused !== null
          ? editImageCreative.imageCreativeVersion.paused
          : false
      );
    }
  }, []);

  function _bindCreativeProps(propType, propValue) {
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>{propType}</h2>
            </div>
            <div className="card-body">
              {propType !== "Retargeting Pixel" ?
                <ul id={propType}>
                  <li className="no-list-style click-url">{propType && propValue}</li>
                </ul>
                : <ul>
                  <li className="no-list-style">
                    {editImageCreative.imageCreativeVersion.retargetingPixel ?
                      editImageCreative.imageCreativeVersion.retargetingPixel.name : ""}
                  </li></ul>}
              {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div className="card-footer">
                  <Button type="link" onClick={() => dispatch(openUpdateCreativeModal(propType))}>
                    Edit
                  </Button>
                  {propType === "Retargeting Pixel" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                  {propType === "Target State" ?
                    <Button onClick={() => removeValues(propType)}>
                      Remove
                    </Button>
                    : ""}
                </div>
              ) : (
                ""
              )}

            </div>
          </div>
        </Col>
      </Row>
    );
  }

  function removeValues(propType) {
    if (propType === "Target State") {
      dispatch(updateDisplayCreative(id, { "targetingState": "" }))
    } else if (propType === "Retargeting Pixel") {
      dispatch(updateDisplayCreative(id, { "retargetingPixelId": "" }))
    }
  }

  function showConfirm() {
    if (pauseValue === false) {
      confirm({
        title: "Are you sure you want to pause?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setPauseValue(true);
          dispatch(updateDisplayCreative(id, { "paused": true }))
        },
        onCancel() {
          setPauseValue(pauseValue);
        },
      });
    } else {
      setPauseValue(false);
      dispatch(updateDisplayCreative(id, { "paused": false }))
      return;
    }
  }

  function info() {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <div className="card-body">
          <Image
            preview={true}
            src={setImagePath(
              editImageCreative &&
              editImageCreative.imageCreativeVersion &&
              editImageCreative.imageCreativeVersion.imageResource
            )}
          />
          <div class="card-footer">
            <span>
              {setImagePath(
                editImageCreative &&
                editImageCreative.imageCreativeVersion &&
                editImageCreative.imageCreativeVersion.imageResource
              )}
            </span>
          </div>
        </div>
      ),
      onOk() { },
    });
  }
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="card-body grid-col overflow-v">
              <Card
                className="grid-items"
                style={{ padding: "10px" }}
                cover={
                  <Image
                    preview={false}
                    src={setImagePath(
                      editImageCreative &&
                      editImageCreative.imageCreativeVersion &&
                      editImageCreative.imageCreativeVersion.imageResource
                    )}
                    onClick={info}
                  />
                }
              >
                <Meta
                  description={
                    (editImageCreative &&
                      editImageCreative.imageCreativeVersion.imageResource.width) +
                    "x" +
                    (editImageCreative &&
                      editImageCreative.imageCreativeVersion.imageResource.height)
                  }
                />
              </Card>
            </div>
          </div>
        </Col>
      </Row>

      {editImageCreative &&
        _bindCreativeProps(
          "Flight",
          moment
            .unix(getLocalDate(editImageCreative.imageCreativeVersion.startAt) / 1000)
            .format("DD MMM, YYYY HH:mm:ss") +
          " - " +
          moment.unix(getLocalDate(editImageCreative.imageCreativeVersion.endAt) / 1000).format("DD MMM, YYYY HH:mm:ss")
        )}
      {editImageCreative &&
        _bindCreativeProps("Weight", editImageCreative.imageCreativeVersion.weight)}
      {editImageCreative &&
        _bindCreativeProps(
          "Click URL",
          editImageCreative.imageCreativeVersion.clickUrl !== null && editImageCreative.imageCreativeVersion.clickUrl !== ""
            ? editImageCreative.imageCreativeVersion.clickUrl
            : "N/A"
        )}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Impression Pixels"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button
                    type="link"
                    onClick={() => dispatch(openUpdateCreativeModal("Impression Pixels"))}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editImageCreative &&
                editImageCreative.imageCreativeVersion.displayImpressionPixels !== null
                ? JSON.parse(editImageCreative.imageCreativeVersion.displayImpressionPixels)
                : []
            }
            renderItem={(pixel) => <List.Item>{pixel.format + " : " + pixel.source}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
                <div>
                  <Button type="link" onClick={() => dispatch(openUpdateCreativeModal("Devices"))}>
                    Edit
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editImageCreative &&
                editImageCreative.imageCreativeVersion.devices &&
                editImageCreative.imageCreativeVersion.devices !== null
                ? JSON.parse(editImageCreative.imageCreativeVersion.devices)
                : []
            }
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canUpdateCreative"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="white-box">
              <div className="title">
                <h2>Paused</h2>
              </div>
              <div className="card-body">
                <ul id="paused">
                  <li className="no-list-style">
                    <Switch
                      key={pauseValue}
                      value={pauseValue}
                      defaultChecked={pauseValue}
                      onClick={showConfirm}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}

      {editImageCreative &&
        _bindCreativeProps("Retargeting Pixel",
          editImageCreative.imageCreativeVersion.retargetingPixelId ||
            editImageCreative.imageCreativeVersion.retargetingPixelId != null ?
            editImageCreative.imageCreativeVersion.retargetingPixel : "N/A")}
      {editImageCreative &&
        _bindCreativeProps("Target State",
          editImageCreative.imageCreativeVersion.targetingState !== null ||
            editImageCreative.imageCreativeVersion.targetingState !== "" ?
            editImageCreative.imageCreativeVersion.targetingState : "N/A")}

    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditDisplayCreativeDetail);
