import { Types } from "./../constants/creative-types";
import creativeApi from "../api/creative-api";

// To open add new Creative Modal
export const openAddCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CREATIVE_MODAL,
    payload: true,
  });
};

// To close add new Creative Modal
export const closeAddCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CREATIVE_MODAL,
    payload: false,
  });
};

// To open update Creative Modal
export const openUpdateCreativeModal = (propType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_CREATIVE_MODAL,
    payload: { isEditCreativeModalOpen: true, propType: propType },
  });
};

// To close update Creative Modal
export const closeUpdateCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_CREATIVE_MODAL,
    payload: { isEditCreativeModalOpen: false, propType: undefined },
  });
};

// To upload image resource
export const uploadImageResource = (file, accountId, displayLineId) => async (dispatch) => {
  try {
    await creativeApi.uploadImageResource(file, accountId, displayLineId).then(
      (data) => {
        console.log(data);
        if (data && data.message) {
          dispatch({
            type: Types.UPLOAD_IMAGE_RESOURCE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPLOAD_IMAGE_RESOURCE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// To upload video resource
export const uploadVideoResource = (file, accountId) => async (dispatch) => {
  try {
    await creativeApi.uploadVideoResource(file, accountId).then(
      (data) => {
        console.log(data);
        if (data && data.message) {
          dispatch({
            type: Types.UPLOAD_VIDEO_RESOURCE,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPLOAD_VIDEO_RESOURCE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// get all Image Resources by AccountId
export const loadAllImageResourcesByAccountId = (accountId) => async (dispatch) => {
  try {
    await creativeApi.loadAllImageResourcesByAccountId(accountId).then((data) => {
      dispatch({
        type: Types.GET_ALL_IMAGE_RESOURCES_BY_ACCOUNT_ID,
        payload: data,
      });
    });
  } catch (err) {}
};

// Add new Image Creative
export const addImageCreative = (imageCreativeData) => async (dispatch) => {
  try {
    console.log(imageCreativeData);
    await creativeApi.addImageCreative(imageCreativeData).then(
      (data) => {
        if (data && data.message && data.message === "ImageCreative added successfully!")
          dispatch({
            type: Types.ADD_IMAGE_CREATIVE,
            payload: {
              success: true, message: data.message ,
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_IMAGE_CREATIVE,
            payload: {
              success: false, message: error.response.data.message ,
            },
          });
        }
      }
    );
  } catch (err) {}
};

// To open add new Video Creative Modal
export const openAddVideoCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_VIDEO_CREATIVE_MODAL,
    payload: true,
  });
};

// To close add new Video Creative Modal
export const closeAddVideoCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_VIDEO_CREATIVE_MODAL,
    payload: false,
  });
};

// get all Video Resources by AccountId
export const loadAllVideoResourcesByAccountId = (accountId) => async (dispatch) => {
  try {
    await creativeApi.loadAllVideoResourcesByAccountId(accountId).then((data) => {
      dispatch({
        type: Types.GET_ALL_VIDEO_RESOURCES_BY_ACCOUNT_ID,
        payload: data,
      });
    });
  } catch (err) {}
};

// load Image Creative by id
export const loadImageCreativeById = (creativeId) => async (dispatch) => {
  try {
    await creativeApi.loadImageCreativeById(creativeId).then((data) => {
      dispatch({
        type: Types.GET_EDIT_IMAGE_CREATIVE,
        payload: data,
      });
    });
  } catch (err) {}
};

// Add new Video Creative
export const addVideoCreative = (videoCreativeData) => async (dispatch) => {
  try {
    await creativeApi.addVideoCreative(videoCreativeData).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "VideoCreative added successfully!")
          dispatch({
            type: Types.ADD_VIDEO_CREATIVE,
            payload: {
              videoCreativeAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_VIDEO_CREATIVE,
            payload: {
              videoCreativeAdded: { success: false, message: error.response.data.message },
            },
          });
        }
      }
    );
  } catch (err) {}
};

// Add new Truengage Creative
export const addTruengageCreative = (truengageCreativeData) => async (dispatch) => {
  try {
    await creativeApi.addTruengageCreative(truengageCreativeData).then(
      (data) => {
        if (data && data.message && data.message === "TruengageCreative added successfully!")
          dispatch({
            type: Types.ADD_TRUENGAGE_CREATIVE,
            payload: {
              truengageCreativeAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_TRUENGAGE_CREATIVE,
            payload: {
              truengageCreativeAdded: { success: false, message: error.response.data.message },
            },
          });
        }
      }
    );
  } catch (err) {}
};

// Update display Creative
export const updateDisplayCreative = (creativeId, data) => async (dispatch) => {
  try {
    await creativeApi.updateDisplayCreative(creativeId, data).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "ImageCreative updated successfully!")
          dispatch({
            type: Types.UPDATE_DISPLAY_CREATIVE,
            payload: { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_DISPLAY_CREATIVE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// Add new Tag Display Creative
export const addTagDisplayCreative = (creativeData) => async (dispatch) => {
  try {
    await creativeApi.addTagDisplayCreative(creativeData).then(
      (data) => {
        if (data && data.message && data.message === "TagDisplayCreative added successfully!")
          dispatch({
            type: Types.ADD_TAG_DISPLAY_CREATIVE,
            payload: {
              success: true, message: data.message,
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_TAG_DISPLAY_CREATIVE,
            payload: {
              success: false, message: error.response.data.message,
            },
          });
        }
      }
    );
  } catch (err) {}
};

// Add new Tag Video Creative
export const addTagVideoCreative = (creativeData) => async (dispatch) => {
  try {
    await creativeApi.addTagVideoCreative(creativeData).then(
      (data) => {
        if (data && data.message && data.message === "TagVideoCreative added successfully!")
          dispatch({
            type: Types.ADD_TAG_VIDEO_CREATIVE,
            payload: {
              tagVideoCreativeAdded: { success: true, message: data.message },
            },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_TAG_VIDEO_CREATIVE,
            payload: {
              tagVideoCreativeAdded: { success: false, message: error.response.data.message },
            },
          });
        }
      }
    );
  } catch (err) {}
};

// load Tag Display Creative by id
export const loadTagDisplayCreativeById = (creativeId) => async (dispatch) => {
  try {
    await creativeApi.loadTagDisplayCreativeById(creativeId).then((data) => {
      dispatch({
        type: Types.GET_EDIT_TAG_DISPLAY_CREATIVE,
        payload: data,
      });
    });
  } catch (err) {}
};

// To open update Tag Display Creative Modal
export const openUpdateTagDisplayCreativeModal = (propType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TAG_DISPLAY_CREATIVE_MODAL,
    payload: { isEditTagDisplayCreativeModalOpen: true, propType: propType },
  });
};

// To close update Tag Display Creative Modal
export const closeUpdateTagDisplayCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TAG_DISPLAY_CREATIVE_MODAL,
    payload: { isEditTagDisplayCreativeModalOpen: false, propType: undefined },
  });
};

// Update tag display Creative
export const updateTagDisplayCreative = (creativeId, data) => async (dispatch) => {
  try {
    await creativeApi.updateTagDisplayCreative(creativeId, data).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "TagDisplayCreative updated successfully!")
          dispatch({
            type: Types.UPDATE_TAG_DISPLAY_CREATIVE,
            payload: { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_TAG_DISPLAY_CREATIVE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// load Video Creative by id
export const loadVideoCreativeById = (creativeId) => async (dispatch) => {
  try {
    await creativeApi.loadVideoCreativeById(creativeId).then((data) => {
      dispatch({
        type: Types.GET_EDIT_VIDEO_CREATIVE,
        payload: data,
      });
    });
  } catch (err) {}
};

// load Truengage Creative by id
export const loadTruengageCreativeById = (creativeId) => async (dispatch) => {
  try {
    await creativeApi.loadTruengageCreativeById(creativeId).then((data) => {
      dispatch({
        type: Types.GET_EDIT_TRUENGAGE_CREATIVE,
        payload: data,
      });
    });
  } catch (err) {}
};

// To update Video Creative Modal
export const openUpdateVideoCreativeModal = (propType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_VIDEO_CREATIVE_MODAL,
    payload: {isEditVideoCreativeModalOpen: true, propType: propType},
  });
};

// To update Video Creative Modal
export const closeUpdateVideoCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_VIDEO_CREATIVE_MODAL,
    payload: { isEditVideoCreativeModalOpen: false, propType: undefined },
  });
};

// To update Truengage Creative Modal
export const openUpdateTruengageCreativeModal = (propType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TRUENGAGE_CREATIVE_MODAL,
    payload: { isEditTruengageCreativeModalOpen: true, propType: propType },
  });
};

// To update Truengage Creative Modal
export const closeUpdateTruengageCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TRUENGAGE_CREATIVE_MODAL,
    payload: { isEditTruengageCreativeModalOpen: false, propType: undefined },
  });
};

// Update video Creative
export const updateVideoCreative = (creativeId, data) => async (dispatch) => {
  try {
    await creativeApi.updateVideoCreative(creativeId, data).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "VideoCreative updated successfully!")
          dispatch({
            type: Types.UPDATE_VIDEO_CREATIVE,
            payload: { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_VIDEO_CREATIVE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// Update truengage Creative
export const updateTruengageCreative = (creativeId, data) => async (dispatch) => {
  try {
    await creativeApi.updateTruengageCreative(creativeId, data).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "TruengageCreative updated successfully!")
          dispatch({
            type: Types.UPDATE_TRUENGAGE_CREATIVE,
            payload: { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_TRUENGAGE_CREATIVE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// load Tag-Video Creative by id
export const loadTagVideoCreativeById = (creativeId) => async (dispatch) => {
  try {
    await creativeApi.loadTagVideoCreativeById(creativeId).then((data) => {
      dispatch({
        type: Types.GET_EDIT_TAG_VIDEO_CREATIVE,
        payload: data,
      });
    });
  } catch (err) {}
};

// Update tag-video Creative
export const updateTagVideoCreative = (creativeId, data) => async (dispatch) => {
  try {
    await creativeApi.updateTagVideoCreative(creativeId, data).then(
      (data) => {
        console.log(data);
        if (data && data.message && data.message === "TagVideoCreative updated successfully!")
          dispatch({
            type: Types.UPDATE_TAG_VIDEO_CREATIVE,
            payload: { success: true, message: data.message },
          });
      },
      (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_TAG_VIDEO_CREATIVE,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// To update Tag-video Creative Modal
export const openUpdateTagVideoCreativeModal = (propType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TAG_VIDEO_CREATIVE_MODAL,
    payload: { isEditTagVideoCreativeModalOpen: true, propType: propType },
  });
};

// To update Tag-video Creative Modal
export const closeUpdateTagVideoCreativeModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_EDIT_TAG_VIDEO_CREATIVE_MODAL,
    payload: { isEditTagVideoCreativeModalOpen: false, propType: undefined },
  });
};

// To clear states from redux store
export const clearEditCreativeReduxState = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_EDIT_CREATIVE_STATES,
    payload: undefined,
  });
};
