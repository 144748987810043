import { Types } from "../constants/header-types";
const initialState = {
  searchResult: [],
};
export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    default:
      return state;
  }
}
