import { Types } from "./../constants/campaign-types";
import camapaignApi from "../api/campaign-api";

// Load all campaigns by accountId
export const loadAllCampaignsByAccountId = (accountId) => async (dispatch) => {
  try {
    await camapaignApi.loadAllCampaignsByAccountId(accountId).then(
      (data) => {
        dispatch(setCamapignList(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// Add new campaign
export const addCampaign = (accountId, campaignData) => async (dispatch) => {
  try {
    await camapaignApi.addCampaign(accountId, campaignData).then(
      (data) => {
        if (data && data.message && data.message === "Campaign added successfully!") {
          dispatch({
            type: Types.ADD_CAMPAIGN,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.ADD_CAMPAIGN,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

// Update existing campaign
export const updateCampaign = (campaignId, campaignData) => async (dispatch) => {
  try {
    await camapaignApi.updateCampaign(campaignId, campaignData).then(
      (data) => {
        if (data && data.message && data.message === "Campaign updated successfully!") {
          dispatch({
            type: Types.UPDATE_CAMPAIGN,
            payload: { success: true, message: data.message },
          });
        }
      },
      (error) => {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          dispatch({
            type: Types.UPDATE_CAMPAIGN,
            payload: { success: false, message: error.response.data.message },
          });
        }
      }
    );
  } catch (err) {}
};

export const loadCampaignById = (id) => async (dispatch) => {
  try {
    await camapaignApi.loadCampaignById(id).then(
      (data) => {
        dispatch(setEditCampaign(data));
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// To open add new Campaign Modal
export const openNewCampaignModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_MODAL,
    payload: true,
  });
};

// To close add new Campaign Modal
export const closeNewCampaignModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_MODAL,
    payload: false,
  });
};

export const openBlacklistWhitelistPublisherModal = (type, publisherList) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_PUBLISHER_MODAL,
    payload: {
      status: true,
      publisherType: type,
      selectedPublisherList: publisherList,
    },
  });
};

export const closeBlacklistWhitelistPublisherModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_PUBLISHER_MODAL,
    payload: {
      status: false,
      publisherType: undefined,
    },
  });
};

export const openBlacklistWhitelistPrivateBuyModal = (
  privateBuyType,
  updateType,
  selectedPrivateBuyList
) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_PRIVATE_BUY_MODAL,
    payload: {
      status: true,
      privateBuyType: privateBuyType,
      updateType: updateType,
      selectedPrivateBuyList: selectedPrivateBuyList,
    },
  });
};

export const closeBlacklistWhitelistPrivateBuyModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_PRIVATE_BUY_MODAL,
    payload: {
      status: false,
      privateBuyType: undefined,
      updateType: undefined,
    },
  });
};

export const openBlacklistWhitelistSiteBuyModal = (
  siteBuyType,
  updateType,
  selectedSiteBuyList
) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_SITE_BUY_MODAL,
    payload: {
      status: true,
      siteBuyType: siteBuyType,
      updateType: updateType,
      selectedSiteBuyList: selectedSiteBuyList,
    },
  });
};

export const closeBlacklistWhitelistSiteBuyModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_BLACKLIST_WHITELIST_SITE_BUY_MODAL,
    payload: {
      status: false,
      siteBuyType: undefined,
      updateType: undefined,
    },
  });
};

const setCamapignList = (campaigns) => {
  return {
    type: Types.GET_CAMPAIGN_LIST,
    payload: campaigns,
  };
};

const setEditCampaign = (campaign) => {
  return {
    type: Types.EDIT_CAMPAIGN,
    payload: campaign,
  };
};

// To open add new Campaign Category Modal
export const openAddCampaignCategoryModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_CATEGORY_MODAL,
    payload: true,
  });
};

// To close add new Campaign Category Modal
export const closeAddCampaignCategoryModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_CATEGORY_MODAL,
    payload: false,
  });
};

export const loadAllCampaignCategories = () => async (dispatch) => {
  try {
    await camapaignApi.loadAllCampaignCategories().then(
      (data) => {
        dispatch({
          type: Types.GET_ALL_CAMPAIGN_CATEGORIES,
          payload: data,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  } catch (err) {}
};

// To open add Campaign Pixel Modal
export const openAddCampaignPixelModal = (selectedPixelList, pixelList, pixelType) => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_PIXEL_MODAL,
    payload: {
      isAddCampaignPixelModalOpen: true, 
      selectedPixelList: selectedPixelList,
      pixelList: pixelList,
      pixelType: pixelType,
    }
  });
};

// To close add Campaign Pixel Modal
export const closeAddCampaignPixelModal = () => async (dispatch) => {
  dispatch({
    type: Types.OPEN_ADD_CAMPAIGN_PIXEL_MODAL,
    payload: {
      isAddCampaignPixelModalOpen: false, 
      selectedPixelList: undefined,
      pixelList: undefined,
      pixelType: undefined,
    }
  });
};

// To clear states from redux store
export const clearEditCampaignStates = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_EDIT_CAMPAIGN_STATES,
    payload: undefined,
  });
};
