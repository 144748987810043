import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import {
    Form,
    Space,
    DatePicker,
    Table,
    Collapse,
    Drawer,
    Button,
    Spin,
    Card,
    Col,
    Row,
} from "antd";
import moment from "moment";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
    FilterFilled,
    CloseCircleTwoTone,
    CheckCircleTwoTone,
    DownloadOutlined,
} from "@ant-design/icons";
import { getUTCDate } from "../../utils/appUtils";
import { loadForecastingData, exportForecastingData } from "../../redux/actions/forecasting-actions";

const { Panel } = Collapse;
const { MonthPicker } = DatePicker;
const gridStyle = {
    border: "none",
    boxShadow: "none",
};


var columns = [
    {
        title: "Account",
        dataIndex: "account",
        key: "account",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Campaign",
        dataIndex: "campaign",
        key: "campaign",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    }, {
        title: "Line",
        dataIndex: "line",
        key: "line",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Media",
        dataIndex: "media",
        key: "media",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Rate Type",
        dataIndex: "rateType",
        key: "rateType",
    },
    {
        title: "Total Units",
        dataIndex: "totalUnits",
        key: "totalUnits",
        render: (text) =>
            text !== null
                ? text
                : "-"
    },
    {
        title: "Month Units",
        dataIndex: "monthUnits",
        key: "monthUnits",
        render: (text) =>
            text !== null
                ? text
                : "-"
    },
    {
        title: "Month Revenue",
        dataIndex: "monthRevenue",
        key: "monthRevenue",
        render: (text) =>
            text !== null
                ? text
                : "-"
    },
    {
        title: "Flight",
        dataIndex: "flight",
        key: "flight",
        width: 200,
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Billing Server",
        dataIndex: "billingServer",
        key: "billingServer",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Billing Profile",
        dataIndex: "billingProfile",
        key: "billingProfile",
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Geo",
        dataIndex: "geo",
        key: "geo",
        width: 250,
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "DMA",
        dataIndex: "dma",
        key: "dma",
        width: 250,
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Devices",
        dataIndex: "devices",
        key: "devices",
        width: 250,
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    },
    {
        title: "Ad sizes",
        dataIndex: "adSizes",
        key: "adSizes",
        width: 250,
        render: (text) =>
            text && text !== null
                ? text.includes("-")
                    ? text.replace("-", "").replace("-", "")
                    : text
                : "-"
    }
];

const ForecastingDashboard = ({ getForecastingData, isNew }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [updateUI, setUpdateUI] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selectedAccountValues, setSelectedAccountValues] = useState([]);
    const [selectedCampaignValues, setSelectedCampaignValues] = useState([]);
    const [selectedLineValues, setSelectedLineValues] = useState([]);
    const [selectedMediaValues, setSelectedMediaValues] = useState([]);
    const [dashboardData, setdashboardData] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSet, setIsSet] = useState(true);
    const [dated, setDated] = useState(moment(new Date(getUTCDate(new Date().getTime()))).format("YYYY-MM"));

    useEffect(() => {
        let isMounted = true;

        (async () => {
            setSubmitted(true);
            await dispatch(loadForecastingData(dated, true));
            if (isMounted) {

                if (getForecastingData && getForecastingData["dashboardList"]) {
                    setdashboardData(getForecastingData ? getForecastingData : []);
                    loadFilters();
                }
                setSubmitted(false);
            }
        })();
        return () => {
            isMounted = false;

            console.log("Unmounting Forecasting Dashboard");
        };
    }, [isNew]);

    useEffect(() => {
        handleFilters();
        return () => {
        };
    }, [isSet]);



    function loadFilters() {
        loadAccountFilters();
        loadCampaignFilters();
        loadLineFilters();
        loadMediaFilters();
    }

    function loadAccountFilters() {
        const selectAccountValuesTemp = [];
        if (
            getForecastingData &&
            getForecastingData["accounts"]
        ) {
            Object.keys(getForecastingData["accounts"]).filter(function (obj) {
                selectAccountValuesTemp.push(obj);
            });
            setSelectedAccountValues(selectAccountValuesTemp);
            setUpdateUI(!updateUI);
        }
        return selectAccountValuesTemp;
    }

    function loadCampaignFilters() {
        const selectCampaignValuesTemp = [];
        if (
            getForecastingData &&
            getForecastingData["campaigns"]
        ) {
            Object.keys(getForecastingData["campaigns"]).filter(function (obj) {
                selectCampaignValuesTemp.push(obj);
            });
            setSelectedCampaignValues(selectCampaignValuesTemp);
        }
    }

    function loadLineFilters() {
        const selectLineValuesTemp = [];
        if (
            getForecastingData &&
            getForecastingData["lines"]
        ) {
            Object.keys(getForecastingData["lines"]).filter(function (obj) {
                selectLineValuesTemp.push(obj);
            });
            setSelectedLineValues(selectLineValuesTemp);
        }
    }

    function loadMediaFilters() {
        const selectMediaValuesTemp = [];
        if (
            getForecastingData &&
            getForecastingData["medias"] &&
            getForecastingData["medias"].length > 0
        ) {
            getForecastingData["medias"].filter(function (obj) {
                selectMediaValuesTemp.push(obj);
            });
            setSelectedMediaValues(selectMediaValuesTemp);
        }
    }

    async function loadData() {
        setSubmitted(true);
        await dispatch(loadForecastingData(dated, false));
        setdashboardData(getForecastingData["dashboardList"]);
        setSubmitted(false);
    }

    const onMonthChange = (value, dateString) => {
        var dated = moment(dateString).format("YYYY-MM");
        setDated(dated);
        loadData();
    };

    const showDrawer = () => {
        setVisible(true);
    };

    async function downloadFile() {
        setSubmitted(true);
        var response = await dispatch(exportForecastingData(dated));
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        a.download = "ForecastingDataExport.xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
        setSubmitted(false);
    }

    const onClose = () => {
        setVisible(false);
    };

    const onReset = () => {
        loadFilters();
        setdashboardData(getForecastingData);
    };

    const isAccountSelected = (id) => {
        var filtered = selectedAccountValues.filter(function (obj) {
            return id === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    const isCampaignSelected = (id) => {
        var filtered = selectedCampaignValues.filter(function (obj) {
            return id === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    const isLineSelected = (id) => {
        var filtered = selectedLineValues.filter(function (obj) {
            return id === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    const isMediaSelected = (item) => {
        var filtered = selectedMediaValues.filter(function (obj) {
            return item === obj;
        });
        return filtered.length > 0 ? true : false;
    };

    const onAccountChange = (e) => {
        const selectAccountValuesTemp = selectedAccountValues;
        if (selectAccountValuesTemp.indexOf(e.target.value) > -1) {
            selectAccountValuesTemp.splice(
                selectAccountValuesTemp.indexOf(e.target.value),
                1
            );
        } else {
            selectAccountValuesTemp.push(e.target.value);
        }
        setSelectedAccountValues(selectAccountValuesTemp);
        handleFilters();
    };

    const onCampaignChange = (e) => {
        const selectCampaignValuesTemp = selectedCampaignValues;
        if (selectCampaignValuesTemp.indexOf(e.target.value) > -1) {
            selectCampaignValuesTemp.splice(
                selectCampaignValuesTemp.indexOf(e.target.value),
                1
            );
        } else {
            selectCampaignValuesTemp.push(e.target.value);
        }
        setSelectedCampaignValues(selectCampaignValuesTemp);
        handleFilters();
    };

    const onLineChange = (e) => {
        const selectLineValuesTemp = selectedLineValues;
        if (selectLineValuesTemp.indexOf(e.target.value) > -1) {
            selectLineValuesTemp.splice(
                selectLineValuesTemp.indexOf(e.target.value),
                1
            );
        } else {
            selectLineValuesTemp.push(e.target.value);
        }
        setSelectedLineValues(selectLineValuesTemp);
        handleFilters();
    };

    const onMediaChange = (e) => {
        const selectMediaValuesTemp = selectedMediaValues;
        if (selectMediaValuesTemp.indexOf(e.target.value) > -1) {
            selectMediaValuesTemp.splice(
                selectMediaValuesTemp.indexOf(e.target.value),
                1
            );
        } else {
            selectMediaValuesTemp.push(e.target.value);
        }
        setSelectedMediaValues(selectMediaValuesTemp);
        handleFilters();
    };

    function handleFilters() {
        var selectAccounts = [];
        var selectCampaigns = [];
        var selectLines = [];
        var selectMedia = [];
        const selectCampaignValuesTemp = selectedCampaignValues;
        const selectAccountValuesTemp = selectedAccountValues;
        const selectLineValuesTemp = selectedLineValues;
        const selectMediaValuesTemp = selectedMediaValues;

        if (
            dashboardData &&
            dashboardData["dashboardList"] &&
            dashboardData["accounts"] &&
            dashboardData["campaigns"] &&
            dashboardData["lines"] &&
            dashboardData["medias"]
        ) {
            Object.keys(dashboardData["accounts"]).filter(function
                (accountId) {
                selectAccountValuesTemp &&
                    selectAccountValuesTemp.length > 0 &&
                    selectAccountValuesTemp.map((selectedValue) =>
                        selectedValue === accountId
                            ? selectAccounts.push(dashboardData["accounts"][accountId])
                            : ""
                    )
            });
            Object.keys(dashboardData["campaigns"]).filter(function
                (campaignId) {
                selectCampaignValuesTemp &&
                    selectCampaignValuesTemp.length > 0 &&
                    selectCampaignValuesTemp.map((selectedValue) =>
                        selectedValue === campaignId
                            ? selectCampaigns.push(dashboardData["campaigns"][campaignId])
                            : ""
                    )
            });
            Object.keys(dashboardData["lines"]).filter(function
                (lineId) {
                selectLineValuesTemp &&
                    selectLineValuesTemp.length > 0 &&
                    selectLineValuesTemp.map((selectedValue) =>
                        selectedValue === lineId ? selectLines.push(dashboardData["lines"][lineId]) : ""
                    )
            });
            dashboardData["medias"].map(
                (media) =>
                    selectMediaValuesTemp &&
                    selectMediaValuesTemp.length > 0 &&
                    selectMediaValuesTemp.map((selectedValue) =>
                        selectedValue === media ? selectMedia.push(media) : ""
                    )
            );

            var tempForecastingData = [];
            getForecastingData["dashboardList"].map((data) =>
                selectCampaigns.includes(data.campaign) &&
                    selectAccounts.includes(data.account) &&
                    selectLines.includes(data.line) &&
                    selectMedia.includes(data.media)
                    ? tempForecastingData.push(data)
                    : ""
            );
            var tempDashboardData = {};
            tempDashboardData["dashboardList"] = tempForecastingData;
            tempDashboardData["accounts"] = dashboardData["accounts"];
            tempDashboardData["campaigns"] = dashboardData["campaigns"];
            tempDashboardData["lines"] = dashboardData["lines"];
            tempDashboardData["medias"] = dashboardData["medias"];
            setdashboardData(tempDashboardData);
            setUpdateUI(!updateUI);
        }
    }

    const onCheck = (type) => {
        if (type === "account") {
            loadAccountFilters();
        } else if (type === "campaign") {
            loadCampaignFilters();
        } else if (type === "line") {
            loadLineFilters();
        } else if (type === "media") {
            loadMediaFilters();
        }
        setIsSet(!isSet);
        handleFilters();
    };

    const onUncheck = (type) => {
        if (type === "account") {
            setSelectedAccountValues([]);
        } else if (type === "campaign") {
            setSelectedCampaignValues([]);
        } else if (type === "line") {
            setSelectedLineValues([]);
        } else if (type === "media") {
            setSelectedMediaValues([]);
        }
        setIsSet(!isSet);
        handleFilters();
    };

    return (
        <div>
            <Spin size={"large"} spinning={submitted}></Spin>
            <Card>
                <Form form={form} layout={"vertical"}>
                    <Row className="account-form new_sectionadd">
                        <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                            <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                                <Form.Item name="month" initialValue={moment(new Date(getUTCDate(new Date().getTime())), 'MMM yyyy')}>
                                    <MonthPicker
                                        format={'MMMM yyyy'} onChange={onMonthChange} />
                                </Form.Item>
                            </Card.Grid>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                            <div className="upload-right account-form-filter">
                                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                                    <FilterFilled onClick={showDrawer} />
                                </Card.Grid>
                                <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                                    <DownloadOutlined onClick={downloadFile} />
                                </Card.Grid>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Drawer
                title={
                    <>
                        <Space size={80}>
                            <span>Filters</span>
                            <Button type="primary" onClick={onReset}>
                                RESET
                            </Button>
                        </Space>
                    </>
                }
                placement={"right"}
                width={300}
                onClose={onClose}
                visible={visible}
            >
                <Collapse>
                    <Panel header="Account" key="1">
                        <Space size={10}>
                            <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                onClick={() => onCheck("account")}
                            />
                            <CloseCircleTwoTone
                                twoToneColor="#eb2f96"
                                onClick={() => onUncheck("account")}
                            />
                        </Space>
                        {getForecastingData &&
                            Object.keys(getForecastingData["accounts"]).map((accountId) => (
                                <ul key={accountId}>
                                    <Checkbox
                                        onChange={onAccountChange}
                                        checked={isAccountSelected(accountId)}
                                        defaultChecked={isAccountSelected(accountId)}
                                        value={accountId}
                                    >
                                        {getForecastingData["accounts"][accountId]}
                                    </Checkbox>
                                </ul>
                            ))}
                    </Panel>
                    <Panel header="Campaign" key="2">
                        <Space size={10}>
                            <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                onClick={() => onCheck("campaign")}
                            />
                            <CloseCircleTwoTone
                                twoToneColor="#eb2f96"
                                onClick={() => onUncheck("campaign")}
                            />
                        </Space>
                        {getForecastingData &&
                            Object.keys(getForecastingData["campaigns"]).map((campaignId) => (
                                <ul key={campaignId}>                                    <Checkbox
                                    onChange={onCampaignChange}
                                    checked={isCampaignSelected(campaignId)}
                                    value={campaignId}
                                >
                                    {getForecastingData["campaigns"][campaignId]}
                                </Checkbox>
                                </ul>
                            ))}
                    </Panel>
                    <Panel header="Line" key="3">
                        <Space size={10}>
                            <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                onClick={() => onCheck("line")}
                            />
                            <CloseCircleTwoTone
                                twoToneColor="#eb2f96"
                                onClick={() => onUncheck("line")}
                            />
                        </Space>
                        {getForecastingData &&
                            Object.keys(getForecastingData["lines"]).map((lineId) => (
                                <ul key={lineId}>                                      <Checkbox
                                    onChange={onLineChange}
                                    checked={isLineSelected(lineId)}
                                    value={lineId}
                                >
                                    {getForecastingData["lines"][lineId]}
                                </Checkbox>
                                </ul>
                            ))}
                    </Panel>
                    <Panel header="Media" key="4">
                        <Space size={10}>
                            <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                onClick={() => onCheck("media")}
                            />
                            <CloseCircleTwoTone
                                twoToneColor="#eb2f96"
                                onClick={() => onUncheck("media")}
                            />
                        </Space>
                        {getForecastingData &&
                            getForecastingData["medias"].map((item) => (
                                <ul key={item}>
                                    <Checkbox
                                        onChange={onMediaChange}
                                        checked={isMediaSelected(item)}
                                        value={item}
                                    >
                                        {item}
                                    </Checkbox>
                                </ul>
                            ))}
                    </Panel>

                </Collapse>
            </Drawer>
            <Card>
                <Table
                    rowKey={record => JSON.stringify(record)}
                    scroll={{ x: 1200 }}
                    columns={columns}
                    dataSource={dashboardData && dashboardData["dashboardList"] ? dashboardData["dashboardList"] : []}
                />
            </Card>

        </div>
    );
};

function mapStateToProps(state) {
    return {
        getForecastingData: state.forecasting.getForecastingData,
        isNew: state.forecasting.isNew
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    ForecastingDashboard
);
