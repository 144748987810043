import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openAddVideoCreativeModal } from "../../../redux/actions/creative-actions";
import { openAddPrivateBuyModal } from "../../../redux/actions/private-buy-actions";
import { openExportReportModal } from "../../../redux/actions/export-report-actions";
import {
  openUpdateDeviceModal,
  openUpdateGeoNameModal,
  openUpdateExelateSegmentsModal,
  openUpdateDmaRegionModal,
  openNewLineModal,
  openUpdateDeliveryModal,
  openUpdateSiteListModal,
  openUpdateClickUrlModal,
} from "../../../redux/actions/line-actions";
import {
  Divider,
  Row,
  Col,
  Button,
  Card,
  Typography,
  Switch,
  List,
  Collapse,
  Space,
} from "antd";
import AddLineModal from "../../modals/line/add-line";
import EditDelivery from "../../modals/line/edit-delivery";
import { compose } from "redux";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { testVideoAdUrl } from "../../../constants/defaultValues";
import UserService from "./../../../services/user-service";
import { currentUserPermissionMap } from "./../../../constants/permissions";
import UsaDmaMap from "../../../pages/map/usaDmaMap";
import { getLocalDate } from "../../../utils/appUtils";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const options = {
  chart: {
    height: 100,
    type: "rangeBar",
  },
  plotOptions: {
    bar: {
      horizontal: true,
      distributed: true,
      dataLabels: {
        hideOverflowingLabels: false,
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val, opts) {
      var label = opts.w.globals.labels[opts.dataPointIndex];
      return label;
    },
    style: {
      colors: ["#f3f4f5", "#fff"],
    },
  },
  xaxis: {
    type: "datetime",
  },
  yaxis: {
    show: false,
  },
  grid: {
    row: {
      colors: ["#f3f4f5", "#fff"],
      opacity: 1,
    },
  },
};

const VideoLineDetails = ({ editVideoLine, videoBuys, videoSiteBuyList }) => {
  const dispatch = useDispatch();
  const [seriesData, setSeriesData] = useState([]);
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0]
      ? currentUser["roles"][0]
      : "";
  const [testPageUrl, setTestPageUrl] = useState("");
  const [creativesList, setCreativesList] = useState([]);
  const [testAdUrl, setTestAdUrl] = useState("");

  useEffect(() => {
    const data = [];
    const series = [];
    var url = testVideoAdUrl;
    let creatives = [];

    if (videoSiteBuyList && videoSiteBuyList.length > 0) {
      var siteBuyId = "";
      for (var i = 0; i < videoSiteBuyList.length; i++) {
        var siteBuy = videoSiteBuyList[i];
        if (siteBuy && siteBuy.videoSiteBuyId) {
          if (siteBuy.publisher && siteBuy.publisher.blacklistedAccountIds && siteBuy.publisher.blacklistedAccountIds.length > 0) {
            if (!siteBuy.publisher.blacklistedAccountIds.includes(editVideoLine && editVideoLine.accountId)) {
              siteBuyId = siteBuy.videoSiteBuyId;
              break;
            }
          } else {
            siteBuyId = siteBuy.videoSiteBuyId;
            break;
          }
        }
      }
      url = url.concat("?siteBuyId=" + siteBuyId);
      setTestAdUrl(url);
      // for (var i = 0; i < videoSiteBuyList.length; i++) {
      //   if (videoSiteBuyList[i] && videoSiteBuyList[i].videoSiteBuyId&& videoSiteBuyList[i].publisher && videoSiteBuyList[i].publisher.publisherId !== '01ed3597ec3814d4ad208d1e5ebf7201' && videoSiteBuyList[i].publisher.name !== 'Pk4Media') {
      //     url = url.concat("?siteBuyId=" + videoSiteBuyList[i].videoSiteBuyId);
      //     setTestAdUrl(url);
      //     break;
      //   }
      // }
    } else {
      url = url + "?siteBuyId=1";
    }

    if (editVideoLine && editVideoLine.videoCreatives) {
      editVideoLine.videoCreatives.map((creative, index) => {
        var obj = {};
        obj["x"] = creative.videoCreativeVersion.name;
        var arrYaxis = [];
        arrYaxis.push(creative.videoCreativeVersion.startAt);
        arrYaxis.push(creative.videoCreativeVersion.endAt);
        obj["y"] = arrYaxis;
        obj["fillColor"] = "#008FFB"; //generateColorCode("#FF45", 2);
        data.push(obj);

        url = url.concat(
          "&placement=" +
          creative.videoLineId +
          ";" +
          creative.videoCreativeId +
          ";" +
          creative.videoCreativeVersion.name
        );

        creatives.push({
          id: creative.videoCreativeId,
          name:
            creative &&
            creative.videoCreativeVersion &&
            creative.videoCreativeVersion.name,
          type: "Video Creatives",
        });
      });
      editVideoLine && editVideoLine.tagVideoCreatives && editVideoLine.tagVideoCreatives !== null && editVideoLine.tagVideoCreatives.map((creative, index) => {
        var obj = {};
        obj["x"] = creative.tagVideoCreativeVersion.name;
        var arrYaxis = [];
        arrYaxis.push(creative.tagVideoCreativeVersion.startAt);
        arrYaxis.push(creative.tagVideoCreativeVersion.endAt);
        obj["y"] = arrYaxis;
        obj["fillColor"] = "#008FFB"; //generateColorCode("#008F", 2);
        data.push(obj);

        url = url.concat(
          "&placement=" +
          creative.videoLineId +
          ";" +
          creative.tagVideoCreativeId +
          ";" +
          creative.tagVideoCreativeVersion.name
        );

        creatives.push({
          id: creative.tagVideoCreativeId,
          name:
            creative &&
            creative.tagVideoCreativeVersion &&
            creative.tagVideoCreativeVersion.name,
          type: "Tag Video Creatives",
        });
      });
      editVideoLine && editVideoLine.truengageCreatives && editVideoLine.truengageCreatives.map((creative, index) => {
        var obj = {};
        obj["x"] = creative.truengageCreativeVersion.name;
        var arrYaxis = [];
        arrYaxis.push(creative.truengageCreativeVersion.startAt);
        arrYaxis.push(creative.truengageCreativeVersion.endAt);
        obj["y"] = arrYaxis;
        obj["fillColor"] = "#008FFB"; //generateColorCode("#008F", 2);
        data.push(obj);

        url = url.concat(
          "&placement=" +
          creative.videoLineId +
          ";" +
          creative.truengageCreativeId +
          ";" +
          creative.truengageCreativeVersion.name
        );

        // creatives.push({ id: creative.truengageCreativeId, name: creative && creative.truengageCreativeVersion && creative.truengageCreativeVersion.name, type: "Truengage Creatives" })
      });
      series.push({ data: data });
      setSeriesData(series);
      setTestPageUrl(url);

      setCreativesList(creatives);
    }
    return () => {
      console.log("LineDetails Unmounting");
    };
  }, [videoBuys, editVideoLine, videoSiteBuyList]);

  function _bindVideoCreative(videoCreatives) {
    return videoCreatives.map((videoCreative, index) => {
      return (
        <li key={"videoCreative" + index} className="check-box">
          <Link
            to={`/creatives/video/video-creative/${videoCreative.videoCreativeId}`}
          >
            <span>
              {videoCreative &&
                videoCreative.videoCreativeVersion &&
                videoCreative.videoCreativeVersion.name}{" "}
            </span>
            <span>
              {videoCreative &&
                videoCreative.videoCreativeVersion &&
                moment
                  .unix(
                    getLocalDate(videoCreative.videoCreativeVersion.startAt) /
                    1000
                  )
                  .format("DD MMM YYYY") +
                " - " +
                moment
                  .unix(
                    getLocalDate(videoCreative.videoCreativeVersion.endAt) /
                    1000
                  )
                  .format("DD MMM YYYY")}
                  {videoCreative.videoCreativeVersion.paused != undefined && videoCreative.videoCreativeVersion.paused == true ? " - PAUSED" : ""}
            </span>
          </Link>
          {currentUserPermissionMap[currentUserRole]["canTestAd"] ? (
            <a
            rel="noopener noreferrer"
              target="_blank"
              href={`${testAdUrl}&placement=${videoCreative.videoLineId};${videoCreative.videoCreativeId};${videoCreative.videoCreativeVersion.name};`}
            >
              <Button type="link">TEST AD</Button>
            </a>
          ) : (
            ""
          )}
        </li>
      );
    });
  }

  function _bindTruengageCreative(truengageCreatives) {
    return truengageCreatives.map((truengageCreative, index) => {
      return (
        <li key={"videoCreative" + index} className="check-box">
          <Link
            to={`/creatives/video/truengage-creative/${truengageCreative.truengageCreativeId}`}
          >
            <span>
              {truengageCreative &&
                truengageCreative.truengageCreativeVersion &&
                truengageCreative.truengageCreativeVersion.name}
            </span>
            <span>
              {truengageCreative &&
                truengageCreative.truengageCreativeVersion &&
                moment
                  .unix(
                    getLocalDate(
                      truengageCreative.truengageCreativeVersion.startAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY") +
                " - " +
                moment
                  .unix(
                    getLocalDate(
                      truengageCreative.truengageCreativeVersion.endAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY")}
                  {truengageCreative.truengageCreativeVersion.paused != undefined && truengageCreative.truengageCreativeVersion.paused == true ? " - PAUSED" : ""}
            </span>
          </Link>
          <a
          rel="noopener noreferrer"
            target="_blank"
            href={`${testAdUrl}&placement=${truengageCreative.videoLineId};${truengageCreative.truengageCreativeId};${truengageCreative.truengageCreativeVersion.name};`}
          >
            <Button type="link">TEST AD</Button>
          </a>
        </li>
      );
    });
  }

  function _bindTagVideoCreative(tagVideoCreatives) {
    return tagVideoCreatives.map((tagVideoCreative, index) => {
      return (
        <li key={"tagVideoCreative" + index} className="check-box">
          <Link
            to={`/creatives/video/tag-video-creative/${tagVideoCreative.tagVideoCreativeId}`}
          >
            <span>
              {tagVideoCreative &&
                tagVideoCreative.tagVideoCreativeVersion &&
                tagVideoCreative.tagVideoCreativeVersion.name}
            </span>
            <span>
              {tagVideoCreative &&
                tagVideoCreative.tagVideoCreativeVersion &&
                moment
                  .unix(
                    getLocalDate(
                      tagVideoCreative.tagVideoCreativeVersion.startAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY") +
                " - " +
                moment
                  .unix(
                    getLocalDate(
                      tagVideoCreative.tagVideoCreativeVersion.endAt
                    ) / 1000
                  )
                  .format("DD MMM YYYY")}
                  {tagVideoCreative.tagVideoCreativeVersion.paused != undefined && tagVideoCreative.tagVideoCreativeVersion.paused == true ? " - PAUSED" : ""}
            </span>
          </Link>
          <a
          rel="noopener noreferrer"
            target="_blank"
            href={`${testAdUrl}&placement=${tagVideoCreative.videoLineId};${tagVideoCreative.tagVideoCreativeId};${tagVideoCreative.tagVideoCreativeVersion.name};`}
          >
            <Button type="link">TEST AD</Button>
          </a>
        </li>
      );
    });
  }

  return (
    <>
      {editVideoLine && editVideoLine.videoLineVersion ? (
        <>
          <Row>
            <Space size={8} style={{ paddingBottom: "10px" }}>
              <Button
                className="btn-style"
                type="primary"
                onClick={() => dispatch(openExportReportModal())}
              >
                Export Report
              </Button>

              {currentUserPermissionMap[currentUserRole]["canTestAd"]
                ? creativesList &&
                creativesList.length > 0 && (
                  <a target="_blank"
                  rel="noopener noreferrer"
                    href={testPageUrl}>
                    <Button type="btn-style">Test Page</Button>
                  </a>
                )
                : ""}
            </Space>
          </Row>

          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole][
              "canUpdateLineBilling"
            ] ? (
              <Col span={24}>
                <Card title="Billing">
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {"$" +
                        editVideoLine.videoLineVersion.rate +
                        " " +
                        editVideoLine.videoLineVersion.rateType}
                    </Title>
                    <Text type="secondary">Rate</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.videoLineVersion.billingServer
                        ? editVideoLine.videoLineVersion.billingServer
                        : "XPS"}
                    </Title>
                    <Text type="secondary">Billing Server</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.videoLineVersion.billingProfile
                        ? editVideoLine.videoLineVersion.billingProfile
                        : "Standard"}
                    </Title>
                    <Text type="secondary">Billing Profile</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {moment
                        .unix(
                          getLocalDate(editVideoLine.videoLineVersion.startAt) /
                          1000
                        )
                        .format("DD MMM YYYY, HH:mm") +
                        " - " +
                        moment
                          .unix(
                            getLocalDate(editVideoLine.videoLineVersion.endAt) /
                            1000
                          )
                          .format("DD MMM YYYY, HH:mm")}
                    </Title>
                    <Text type="secondary">Flight</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Collapse bordered={false}>
                      {editVideoLine.videoLineVersion.allocations &&
                        editVideoLine.videoLineVersion.allocations != null ? (
                        <Panel
                          header={
                            JSON.parse(
                              editVideoLine.videoLineVersion.allocations
                            ).length +
                            " Allocations $" +
                            editVideoLine.videoLineVersion.totalBudget
                          }
                          key="1"
                        >
                          {JSON.parse(
                            editVideoLine.videoLineVersion.allocations
                          ).map((allocation) => (
                            <Row gutter={16}>
                              {Object.keys(allocation).map((key) => (
                                <>
                                  <Col span={8}>
                                    <Title level={4}>
                                      {key
                                        ? moment(key).format("MMMM, YYYY") +
                                        " Budget"
                                        : ""}
                                    </Title>
                                    <span className="icon-dollar">
                                      <i className="fas fa-dollar-sign"></i>
                                    </span>
                                    <Text type="secondary">
                                      {allocation[key]["billing"]}
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Title level={4}>
                                      {"Additional Quantity"}
                                    </Title>
                                    <Text type="secondary">
                                      {allocation[key]["quantity"]}
                                    </Text>
                                  </Col>
                                  <Col span={8}>
                                    <Title level={4}>{"Discrepancy(%)"}</Title>
                                    <Text type="secondary">
                                      {allocation[key]["discrepancy"]}
                                    </Text>
                                  </Col>
                                </>
                              ))}
                            </Row>
                          ))}
                        </Panel>
                      ) : (
                        ""
                      )}
                    </Collapse>
                  </Card.Grid>
                  <Divider />
                  <div style={{ margin: "20px" }}>
                    <Button
                      onClick={() => {
                        dispatch(openNewLineModal("Video"));
                      }}
                      type="link"
                    >
                      Edit
                    </Button>
                  </div>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row gutter={16}>
            {currentUserPermissionMap[currentUserRole][
              "canUpdateLineDelivery"
            ] ? (
              <Col span={24}>
                <Card title="Delivery">
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.campaign.padding
                        ? editVideoLine.campaign.padding
                        : 10}
                      %
                    </Title>
                    <Text type="secondary">Padding</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.videoLineVersion.clickWeight
                        ? editVideoLine.videoLineVersion.clickWeight
                        : 0}
                    </Title>
                    <Text type="secondary">Click Weight</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.videoLineVersion.deliveryCapType ===
                        "PERCENT"
                        ? editVideoLine.videoLineVersion
                          .percentDailyDeliveryCap *
                        100 +
                        "%"
                        : editVideoLine.videoLineVersion.deliveryCapType ===
                          "DISABLED"
                          ? "Disabled"
                          : editVideoLine.videoLineVersion.fixedDailyDeliveryCap}
                    </Title>
                    <Text type="secondary">Daily Cap</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {editVideoLine.videoLineVersion.frequencyCapLimit &&
                        editVideoLine.videoLineVersion.frequencyCapLimit > 0
                        ? editVideoLine.videoLineVersion.frequencyCapLimit +
                        " every " +
                        editVideoLine.videoLineVersion.frequencyCapDuration +
                        (editVideoLine.videoLineVersion.frequencyCapDuration >
                          1
                          ? " days"
                          : " day")
                        : "None"}
                    </Title>
                    <Text type="secondary">Frequency Cap</Text>
                  </Card.Grid>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Switch
                      disabled={true}
                      defaultChecked={editVideoLine.videoLineVersion.paused}
                      checked={editVideoLine.videoLineVersion.paused}
                    ></Switch>
                    {" Paused"}
                  </Card.Grid>
                  <Divider />
                  <div style={{ margin: "20px" }}>
                    <Button
                      onClick={() => {
                        dispatch(openUpdateDeliveryModal("Video"));
                      }}
                      type="link"
                    >
                      Edit
                    </Button>
                  </div>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </>
      ) : (
        ""
      )}

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box toolbar_remove">
            <div className="title">
              <h2>{"Creatives "}</h2>
            </div>
            {editVideoLine && seriesData && seriesData.length > 0 ? (
              <div id="chart">
                <ReactApexChart
                  options={options}
                  series={seriesData}
                  type="rangeBar"
                  height={50 * (editVideoLine.tagVideoCreatives.length + editVideoLine.videoCreatives.length + editVideoLine.tagVideoCreatives.length) + "px"}
                  barheight={"50%"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="card-cnt-sec">
              <div className="card-body">
                <Collapse bordered={false}>
                  <Panel header="Details" key="2">
                    <div className="card-body">
                      <ul id="videoCreative">
                        {editVideoLine && editVideoLine.videoCreatives
                          ? _bindVideoCreative(editVideoLine.videoCreatives)
                          : ""}
                      </ul>
                      <ul id="truengageCreative">
                        {editVideoLine && editVideoLine.truengageCreatives
                          ? _bindTruengageCreative(
                            editVideoLine.truengageCreatives
                          )
                          : ""}
                      </ul>
                      <ul id="tagVideoCreative">
                        {editVideoLine && editVideoLine.tagVideoCreatives
                          ? _bindTagVideoCreative(
                            editVideoLine.tagVideoCreatives
                          )
                          : ""}
                      </ul>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
            {currentUserPermissionMap[currentUserRole][
              "canCreateLineCreative"
            ] ? (
              <div className="card-footer">
                <Button
                  onClick={() => dispatch(openAddVideoCreativeModal())}
                  type="link"
                >
                  New Creative
                </Button>
                <Button
                  type="link"
                  onClick={() =>
                    dispatch(openUpdateClickUrlModal(creativesList))
                  }
                >
                  Set Click Url
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Devices"}
            footer={
              currentUserPermissionMap[currentUserRole][
                "canUpdateLineDevices"
              ] ? (
                <div>
                  <Button
                    onClick={() =>
                      dispatch(
                        openUpdateDeviceModal(
                          editVideoLine &&
                            editVideoLine.videoLineVersion &&
                            editVideoLine.videoLineVersion.devices &&
                            editVideoLine.videoLineVersion.devices != null
                            ? JSON.parse(editVideoLine.videoLineVersion.devices)
                            : []
                        )
                      )
                    }
                    type="link"
                  >
                    {"Edit"}
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editVideoLine &&
                editVideoLine.videoLineVersion &&
                editVideoLine.videoLineVersion.devices &&
                editVideoLine.videoLineVersion.devices != null
                ? JSON.parse(editVideoLine.videoLineVersion.devices)
                : []
            }
            renderItem={(device) => <List.Item>{device}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>Site List</h2>
            </div>
            <div className="card-body">
              <ul id="siteList">
                {editVideoLine &&
                  editVideoLine.videoLineVersion &&
                  editVideoLine.videoLineVersion.siteList &&
                  editVideoLine.videoLineVersion.siteList != null &&
                  editVideoLine.videoLineVersion.siteListId &&
                  editVideoLine.videoLineVersion.siteListId != null ? (
                  <Link
                    to={`/site-list/${editVideoLine.videoLineVersion.siteListId}`}
                  >
                    <li>{editVideoLine.videoLineVersion.siteList.name}</li>
                  </Link>
                ) : (
                  ""
                )}
              </ul>
              <div className="card-footer">
                {currentUserPermissionMap[currentUserRole][
                  "canUpdateLineSiteList"
                ] ? (
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch(
                        openUpdateSiteListModal(
                          editVideoLine &&
                          editVideoLine.videoLineVersion &&
                          editVideoLine.videoLineVersion.siteList &&
                          editVideoLine.videoLineVersion.siteList,
                          editVideoLine &&
                            editVideoLine.campaign &&
                            editVideoLine.campaign.siteLists &&
                            editVideoLine.campaign.siteLists != null
                            ? editVideoLine.campaign.siteLists
                            : []
                        )
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <List
            header={"Geo - Country/US States"}
            footer={
              currentUserPermissionMap[currentUserRole][
                "canUpdateLineGeoTargeting"
              ] ? (
                <div>
                  <Button
                    onClick={() =>
                      dispatch(
                        openUpdateGeoNameModal(
                          editVideoLine &&
                            editVideoLine.videoLineVersion &&
                            editVideoLine.videoLineVersion.geoNames &&
                            editVideoLine.videoLineVersion.geoNames != null
                            ? JSON.parse(
                              editVideoLine.videoLineVersion.geoNames
                            )
                            : []
                        )
                      )
                    }
                    type="link"
                  >
                    {"Edit"}
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            bordered
            dataSource={
              editVideoLine &&
                editVideoLine.videoLineVersion &&
                editVideoLine.videoLineVersion.geoNames &&
                editVideoLine.videoLineVersion.geoNames != null
                ? JSON.parse(editVideoLine.videoLineVersion.geoNames)
                : []
            }
            renderItem={(geoName) => <List.Item>{geoName.name}</List.Item>}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="white-box">
            <div className="title">
              <h2>DMA Targeting</h2>
            </div>
            <div className="card-body">
              <ul id="dmaTargeting">
                {editVideoLine &&
                  editVideoLine.videoLineVersion &&
                  editVideoLine.videoLineVersion.dmaRegions &&
                  editVideoLine.videoLineVersion.dmaRegions != null &&
                  JSON.parse(editVideoLine.videoLineVersion.dmaRegions).map(
                    function (item) {
                      return <li>{item.name}</li>;
                    }
                  )}
              </ul>
              <div className="fusioncenter">
                <UsaDmaMap
                  selectedDmaRegions={
                    editVideoLine &&
                    editVideoLine.videoLineVersion &&
                    editVideoLine.videoLineVersion.dmaRegions
                  }
                />
              </div>
              <div className="card-footer">
                {currentUserPermissionMap[currentUserRole][
                  "canUpdateLineDmaTargeting"
                ] ? (
                  <Button
                    type="link"
                    onClick={() =>
                      dispatch(
                        openUpdateDmaRegionModal(
                          editVideoLine &&
                            editVideoLine.videoLineVersion &&
                            editVideoLine.videoLineVersion.dmaRegions &&
                            editVideoLine.videoLineVersion.dmaRegions != null
                            ? JSON.parse(
                              editVideoLine.videoLineVersion.dmaRegions
                            )
                            : []
                        )
                      )
                    }
                  >
                    Edit
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {currentUserPermissionMap[currentUserRole]["canAccessExelateSegments"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              header={"Exelate segments"}
              footer={
                currentUserPermissionMap[currentUserRole][
                  "canUpdateLineExelateSegments"
                ] ? (
                  <div>
                    <Button
                      onClick={() =>
                        dispatch(
                          openUpdateExelateSegmentsModal(
                            editVideoLine &&
                              editVideoLine.videoLineVersion &&
                              editVideoLine.videoLineVersion.exelateSegments &&
                              editVideoLine.videoLineVersion.exelateSegments !=
                              null
                              ? JSON.parse(
                                editVideoLine.videoLineVersion.exelateSegments
                              )
                              : []
                          )
                        )
                      }
                      type="link"
                    >
                      {"Edit"}
                    </Button>
                  </div>
                ) : (
                  ""
                )
              }
              bordered
              dataSource={
                editVideoLine &&
                  editVideoLine.videoLineVersion &&
                  editVideoLine.videoLineVersion.exelateSegments &&
                  editVideoLine.videoLineVersion.exelateSegments != null
                  ? JSON.parse(editVideoLine.videoLineVersion.exelateSegments)
                  : []
              }
              renderItem={(exelateSegment) => (
                <List.Item>
                  {exelateSegment.exelateSegmentId +
                    " - " +
                    exelateSegment.name}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      {currentUserPermissionMap[currentUserRole]["canCreateLinePrivateBuy"] ? (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <List
              header={"Private Buys"}
              footer={
                <div>
                  <Button
                    onClick={() => dispatch(openAddPrivateBuyModal())}
                    type="link"
                  >
                    {"New Private Buy"}
                  </Button>
                </div>
              }
              bordered
              dataSource={videoBuys && videoBuys}
              renderItem={(privateBuy) => (
                <Link to={`/privateBuys/video/${privateBuy.videoPrivateBuyId}`}>
                  <List.Item>{privateBuy.name}</List.Item>
                </Link>
              )}
            />
          </Col>
        </Row>
      ) : (
        ""
      )}

      <AddLineModal editLineInfo={editVideoLine} />
      <EditDelivery editLineInfo={editVideoLine} />
    </>
  );
};
function mapStateToProps(state) {
  return {
    videoBuys: state.privateBuy.videoBuys,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  VideoLineDetails
);
