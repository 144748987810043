import React from "react";
import { connect } from "react-redux";
import { openEditSiteBuyModal } from "../../redux/actions/site-buy-actions";
import { useDispatch } from "react-redux";
import {
  Divider,
  Row,
  Col,
  Button,
  Card,
  Typography,
} from "antd";
import { compose } from "redux";
import EditSiteBuyModal from "../../components/modals/site-buy/edit-site-buy";
import { currentUserPermissionMap } from "./../../constants/permissions";
import UserService from "./../../services/user-service";

const { Text, Title } = Typography;

const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};

const SiteBuyDetails = ({ siteBuy, siteBuyType }) => {
  const dispatch = useDispatch();
  const currentUser = UserService.getCurrentUser();
  const currentUserRole =
    currentUser && currentUser["roles"] && currentUser["roles"][0] ? currentUser["roles"][0] : "";

  return (
    <>
      {siteBuy && (
        <Row gutter={16}>
          <Col span={24}>
            <Card title="Basic Information">
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Title level={4}>{siteBuy.name}</Title>
                <Text type="secondary">Name</Text>
              </Card.Grid>
              <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                <Title level={4}>
                  ${siteBuy.rate} {siteBuy.rateType}
                </Title>
                <Text type="secondary">Rate</Text>
              </Card.Grid>
              <Divider />
              <div style={{ margin: "20px" }}>
              {currentUserPermissionMap[currentUserRole]["canUpdateSiteBuyInfo"] ? (
                <Button onClick={() => dispatch(openEditSiteBuyModal(siteBuyType))} type="link">
                  Edit
                </Button>
              ):(
                ""
              )}
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <EditSiteBuyModal siteBuy={siteBuy} siteBuyType={siteBuyType} />
    </>
  );
};
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    openEditSiteBuyModal: () => dispatch(openEditSiteBuyModal()),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SiteBuyDetails);
