import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Typography } from "antd";
import { compose } from "redux";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";

const { Text, Title } = Typography;
const gridStyle = {
  width: "100%",
  textAlign: "left",
  border: "none",
  boxShadow: "none",
};
const DisplayLineHistory = ({ displayHistory }) => {
  const itemsPerPage = 20;
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);
  const [remainingRecords, setRemainingRecords] = useState(0);

  useEffect(() => {

  }, []);

  const loadMore = () => {
    if (records >= displayHistory.length) {
      sethasMoreItems(false);
    } else {
      setTimeout(() => {
        remainingRecords >= itemsPerPage ? setRecords(records + itemsPerPage)
          : setRecords(records + remainingRecords);
      }, 2000);
      setRemainingRecords(displayHistory.length - records);
    }
  };

  const showItems = displayHistory => {
    var items = [];
    for (var i = 0; i < records; i++) {
      if (displayHistory && displayHistory[i]) {
        items.push(
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Card>
                  <Card.Grid hoverable={false} style={{ ...gridStyle }}>
                    <Title level={4}>
                      {displayHistory[i].updatedBy} on {moment
                        .unix(displayHistory[i].updatedAt / 1000)
                        .format("MMMM Do YYYY, LT")}
                    </Title>
                    {displayHistory[i].changedContent.map(content =>
                      <Text type="secondary">
                        <br />
                        &#123;<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;{content.propertyName} &nbsp; :<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: 'red' }}>{content.previousValue}</span>
                        : <span style={{ color: 'green' }}>{content.currentValue}</span>
                        <br />&#125;,
                      </Text>
                    )}
                  </Card.Grid>
                </Card>
              </Col>
            </Row>
          </>)
      }
    }
    return items;
  }

  return (
    <>
      <Card title="History">
        <div style={{
          height: "520px",
          overflow: "auto",
          padding: "15px"
        }}>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMoreItems}
            loader={<div className="loader"> Loading... </div>}
            useWindow={false}
          >
            {showItems(displayHistory)}
          </InfiniteScroll></div>
      </Card>
    </>
  );
};
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(DisplayLineHistory);
